import styles from "./AddNewMaterialModal.module.css";
import React, { useState, useContext } from "react";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import classNames from "classnames";
import {
  MaterialClassificationList,
  serverNameForClassification,
} from "types/MaterialClassification";
import { createArrayWithNumbers } from "lib/Arrays";
import { IMaterialPost } from "types/Material";
import { addNewMaterial } from "api/PostMaterial";
import { useMutation, queryCache } from "react-query";
import {
  MeasurementUnitList,
  displayNameForUnitOfMeasure,
} from "types/MeasurementUnit";
import { ModalContext } from "contexts/ModalContext";
import Toast from "components/Toast/Toast";
import { MaterialType } from "types/MaterialType";

interface INewMaterials {
  materials: IMaterialPost[];
}

const removeEmpty = (materials: IMaterialPost[]) => {
  return materials.filter((material) => material.name !== "");
};

export const AddNewMaterialModal: React.FC = () => {
  const { register, handleSubmit, control, getValues, errors } =
    useForm<INewMaterials>();

  const modalProps = useContext(ModalContext);
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isProceeding, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [mutate] = useMutation(
    (materials: IMaterialPost[]) => {
      return addNewMaterial(materials);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          queryCache.invalidateQueries("materials");
          modalProps.hideModal(true, "Material(s) added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const materials = removeEmpty(data.materials);
    if (materials.length === 0) {
      setToastMessage("Atleast one material is required!");
      setShowToast(true);
      setSubmitting(false);
      return;
    }

    mutate(materials);
  };

  const isValid = (value: string, field: string, fieldPrefix: string) => {
    if (value) {
      return true;
    }

    const name = getValues(`${fieldPrefix}.name`);
    const unitOfMeasure = getValues(`${fieldPrefix}.unitOfMeasure`);
    const classification = getValues(`${fieldPrefix}.classification`);

    switch (field) {
      case "name":
        return !(unitOfMeasure || classification);
      case "unitOfMeasure":
        return !(name || classification);
      case "classification":
        return !(name || unitOfMeasure);
      default:
        throw new Error("Unimplemented case " + field);
    }
  };

  const [size, setSize] = useState(5);
  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add New Material</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isProceeding}
      showDatePicker={false}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      <div className={styles.modalBody}>
        {createArrayWithNumbers(size).map((index: number) => {
          const fieldName = `materials[${index}]`;
          return (
            <div className={styles.inputOptions} key={index}>
              <div className={styles.rowNumber}>
                {(index + 1).toString() + "."}
              </div>
              <TextField
                InputProps={{
                  className: classNames(styles.materialName),
                }}
                error={
                  errors.materials &&
                  errors.materials[index]?.name !== undefined
                }
                autoFocus={index === 0}
                type={"text"}
                label="Ingredient Name"
                variant="outlined"
                name={`${fieldName}.name`}
                inputRef={register({
                  validate: (value) => isValid(value, "name", fieldName),
                })}
              />

              <Controller
                control={control}
                name={`${fieldName}.unitOfMeasure`}
                rules={{
                  validate: (value) =>
                    isValid(value, "unitOfMeasure", fieldName),
                }}
                render={({ onChange }) => (
                  <>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: classNames(styles.input, styles.materialUnit),
                      }}
                    >
                      <InputLabel
                        id="unit-of-measure-label"
                        style={{
                          color:
                            errors.materials &&
                            errors.materials[index]?.unitOfMeasure !== undefined
                              ? "#f44336"
                              : "",
                        }}
                      >
                        Unit of Measure
                      </InputLabel>
                      <Select
                        labelId="unit-of-measure-label"
                        label="Unit of Measure"
                        error={
                          errors.materials &&
                          errors.materials[index]?.unitOfMeasure !== undefined
                        }
                        value={getValues(`${fieldName}.unitOfMeasure`) ?? ""}
                        onChange={(event) => {
                          onChange(event.target.value as string);
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {MeasurementUnitList.map((measureUnit, index) => (
                          <MenuItem value={measureUnit} key={index}>
                            {displayNameForUnitOfMeasure(measureUnit)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
              />

              <Controller
                control={control}
                name={`${fieldName}.classification`}
                rules={{
                  validate: (value) =>
                    isValid(value, "classification", fieldName),
                }}
                render={({ onChange }) => (
                  <>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      classes={{
                        root: classNames(styles.input, styles.materialUnit),
                      }}
                    >
                      <InputLabel
                        id="type-label"
                        style={{
                          color:
                            errors.materials &&
                            errors.materials[index]?.classification !==
                              undefined
                              ? "#f44336"
                              : "",
                        }}
                      >
                        Type
                      </InputLabel>
                      <Select
                        labelId="type-label"
                        error={
                          errors.materials &&
                          errors.materials[index]?.classification !== undefined
                        }
                        label="Type"
                        value={getValues(`${fieldName}.classification`) ?? ""}
                        onChange={(event) => {
                          onChange(event.target.value as string);
                        }}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {MaterialClassificationList.map(
                          (classification, index) => (
                            <MenuItem
                              value={serverNameForClassification(
                                classification
                              )}
                              key={index}
                            >
                              {classification}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </>
                )}
              />

              <input
                type="hidden"
                name={`${fieldName}.type`}
                ref={register}
                value={MaterialType.RAW}
              />
            </div>
          );
        })}

        <button
          type="button"
          className={styles.addMore}
          onClick={() => setSize(size + 1)}
        >
          Add More
        </button>
      </div>
    </ModalPopup>
  );
};

import {
  TableRow,
  TableCell,
  Chip,
  CircularProgress,
  IconButton,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import styles from "./ComplimentaryFoodTable.module.css";
import classNames from "classnames";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IComplimentaryFoodView } from "types/ComplimentaryFood";
import { IBranch } from "types/Branch";
import { Status } from "types/Status";
import { DatePickersForTableHeaders } from "components/datePickersForTableHeader/DatePickersForTableHeader";
import { approveComplimentaryFood } from "api/PostComplimentaryFood";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { CommentBox } from "components/commentBox/CommentBox";
import { invalidateComplimentaryFoodCache } from "hooks/ComplimentaryFoods";
import { VariableSizeList } from "react-window";
import { WindowScroller } from "react-virtualized";
import { IMaterial } from "types/Material";
import { ErrorBox } from "components/errorBox/ErrorBox";
import moment from "moment";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import CommentIcon from "assets/CommentIcon";

interface IComplimentaryFoodTableHeaders {
  title: string;
  width: number;
}

interface IComplimentaryFoodTableHelper {
  selectedBranch: IBranch;
  data: IComplimentaryFoodView[];
  tableClass: string;
  isLoading: boolean;
  selectedDate: Date;
  selectedDateFrom: Date | null;
  selectedDateTo: Date | null;
  handleDateChangeFrom: (date: MaterialUiPickersDate) => void;
  handleDateChangeTo: (date: MaterialUiPickersDate) => void;
  handleDateChange: (date: MaterialUiPickersDate) => void;
  handleShowPendingButtonClicked: (showPending: boolean) => void;
}

interface IPendingStatusView {
  complimentaryFood: IComplimentaryFoodView;
  openApprove: (complimentaryFoodId: string) => void;
}

export const PendingStatusView: React.FC<IPendingStatusView> = (props) => {
  const { complimentaryFood, openApprove } = props;
  const { status, employeeName, date, comment } = complimentaryFood;
  const statusPending = status === Status.PENDING;
  const [showComment, setShowComment] = useState(false);
  return (
    <>
      <Chip
        label={complimentaryFood.status}
        className={statusPending ? styles.pink : styles.green}
        onClick={() => {
          if (statusPending) {
            openApprove(complimentaryFood.id);
          }
        }}
      />
      <IconButton color="inherit" onClick={() => setShowComment(!showComment)}>
        <CommentIcon />
      </IconButton>
      {showComment && (
        <CommentBox
          employeeName={employeeName}
          comment={comment}
          date={date}
          onClose={() => setShowComment(false)}
        />
      )}
    </>
  );
};

export const ComplimentaryFoodTableHelper: React.FC<IComplimentaryFoodTableHelper> = (
  props
) => {
  const {
    data,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
    handleShowPendingButtonClicked,
  } = props;
  const [insufficientMaterials, setInsufficientMaterials] = useState<
    IMaterial[] | undefined
  >(undefined);
  const defaultHeaders: IComplimentaryFoodTableHeaders[] = [
    {
      title: "Item",
      width: 290,
    },
    {
      title: "Quantity",
      width: 158,
    },
    {
      title: "Branch",
      width: 178,
    },
    {
      title: "Status",
      width: 174,
    },
  ];

  const headersWithDate: IComplimentaryFoodTableHeaders[] = [
    {
      title: "Item",
      width: 246,
    },
    {
      title: "Quantity",
      width: 120,
    },
    {
      title: "Branch",
      width: 130,
    },
    {
      title: "Date",
      width: 130,
    },
    {
      title: "Status",
      width: 152,
    },
  ];

  const [headers, setHeaders] = useState(defaultHeaders);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });
  const [showApprove, setShowApprove] = useState(false);
  const [foodIdUnderApproval, setFoodUnderApproval] = useState<string | null>(
    null
  );
  const openApprove = (complimentaryID: string) => {
    setShowApprove(true);
    setFoodUnderApproval(complimentaryID);
  };

  const [isApproving, setApproving] = useState(false);
  const onApprove = async (complimentaryId: string) => {
    setApproving(true);
    const error = await approveComplimentaryFood(complimentaryId);
    setApproving(false);
    if (error) {
      if (error.insufficientMaterials) {
        setInsufficientMaterials(error.insufficientMaterials);
      } else {
        const message = error.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      }
      return;
    }
    setShowApprove(false);
    setShowToast({
      open: true,
      message: "The item has been approved",
      type: "success",
    });

    if (showPendingClicked) {
      // this will refresh the pending stock list
      handleShowPendingButtonClicked(true);
    }

    invalidateComplimentaryFoodCache();
  };

  const listRef = React.useRef<VariableSizeList>(null);
  const onScroll = React.useCallback(({ scrollTop }) => {
    listRef.current?.scrollTo(scrollTop);
  }, []);

  const [showPendingClicked, setShowPendingClicked] = useState(false);
  const onClickShowPending = () => {
    const showPending = !showPendingClicked;
    setShowPendingClicked(showPending);
    if (showPending) {
      setHeaders(headersWithDate);
    } else {
      setHeaders(defaultHeaders);
    }
    handleShowPendingButtonClicked(showPending);
  };

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const complimentaryFood = data[index];
      return (
        <div style={style} key={index}>
          {complimentaryFood.foodItems.map((foodItem, innerIndex, array) => {
            const isLastRowForComplimentaryFood =
              innerIndex === array.length - 1;
            return (
              <div key={innerIndex}>
                <TableRow
                  className={classNames(
                    isLastRowForComplimentaryFood ? styles.lastrow : "",
                    styles.row
                  )}
                  key={innerIndex}
                >
                  <TableCell
                    className={classNames(styles.cell)}
                    style={{
                      maxWidth: headers[0].width,
                      minWidth: headers[0].width,
                    }}
                  >
                    {foodItem.materialName}
                  </TableCell>
                  <TableCell
                    className={classNames(styles.center, styles.cell)}
                    style={{
                      maxWidth: headers[1].width,
                      minWidth: headers[1].width,
                    }}
                  >
                    {foodItem.displayQuantity.quantity}{" "}
                    {displayNameForUnitOfMeasure(foodItem.displayQuantity.unit)}
                  </TableCell>

                  <TableCell
                    className={classNames(styles.center, styles.cell)}
                    style={{
                      maxWidth: headers[2].width,
                      minWidth: headers[2].width,
                    }}
                  >
                    {innerIndex === 0 && props.selectedBranch.name}
                  </TableCell>
                  {showPendingClicked && (
                    <>
                      <TableCell
                        className={classNames(styles.center, styles.cell)}
                        style={{
                          maxWidth: headers[2].width,
                          minWidth: headers[2].width,
                        }}
                      >
                        {innerIndex === 0 &&
                          moment(complimentaryFood.date).format("DD-MM-yyyy")}
                      </TableCell>
                      <TableCell
                        className={classNames(styles.center, styles.cell)}
                        style={{
                          maxWidth: headers[3].width,
                          minWidth: headers[3].width,
                        }}
                      >
                        {innerIndex === 0 && (
                          <PendingStatusView
                            complimentaryFood={complimentaryFood}
                            openApprove={openApprove}
                          />
                        )}
                      </TableCell>
                    </>
                  )}
                  {!showPendingClicked && (
                    <TableCell
                      className={classNames(styles.center, styles.cell)}
                      style={{
                        maxWidth: headers[2].width,
                        minWidth: headers[2].width,
                      }}
                    >
                      {innerIndex === 0 && (
                        <PendingStatusView
                          complimentaryFood={complimentaryFood}
                          openApprove={openApprove}
                        />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              </div>
            );
          })}
        </div>
      );
    },
    [data, headers, props.selectedBranch.name, showPendingClicked]
  );

  // Render the UI for your table
  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      {insufficientMaterials !== undefined && (
        <ErrorBox
          errorMaterialsArr={insufficientMaterials}
          open={true}
          onClose={() => {
            setInsufficientMaterials(undefined);
          }}
          selectedBranch={props.selectedBranch}
        />
      )}
      <div className={classNames(styles.container, props.tableClass)}>
        <div className={styles.tableHeader}>
          {!showPendingClicked && (
            <DatePickersForTableHeaders
              selectedDate={selectedDate!}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              handleDateChange={handleDateChange}
              handleDateChangeFrom={handleDateChangeFrom}
              handleDateChangeTo={handleDateChangeTo}
            />
          )}
          <Button
            classes={{
              root: styles.pendingButton,
            }}
            variant="contained"
            onClick={onClickShowPending}
          >
            {!showPendingClicked && "View Pending"}
            {showPendingClicked && "Back"}
          </Button>
        </div>
        {props.isLoading && (
          <div className={styles.loadingDiv}>
            <CircularProgress
              size={30}
              color={"inherit"}
              className={styles.loading}
            />
          </div>
        )}
        <WindowScroller onScroll={onScroll}>
          {() => (
            <div className={styles.table}>
              <div className={classNames(styles.lastrow, styles.headerBox)}>
                {headers.map((header, index) => (
                  <div
                    style={{ width: header.width }}
                    className={classNames(styles.cell, styles.header)}
                    key={index}
                  >
                    {header.title}
                  </div>
                ))}
              </div>
              <div>
                <VariableSizeList
                  ref={listRef}
                  className={styles.list}
                  height={window.innerHeight}
                  itemCount={data.length}
                  itemSize={(index: number) =>
                    (data[index].foodItems.length - 1) * 54 + 70
                  } /// line height is 54 for normal rows and 70 for the one with comment button
                  width={892}
                >
                  {RenderRow}
                </VariableSizeList>
              </div>
            </div>
          )}
        </WindowScroller>
      </div>
      {showApprove && foodIdUnderApproval !== null && (
        <ConfirmationDialog
          bodyText={"Do you want to approve this item?"}
          buttonText={"Approve"}
          open={showApprove}
          onClose={() => setShowApprove(false)}
          onApprove={() => onApprove(foodIdUnderApproval)}
          isApproving={isApproving}
        />
      )}
    </>
  );
};

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function ReminderIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.68}
      height={15.682}
      viewBox="0 0 15.68 15.682"
    >
      <defs>
        <style>
          {".reminderIconComponent__a{fill:" + primaryTextColor + "}"}
        </style>
      </defs>
      <path
        className="reminderIconComponent__a"
        d="M4.234 13.841H1.713a1.73 1.73 0 01-1.7-1.594 2.837 2.837 0 01.111-1.05 8.507 8.507 0 01.813-2.032c.134-.258.264-.521.381-.789a3.135 3.135 0 00.244-1.271c0-.42-.007-.842.022-1.261a4.844 4.844 0 011.026-2.7 4.233 4.233 0 012.376-1.567.86.86 0 01.2-.038c.132 0 .155-.075.15-.2-.009-.232 0-.465 0-.7a.564.564 0 111.116.005v.747c0 .089.026.121.1.133a3.981 3.981 0 011.662.671A4.662 4.662 0 019.84 4.181a5.211 5.211 0 01.435 2.2v.612a3.371 3.371 0 00.268 1.449.672.672 0 010 .61.519.519 0 01-.5.305.559.559 0 01-.5-.384 4.62 4.62 0 01-.378-1.859v-.808a3.542 3.542 0 00-1.941-3.314 2.982 2.982 0 00-3.476.63 3.723 3.723 0 00-1.069 2.7c0 .2-.006.408 0 .612a4.993 4.993 0 01-.6 2.522c-.247.474-.481.958-.683 1.456a5.586 5.586 0 00-.263.983.587.587 0 00.462.712 1.079 1.079 0 00.189.011h5.537a.721.721 0 01.352.085.625.625 0 01.007 1.044.172.172 0 00-.088.163 1.783 1.783 0 01-1.515 1.763 1.714 1.714 0 01-1.8-1.459c-.02-.118-.029-.24-.043-.373zm1.135.006a.55.55 0 101.094 0z"
      />
      <path
        className="reminderIconComponent__a"
        d="M11.26 12.793H9.134a.746.746 0 01-.721-.542.724.724 0 01.287-.838 1.08 1.08 0 01.5-.148c.619-.017 1.239-.007 1.859-.007h.187c0-.061.007-.115.007-.169V9.168a.768.768 0 111.535 0v2.086h2.088a.768.768 0 110 1.535h-2.093v2.114a.734.734 0 01-.42.683.719.719 0 01-.759-.038.766.766 0 01-.357-.691v-1.875z"
      />
    </svg>
  );
}

const ReminderIcon = React.memo(ReminderIconComponent);
export default ReminderIcon;

import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IEventCategories } from "types/Event";

export const updateEventCategories = async (
  eventCategories: IEventCategories
): Promise<IEventCategories | FMSError> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/events/eventCategories`,
      eventCategories
    );
    if (Math.floor(response.status / 100) === 2) {
      return response?.parsedBody as IEventCategories;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Event category could not be added";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function RecipeIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.105}
      height={13.8}
      viewBox="0 0 12.105 13.8"
    >
      <defs>
        <style>
          {".recipeIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke: " +
            primaryTextColor +
            ";stroke-width:.3px}"}
        </style>
      </defs>
      <path
        className="recipeIconComponent__a"
        d="M4.359 5.601h4.236a.4.4 0 000-.791H4.359a.4.4 0 000 .791zM4.359 7.295h4.236a.4.4 0 000-.791H4.359a.4.4 0 000 .791zM4.359 8.99h1.695a.4.4 0 000-.791H4.359a.4.4 0 100 .791z"
      />
      <path
        className="recipeIconComponent__a"
        d="M11.56.15H1.393a.4.4 0 00-.4.4v1.718H.541a.4.4 0 000 .791h.452v1.327H.541a.4.4 0 000 .791h.452v1.327H.541a.4.4 0 000 .791h.452v1.328H.541a.4.4 0 000 .791h.452v1.327H.541a.4.4 0 000 .791h.452v1.718a.4.4 0 00.4.4H11.56a.4.4 0 00.4-.4V.55a.4.4 0 00-.4-.4zm-.4 12.709H1.788v-1.327h.452a.4.4 0 000-.791h-.452V9.414h.452a.4.4 0 000-.791h-.452V7.296h.452a.4.4 0 000-.791h-.452V5.177h.452a.4.4 0 000-.791h-.452V3.059h.452a.4.4 0 000-.791h-.452V.941h9.376z"
      />
    </svg>
  );
}
const RecipeIcon = React.memo(RecipeIconComponent);
export default RecipeIcon;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function FavouritesIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.001}
      height={13.078}
      viewBox="0 0 13.001 13.078"
    >
      <path
        d="M12.278 4.776a.869.869 0 00-.529-.282h0l-3.187-.441L7.3 1.037a.866.866 0 00-1.6 0L4.44 4.053l-3.19.44a.869.869 0 00-.458 1.511l2.4 2.143-.491 3.357a.867.867 0 001.344.845l2.455-1.67 2.456 1.675a.867.867 0 001.344-.85l-.493-3.359 2.4-2.143a.869.869 0 00.071-1.226zM9.064 7.661a.429.429 0 00-.138.382l.514 3.6-2.7-1.833a.429.429 0 00-.482 0l-2.709 1.822.529-3.59a.429.429 0 00-.138-.381L1.367 5.344l3.432-.474a.428.428 0 00.337-.259l1.37-3.249a.019.019 0 010 .006l1.36 3.241a.429.429 0 00.337.259l3.436.495z"
        fill={primaryTextColor}
        stroke={primaryTextColor}
      />
    </svg>
  );
}

const FavouritesIcon = React.memo(FavouritesIconComponent);
export default FavouritesIcon;

import { get, BACKEND_URL_V1, post } from "lib/Api";
import { downloadFile } from "lib/FileUtils";
import { FMSError } from "types/Error";
import { ISupplier, ISupplierPost } from "types/Supplier";

interface IGetSuppliersResponse {
  suppliersListView: ISupplier[];
}

interface IGetSupplierResponse {
  supplier: ISupplier;
}

export const getSuppliers = async (): Promise<ISupplier[] | FMSError> => {
  try {
    const response = await get<IGetSuppliersResponse>(
      `${BACKEND_URL_V1}/suppliers`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.suppliersListView) {
        return response.parsedBody.suppliersListView;
      }
      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Supplier list could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getSupplier = async (
  supplierId: string
): Promise<ISupplier | FMSError> => {
  try {
    const response = await get<IGetSupplierResponse>(
      `${BACKEND_URL_V1}/suppliers/${supplierId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.supplier) {
        return response.parsedBody.supplier;
      }
    }

    const errorMessage =
      response.serverError?.error.message ?? "Suppliers could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getSupplierUploadTemplate = async (
  supplier: ISupplierPost
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/suppliers/download/uploadReportFormat`,
      supplier,
      {
        method: "post",
        body: JSON.stringify(supplier),
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
        },
      },
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Add_Supplier_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Supplier template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

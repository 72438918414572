import React, { useState, useEffect, useContext } from "react";
import { Chart } from "react-charts";
import styles from "./ColumnGraph.module.css";
import "./style.module.css";
import { ChartCoordinateData } from "types/Chart";
import moment from "moment";
import { Button } from "@material-ui/core";
import { IBranch } from "types/Branch";
import classNames from "classnames";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IProps {
  dailyStats: ChartCoordinateData[];
  monthlyStats: ChartCoordinateData[];
  setEndIndex: (value: any) => void;
  setStartIndex: (value: any) => void;
}

const MonthShifter: React.FC<IProps> = (props) => {
  const { dailyStats, monthlyStats, setStartIndex, setEndIndex } = props;
  const numberOfMonths = monthlyStats.length;
  const [currentIndex, setCurrentIndex] = useState(numberOfMonths - 1);
  useEffect(() => {
    const lastDate = dailyStats[dailyStats.length - 1];
    const firstDateCoordinate = dailyStats[0];
    const firstDate = moment(firstDateCoordinate.x, "DD MMM YYYY");
    const firstDay = firstDate.date();
    const daysInFirstMonth = firstDate.daysInMonth();
    const daysInStatsArrayFromFirstMonth = daysInFirstMonth - firstDay;
    if (currentIndex === 0) {
      setStartIndex(0);
      setEndIndex(daysInStatsArrayFromFirstMonth);
    } else if (currentIndex === numberOfMonths - 1) {
      const day = moment(lastDate.x, "DD MMM YYYY").date();
      setStartIndex(dailyStats.length - day);
      setEndIndex(dailyStats.length - 1);
    } else {
      let startIndex = daysInStatsArrayFromFirstMonth;
      for (let i = 1; i < numberOfMonths - 1; i++) {
        if (currentIndex === i) {
          break;
        }
        const month = moment(monthlyStats[i].x, "MMM YYYY");
        startIndex += month.daysInMonth();
      }
      const daysInNextMonth = moment(
        monthlyStats[currentIndex].x,
        "MMM YYYY"
      ).daysInMonth();
      setStartIndex(startIndex + 1);
      setEndIndex(startIndex + daysInNextMonth);
    }
  }, [
    currentIndex,
    dailyStats,
    monthlyStats,
    numberOfMonths,
    setStartIndex,
    setEndIndex,
  ]);

  return (
    <div className={classNames(styles.header, styles.headerDate)}>
      <Button
        className={currentIndex !== 0 ? styles.btn : styles.btnHidden}
        onClick={() => setCurrentIndex(currentIndex - 1)}
      >
        {" "}
        {"<"}{" "}
      </Button>

      {currentIndex >= 0 && currentIndex <= numberOfMonths - 1 && (
        <div>{moment(monthlyStats[currentIndex].x).format("MMM YYYY")}</div>
      )}
      <Button
        className={
          currentIndex !== numberOfMonths - 1 ? styles.btn : styles.btnHidden
        }
        onClick={() => setCurrentIndex(currentIndex + 1)}
      >
        {" "}
        {">"}{" "}
      </Button>
    </div>
  );
};

interface IColumnGraph {
  data: ChartCoordinateData[];
  monthlyData: ChartCoordinateData[];
  selectedBranch: IBranch;
  pageTitle: string;
  xFormat?: (value: string) => string;
}
const DailyColumnGraph: React.FC<IColumnGraph> = React.memo((props) => {
  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );
  const { monthlyData, data, selectedBranch, pageTitle } = props;
  const { primaryColor } = useContext(FMSThemeContext);
  const getSeriesStyle = React.useCallback(
    () => ({
      color: primaryColor,
      transition: "height .5s ease",
    }),
    [primaryColor]
  );

  const getDatumStyle = React.useCallback(
    () => ({
      transition: "height .5s ease",
    }),
    []
  );

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(data.length - 1);

  const axes = React.useMemo(() => {
    const slicedData = data.slice(startIndex, endIndex + 1);
    let minY = slicedData[0].y;
    slicedData.forEach((value) => {
      if (value.y < minY) {
        minY = value.y;
      }
    });

    minY = minY - minY / 10;
    if (minY < 0) {
      minY = 0;
    }

    return [
      {
        primary: true,
        type: "ordinal",
        position: "bottom",
        showGrid: false,
        format: props.xFormat,
      },
      {
        position: "left",
        type: "linear",
        stacked: false,
        showGrid: false,
        hardMin: minY,
      },
    ];
  }, [props.xFormat, startIndex, endIndex, data]);
  return (
    <div className={styles.body}>
      <div className={styles.graphHeader}>
        <div className={styles.headerBranch}> {selectedBranch.name}</div>
        <div className={styles.headerCentral}>
          <div className={styles.headerTitle}>
            {pageTitle} {" - Daily"}
          </div>
          <MonthShifter
            dailyStats={data}
            monthlyStats={monthlyData}
            setStartIndex={setStartIndex}
            setEndIndex={setEndIndex}
          />
        </div>
      </div>
      <div className={styles.chart}>
        <Chart
          series={series}
          data={[{ datums: data.slice(startIndex, endIndex + 1) }]}
          axes={axes}
          getSeriesStyle={getSeriesStyle}
          getDatumStyle={getDatumStyle}
          tooltip
        />
      </div>
    </div>
  );
});

export default DailyColumnGraph;

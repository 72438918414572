import React, { useContext } from "react";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import ContentPage from "components/contentPage/ContentPage";
import { AddNewMaterialModal } from "components/addNewMaterialModal/AddNewMaterialModal";
import {
  BranchSelector,
  IBranchSelectorProps,
} from "components/branchSelector/BranchSelector";
import styles from "./Stock.module.css";
import { IBranch } from "types/Branch";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { AddRawOrClosingMaterialModal } from "components/addRawMaterialModal/AddRawOrClosingMaterialModal";
import { StockTable } from "components/stockTable/StockTable";
import { useLocalBranch } from "hooks/LocalStorage";
import { AddFavouritesModal } from "components/addFavouritesModal/AddFavouritesModal";
import {
  MaterialContext,
  IMaterialContextInterface,
} from "contexts/MaterialsContext";
import { StockType } from "types/StockType";
import { FavouriteEntity } from "types/Favourite";
import { getSettings } from "api/GetSettings";
import { AddFavouriteModalHelper } from "components/addFavouriteModalHelper/AddFavouriteModalHelper";
import { Auth } from "lib/Auth";
import ClosingStockIcon from "assets/ClosingStockIcon";
import StockIcon from "assets/StockIcon";

const Stock: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  // // TODO handle no branches error
  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }

  const materials = materialContextValues.branchWiseMaterialsMap.get(
    selectedBranch.id
  )!;
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={
        <MainSection
          selectedBranch={selectedBranch}
          materialContextValues={materials}
        />
      }
      rightSection={
        <RightSection
          selectedBranch={selectedBranch}
          materialContextValues={materials}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Stock"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    <StockTable
      selectedBranch={props.selectedBranch}
      materialContextValues={props.materialContextValues}
    />
  </>
);

const RightSection: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    <ModalWithButton title={"Add Raw Material"} icon={<StockIcon />}>
      {" "}
      <AddRawOrClosingMaterialModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
        type={StockType.ADDITION}
      />{" "}
    </ModalWithButton>
    <ModalWithButton title={"Add Closing Material"} icon={<ClosingStockIcon />}>
      {" "}
      <AddRawOrClosingMaterialModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
        type={StockType.CLOSING}
      />{" "}
    </ModalWithButton>
    <AddFavouriteModalHelper>
      {" "}
      <AddFavouritesModal
        selectedBranch={props.selectedBranch}
        materials={
          props.materialContextValues?.rawMaterialsArrForFoodAnalysis ?? []
        }
        favouriteEntity={FavouriteEntity.RAW_STOCK}
        onClose={getSettings}
      />{" "}
    </AddFavouriteModalHelper>
    {Auth.getInstance().isAdmin() && (
      <ModalWithButton title={"Add New Material"} icon={<StockIcon />}>
        {" "}
        <AddNewMaterialModal />{" "}
      </ModalWithButton>
    )}
  </>
);

export default Stock;

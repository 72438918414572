import { Auth } from "./Auth";
import { FMSError } from "types/Error";
import { IServerError } from "types/ServerError";
import { Config } from "./Config";

const FMS_BACKEND = Config.getInstance().getHost();
const VERSION1 = "v1";
const VERSION2 = "v2";
export const BACKEND_URL_V1 = `${FMS_BACKEND}/${VERSION1}`;
export const BACKEND_URL_V2 = `${FMS_BACKEND}/${VERSION2}`;

interface HttpResponse<T> extends Response {
  parsedBody?: T;
  serverError?: IServerError;
}

async function http<T>(
  path: string,
  args: RequestInit,
  processSucessResponse: boolean = true,
  processErrorResponse: boolean = true,
  retries: number = 1
): Promise<HttpResponse<T>> {
  const tokenOrError = await Auth.getInstance().getToken();
  const newHeaders = new Headers(args.headers);
  if (tokenOrError && !(tokenOrError instanceof FMSError)) {
    newHeaders.set("Authorization", `Bearer ${tokenOrError}`);
  }
  args.headers = newHeaders;
  const request = new Request(path, args);
  const response: HttpResponse<T> = await fetch(request);

  if (response.ok) {
    if (!processSucessResponse) {
      return response;
    }
    try {
      response.parsedBody = await response.json();
    } catch (ex) {
      // may error if there is no body
    }
    return response;
  }

  if (response.status === 401 && retries > 0) {
    await Auth.getInstance().refreshToken();
    return http<T>(
      path,
      args,
      processSucessResponse,
      processErrorResponse,
      retries - 1
    );
  }

  if (!processErrorResponse) {
    return response;
  }

  try {
    response.serverError = await response.json();
    if (response.serverError) {
      const insufficientMaterials =
        response.serverError.error.errorCode ===
        "INSUFFICIENT_STOCK_FOR_CONSUMPTION"
          ? response.serverError.error.details
          : undefined;
      response.serverError.error.insufficientMaterials = insufficientMaterials;
    }
  } catch (ex) {
    // may error if there is no body
    console.log(ex);
  }

  return response;
}

export async function get<T>(
  path: string,
  processSucessResponse?: boolean,
  args: RequestInit = { method: "get" }
): Promise<HttpResponse<T>> {
  return await http<T>(path, args, processSucessResponse);
}

export async function post<T>(
  path: string,
  body?: any,
  args: RequestInit = {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
  processSucessResponse: boolean = true,
  processErrorResponse?: boolean
): Promise<HttpResponse<T>> {
  return await http<T>(path, args, processSucessResponse, processErrorResponse);
}

// 'delete' is a reserved word, so just adding an extra 'e' at the end
export async function deletee<T>(
  path: string,
  body?: any,
  args: RequestInit = { method: "delete", body: JSON.stringify(body) }
): Promise<HttpResponse<T>> {
  return await http<T>(path, args);
}

import { createArrayWithNumbers } from "lib/Arrays";
import React, { useState } from "react";
import styles from "./AddRawOrClosingMaterialModal.module.css";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { IMaterial } from "types/Material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { isUnitGmsOrMl, decorateDisplayUnit } from "lib/MaterialHelper";
import { StockType } from "types/StockType";

interface IProps {
  getValues: any;
  tabValue: number;
  index: number;
  name: string;
  materialsMap: Map<string, IMaterial>;
  materialsArr: IMaterial[];
  favourites: IMaterial[];
  control: any;
  type: StockType;
}

interface IRowProps {
  index: number;
  name: string;
  materialsArr: IMaterial[];
  control: any;
  type: StockType;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
}

const RowComponent: React.FC<IRowProps> = (props) => {
  const { name, index, materialsArr, control, getMaterial } = props;
  const fieldName = `${name}.materials[${index}]`;
  const defaultMaterial = getMaterial(fieldName, index);
  const [material, setMaterial] = useState<IMaterial | null>(defaultMaterial);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        control={control}
        name={`${fieldName}.materialId`}
        defaultValue={defaultMaterial?.id}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            value={getMaterial(fieldName, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${fieldName}.measurementQuantity`}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"measurement-unit" + +fieldName}>
              Measurement Unit
            </InputLabel>
            <OutlinedInput
              id={"measurement-unit" + fieldName}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              disabled={material ? false : true}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const shouldConvertToGmsOrMl = material
                  ? isUnitGmsOrMl(material.unitOfMeasure)
                  : false;
                onChange(shouldConvertToGmsOrMl ? value * 1000 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={123}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export const RawMaterialInputTab: React.FC<IProps> = (props) => {
  const {
    getValues,
    name,
    materialsMap,
    materialsArr,
    tabValue,
    index,
    control,
    favourites,
    type,
  } = props;

  const defaultSize = favourites.length > 5 ? favourites.length : 5;
  const [size, setSize] = useState(defaultSize);

  const getMaterial = (fieldName: string, index: number): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`);
    if (materialIdFromValues) {
      const material = materialsMap.get(materialIdFromValues);
      return material ? material : null;
    }

    if (favourites.length > 0 && index <= favourites.length - 1) {
      return favourites[index];
    }

    return null;
  };

  return (
    <>
      <div className={tabValue !== index ? styles.noDisplay : ""}>
        <>
          {createArrayWithNumbers(size).map((index: number) => {
            return (
              <RowComponent
                key={index}
                index={index}
                name={name}
                control={control}
                type={type}
                materialsArr={materialsArr}
                getMaterial={getMaterial}
              />
            );
          })}
        </>

        <button
          type="button"
          className={styles.addMore}
          onClick={() => setSize(size + 1)}
        >
          Add More
        </button>
      </div>
    </>
  );
};

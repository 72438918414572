import {
  useTable,
  Column,
  useGlobalFilter,
  useBlockLayout,
  useSortBy,
  useFilters,
} from "react-table";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import styles from "./DataTable.module.css";
import classNames from "classnames";
import { GlobalFilter } from "components/globalFilter/GlobalFilter";
import { FixedSizeList } from "react-window";
import scrollbarWidth from "components/utils/ScrollbarWidth";

interface IDataTableProps {
  columns: Column<{}>[];
  data: any;
  tableClass?: string;
  tableHeight?: number;
  marginTop?: number;
  marginBottom?: number;
  searchable?: boolean;
  isLoading?: boolean;
  itemHeight?: number;
  initialSortBy?: string;
  initialSortOrder?: "asc" | "desc";
  highlightRows?: number[];
  initialStateFilters?: IReactTableInitialStateFilters[];
  highlightColor?: string;
}

export interface IReactTableInitialStateFilters {
  id: string;
  value: any;
}

export const DataTable: React.FC<IDataTableProps> = (props) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    columns,
    data,
    itemHeight = 51,
    initialSortBy = "",
    initialSortOrder = "asc",
    initialStateFilters = [] } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    totalColumnsWidth,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      disableSortRemove: true,
      defaultCanSort: false,
      initialState: {
        sortBy: [{ id: initialSortBy, desc: initialSortOrder === "desc" }],
        filters: initialStateFilters,
      },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const headerGroup = headerGroups[1];
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      style.display = "";
      const shouldHighlightRow = props.highlightRows && props.highlightRows?.indexOf(row.index) > -1;
      return (
        <TableRow
          hover={!shouldHighlightRow}
          {...row.getRowProps({ style })}
        //style={shouldHighlightRow ? { background: highlightColor } : { background: "#FFFFFF" }}
        >
          {row.cells.map((cell, index) => {
            const columnwidth =
              headerGroup.headers[index % row.cells.length].width;
            return (
              <TableCell
                {...cell.getCellProps()}
                className={classNames(
                  styles.cell,
                  index !== 0 ? styles.centerAlign : ""
                )}
                style={{
                  width: columnwidth,
                  height: itemHeight,
                }}
              >
                <div
                  style={{
                    marginLeft: index === 0 ? "5px" : "0px",
                  }}
                >
                  {cell.render("Cell")}
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [rows, prepareRow, headerGroup.headers, itemHeight, props.highlightRows]
  );

  const list = React.useRef<any>(null);
  const scrollBarSize = React.useMemo(() => scrollbarWidth(), []);

  // Render the UI for your table
  return (
    <>
      <div
        className={classNames(styles.container, props.tableClass)}
        style={{
          marginTop: props.marginTop ?? 0,
          marginBottom: props.marginBottom ?? 0,
        }}
      >
        {props.searchable && (
          <div className={styles.tableHeader}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}

        {props.isLoading && (
          <div className={styles.loadingDiv}>
            <CircularProgress
              size={30}
              color={"inherit"}
              className={styles.loading}
            />
          </div>
        )}

        {!props.isLoading && (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    className={classNames(
                      styles.cell,
                      styles.header,
                      index !== 0 ? styles.centerAlign : ""
                    )}
                    style={{ width: column.width }}
                  >
                    <div
                      style={{
                        marginTop: "15px",
                        marginLeft: index === 0 ? "10px" : "0px",
                        marginBottom: "10px",
                      }}
                    >
                      {column.render("Header")}
                      <div>
                        {column.disableFilters ? (
                          <></>
                        ) : (
                          column.render("Filter")
                        )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              <FixedSizeList
                ref={list}
                height={props.tableHeight ?? 300}
                itemCount={rows.length}
                width={totalColumnsWidth + scrollBarSize}
                itemSize={itemHeight}
              >
                {RenderRow}
              </FixedSizeList>
            </TableBody>
          </Table>
        )}
      </div>
    </>
  );
};

import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { getStaffFood } from "api/GetStaffFood";
import { IStaffFoodForAllBranches } from "types/StaffFood";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  staffFoods: IStaffFoodForAllBranches[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryStaffFoods = (
  startDate: Date,
  endDate: Date
): IQueryBranch => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: staffFoods, isFetching } = useQuery(
    ["staffFood", start, end],
    async (key: string, start: string, end: string) => {
      return await getStaffFood(start, end);
    }
  );
  return { status, staffFoods, isFetching };
};

export const invalidateStaffFoodCache = () => {
  queryCache.invalidateQueries("staffFood");
};

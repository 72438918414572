import { equalsIgnoreCase, removeUnderscore } from "lib/StringHelpers";
import { IExternalBranch } from "./Branch";

export enum Role {
  CONTRACTOR = "CONTRACTOR",
  DATA_ENTRY = "DATA_ENTRY",
  MANAGER = "MANAGER",
  SUPERVISOR = "SUPERVISOR",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export const RoleList = Object.values(Role);

export interface IEmployee {
  name: string;
  id: string;
  parentNamespaceId: string;
  mobileNumber: string;
  role: Role;
  email: string;
  branches: IExternalBranch[];
}

export const isRoleEqual = (roleString: string, role: Role) =>
  equalsIgnoreCase(removeUnderscore(roleString), removeUnderscore(role));
export const isRoleAdmin = (role: Role) =>
  role === Role.ADMIN || role === Role.SUPER_ADMIN;
export const isRoleSupervisor = (role: Role) => role === Role.SUPERVISOR;
export const isRoleManager = (role: Role) => role === Role.MANAGER;
export const isStringRoleAdmin = (role: string) =>
  isRoleEqual(role, Role.ADMIN) || isRoleEqual(role, Role.SUPER_ADMIN);
export const isRoleManagerOrSupervisor = (role: Role) =>
  role === Role.MANAGER || role === Role.SUPERVISOR;
export const isStringRoleManager = (role: string) =>
  isRoleEqual(role, Role.MANAGER) || isRoleEqual(role, Role.SUPERVISOR);

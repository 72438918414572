import styles from "./AddComplimentaryFoodModal.module.css";
import React, { useContext, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { useMutation } from "react-query";
import { IBranch } from "types/Branch";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalContext } from "contexts/ModalContext";
import { createArrayWithNumbers } from "lib/Arrays";
import { IMaterial } from "types/Material";
import { IComplimentaryFoodPost } from "types/ComplimentaryFood";
import { addComplimentaryFood } from "api/PostComplimentaryFood";
import { invalidateComplimentaryFoodCache } from "hooks/ComplimentaryFoods";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Toast from "components/Toast/Toast";
import { isUnitGmsOrMl, decorateDisplayUnit } from "lib/MaterialHelper";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

const extractFoodItemsForPost = (foodItems: any) => {
  const postMaterialsArr = new Array<IComplimentaryFoodPost>();
  if (!foodItems) {
    return postMaterialsArr;
  }

  foodItems.forEach((foodItem: any) => {
    const materialId = foodItem.materialId as string | undefined;
    const quantity = foodItem.quantity as number | undefined;

    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (!quantity || quantity === 0 || isNaN(quantity)) {
      return;
    }

    const postMaterial: IComplimentaryFoodPost = {
      materialId: materialId,
      quantity: quantity,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };

    postMaterialsArr.push(postMaterial);
  });

  return postMaterialsArr;
};

interface IComplimentaryFoodPostRequest {
  foodItems: IComplimentaryFoodPost[];
  comment: string;
}

interface IComplimentaryFoodRow {
  index: number;
  name: string;
  materialsArr: IMaterial[];
  control: any;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
}

const ComplimentaryFoodRow: React.FC<IComplimentaryFoodRow> = (props) => {
  const { index, name, materialsArr, control, getMaterial } = props;
  const defaultMaterial = getMaterial(name, index);
  const [material, setMaterial] = useState(defaultMaterial);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        name={`${name}.materialId`}
        control={control}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            value={getMaterial(name, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${name}.quantity`}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"quantity-input" + name}>Quantity</InputLabel>
            <OutlinedInput
              id={"quantity-input" + name}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              disabled={material ? false : true}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const shouldConvertToGmsOrMl = material
                  ? isUnitGmsOrMl(material.unitOfMeasure)
                  : false;
                onChange(shouldConvertToGmsOrMl ? value * 1000 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export const AddComplimentaryFoodModal: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => {
  const { selectedBranch } = props;
  const { handleSubmit, control, getValues, register, errors } =
    useForm<IComplimentaryFoodPostRequest>();
  const modalProps = useContext(ModalContext);

  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };
  const [postData, setPostData] = useState<IComplimentaryFoodPostRequest>({
    comment: "",
    foodItems: [],
  });

  const [mutate] = useMutation(
    (request: IComplimentaryFoodPostRequest) => {
      return addComplimentaryFood(
        request.foodItems,
        selectedDate!,
        request.comment,
        selectedBranch
      );
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateComplimentaryFoodCache();
          modalProps.hideModal(true, "Complimentary food added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const onSubmit = async (data: any) => {
    const foodItems = extractFoodItemsForPost(data.foodItems);
    if (foodItems.length === 0) {
      setToastMessage("Atleast one food item is required!");
      setShowToast(true);
      return;
    }

    setPostData({ foodItems: foodItems, comment: data.comment });
  };

  const {
    allMaterialsArrForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
    sellableMaterialsMapForFoodAnalysis,
  } = props.materialContextValues;

  const [size, setSize] = useState(4);

  if (allMaterialsArrForFoodAnalysis.length === 0) {
    return <></>;
  }

  const getMaterial = (fieldName: string, index: number): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`) as string;
    if (materialIdFromValues) {
      const material =
        sellableMaterialsMapForFoodAnalysis.get(materialIdFromValues);
      return material ? material : null;
    }

    return null;
  };

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Complimentary Food</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      {postData.foodItems.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={sellableMaterialsMapForFoodAnalysis}
          entries={postData.foodItems}
          confirmationType={"Complimentary Food"}
          onClose={() => {
            setPostData({ foodItems: [], comment: "" });
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(postData);
            setPostData({ foodItems: [], comment: "" });
          }}
        />
      )}

      <div className={styles.modalBody}>
        <>
          <>
            {createArrayWithNumbers(size).map(
              (value: number, index: number) => {
                const fieldName = `foodItems[${index}]`;
                return (
                  <ComplimentaryFoodRow
                    key={index}
                    index={index}
                    name={fieldName}
                    control={control}
                    materialsArr={sellableMaterialsArrForFoodAnalysis}
                    getMaterial={getMaterial}
                  />
                );
              }
            )}
          </>

          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            Add More
          </button>

          <TextField
            style={{ margin: 12 }}
            InputProps={{
              style: {
                minHeight: 100,
              },
            }}
            error={errors.comment !== undefined}
            type={"text"}
            label="Comment*"
            fullWidth
            multiline={true}
            margin="normal"
            variant="outlined"
            name="comment"
            inputRef={register({ required: true })}
          />
        </>
      </div>
    </ModalPopup>
  );
};

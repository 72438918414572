import React, { useContext, useEffect, useState } from "react";
import styles from "./ReportsWithDateRange.module.css";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePicker } from "@material-ui/pickers";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import classNames from "classnames";
import CalendarIcon from "assets/CalendarIcon";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { createTheme } from "@material-ui/core/styles";
import { FMSError } from "types/Error";
import { ISupplier } from "types/Supplier";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getSuppliers } from "api/GetSupplier";
import { Loading } from "components/loading/Loading";
import {
  downloadExportPurchaseOrdersReport,
  downloadUnitWisePurchaseOrders,
  downloadUnitWisePurchaseOrdersForSupplier,
  emailBranchWiseFoodAnalysisReport,
  emailPreparedMaterialPredictionReport,
  emailRawMaterialPredictionReport,
  emailSemiPreparedMaterialPredictionReport,
} from "api/GetReports";
import { BusniessUnit, displayNameForBusinessUnit } from "types/Branch";

interface IProps {
  reportType:
  | "unitWisePO"
  | "unitWisePOForSupplier"
  | "allPOExport"
  | "branchWiseFoodAnalysis"
  | "storeForecast"
  | "kitchenForecast"
  | "salesForecast";

  disableFutureDates: boolean;
}

export const ReportsWithDateRange: React.FC<IProps> = (props) => {
  const { reportType, disableFutureDates } = props;
  const [startDate, handleStartDateChange] =
    useState<MaterialUiPickersDate | null>(null);
  const [endDate, handleEndDateChange] = useState<MaterialUiPickersDate | null>(
    null
  );

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [selectedBusinessUnit, setSelectedBusinessUnit] =
    useState<BusniessUnit>();

  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier>();

  const [isFetchingSuppliers, setIsFetchingSuppliers] = useState(false);

  const [isDownloading, setDownloading] = useState(false);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const handleSubmit = async () => {
    if (startDate === null || endDate === null) {
      setShowToast({
        open: true,
        message: "Select start and end dates for report",
        type: "error",
      });
      return;
    }

    if (startDate.getTime() > endDate.getTime()) {
      setShowToast({
        open: true,
        message: "Start date cannot be after end date",
        type: "error",
      });
      return;
    }

    if (selectedBusinessUnit === undefined) {
      setShowToast({
        open: true,
        message: "Select a business entity to download report",
        type: "error",
      });
      return;
    }

    if (
      reportType === "unitWisePOForSupplier" &&
      selectedSupplier === undefined
    ) {
      setShowToast({
        open: true,
        message: "Select supplier to download report",
        type: "error",
      });
      return;
    }

    setDownloading(true);
    let error: FMSError | null;
    if (reportType === "unitWisePOForSupplier") {
      if (!selectedSupplier) {
        return;
      }

      error = await downloadUnitWisePurchaseOrdersForSupplier(
        selectedSupplier.id,
        startDate,
        endDate,
        selectedBusinessUnit
      );
    } else if (reportType === "unitWisePO") {
      error = await downloadUnitWisePurchaseOrders(
        startDate,
        endDate,
        selectedBusinessUnit
      );
    } else if (reportType === "allPOExport") {
      error = await downloadExportPurchaseOrdersReport(
        startDate,
        endDate,
        selectedBusinessUnit
      );
    } else if (reportType === "branchWiseFoodAnalysis") {
      const response = await emailBranchWiseFoodAnalysisReport(
        startDate,
        endDate,
        selectedBusinessUnit
      );
      if (response instanceof FMSError) {
        error = response;
      } else {
        setDownloading(false);
        setShowToast({
          open: true,
          message: response,
          type: "success",
        });
        return;
      }
    } else if (reportType === "kitchenForecast") {
      const response = await emailSemiPreparedMaterialPredictionReport(
        startDate,
        endDate,
        selectedBusinessUnit
      );
      if (response instanceof FMSError) {
        error = response;
      } else {
        setDownloading(false);
        setShowToast({
          open: true,
          message: response,
          type: "success",
        });
        return;
      }
    } else if (reportType === "salesForecast") {
      const response = await emailPreparedMaterialPredictionReport(
        startDate,
        endDate,
        selectedBusinessUnit
      );
      if (response instanceof FMSError) {
        error = response;
      } else {
        setDownloading(false);
        setShowToast({
          open: true,
          message: response,
          type: "success",
        });
        return;
      }
    } else if (reportType === "storeForecast") {
      const response = await emailRawMaterialPredictionReport(
        startDate,
        endDate,
        selectedBusinessUnit
      );
      if (response instanceof FMSError) {
        error = response;
      } else {
        setDownloading(false);
        setShowToast({
          open: true,
          message: response,
          type: "success",
        });
        return;
      }
    } else { // just to make the IDE happy
      return;
    }

    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
    }
  };

  const fetchSuppliers = async () => {
    setIsFetchingSuppliers(true);
    const response = await getSuppliers();
    setIsFetchingSuppliers(false);
    if (response instanceof FMSError) {
      const message = response.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }

    if (response.length > 0) {
      response.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
      );

      setSuppliers(response);
    }
  };

  useEffect(() => {
    if (reportType === "unitWisePOForSupplier") {
      fetchSuppliers();
    }
  }, [reportType]);

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
  });

  if (isFetchingSuppliers) {
    return <CircularProgress size={20} color={"inherit"} />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <InputLabel
            style={{
              marginRight: "10px",
            }}
          >
            From:
          </InputLabel>
          <ThemeProvider theme={materialTheme}>
            <DatePicker
              views={["date"]}
              value={startDate}
              placeholder={"From"}
              onChange={(startDate) => {
                handleStartDateChange(startDate);
              }}
              autoOk={true}
              variant={"inline"}
              disableFuture={disableFutureDates}
              inputVariant="outlined"
              format="MMM dd"
              InputProps={{
                className: classNames(styles.input, styles.widthDates),
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel
              style={{
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              To:
            </InputLabel>
            <DatePicker
              views={["date"]}
              value={endDate}
              placeholder={"To"}
              onChange={(endDate) => {
                handleEndDateChange(endDate);
              }}
              autoOk={true}
              variant={"inline"}
              disableFuture={disableFutureDates}
              inputVariant="outlined"
              format="MMM dd"
              InputProps={{
                className: classNames(styles.input, styles.widthDates),
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ThemeProvider>
        </div>

        {reportType === "unitWisePOForSupplier" && (
          <Autocomplete
            options={suppliers}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => {
              if (value) {
                setSelectedSupplier(value);
              } else {
                setSelectedSupplier(undefined);
              }
            }}
            defaultValue={null}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.inputField}
                label="Select Supplier"
                variant="outlined"
              />
            )}
          />
        )}

        <Box sx={{ width: 300, marginBottom: 20 }}>
          <FormControl fullWidth>
            <InputLabel id="business-unit-select-label">
              Select Business Entity
            </InputLabel>
            <Select
              value={selectedBusinessUnit}
              label="Select Business Entity"
              labelId="business-unit-select-label"
              onChange={(e) => {
                const value = e.target.value as BusniessUnit;
                if (value) {
                  setSelectedBusinessUnit(value);
                } else {
                  setSelectedBusinessUnit(undefined);
                }
              }}
              defaultValue={null}
            >
              {Object.values(BusniessUnit).map((businessUnit, index) => (
                <MenuItem key={index} value={businessUnit}>
                  {displayNameForBusinessUnit(businessUnit)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Button
          style={{
            color: primaryTextColor,
            backgroundColor: primaryColor,
          }}
          color="primary"
          variant="contained"
          disabled={isDownloading}
          onClick={handleSubmit}
        >
          <Loading text={`Download`} isLoading={isDownloading} />
        </Button>
        <Toast
          open={showToast.open}
          message={showToast.message}
          type={showToast.type}
          onClose={handleToastClose}
        />
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import { Auth } from "lib/Auth";
import { Redirect } from "react-router-dom";
import { clearLocalStorage } from "lib/LocalStorage";

const Logout: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(true);
  useEffect(() => {
    Auth.getInstance().logout();
    clearLocalStorage();
    setLoggedIn(false);
  }, [setLoggedIn]);
  return isLoggedIn ? <div></div> : <Redirect to={{ pathname: "/login" }} />;
};

export default Logout;

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IDashboardViewForBranch } from "types/DashboardView";

interface IDashboardView {
  branchMaterialStatisticViews: IDashboardViewForBranch[];
}

export const getDashboardView = async (
  start: string,
  end: string
): Promise<IDashboardViewForBranch[] | FMSError> => {
  try {
    const response = await get<IDashboardView>(
      `${BACKEND_URL_V1}/materials/statistics?start=${start}&end=${end}`
    );

    if (response.status / 100 === 2) {
      if (response.parsedBody?.branchMaterialStatisticViews) {
        return response.parsedBody.branchMaterialStatisticViews;
      }
      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Dashboard could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import React from "react";
import ColumnGraph from "components/columnGraph/ColumnGraph";
import { IBranch } from "types/Branch";
import { FMSError } from "types/Error";
import { useQueryStaffFoodStats } from "hooks/Stats";
import moment from "moment";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { getStatsData } from "lib/StatsUtils";
import { Loading } from "components/loading/Loading";
import { GraphsHeaderMonthly } from "components/graphsHeader/GraphsHeaderMonthly";
import DailyColumnGraph from "components/columnGraph/DailyColumnGraph";

const StaffFoodStatsGraph: React.FC<{
  selectedBranch: IBranch;
  materialId: string;
}> = (props) => {
  const { selectedBranch, materialId } = props;
  const selectedDatesHookValues = useQuerySelectedFromToDates(true);
  const { start, end } = selectedDatesHookValues;
  const { status, stats } = useQueryStaffFoodStats(
    materialId,
    start,
    end,
    selectedBranch.id
  );

  if (status !== "success" || !stats || stats instanceof FMSError) {
    return <Loading isLoading={true} />;
  }

  const statsData = getStatsData(stats);

  return (
    <>
      <ColumnGraph
        data={statsData.monthlyStats}
        header={
          <GraphsHeaderMonthly
            selectedDatesHookValues={selectedDatesHookValues}
            selectedBranch={selectedBranch}
            pageTitle={"Staff Food"}
          />
        }
      />
      <DailyColumnGraph
        data={statsData.dailyStats}
        monthlyData={statsData.monthlyStats}
        selectedBranch={selectedBranch}
        pageTitle={"Staff Food"}
        xFormat={(value: any) => moment(value, "DD MMM YYYY").date().toString()}
      />
    </>
  );
};

export default StaffFoodStatsGraph;

import React, { ReactNode, useContext, useCallback } from "react";
import { IconButton, InputAdornment, Button, Icon } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import styles from "./ModalPopup.module.css";
import { ModalContext } from "contexts/ModalContext";
import { DatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import { useDropzone } from "react-dropzone";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import CalendarIcon from "assets/CalendarIcon";
import ExcelIcon from "assets/ExcelIcon";
import { Loading } from "components/loading/Loading";
import { isRoleAdmin, Role } from "types/Employee";
import { Auth } from "lib/Auth";

interface IProps {
  className: string;
  children: ReactNode;
  header: ReactNode;
  headerBackgroundColor?: string;
  headerTintColor?: string;
  onSubmit: () => void;
  isSubmitting?: boolean;
  showDatePicker?: boolean;
  selectedDate?: Date;
  handleDateChange?: (date: MaterialUiPickersDate) => void;
  hideModal?: () => void;
  branchName?: string;
  isUploadDownloadApplicable?: boolean;
  handleDownloadTemplate?: () => void;
  handleUploadFile?: (files: File[]) => void;
  isDownloading?: boolean;
  submitText?: string;
}

export const ModalPopup: React.FC<IProps> = ({
  className,
  header: Header,
  headerBackgroundColor = "#f6f6f6",
  headerTintColor = "#4d4d4d",
  onSubmit,
  children,
  showDatePicker = true,
  isSubmitting = false,
  selectedDate,
  handleDateChange = () => {},
  hideModal,
  branchName,
  isUploadDownloadApplicable = false,
  handleDownloadTemplate = () => {},
  handleUploadFile = (files) => {},
  isDownloading = false,
  submitText = "Add",
}) => {
  const modalProps = useContext(ModalContext);
  const isBranchPresent = branchName && branchName.length > 0 ? true : false;

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleUploadFile(acceptedFiles);
    },
    [handleUploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  var showUploadAndDownload: boolean;
  const userRole = Auth.getInstance().getUser()?.role;
  if (isUploadDownloadApplicable && userRole) {
    showUploadAndDownload =
      isRoleAdmin(userRole) || userRole === Role.SUPERVISOR;
  } else {
    showUploadAndDownload = false;
  }

  return (
    <div className={className}>
      <div
        className={
          isBranchPresent
            ? styles.modalTitleBoxWithBranch
            : styles.modalTitleBox
        }
        style={{
          backgroundColor: headerBackgroundColor,
        }}
      >
        {showDatePicker && (
          <div className={styles.date}>
            <DatePicker
              views={["date"]}
              autoOk={true}
              value={selectedDate}
              onChange={handleDateChange}
              variant={"inline"}
              disableFuture
              inputVariant="outlined"
              format="MMM dd"
              InputProps={{
                className: classNames(styles.input, styles.widthDates),
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        )}
        <div
          className={styles.modalTitleText}
          style={{
            color: headerTintColor,
          }}
        >
          <>
            {isBranchPresent && (
              <div className={styles.branchName}>{branchName}</div>
            )}
            {Header}
          </>
        </div>
        <div
          className={styles.modalCloseIcon}
          style={{
            color: headerTintColor,
          }}
        >
          <IconButton
            name="close"
            color="inherit"
            onClick={() => {
              if (hideModal) {
                hideModal();
              } else {
                modalProps.hideModal();
              }
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div className={styles.content}>
          <div className={styles.modalBody}>{children}</div>
          <div className={styles.buttons}>
            <Button
              className={
                showUploadAndDownload ? styles.download : styles.downloadHidden
              }
              onClick={handleDownloadTemplate}
              startIcon={<GetAppRoundedIcon />}
            >
              <Loading text={"Template"} isLoading={isDownloading} />
            </Button>
            <button
              className={styles.modalButton}
              style={{ backgroundColor: primaryColor, color: primaryTextColor }}
            >
              <Loading text={submitText} isLoading={isSubmitting} />
            </button>

            <div
              style={{
                display: "inline",
                visibility: showUploadAndDownload ? "visible" : "hidden",
              }}
              {...getRootProps({ className: styles.dropzone })}
            >
              <input {...getInputProps()} />
              <Button
                className={
                  showUploadAndDownload ? styles.upload : styles.uploadHidden
                }
                startIcon={
                  <Icon>
                    <ExcelIcon />
                  </Icon>
                }
              >
                Upload
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

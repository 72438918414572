import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IEmployee } from "types/Employee";
import { Auth } from "lib/Auth";
import {
  getBranchFromLocalStorage,
  setBranchInLocalStorage,
} from "lib/LocalStorage";
import { enrichBranchWithFavouriteMap } from "lib/BranchUtils";

interface IGetSettings {
  employee: IEmployee;
}

function refreshLocalStorage(employee: IEmployee) {
  const selectedBranch = getBranchFromLocalStorage();
  Auth.getInstance().setUser(employee);
  if (selectedBranch) {
    const newBranch = employee.branches.find(
      (branch) => branch.id === selectedBranch.id
    );
    if (newBranch) {
      setBranchInLocalStorage(enrichBranchWithFavouriteMap(newBranch));
    }
  }
}

export const getSettings = async (): Promise<IEmployee | FMSError> => {
  try {
    const response = await get<IGetSettings>(`${BACKEND_URL_V1}/me/settings`);
    if (response.parsedBody?.employee) {
      refreshLocalStorage(response.parsedBody.employee);
      return response.parsedBody.employee;
    }

    return new FMSError("User settings could not be retrieved");
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

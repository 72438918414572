import styles from "./AddReminderModal.module.css";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import React, { useContext, useEffect, useState } from "react";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { useMutation } from "react-query";
import { Controller, useForm } from "react-hook-form";
import {
  displayNameForEventFrequency,
  displayNameForEventReminderFrequency,
  displayNameForPaymentMethod,
  EventFrequency,
  EventReminderFrequency,
  IEventCategories,
  IEventPost,
  PaymentMethod,
  IEvent,
} from "types/Event";
import {
  invalidateRemindersCache,
  useQueryEventCategories,
} from "hooks/Reminders";
import { addEvent, deleteEvent, updateEvent } from "api/PostEvent";

import { ModalContext } from "contexts/ModalContext";
import {
  Button,
  Checkbox,
  createTheme,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Icon,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQueryEmployees } from "hooks/Employees";
import { SingleInputDropdown } from "components/dropdowns/SingleInputDropdown";
import { MultiInputDropdown } from "components/dropdowns/MultiInputDropdown";
import {
  isRoleManager,
  isStringRoleAdmin,
  isStringRoleManager,
} from "types/Employee";
import { DatePicker } from "@material-ui/pickers";
import CalendarIcon from "assets/CalendarIcon";
import classNames from "classnames";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { FMSError } from "types/Error";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { sortStrings } from "lib/StringHelpers";
import { Auth } from "lib/Auth";

export const AddReminderModal: React.FC<{
  hideModal?: (message?: string) => void;
  event?: IEvent;
}> = (props) => {
  const { hideModal, event } = props;
  const { handleSubmit, register, control, errors, getValues } =
    useForm<IEventPost>();
  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [isSubmitting, setSubmitting] = useState(false);

  const [updateParentPeriodicEvent, setUpdateParentPeriodicEvent] =
    useState(true);

  const [confirmationDialog, setConfirmationDialog] = useState<{
    show: boolean;
    onConfirm: () => void;
    buttonText: string;
    title: string;
    checkboxLabel: string;
    showCheckbox: boolean;
  }>({
    show: false,
    onConfirm: () => {},
    buttonText: "",
    title: "",
    checkboxLabel: "",
    showCheckbox: true,
  });

  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  const eventCategoriesGetQuery = useQueryEventCategories();
  const [eventCategories, setEventCategories] = useState<IEventCategories>({
    id: "",
    businessUnits: [],
    types: [],
    items: [],
  });

  useEffect(() => {
    if (eventCategoriesGetQuery.status === "success") {
      if (
        !eventCategoriesGetQuery.eventCategoriesFetched ||
        eventCategoriesGetQuery.eventCategoriesFetched instanceof FMSError
      ) {
        const message =
          eventCategoriesGetQuery.eventCategoriesFetched?.message ??
          "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        const eventCategories = eventCategoriesGetQuery.eventCategoriesFetched;
        eventCategories.businessUnits = sortStrings(
          eventCategories.businessUnits
        );
        eventCategories.types = sortStrings(eventCategories.types);
        eventCategories.items = sortStrings(eventCategories.items);
        setEventCategories(eventCategories);
      }
    }
  }, [
    eventCategoriesGetQuery.status,
    eventCategoriesGetQuery.eventCategoriesFetched,
  ]);

  const modalProps = useContext(ModalContext);

  const [mutate] = useMutation(
    (request: IEventPost) => {
      if (event) {
        const updateParent =
          event.frequency === EventFrequency.ONE_TIME
            ? false
            : updateParentPeriodicEvent;
        return updateEvent(event.id, request, updateParent);
      } else {
        return addEvent(request);
      }
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateRemindersCache();
          const message = event
            ? "Reminder updated successfully"
            : "Reminder added successfully";
          if (hideModal) {
            hideModal(message);
          } else {
            modalProps.hideModal(true, message);
          }
          return;
        }

        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const [mutateForDelete] = useMutation(
    (eventId: string) => {
      return deleteEvent(eventId, updateParentPeriodicEvent);
    },
    {
      onSuccess: (error) => {
        setIsConfirming(false);
        if (!error) {
          invalidateRemindersCache();
          const message = "Reminder deleted successfully";
          if (hideModal) {
            hideModal(message);
          } else {
            modalProps.hideModal(true, message);
          }
          return;
        }

        setConfirmationDialog({
          show: false,
          onConfirm: () => {},
          buttonText: "",
          title: "",
          checkboxLabel: "",
          showCheckbox: true,
        });
        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const onSubmit = async (data: IEventPost) => {
    if (event && event.frequency !== EventFrequency.ONE_TIME) {
      setConfirmationDialog({
        title: "Are you sure you want to update the entire reminder series?",
        checkboxLabel: "Update only this reminder",
        buttonText: "Update",
        show: true,
        onConfirm: () => {
          setSubmitting(true);
          mutate(data);
        },
        showCheckbox: true,
      });
    } else {
      setSubmitting(true);
      mutate(data);
    }
  };

  const [isConfirming, setIsConfirming] = useState(false);
  const handleDelete = async () => {
    var title: string;
    var showCheckbox: boolean;
    if (event?.frequency === EventFrequency.ONE_TIME) {
      title = "Are you sure you want to delete this reminder?";
      showCheckbox = false;
    } else {
      title = "Are you sure you want to delete the entire reminder series?";
      showCheckbox = true;
    }
    setConfirmationDialog({
      title: title,
      checkboxLabel: "Delete only this event",
      buttonText: "Delete",
      show: true,
      onConfirm: () => {
        if (event) {
          setIsConfirming(true);
          mutateForDelete(event.id);
        }
      },
      showCheckbox: showCheckbox,
    });
  };

  const businessUnits = eventCategories.businessUnits.map((val) => {
    return { key: val, value: val };
  });

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(
    event ? event.businessUnit : ""
  );

  const items = eventCategories.items.map((val) => {
    return { key: val, value: val };
  });
  const [selectedItem, setSelectedItem] = useState(event ? event.item : "");

  const reminderTypes = eventCategories.types.map((val) => {
    return { key: val, value: val };
  });

  const [selectedType, setSelectedType] = useState(event ? event.type : "");

  const paymentMethods = Object.keys(PaymentMethod).map((key) => {
    return {
      key,
      value: displayNameForPaymentMethod(
        PaymentMethod[key as keyof typeof PaymentMethod]
      ),
    };
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>(
    event && event.paymentMethod ? event.paymentMethod : ""
  );

  const eventFrequencies = Object.keys(EventFrequency).map((key) => {
    return {
      key,
      value: displayNameForEventFrequency(
        EventFrequency[key as keyof typeof EventFrequency]
      ),
    };
  });
  const [selectedEventFrequency, setSelectedEventFrequency] = useState<string>(
    event ? event.frequency : ""
  );

  const { status, employees } = useQueryEmployees();

  const [allEmployees, setAllEmployees] = useState<
    { key: string; value: string }[]
  >([]);
  const [allOwnerEmployees, setOwnerEmployees] = useState<
    { key: string; value: string }[]
  >([]);
  useEffect(() => {
    if (status === "success" && employees) {
      const allEmployeeKeyValuePair = employees.map((employee) => {
        return { key: employee.id, value: employee.name };
      });
      setAllEmployees(allEmployeeKeyValuePair);
      const ownerEmployeeKeyValuePair = employees
        .filter(
          (employee) =>
            isStringRoleManager(employee.role) ||
            isStringRoleAdmin(employee.role)
        )
        .map((employee) => {
          return { key: employee.id, value: employee.name };
        });
      setOwnerEmployees(ownerEmployeeKeyValuePair);
    }
  }, [status, employees, setAllEmployees]);

  var initialOwnerEmployeeIds: string[] = [];
  var currentUser = Auth.getInstance().getUser();
  if (event) {
    initialOwnerEmployeeIds = event.ownerViews.map((employee) => employee.id);
  } else if (currentUser && isRoleManager(currentUser.role)) {
    initialOwnerEmployeeIds = [currentUser.id];
  }

  const [ownerEmployeeIds, setOwnerEmpoyeeIds] = useState<string[]>(
    initialOwnerEmployeeIds
  );

  var initialSendReminderToUserIds: string[] = [];
  if (event && event.targetEmployeesForReminders) {
    initialSendReminderToUserIds = event.targetEmployeesForReminders.map(
      (employee) => employee.id
    );
  }
  const [sendReminderToUserIds, setSendReminderToUserIds] = useState<string[]>(
    initialSendReminderToUserIds
  );
  const [selectedStartDate, setSelectedStartDate] =
    useState<MaterialUiPickersDate>(event ? event.date : null);
  const [selectedEndDate, setSelectedEndDate] = useState<MaterialUiPickersDate>(
    event && event.parentPeriodicEventEndDate
      ? event.parentPeriodicEventEndDate
      : null
  );

  var initialSendReminderOn: EventReminderFrequency[] = [
    EventReminderFrequency.ON_THE_DAY,
  ];
  if (event) {
    initialSendReminderOn = event.remindersOn ?? [];
  }

  const [remindersOn, setRemindersOn] = React.useState(initialSendReminderOn);
  const handleRemindersChange = (
    eventReminderFrequency: EventReminderFrequency,
    isChecked: boolean,
    onChange: (value: EventReminderFrequency[]) => void
  ) => {
    if (isChecked && !remindersOn.includes(eventReminderFrequency)) {
      const newReminders = [...remindersOn, eventReminderFrequency];
      setRemindersOn(newReminders);
      onChange(newReminders);
    } else if (!isChecked && remindersOn.includes(eventReminderFrequency)) {
      const filteredList = remindersOn.filter(
        (value) => value !== eventReminderFrequency
      );
      setRemindersOn(filteredList);
      onChange(filteredList);
    }
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ModalPopup
      className={styles.modal}
      header={
        <>
          {event && (
            <>
              <div className={styles.delete}>
                <Button
                  variant="outlined"
                  startIcon={
                    <Icon>
                      <DeleteIcon />
                    </Icon>
                  }
                  onClick={handleDelete}
                >
                  Delete Reminder
                </Button>
              </div>
              <div className={styles.title}>Update Reminder</div>
            </>
          )}
          {!event && "Set Reminder"}
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      showDatePicker={false}
      submitText={event ? "Update" : "Set Reminder"}
      hideModal={hideModal}
    >
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <ThemeProvider theme={materialTheme}>
        <ConfirmationDialog
          buttonText={confirmationDialog.buttonText}
          body={
            <div className={styles.confirmationBody}>
              <div className={styles.confirmationTitle}>
                {confirmationDialog.title}
              </div>
              {confirmationDialog.showCheckbox && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={!updateParentPeriodicEvent}
                        onChange={(event) => {
                          setUpdateParentPeriodicEvent(!event.target.checked);
                        }}
                      />
                    }
                    label={confirmationDialog.checkboxLabel}
                  />
                </div>
              )}
            </div>
          }
          open={confirmationDialog.show}
          onClose={() =>
            setConfirmationDialog({
              show: false,
              onConfirm: () => {},
              buttonText: "",
              title: "",
              checkboxLabel: "",
              showCheckbox: true,
            })
          }
          onApprove={confirmationDialog.onConfirm}
          isApproving={isConfirming}
        />
        <div className={styles.modalBody}>
          <div className={styles.section}>
            <SingleInputDropdown
              name={"businessUnit"}
              label={"Business Unit*"}
              values={businessUnits}
              value={selectedBusinessUnit}
              setValue={setSelectedBusinessUnit}
              control={control}
              errors={errors}
              rules={{ required: true }}
              class={styles.input}
              setDefaultValue={event ? true : false}
            />

            <SingleInputDropdown
              name={"item"}
              label={"Item*"}
              values={items}
              value={selectedItem}
              setValue={setSelectedItem}
              control={control}
              errors={errors}
              rules={{ required: true }}
              class={styles.input}
              setDefaultValue={event ? true : false}
            />

            <SingleInputDropdown
              name={"type"}
              label={"Reminder type*"}
              values={reminderTypes}
              value={selectedType}
              setValue={setSelectedType}
              control={control}
              errors={errors}
              rules={{ required: true }}
              class={styles.input}
              setDefaultValue={event ? true : false}
            />

            <TextField
              classes={{
                root: classNames(styles.input),
              }}
              error={
                errors.amount === undefined &&
                errors.paymentMethod !== undefined
              }
              type={"number"}
              label="Amount (in ₹)"
              defaultValue={event && event.amount !== 0 ? event.amount : ""}
              fullWidth
              margin="none"
              variant="outlined"
              name="amount"
              inputRef={register({ valueAsNumber: true })}
            />

            <SingleInputDropdown
              name={"paymentMethod"}
              label={"Payment Method"}
              values={paymentMethods}
              value={selectedPaymentMethod}
              setValue={setSelectedPaymentMethod}
              control={control}
              errors={errors}
              class={styles.input}
              setDefaultValue={event && event.paymentMethod ? true : false}
              rules={{
                validate: () => {
                  const amount = getValues("amount");
                  if (amount === undefined || isNaN(amount) || amount === 0) {
                    if (selectedPaymentMethod.length > 0) {
                      return false;
                    }
                  } else {
                    if (selectedPaymentMethod.length === 0) {
                      return false;
                    }
                  }
                  return true;
                },
              }}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <SingleInputDropdown
              name={"eventFrequency"}
              label={"Reminder Frequency*"}
              values={eventFrequencies}
              value={selectedEventFrequency}
              setValue={setSelectedEventFrequency}
              control={control}
              errors={errors}
              rules={{ required: true }}
              class={styles.input}
              disabled={event ? true : false}
              setDefaultValue={event ? true : false}
            />
            <div className={styles.defaultMargin}>
              <Controller
                control={control}
                name="startDate"
                rules={{ required: true }}
                defaultValue={selectedStartDate}
                render={({ onChange }) => (
                  <DatePicker
                    views={["date"]}
                    autoOk={true}
                    value={selectedStartDate}
                    onChange={(date) => {
                      setSelectedStartDate(date);
                      onChange(date);
                    }}
                    variant={"inline"}
                    disablePast
                    disabled={event ? true : false}
                    inputVariant="outlined"
                    format="MMM dd"
                    label={
                      selectedEventFrequency !== EventFrequency.ONE_TIME
                        ? "Start Date"
                        : "Date"
                    }
                    error={errors.startDate !== undefined}
                    InputProps={{
                      className: classNames(styles.datesWidth),
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            {selectedEventFrequency !== EventFrequency.ONE_TIME && (
              <div className={styles.defaultMargin}>
                <Controller
                  control={control}
                  name="endDate"
                  rules={{ required: true }}
                  defaultValue={selectedEndDate}
                  render={({ onChange }) => (
                    <DatePicker
                      views={["date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={(date) => {
                        setSelectedEndDate(date);
                        onChange(date);
                      }}
                      disabled={
                        selectedEventFrequency.length === 0 ||
                        selectedEventFrequency === EventFrequency.ONE_TIME
                      }
                      variant={"inline"}
                      disablePast
                      inputVariant="outlined"
                      format="MMM dd"
                      label="End Date"
                      error={errors.endDate !== undefined}
                      InputProps={{
                        className: classNames(styles.datesWidth),
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            )}

            <div
              style={{
                marginTop: 12,
                marginLeft: 8,
              }}
            >
              <Controller
                control={control}
                name="remindersOn"
                defaultValue={remindersOn}
                render={({ onChange }) => (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Set Reminder</FormLabel>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={remindersOn.includes(
                              EventReminderFrequency.ON_THE_DAY
                            )}
                            onChange={(event) => {
                              handleRemindersChange(
                                EventReminderFrequency.ON_THE_DAY,
                                event.target.checked,
                                onChange
                              );
                            }}
                          />
                        }
                        label={displayNameForEventReminderFrequency(
                          EventReminderFrequency.ON_THE_DAY
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={remindersOn.includes(
                              EventReminderFrequency.TWO_DAYS_BEFORE
                            )}
                            onChange={(event) => {
                              handleRemindersChange(
                                EventReminderFrequency.TWO_DAYS_BEFORE,
                                event.target.checked,
                                onChange
                              );
                            }}
                          />
                        }
                        label={displayNameForEventReminderFrequency(
                          EventReminderFrequency.TWO_DAYS_BEFORE
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={remindersOn.includes(
                              EventReminderFrequency.ONE_WEEK_BEFORE
                            )}
                            onChange={(event) => {
                              handleRemindersChange(
                                EventReminderFrequency.ONE_WEEK_BEFORE,
                                event.target.checked,
                                onChange
                              );
                            }}
                          />
                        }
                        label={displayNameForEventReminderFrequency(
                          EventReminderFrequency.ONE_WEEK_BEFORE
                        )}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={remindersOn.includes(
                              EventReminderFrequency.ONE_MONTH_BEFORE
                            )}
                            onChange={(event) => {
                              handleRemindersChange(
                                EventReminderFrequency.ONE_MONTH_BEFORE,
                                event.target.checked,
                                onChange
                              );
                            }}
                          />
                        }
                        label={displayNameForEventReminderFrequency(
                          EventReminderFrequency.ONE_MONTH_BEFORE
                        )}
                      />
                    </FormGroup>
                  </FormControl>
                )}
              />
            </div>
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <MultiInputDropdown
              name={"ownerIds"}
              headerLabel={"Owners*"}
              label={"owner(s)"}
              maxSelectableItems={2}
              values={allOwnerEmployees}
              value={ownerEmployeeIds}
              setValue={setOwnerEmpoyeeIds}
              control={control}
              errors={errors}
              rules={{
                validate: () => {
                  return ownerEmployeeIds.length > 0;
                },
              }}
              class={styles.input}
              setDefaultValue={initialOwnerEmployeeIds.length > 0}
            />

            <MultiInputDropdown
              name={"sendReminderToUserIds"}
              headerLabel={"Send reminder SMS to"}
              label={"user(s)"}
              values={allEmployees}
              value={sendReminderToUserIds}
              setValue={setSendReminderToUserIds}
              control={control}
              errors={errors}
              class={styles.input}
              setDefaultValue={
                event && event.targetEmployeesForReminders ? true : false
              }
            />

            <TextField
              type={"text"}
              label="Remark"
              multiline
              defaultValue={event ? event.remark : ""}
              margin="normal"
              variant="outlined"
              name="remark"
              rows={5}
              inputRef={register()}
              classes={{
                root: classNames(styles.input),
              }}
            />
          </div>
        </div>
      </ThemeProvider>
    </ModalPopup>
  );
};

import styles from "./ApprovalDialogWithComment.module.css";
import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Button, Dialog, IconButton, TextField } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IApprovalDialogWithComment {
  className?: string;
  open: boolean;
  onClose: () => void;
  onApprove: () => void;
  isApproving: boolean;
  onComment: (comment: string) => void;
  isCommenting: boolean;
}

export const ApprovalDialogWithComment: React.FC<IApprovalDialogWithComment> = (
  props
) => {
  const [showErrorOnComment, setShowErrorOnComment] = useState(false);
  const [comment, setComment] = useState("");
  const onComment = () => {
    if (comment.length === 0) {
      setShowErrorOnComment(true);
      return;
    }

    props.onComment(comment);
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <div className={styles.modalCloseIcon}>
        <IconButton name="close" color="inherit" onClick={props.onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className={classNames(styles.approval, props.className)}>
        <div className={styles.body}>
          <div className={styles.approvalText}>
            Do you want to approve this item?
          </div>
        </div>
        <Button
          onClick={props.onApprove}
          className={styles.approvalButton}
          style={{ backgroundColor: primaryColor, color: primaryTextColor }}
        >
          <Loading text="Approve" isLoading={props.isApproving} />
        </Button>

        <div className={styles.line}>
          <span>OR</span>
        </div>

        <div className={styles.comment}>
          <TextField
            style={{ margin: 8 }}
            InputProps={{
              style: {
                minHeight: 100,
              },
            }}
            error={showErrorOnComment}
            type={"text"}
            label="Comment"
            fullWidth
            multiline={true}
            margin="normal"
            variant="outlined"
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
        </div>

        <Button onClick={onComment} className={styles.commentButton}>
          <Loading text="Comment" isLoading={props.isCommenting} />
        </Button>
      </div>
    </Dialog>
  );
};

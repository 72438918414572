import {
  BranchSelector,
  IBranchSelectorProps,
} from "components/branchSelector/BranchSelector";
import ContentPage from "components/contentPage/ContentPage";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { useLocalBranch } from "hooks/LocalStorage";
import React, { useContext } from "react";
import { IBranch } from "types/Branch";
import styles from "./SalesForecast.module.css";
import { PredictionsPreparedMaterialTable } from "components/predictions/prepared/PredictionsPreparedMaterialTable";

const SalesForecast: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  if (!selectedBranch) {
    return <></>;
  }

  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch!}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={<MainSection selectedBranch={selectedBranch} />}
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Sales Forecast"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch }> = (props) => (
  <>
    <PredictionsPreparedMaterialTable selectedBranch={props.selectedBranch} />
  </>
);

export default SalesForecast;

import { useState } from "react";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { parse } from "date-fns";

export interface ISelectedDatesHookValues {
  start: Date;
  end: Date;
  startV2: Date | null;
  endV2: Date | null;
  selectedDate: Date | null;
  selectedDateFrom: Date | null;
  selectedDateTo: Date | null;
  handleDateChangeFrom: (date: MaterialUiPickersDate) => void;
  handleDateChangeTo: (date: MaterialUiPickersDate) => void;
  handleDateChange: (date: MaterialUiPickersDate) => void;
}

export const useQuerySelectedFromToDates = (
  graphs: boolean = false,
  tableDefault: "month" | "date" = "date",
  shouldInitializeDates: boolean = true
): ISelectedDatesHookValues => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  let startDate: Date | null = null;
  let endDate: Date | null = null;
  if (params != null) {
    if (params.startDate != null) {
      let startDateParam = params.startDate as string;
      startDate = parse(startDateParam, "yyyy-MM-dd", new Date());
      if (startDate > new Date()) {
        startDate = null;
      }
    }
    if (params.endDate != null) {
      let endDataParam = params.endDate as string;
      endDate = parse(endDataParam, "yyyy-MM-dd", new Date());
      if (endDate > new Date()) {
        endDate = null;
      }
    }
  }

  const [selectedDateFrom, handleDateChangeFrom] = useState<
    MaterialUiPickersDate
  >(startDate);
  const [selectedDateTo, handleDateChangeTo] = useState<MaterialUiPickersDate>(
    endDate
  );

  let defaultSelectedDate: Date | null = null;
  if ((!startDate || !endDate) && shouldInitializeDates) {
    defaultSelectedDate = new Date();
  }

  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    defaultSelectedDate
  );

  let start: Date;
  let end: Date;
  let startV2: Date | null;
  let endV2: Date | null;
  if (selectedDateTo && selectedDateFrom) {
    start = startV2 = selectedDateFrom;
    end = endV2 = selectedDateTo;
  } else {
    if (graphs) {
      if (selectedDate !== null) {
        const dateValues = defaultDatesForGraphs(selectedDate);
        start = startV2 = dateValues.start;
        end = endV2 = dateValues.end;
      } else {
        const dateValues = defaultDatesForTables(new Date(), tableDefault);
        start = dateValues.start;
        end = dateValues.end;
        startV2 = null;
        endV2 = null;
      }
    } else {
      if (selectedDate !== null) {
        const dateValues = defaultDatesForTables(selectedDate, tableDefault);
        start = dateValues.start;
        end = dateValues.end;
        startV2 = start;
        endV2 = end;
      } else {
        const dateValues = defaultDatesForTables(new Date(), tableDefault);
        start = dateValues.start;
        end = dateValues.end;
        startV2 = null;
        endV2 = null;
      }
    }
  }
  return {
    start,
    end,
    startV2,
    endV2,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  };
};

const defaultDatesForTables = (
  selectedDate: Date,
  tableDefault: "month" | "date"
) => {
  if (tableDefault === "month") {
    const start = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      1
    );
    const end = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      0
    );
    return { start, end };
  }

  const date = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate()
  );

  return { start: date, end: date };
};

const defaultDatesForGraphs = (selectedDate: Date) => {
  const start = moment(selectedDate).subtract(5, "months").toDate();
  return { start, end: selectedDate };
};


import { IGetSemiPreparedMaterialConsumptionResponse, getConsumptionEstimatesForSemiPreparedMaterials } from "api/GetPredictions";
import { formatStartEndDates } from "lib/Date";
import { QueryStatus, useQuery } from "react-query";
import { FMSError } from "types/Error";

interface IQueryPredictionsPreparedMaterial {
  status: QueryStatus;
  getSemiPreparedMaterialConsumptionResponse: IGetSemiPreparedMaterialConsumptionResponse | FMSError | undefined;
  isFetching: boolean;
}

export const useQuerySemiPredictionsPreparedMaterial = (
  startDate: Date,
  endDate: Date
): IQueryPredictionsPreparedMaterial => {
  const [start, end] = formatStartEndDates(startDate, endDate, true);
  const {
    status,
    data: getSemiPreparedMaterialConsumptionResponse,
    isFetching,
  } = useQuery(
    ["semiPredictionsPreparedMaterial", start, end],
    async (_: string, start: string, end: string) => {
      return await getConsumptionEstimatesForSemiPreparedMaterials(start, end);
    }
  );
  return { status, getSemiPreparedMaterialConsumptionResponse, isFetching };
};

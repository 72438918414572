import React, { useEffect, useState } from "react";
import styles from "./StockTable.module.css";
import { IBranch } from "types/Branch";
import {
  DataTableWithDates,
  IReactTableInitialStateFilters,
} from "components/dataTableWithDates/DataTableWithDates";
import { IStockFood, IStockForAllBranches } from "types/Stock";
import { invalidateStockEntriesCache, useQueryStock } from "hooks/Stock";
import moment from "moment";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { Button, MenuItem, Select } from "@material-ui/core";
import { displayQuantity } from "types/MeasurementUnit";
import { HeaderGroup } from "react-table";
import CSVDownload from "components/utils/CSVDownload";
import { getStockDataForExport } from "lib/CSVExportUtils";
import { ModifyStockModal } from "components/modifyStockModal/ModifyStockModal";
import { Auth } from "lib/Auth";
import { useHistory, useLocation } from "react-router-dom";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import queryString from "query-string";

interface IFilterProps {
  column: HeaderGroup;
}

export const stockForBranch = (
  stockForAllBranches: IStockForAllBranches[],
  selectedBranch: IBranch
): IStockFood[] => {
  for (let i = 0; i < stockForAllBranches.length; i++) {
    if (stockForAllBranches[i].branchId === selectedBranch.id) {
      return stockForAllBranches[i].stockList;
    }
  }
  return [];
};

function SelectFavouritesFilter(props: IFilterProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const params = queryString.parse(location.search);
  return (
    <Select
      value={props.column.filterValue ?? ""}
      displayEmpty
      onChange={(e) => {
        const materialFilter = (e.target.value ?? "") as string;
        props.column.setFilter(materialFilter);
        let searchParams = "";
        if (materialFilter.length > 0) {
          params.materialFilter = materialFilter;
          searchParams = queryString.stringify(params);
        } else {
          searchParams = queryString.exclude(location.search, [
            "materialFilter",
          ]);
        }

        history.push({
          pathname: currentPath,
          search: searchParams,
        });
      }}
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="favourites">Favourites</MenuItem>
    </Select>
  );
}

function filterFavourites(
  rows: [any],
  filterValue: string,
  selectedBranch: IBranch
) {
  if (filterValue === "favourites") {
    return rows.filter((row) => {
      const dashboardMaterialId = row.original.materialId as string;

      if (
        selectedBranch.favourites &&
        selectedBranch.favourites?.rawStock?.length > 0
      ) {
        return selectedBranch.favouriteRawStockMap.has(dashboardMaterialId);
      }
      return false;
    });
  }
  return rows;
}

export const StockTable: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();
  const { status, stocks, isFetching } = useQueryStock(start, end);
  const [stock, setStock] = useState<IStockFood[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<{
    materialId: string;
    materialName: string;
  }>();
  const location = useLocation();
  const params = queryString.parse(location.search);
  useEffect(() => {
    if (status === "success") {
      if (!stocks || stocks instanceof FMSError) {
        const message = stocks?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setStock(stockForBranch(stocks, props.selectedBranch));
      }
    }
  }, [status, stocks, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            width: 250,
            Filter: SelectFavouritesFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterFavourites(rows, filterValue, props.selectedBranch);
            },
            disableSortBy: true,
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.materialName.toUpperCase() >
                rowB.original.materialName.toUpperCase()
                ? 1
                : -1;
            },
            Cell: (props: any) => {
              const stockFood = props.row.original as IStockFood;
              const history = useHistory();
              const queryStr = useLocation().search;
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    if (Auth.getInstance().isAdminOrSupervisor()) {
                      setSelectedMaterial({
                        materialId: stockFood.materialId,
                        materialName: stockFood.materialName,
                      });
                    } else {
                      history.push({
                        pathname: `/stock-stats/${stockFood.materialId}`,
                        search: queryStr,
                      });
                    }
                  }}
                >
                  {stockFood.materialName}
                </Button>
              );
            },
          },
          {
            Header: "Opening Stock",
            accessor: (row: IStockFood) =>
              `${displayQuantity(row.displayOpeningQuantity)}`,
            width: 130,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Purchased",
            accessor: (row: IStockFood) =>
              `${displayQuantity(row.displayQuantity)}`,
            width: 130,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Closing Stock",
            accessor: (row: IStockFood) =>
              `${displayQuantity(row.displayClosingQuantity)}`,
            width: 130,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Consumption",
            accessor: (row: IStockFood) =>
              `${displayQuantity(row.displayConsumptionQuantity)}`,
            width: 130,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Last update",
            sortType: (rowA: any, rowB: any) => {
              if (!rowA.original.lastUpdated) {
                return -1;
              }

              if (!rowB.original.lastUpdated) {
                return 1;
              }

              return rowA.original.lastUpdated > rowB.original.lastUpdated
                ? 1
                : -1;
            },
            accessor: (row: IStockFood) =>
              row.lastUpdated
                ? moment(row.lastUpdated).format("DD-MM-yyyy")
                : "-",
            width: 130,
            disableGlobalFilter: true,
            disableFilters: true,
          },
        ],
      },
    ],
    [props.selectedBranch]
  );

  const initialStateFilters: IReactTableInitialStateFilters[] = [];

  if (params.materialFilter) {
    initialStateFilters.push({
      id: "materialName",
      value: params.materialFilter,
    });
  }

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <div
        style={{
          textAlign: "end",
        }}
      >
        <CSVDownload
          filename={`Stock-${props.selectedBranch.name}-${moment(start).format(
            "DD MMM"
          )}-to-${moment(end).format("DD MMM")}`}
          data={getStockDataForExport(stock)}
        />
      </div>
      {selectedMaterial && (
        <ModifyStockModal
          material={
            props.materialContextValues.allMaterialsMap.get(
              selectedMaterial.materialId
            )!
          }
          startDate={start}
          endDate={end}
          selectedBranch={props.selectedBranch}
          onClose={(isStockEntryUpdated) => {
            setSelectedMaterial(undefined);
            if (isStockEntryUpdated) {
              invalidateStockEntriesCache();
            }
          }}
        />
      )}
      <DataTableWithDates
        isLoading={isFetching}
        columns={columns}
        data={stock}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        initialSortBy={"materialName"}
        initialStateFilters={initialStateFilters}
      />
    </>
  );
};

import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { ISupplier } from "types/Supplier";
import { getSupplier, getSuppliers } from "api/GetSupplier";

interface IQuerySuppliers {
  status: QueryStatus;
  supplierList: ISupplier[] | FMSError | undefined;
  isFetching: boolean;
}

interface IQuerySupplier {
  status: QueryStatus;
  supplierResponse: ISupplier | FMSError | undefined;
  isFetching: boolean;
}

export const useQuerySuppliers = (): IQuerySuppliers => {
  const {
    status,
    data: supplierList,
    isFetching,
  } = useQuery(["suppliers"], async (key: string) => {
    return await getSuppliers();
  });

  if (supplierList && !(supplierList instanceof FMSError)) {
    supplierList.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
  }

  return { status, supplierList, isFetching };
};

export const invalidateSuppliersCache = () => {
  queryCache.invalidateQueries("suppliers");
};

export const useQuerySupplier = (supplierId: string): IQuerySupplier => {
  const {
    status,
    data: supplier,
    isFetching,
  } = useQuery(["supplier-".concat(supplierId)], async (key: string) => {
    return await getSupplier(supplierId);
  });
  return { status, supplierResponse: supplier, isFetching };
};

import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  MenuItem,
  Select,
} from "@material-ui/core";
import { downloadEvents } from "api/GetEvents";
import { DataTable } from "components/dataTable/DataTable";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { ViewReminderModal } from "components/viewReminderModal/ViewReminderModal";
import { ViewReminderNonEditableModal } from "components/viewReminderNonEditableModal/ViewReminderNonEditableModal";
import { themeConfig } from "configs/ConfigProvider";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { useQueryReminders } from "hooks/Reminders";
import { Auth } from "lib/Auth";
import { today, dateAddingDaysFromDate } from "lib/Date";
import { sortStrings } from "lib/StringHelpers";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { HeaderGroup } from "react-table";
import { FMSError } from "types/Error";
import {
  DerivedEventStatus,
  derivedEventStatusFromEventStatus,
  displayNameForDerivedEventStatus,
  EventCategoryType,
  EventStatus,
  IEvent,
  IEventCategories,
} from "types/Event";
import { IReminderDateRange } from "types/ReminderDateRange";
import styles from "./RemindersTable.module.css";
import { Loading } from "components/loading/Loading";

interface IFilterProps {
  column: HeaderGroup;
}

function getColorsForEvent(
  event: IEvent,
  theme: ReturnType<typeof themeConfig>
) {
  let backgroundColor = "#efefef";
  let textColor = "#4d4d4d";
  let dividerColor = "#cecece";

  const todaysDate = today();
  if (
    event.date > todaysDate &&
    event.date <= dateAddingDaysFromDate(todaysDate, 7)
  ) {
    backgroundColor = theme.reminderColors.upcomingPrimaryColor;
    textColor = theme.reminderColors.upcomingPrimaryTextColor;
    dividerColor = theme.reminderColors.upcomingDividerColor;
  }

  return { backgroundColor, textColor, dividerColor };
}

const DateComponent: React.FC<{
  date: Date;
  backgroundColor: string;
  textColor: string;
  dividerColor: string;
}> = (props) => {
  const { date, backgroundColor, textColor, dividerColor } = props;
  return (
    <Card
      style={{
        width: 70,
        height: 50,
      }}
    >
      <CardContent
        style={{
          padding: 5,
          textAlign: "center",
          backgroundColor: backgroundColor,
          color: textColor,
          fontSize: 14,
          fontWeight: "bold",
        }}
      >
        {moment(date).format("DD MMM").toUpperCase()}
        <Divider
          style={{
            marginLeft: 5,
            width: 50,
            backgroundColor: dividerColor,
          }}
        />
        {moment(date).format("ddd").toUpperCase()}
      </CardContent>
    </Card>
  );
};

export const RemindersTable: React.FC<{
  selectedDateRange: IReminderDateRange;
}> = (props) => {
  const { start, end } = props.selectedDateRange.dateRange();
  const { status, getEventsResponse, isFetching } = useQueryReminders(
    start,
    end
  );

  const [reminders, setReminders] = useState<IEvent[]>([]);
  const [eventCategories, setEventCategories] = useState<IEventCategories>({
    id: "",
    businessUnits: [],
    types: [],
    items: [],
  });

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [showModal, setShowModal] = useState<{
    selectedEvent: IEvent;
    modalType: "ViewOnly" | "ReviewOrComplete";
  }>();

  const theme = useContext(FMSThemeContext);

  const [eventStatusFilters, setEventStatusFilters] = useState<string[]>([]);

  const [isDownloading, setDownloading] = useState(false);

  useEffect(() => {
    if (status === "success") {
      if (!getEventsResponse || getEventsResponse instanceof FMSError) {
        const message = getEventsResponse?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        const remindersList = getEventsResponse.events.concat(
          getEventsResponse.overdueEventViews
        );

        const uniqueRemindersList = [
          ...new Map(
            remindersList.map((reminder) => [reminder.id, reminder])
          ).values(),
        ];
        setReminders(uniqueRemindersList);
        const businessUnits = new Set<string>();
        const items = new Set<string>();
        const types = new Set<string>();
        const eventStatuses = new Set<string>();
        uniqueRemindersList.forEach((event) => {
          businessUnits.add(event.businessUnit);
          items.add(event.item);
          types.add(event.type);
          event.derivedStatus = derivedEventStatusFromEventStatus(
            event.status,
            event.date
          );
          eventStatuses.add(
            displayNameForDerivedEventStatus(event.derivedStatus)
          );
        });

        setEventCategories({
          businessUnits: sortStrings([...businessUnits]),
          items: sortStrings([...items]),
          types: sortStrings([...types]),
        } as IEventCategories);

        setEventStatusFilters(sortStrings([...eventStatuses]));
      }
    }
  }, [status, getEventsResponse]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const SelectBusinessUnitFilter = useCallback(
    (props: IFilterProps) => {
      return (
        <Select
          value={props.column.filterValue ?? ""}
          displayEmpty
          onChange={(e) => {
            props.column.setFilter(e.target.value ?? "");
          }}
        >
          <MenuItem value="">All</MenuItem>
          {eventCategories.businessUnits.map((businessUnit) => (
            <MenuItem key={businessUnit} value={businessUnit}>
              {businessUnit}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [eventCategories.businessUnits]
  );

  const SelectItemFilter = useCallback(
    (props: IFilterProps) => {
      return (
        <Select
          value={props.column.filterValue ?? ""}
          displayEmpty
          onChange={(e) => {
            props.column.setFilter(e.target.value ?? "");
          }}
        >
          <MenuItem value="">All</MenuItem>
          {eventCategories.items.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [eventCategories.items]
  );
  const SelectTypeFilter = useCallback(
    (props: IFilterProps) => {
      return (
        <Select
          value={props.column.filterValue ?? ""}
          displayEmpty
          onChange={(e) => {
            props.column.setFilter(e.target.value ?? "");
          }}
        >
          <MenuItem value="">All</MenuItem>
          {eventCategories.types.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [eventCategories.types]
  );

  const SelectStatusFilter = useCallback(
    (props: IFilterProps) => {
      return (
        <Select
          value={props.column.filterValue ?? ""}
          displayEmpty
          onChange={(e) => {
            props.column.setFilter(e.target.value ?? "");
          }}
        >
          <MenuItem value="">All</MenuItem>
          {eventStatusFilters.map((eventStatus) => (
            <MenuItem key={eventStatus} value={eventStatus}>
              {eventStatus}
            </MenuItem>
          ))}
        </Select>
      );
    },
    [eventStatusFilters]
  );

  const filterStatus = (rows: [any], filterValue: string) => {
    return rows.filter(
      (row) =>
        displayNameForDerivedEventStatus(row.original.derivedStatus) ===
          filterValue || filterValue.length === 0
    );
  };

  const filterEventCategory = (
    rows: [any],
    filterValue: string,
    eventCategoryType: EventCategoryType
  ) => {
    if (eventCategoryType === EventCategoryType.BUSINESS_UNIT) {
      return rows.filter(
        (row) =>
          row.original.businessUnit === filterValue || filterValue.length === 0
      );
    } else if (eventCategoryType === EventCategoryType.ITEMS) {
      return rows.filter(
        (row) => row.original.item === filterValue || filterValue.length === 0
      );
    } else if (eventCategoryType === EventCategoryType.TYPES) {
      return rows.filter(
        (row) => row.original.type === filterValue || filterValue.length === 0
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Reminders Table",
        columns: [
          {
            Header: "Due Date",
            accessor: "date",
            width: 100,
            sortType: (rowA: any, rowB: any) => {
              const eventA = rowA.original as IEvent;
              const eventB = rowB.original as IEvent;
              return moment(eventA.date).diff(moment(eventB.date));
            },
            Cell: (props: any) => {
              const event = props.row.original as IEvent;
              const colors = getColorsForEvent(event, theme);
              return (
                <DateComponent
                  date={event.date}
                  backgroundColor={colors.backgroundColor}
                  textColor={colors.textColor}
                  dividerColor={colors.dividerColor}
                />
              );
            },
            disableFilters: true,
          },
          {
            Header: "Business Unit",
            accessor: "businessUnit",
            width: 200,
            Filter: SelectBusinessUnitFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterEventCategory(
                rows,
                filterValue,
                EventCategoryType.BUSINESS_UNIT
              );
            },
          },
          {
            Header: "Item",
            accessor: "item",
            width: 180,
            Filter: SelectItemFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterEventCategory(
                rows,
                filterValue,
                EventCategoryType.ITEMS
              );
            },
          },
          {
            Header: "Type",
            accessor: "type",
            width: 180,
            Filter: SelectTypeFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterEventCategory(
                rows,
                filterValue,
                EventCategoryType.TYPES
              );
            },
          },
          {
            Header: "Amount",
            accessor: (row: IEvent) =>
              row.amount === 0 ? "-" : `Rs. ${row.amount}`,
            width: 100,
            disableFilters: true,
          },
          {
            Header: "Status",
            width: 120,
            Cell: (props: any) => {
              const event = props.row.original as IEvent;
              if (event.status === undefined) {
                return <></>;
              }

              let chipStyle: string | undefined;
              if (event.derivedStatus === DerivedEventStatus.REVIEWED) {
                chipStyle = styles.green;
              } else if (event.derivedStatus === DerivedEventStatus.COMPLETE) {
                chipStyle = styles.blue;
              } else if (event.derivedStatus === DerivedEventStatus.OVERDUE) {
                chipStyle = styles.red;
              } else {
                chipStyle = styles.yellow;
              }

              return (
                <Chip
                  style={{
                    width: 80,
                  }}
                  label={displayNameForDerivedEventStatus(event.derivedStatus)}
                  className={chipStyle}
                  onClick={() => {
                    switch (event.status) {
                      case EventStatus.PENDING:
                        setShowModal({
                          selectedEvent: event,
                          modalType: "ReviewOrComplete",
                        });
                        break;
                      case EventStatus.REVIEWED:
                        setShowModal({
                          selectedEvent: event,
                          modalType: "ViewOnly",
                        });
                        break;
                      case EventStatus.COMPLETE:
                        if (Auth.getInstance().isAdmin()) {
                          setShowModal({
                            selectedEvent: event,
                            modalType: "ReviewOrComplete",
                          });
                        } else {
                          setShowModal({
                            selectedEvent: event,
                            modalType: "ViewOnly",
                          });
                        }
                        break;
                    }
                  }}
                />
              );
            },
            Filter: SelectStatusFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterStatus(rows, filterValue);
            },
          },
        ],
      },
    ],
    [
      theme,
      SelectBusinessUnitFilter,
      SelectItemFilter,
      SelectTypeFilter,
      SelectStatusFilter,
    ]
  );

  const downloadEventsFile = async () => {
    setDownloading(true);
    const error = await downloadEvents();
    setDownloading(false);
    if (error) {
      setShowToast({
        message: error.message ?? "Something went wrong!",
        open: true,
        type: "error",
      });
    }
  };

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      {Auth.getInstance().isAdmin() && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="text"
            style={{
              color: "-webkit-link",
              textDecoration: "underline",
            }}
            onClick={downloadEventsFile}
            disabled={isDownloading}
          >
            <Loading
              text="Export Reminders"
              isLoading={isDownloading}
              spinnerSize={20}
            />
          </Button>
        </div>
      )}
      <DataTable
        columns={columns}
        data={reminders}
        tableClass={styles.table}
        isLoading={isFetching}
        marginTop={0}
        itemHeight={60}
        tableHeight={500}
        initialSortBy="date"
      />

      {showModal && showModal.modalType === "ReviewOrComplete" && (
        <ViewReminderModal
          event={showModal.selectedEvent}
          onClose={(message) => {
            setShowModal(undefined);
            if (message) {
              setShowToast({
                open: true,
                message: message,
                type: "success",
              });
            }
          }}
        />
      )}

      {showModal && showModal.modalType === "ViewOnly" && (
        <ViewReminderNonEditableModal
          event={showModal.selectedEvent}
          onClose={() => {
            setShowModal(undefined);
          }}
        />
      )}
    </>
  );
};

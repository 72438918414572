import { Avatar, Button } from "@material-ui/core";
import { DataTable } from "components/dataTable/DataTable";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ISupplier } from "types/Supplier";
import CreateIcon from "@material-ui/icons/Create";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { deactivateSupplier } from "api/DeactivateSupplier";
import { invalidateSuppliersCache, useQuerySuppliers } from "hooks/Suppliers";
import { FMSError } from "types/Error";
import { useHistory } from "react-router-dom";
import styles from "./ViewSuppliersTable.module.css";
import { Auth } from "lib/Auth";
import { activateSupplier } from "api/PostSupplier";

export const ViewSuppliersTable: React.FC = () => {
  const [selectedSupplierToDeactivate, setSelectedSupplierToDeactivate] = useState<ISupplier>();
  const [selectedSupplierToActivate, setSelectedSupplierToActivate] = useState<ISupplier>();
  const [showDeactivateConfirmation, setShowDeactivateConfirmation] = useState<boolean>(false);
  const [showActivateConfirmation, setShowActivateConfirmation] = useState<boolean>(false);
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const [highlightedRowIndexes, setHighlightedRowIndexes] = useState<number[]>([]);

  const { status, supplierList, isFetching } = useQuerySuppliers();

  const deactivateSuppliers = useCallback(() => {
    const deactivateSuppliersList: number[] = []
    suppliers.forEach((supplier) => {
      if (supplier.isDeactivated) {
        deactivateSuppliersList.push(suppliers.indexOf(supplier))
      }
    })
    setHighlightedRowIndexes(deactivateSuppliersList)
  },
    [suppliers]
  );

  useEffect(() => {
    if (status === "success") {
      if (!supplierList || supplierList instanceof FMSError) {
        const message = supplierList?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSuppliers(supplierList);
        deactivateSuppliers()
      }
    }
  }, [status, supplierList, deactivateSuppliers]);

  const onDeactivateSupplier = async (supplierId: string) => {
    setIsDeactivating(true);
    const error = await deactivateSupplier(supplierId);
    setIsDeactivating(false);
    setShowDeactivateConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Deactivated successfully",
        type: "success",
      });
      invalidateSuppliersCache();
      setSelectedSupplierToDeactivate(undefined);
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const onActivateSupplier = async (supplierId: string) => {
    setIsActivating(true)
    const error = await activateSupplier(supplierId);
    setIsActivating(false)
    setShowActivateConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Activated successfully",
        type: "success",
      });
      invalidateSuppliersCache();
      setSelectedSupplierToActivate(undefined)
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const isAdminOrSupervisor = Auth.getInstance().isAdminOrSupervisor();

  const columns = React.useMemo(
    () => [
      {
        Header: "Suppliers Table",
        columns: [
          {
            Header: "Supplier Name",
            width: 150,
            accessor: "name",
            disableFilters: true,
          },
          {
            Header: "Address",
            width: 250,
            accessor: "address",
            disableFilters: true,
          },
          {
            Header: "Email ID",
            width: 250,
            accessor: "email",
            disableFilters: true,
          },
          {
            Header: "Contact Person",
            width: 120,
            accessor: "contactPerson",
            disableFilters: true,
          },
          {
            width: 140,
            Header: "Contact Number",
            accessor: "contactNumber",
            disableFilters: true,
          },
          {
            Header: " ",
            width: 60,
            Cell: (props: any) => {
              const supplier = props.row.original as ISupplier;
              const history = useHistory();
              if (!supplier.isDeactivated) {
                return (
                  <Avatar
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => {
                      history.push({
                        pathname: `/purchase-orders/suppliers/${supplier.id}`,
                      });
                    }}
                  >
                    <CreateIcon style={{ fontSize: 15 }} />
                  </Avatar>
                );
              } else {
                return (
                  <></>
                );
              }
            },
            disableFilters: true,
          },
          {
            Header: "  ",
            width: isAdminOrSupervisor ? 150 : 1,
            Cell: (props: any) => {
              const row = props.row.original as ISupplier;
              if (isAdminOrSupervisor) {
                const isDeactivated = row.isDeactivated;
                const buttonText = isDeactivated ? "ENABLE" : "DISABLE"
                const backgroundColor = isDeactivated ? "#808080" : primaryColor
                return (
                  <Button
                    style={{
                      backgroundColor: backgroundColor,
                      color: primaryTextColor,
                      fontWeight: "bolder"
                    }}
                    className={styles.addSubmitBtn}
                    onClick={() => {
                      if (!isDeactivated) {
                        setSelectedSupplierToDeactivate(row);
                        setShowDeactivateConfirmation(true);
                      } else {
                        setSelectedSupplierToActivate(row)
                        setShowActivateConfirmation(true)
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                );
              }
              else {
                return <></>;
              }
            },
            disableFilters: true,
          },
        ],
      },
    ],
    [primaryColor, primaryTextColor, isAdminOrSupervisor]
  );

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        buttonText={"Disable"}
        bodyText={`Do you want to disable supplier: ${selectedSupplierToDeactivate?.name}?`}
        open={showDeactivateConfirmation}
        onClose={() => setShowDeactivateConfirmation(false)}
        onApprove={() => {
          if (selectedSupplierToDeactivate) {
            onDeactivateSupplier(selectedSupplierToDeactivate.id);
          }
        }}
        isApproving={isDeactivating}
      />
      <ConfirmationDialog
        buttonText={"Enable"}
        bodyText={`Do you want to enable supplier: ${selectedSupplierToActivate?.name}?`}
        open={showActivateConfirmation}
        onClose={() => setShowActivateConfirmation(false)}
        onApprove={() => {
          if (selectedSupplierToActivate) {
            onActivateSupplier(selectedSupplierToActivate.id);
          }
        }}
        isApproving={isActivating}
      />
      <DataTable
        columns={columns}
        initialSortBy={"name"}
        tableClass={styles.table}
        data={suppliers}
        marginTop={30}
        searchable={false}
        isLoading={isFetching}
        tableHeight={600}
        highlightRows={highlightedRowIndexes}
        highlightColor={"#CCCCCC"}
      />
    </>
  );
};

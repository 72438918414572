import { capitalizeFirstLetter } from "lib/StringHelpers";
import { IExternalBranch } from "./Branch";
import { IEmployee } from "./Employee";
import { IngredientToQuantityMap } from "./Material";
import { IDisplayQuantity, MeasurementUnit } from "./MeasurementUnit";
import { ISupplier } from "./Supplier";

export interface IPostGrn {
  dateOfReceipt: Date;
  gateRegisteredDate: Date;
  gateRegisteredNo: string;
  materialIdToQuantityOverrideMap: IngredientToQuantityMap;
  billNumber: string;
  billDate: Date;
  generatePendingMaterialsPO: boolean;
}

export interface IPostPurchaseOrder {
  supplierId: string;
  deliveryAddress: string;
  materialIdToQuantityMap: Map<string, number>;
  materialIdToExistingStockQuantityMap: Map<string, number>;
  preferredDeliveryDate?: string;
}

export interface IGetPurchaseOrdersResponse {
  purchaseOrders: IPurchaseOrder[];
}

export interface IGetPurchaseOrderResponse {
  purchaseOrderView: IPurchaseOrder;
}

export interface IPurchaseOrder {
  id: string;
  creationTime: number;
  branch: IExternalBranch;
  vanityNumber: string;
  supplierView: ISupplier;
  deliveryAddress: string;
  preferredDeliveryDate?: string;
  purchaseOrderStatus: PurchaseOrderStatus;
  purchaseOrderItems: PurchaseOrderItem[];
  receivedOverrideOrderItems: PurchaseOrderItem[];
  createdByEmployee: IEmployee;
  dateOfReceipt: number;
  gateRegisteredDate: number;
  gateRegisteredNo: string;
  billNumber: string;
  billDate: number;
  parentPurchaseOrderId?: string;
  parentPurhcaseOrderVanityNumber?: string;
}

export interface PurchaseOrderItem {
  materialId: string;
  materialName: string;
  measurementUnit: MeasurementUnit;
  displayQuantity: IDisplayQuantity;
  existingStockDisplayQuantity?: IDisplayQuantity;
}

export enum PurchaseOrderStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  ORDERED = "ORDERED",
  DELIVERED = "DELIVERED",
}

export const displayNameForPurchaseOrderStatus = (
  status: PurchaseOrderStatus
) => {
  return capitalizeFirstLetter(status);
};

export const sortPurchaseOrdersByDate = (purchaseOrders: IPurchaseOrder[]) => {
  return purchaseOrders.sort((a, b) =>
    a.creationTime > b.creationTime ? 1 : -1
  );
};

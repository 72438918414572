import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { IStockTransfersByBranch } from "types/StockTransfer";
import { getStockTransfers } from "api/GetStockTransfers";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  stockTransfers: IStockTransfersByBranch[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryStockTransfers = (
  startDate: Date,
  endDate: Date
): IQueryBranch => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stockTransfers, isFetching } = useQuery(
    ["stockTransfers", start, end],
    async (key: string, start: string, end: string) => {
      return await getStockTransfers(start, end);
    }
  );
  return { status, stockTransfers, isFetching };
};

export const invalidateStockTransferCache = () => {
  queryCache.invalidateQueries("stockTransfers");
};

import React, { useContext } from "react";
import ContentPage from "components/contentPage/ContentPage";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { IBranch } from "types/Branch";
import { SalesReportTable } from "components/salesReportTable/SalesReportTable";
import styles from "./SalesReport.module.css";
import {
  BranchSelector,
  IBranchSelectorProps,
} from "components/branchSelector/BranchSelector";
import { useLocalBranch } from "hooks/LocalStorage";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddSalesModal } from "components/addSalesModal/AddSalesModal";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { AddComplimentaryFoodModal } from "components/addComplimentaryFoodModal/AddComplimentaryFoodModal";
import SalesIcon from "assets/SalesIcon";
import ComplimentaryFoodIcon from "assets/ComplimentaryFoodIcon";

const SalesReport: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  // // TODO handle no branches error
  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={<MainSection selectedBranch={selectedBranch} />}
      rightSection={
        <RightSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
          selectedBranch={selectedBranch}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Sales Report"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch }> = (props) => (
  <>
    <SalesReportTable selectedBranch={props.selectedBranch} />
  </>
);

const RightSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => (
  <>
    <ModalWithButton title={"Add Sales"} icon={<SalesIcon />}>
      {" "}
      <AddSalesModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
    <ModalWithButton
      title={"Add Complimentary Food"}
      icon={<ComplimentaryFoodIcon />}
    >
      {" "}
      <AddComplimentaryFoodModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
  </>
);

export const DateSection: React.FC<{ lastUpdatedDate: string }> = (props) => (
  <>
    <div className={styles.date}>Last Updated: {props.lastUpdatedDate}</div>
  </>
);

export default SalesReport;

import { BorderLessSelectInput } from "components/customSelectInput/CustomSelectInput";
import React, { useState, useEffect } from "react";
import { MenuItem, Select } from "@material-ui/core";
import styles from "./MaterialSelector.module.css";
import { IMaterial } from "types/Material";

export interface IMaterialSelectorProps {
  materials: IMaterial[];
  materialsMap: Map<string, IMaterial>;
  selectedMaterial: IMaterial;
  setSelectedMaterial: (material: IMaterial) => void;
}

export const MaterialSelector: React.FC<IMaterialSelectorProps> = (props) => {
  const {
    materials,
    setSelectedMaterial,
    selectedMaterial,
    materialsMap,
  } = props;
  const [materialId, setMaterialSelected] = useState<string | null>(null);

  useEffect(() => {
    if (materialId) {
      setSelectedMaterial(materialsMap.get(materialId)!);
    }
  }, [materialId, materialsMap, setSelectedMaterial]);

  return (
    <Select
      input={<BorderLessSelectInput />}
      className={styles.select}
      classes={{ root: styles.root }}
      value={selectedMaterial.id}
      onChange={(event) => setMaterialSelected(event.target.value as string)}
    >
      {/* TODO replace this with favorites */}
      {materials.map((material, index) => (
        <MenuItem value={material.id} key={index}>
          {material.name}
        </MenuItem>
      ))}
    </Select>
  );
};

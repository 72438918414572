import { Button } from "@material-ui/core";
import AddEventCategoriesListView from "components/addEventCategoriesListView/AddEventCategoriesListView";
import ContentPage from "components/contentPage/ContentPage";
import React from "react";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const AddEventCategories: React.FC = React.memo(() => {
  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = () => {
  const history = useHistory();
  return (
    <>
      <Button
        onClick={() => {
          history.replace("/reminders");
        }}
        style={{
          border: "1px solid #9B9B9B",
          borderRadius: "3px",
          color: "black",
          height: "35px",
          width: "100px",
          padding: "0 30px",
        }}
      >
        <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
      </Button>
    </>
  );
};

const MainSection: React.FC = () => <AddEventCategoriesListView />;

const RightSection: React.FC = () => <></>;

export default AddEventCategories;

import React, { useState, useEffect, useMemo } from "react";
import { MenuItem, Select } from "@material-ui/core";
import { IBranch } from "types/Branch";
import styles from "./BranchSelector.module.css";

export interface IBranchSelectorProps {
  branches: IBranch[];
  selectedBranch: IBranch;
  setSelectedBranch: (branch: IBranch) => void;
}

export const BranchSelector: React.FC<IBranchSelectorProps> = (props) => {
  const { branches, setSelectedBranch, selectedBranch } = props;
  let branchMap = useMemo(() => {
    const map = new Map();
    branches.forEach((branch) => map.set(branch.id, branch));
    return map;
  }, [branches]);

  const [branchIdSelected, setBranchSelected] = useState<string | null>(null);

  useEffect(() => {
    if (branchIdSelected) {
      setSelectedBranch(branchMap.get(branchIdSelected));
    }
  }, [branchIdSelected, branchMap, setSelectedBranch]);

  return (
    <Select
      className={styles.select}
      disableUnderline
      classes={{ root: styles.root }}
      value={selectedBranch.id}
      onChange={(event) => setBranchSelected(event.target.value as string)}
    >
      {/* TODO replace this with favorites */}
      {branches.map((branch, index) => (
        <MenuItem value={branch.id} key={index}>
          {branch.name}
        </MenuItem>
      ))}
    </Select>
  );
};

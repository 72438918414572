import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { postPurchaseOrder } from "api/PostPurchaseOrder";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { Loading } from "components/loading/Loading";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { invalidatePurchaseOrdersCache } from "hooks/purchaseOrders";
import { createArrayWithNumbers } from "lib/Arrays";
import { decorateDisplayUnit, isUnitGmsOrMl } from "lib/MaterialHelper";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { IBranch } from "types/Branch";
import { IMaterial } from "types/Material";
import { IPostPurchaseOrder } from "types/PurchaseOrder";
import { ISupplier } from "types/Supplier";
import styles from "./GeneratePurchaseOrderMaterialsForm.module.css";

interface IRowProps {
  index: number;
  materialsArr: IMaterial[];
  control: any;
}

const RowComponent: React.FC<IRowProps> = (props) => {
  const { index, materialsArr, control } = props;

  const fieldName = `materials[${index}]`;
  const [material, setMaterial] = useState<IMaterial | null>(null);

  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        control={control}
        name={`${fieldName}.materialId`}
        defaultValue={null}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.inputField}
                label="Select Item"
                variant="outlined"
              />
            )}
          />
        )}
      />
      <Controller
        control={control}
        name={`${fieldName}.quantity`}
        style={{ marginLeft: "10px", width: "450px" }}
        defaultValue={null}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"Quantity" + fieldName}>Order Quantity</InputLabel>
            <OutlinedInput
              id={"Quantity" + fieldName}
              type={"number"}
              disabled={material ? false : true}
              inputProps={{
                step: "0.01",
              }}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                onChange(isNaN(value) ? 0 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={100}
            />
          </FormControl>
        )}
      />
      <Controller
        control={control}
        name={`${fieldName}.existingStockQuantity`}
        style={{ marginLeft: "10px", width: "450px" }}
        defaultValue={null}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"Quantity" + fieldName}>Existing Stock</InputLabel>
            <OutlinedInput
              id={"ExistingStock" + fieldName}
              type={"number"}
              disabled={material ? false : true}
              inputProps={{
                step: "0.01",
              }}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                onChange(isNaN(value) ? 0 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={95}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

const GeneratePurchaseOrderMaterialsForm: React.FC<{
  selectedSupplier: ISupplier;
  selectedBranch: IBranch;
  deliveryAddress: string;
  preferredDeliveryDate?: string;
}> = (props) => {
  const {
    selectedSupplier,
    selectedBranch,
    deliveryAddress,
    preferredDeliveryDate,
  } = props;
  const [size, setSize] = useState(8);
  const { handleSubmit, control } = useForm<IMaterial[]>();
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  interface IMaterialQuantity {
    materialId: string;
    quantity: number;
    existingStockQuantity: number;
  }

  interface IMaterialQuantityList {
    materials: IMaterialQuantity[];
  }

  const history = useHistory();

  const [mutate] = useMutation(
    (request: { postRequest: IPostPurchaseOrder; branchId: string }) => {
      setSubmitting(true);
      return postPurchaseOrder(request.branchId, request.postRequest);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidatePurchaseOrdersCache();
          setShowSuccessDialog(true);
        } else {
          setShowToast({
            open: true,
            message: error.message ?? "Something went wrong!",
            type: "error",
          });
        }
      },
    }
  );

  const supplierMaterialIdToMaterialMap = new Map<string, IMaterial>();
  selectedSupplier.materialList?.forEach((material) =>
    supplierMaterialIdToMaterialMap.set(material.id, material)
  );

  const onSubmitPurchaseOrder = (materialList: IMaterialQuantityList) => {
    let materialIdToQuantityMap: Map<string, number> = new Map();
    let materialIdToExistingStockQuantityMap: Map<string, number> = new Map();
    materialList.materials
      .filter(
        (material: IMaterialQuantity) =>
          material.materialId !== null && material.quantity > 0
      )
      .forEach((material: IMaterialQuantity) => {
        const materialObject = supplierMaterialIdToMaterialMap.get(
          material.materialId
        );
        if (materialObject) {
          if (isUnitGmsOrMl(materialObject.unitOfMeasure)) {
            material.quantity = material.quantity * 1000;
          }

          if (material.materialId in materialIdToQuantityMap) {
            materialIdToQuantityMap[material.materialId] += material.quantity;
          } else {
            materialIdToQuantityMap[material.materialId] = material.quantity;
          }

          if (material.existingStockQuantity) {
            if (isUnitGmsOrMl(materialObject.unitOfMeasure)) {
              material.existingStockQuantity = material.existingStockQuantity * 1000;
            }
            if (material.materialId in materialIdToExistingStockQuantityMap) {
              materialIdToExistingStockQuantityMap[material.materialId] += material.existingStockQuantity;
            } else {
              materialIdToExistingStockQuantityMap[material.materialId] = material.existingStockQuantity;
            }
          }
        }
      });

    const isValid = Object.values(materialIdToQuantityMap).length > 0;

    if (isValid) {
      mutate({
        postRequest: {
          supplierId: selectedSupplier.id,
          deliveryAddress,
          materialIdToQuantityMap,
          preferredDeliveryDate,
          materialIdToExistingStockQuantityMap
        },
        branchId: selectedBranch.id,
      });
    } else {
      setShowToast({
        open: true,
        message:
          "Both material name and quantity are required, material count must be greater than 0",
        type: "error",
      });
    }
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <ConfirmationDialog
        buttonText={"Okay"}
        bodyText={"Purchase Order is created successfully"}
        open={showSuccessDialog}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headText}>{"Order Details"}</div>
          <Button
            className={styles.addSupplierButton}
            style={{
              backgroundColor: primaryColor,
              color: primaryTextColor,
              fontWeight: "bold",
            }}
            onClick={handleSubmit(onSubmitPurchaseOrder)}
            disabled={isSubmitting}
          >
            <Loading text={"Generate P.O."} isLoading={isSubmitting} />
          </Button>
        </div>
        <div className={styles.columns}>
          <div>{"Item Name"}</div>
          <div>{"Order Quantity"}</div>
          <div>{"Existing Stock"}</div>
        </div>

        <div
          style={{
            padding: "10px",
            background: "#ffffff",
          }}
        >
          {createArrayWithNumbers(size).map((index: number) => {
            return (
              <RowComponent
                key={index}
                index={index}
                control={control}
                materialsArr={selectedSupplier.materialList ?? []}
              />
            );
          })}
          <div className={styles.addItemDiv}>
            <button
              type="button"
              className={styles.addItem}
              onClick={() => setSize(size + 10)}
            >
              {" "}
              Add More{" "}
            </button>
            <div
              style={{
                border: "1px solid #EAEAEA",
                height: "1px",
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratePurchaseOrderMaterialsForm;

import styles from "./AddUserModal.module.css";
import React, { useContext, useState } from "react";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  OutlinedInput,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { RoleList, Role, isRoleEqual, isStringRoleAdmin } from "types/Employee";
import { removeUnderscore, capitalizeFirstLetter } from "lib/StringHelpers";
import { IApiUserDisplay, IUserForm } from "types/User";
import { addUser, updateUser } from "api/PostUser";
import { useMutation, queryCache } from "react-query";
import { useQueryBranches } from "hooks/Branches";
import { ModalContext } from "contexts/ModalContext";
import Toast from "components/Toast/Toast";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Auth } from "lib/Auth";
import { Config } from "lib/Config";
import { MultiInputDropdown } from "components/dropdowns/MultiInputDropdown";
import { SingleInputDropdown } from "components/dropdowns/SingleInputDropdown";

export const AddUserModal: React.FC<{
  selectedUser?: IApiUserDisplay;
  onClose?: (success?: boolean, message?: string) => void;
  title: string;
  buttonText: string;
}> = (props) => {
  const { selectedUser, onClose, title, buttonText } = props;
  const { register, handleSubmit, control, errors, getValues } =
    useForm<IUserForm>();
  const modalProps = useContext(ModalContext);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const supportedCountries = Config.getInstance().getSupportedCountries();
  const [countryPrefix, setCountryPrefix] = useState(
    supportedCountries[0].prefix
  );

  const [mutate] = useMutation(
    (userInfo: IUserForm) => {
      setSubmitting(true);
      if (selectedUser) {
        return updateUser(selectedUser.id, userInfo);
      } else {
        return addUser(userInfo);
      }
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          queryCache.invalidateQueries("employees");
          const successMessage = selectedUser
            ? "User updated successfully!"
            : "User added successfully!";
          if (onClose) {
            onClose(true, successMessage);
          } else {
            modalProps.hideModal(true, successMessage);
          }
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );
  const onSubmit = async (userInfo: IUserForm) => {
    userInfo.mobileNumber = countryPrefix.concat(userInfo.mobileNumber);
    mutate(userInfo);
  };

  const isValidBranch = (branches?: string[]) => {
    if (branches && branches.length > 0) {
      // if branch is provided, no further validation required
      return true;
    }

    const role = getValues("role");
    if (
      !role ||
      isRoleEqual(role, Role.CONTRACTOR) ||
      isStringRoleAdmin(role)
    ) {
      // if role selection is not made do not show error
      // or if role is admin or contractor or super_admin then branch is not required
      return true;
    }

    return false;
  };

  const isValidEmail = (email?: string) => {
    if (email && email.length > 0) {
      // if email is provided, no further validation required
      // FIXME: do some basic email validation
      return true;
    }

    const role = getValues("role");
    if (
      !role ||
      isRoleEqual(role, Role.DATA_ENTRY) ||
      isRoleEqual(role, Role.CONTRACTOR)
    ) {
      // if role selection is not made do not show error
      // or if role is data_entry or contractor then email is not required
      return true;
    }

    return false;
  };

  const displayRoles = () => {
    const currentRole = Auth.getInstance().getUser()!.role;
    if (currentRole !== Role.SUPER_ADMIN) {
      const rolesList = RoleList.filter((role) => role !== Role.SUPER_ADMIN);
      return rolesList;
    }
    const rolesList = RoleList;
    return rolesList;
  };

  const { branches } = useQueryBranches();
  const finalRoles = displayRoles().map((role) => {
    const displayRole = capitalizeFirstLetter(removeUnderscore(role));
    return { key: role, value: displayRole };
  });
  const [role, setRole] = useState(selectedUser?.role ?? "");

  const removeCountryPrefix = (mobileNumber?: string) => {
    return mobileNumber ? mobileNumber.replace(countryPrefix, "") : "";
  };

  const allBranches = branches
    ? branches.map((branch) => {
        return { key: branch.id, value: capitalizeFirstLetter(branch.name) };
      })
    : [];

  const initialBranches = selectedUser ? selectedUser.branchIds : [];
  const [selectedBranches, setSelectedBranches] =
    useState<string[]>(initialBranches);

  return (
    <ModalPopup
      className={styles.modal}
      header={title}
      submitText={buttonText}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      showDatePicker={false}
      hideModal={selectedUser ? onClose : undefined}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      <div className={styles.modalBody}>
        <TextField
          autoFocus={true}
          error={errors.name !== undefined}
          type={"text"}
          label="Name*"
          fullWidth
          margin="normal"
          variant="outlined"
          name="name"
          defaultValue={selectedUser?.name ?? ""}
          inputRef={register({ required: true })}
        />
        <div style={{ display: "flex", width: "500px" }}>
          <Select
            native
            value={countryPrefix}
            disabled={selectedUser && true}
            onChange={(e) => {
              setCountryPrefix(e.target.value as string);
            }}
            style={{
              width: "60px",
              height: "50px",
              marginRight: "10px",
              marginTop: "20px",
            }}
          >
            {supportedCountries.map((country, index) => {
              return (
                <option value={country.prefix} key={index}>
                  {country.prefix}
                </option>
              );
            })}
          </Select>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="mobile-number" className={styles.input}>
              Contact Number*
            </InputLabel>
            <OutlinedInput
              className={styles.label}
              id="mobile-number"
              type={"number"}
              label="Contact Number*"
              error={errors.mobileNumber !== undefined}
              fullWidth
              name="mobileNumber"
              defaultValue={removeCountryPrefix(selectedUser?.mobileNumber)}
              inputProps={{ readOnly: selectedUser && true }}
              inputRef={register({
                required: true,
                validate: (number: string) => {
                  number = countryPrefix.concat(number);
                  return isValidPhoneNumber(number);
                },
              })}
            />
          </FormControl>
        </div>

        <SingleInputDropdown
          name={"role"}
          label={"User Role*"}
          values={finalRoles}
          value={role}
          setValue={setRole}
          control={control}
          errors={errors}
          rules={{ required: true }}
          setDefaultValue={selectedUser ? true : false}
        />

        <TextField
          type={"text"}
          label="Email"
          error={errors.email !== undefined}
          helperText={
            errors.email ? "Email required for Admins & Managers" : ""
          }
          defaultValue={selectedUser?.email ?? ""}
          fullWidth
          margin="normal"
          variant="outlined"
          name="email"
          inputRef={register({ validate: isValidEmail })}
        />

        <MultiInputDropdown
          name={"branchIds"}
          headerLabel={"Branches"}
          label={"branch"}
          values={allBranches}
          value={selectedBranches}
          setValue={setSelectedBranches}
          control={control}
          errors={errors}
          rules={{ validate: isValidBranch }}
          setDefaultValue={true}
          disabled={
            isRoleEqual(role, Role.CONTRACTOR) || isStringRoleAdmin(role)
          }
        />
      </div>
    </ModalPopup>
  );
};

import { dateAddingDaysFromDate, today } from "lib/Date";

export interface IReminderDateRange {
  title: string;
  dateRange: () => { start: Date; end: Date };
}

export const reminderDateRanges: IReminderDateRange[] = [
  {
    title: "Last 90 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -90);
      start.setHours(0, 0, 0); // start of the day
      const end = dateAddingDaysFromDate(today(), -1);
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Last 30 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -30);
      start.setHours(0, 0, 0); // start of the day
      const end = dateAddingDaysFromDate(today(), -1);
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Last 7 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -7);
      start.setHours(0, 0, 0); // start of the day
      const end = dateAddingDaysFromDate(today(), -1);
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Next 7 days",
    dateRange: () => {
      const start = today();
      const end = dateAddingDaysFromDate(start, 7);
      return { start, end };
    },
  },
  {
    title: "Next 30 days",
    dateRange: () => {
      const start = today();
      const end = dateAddingDaysFromDate(start, 30);
      return { start, end };
    },
  },
  {
    title: "Next 90 days",
    dateRange: () => {
      const start = today();
      const end = dateAddingDaysFromDate(start, 90);
      return { start, end };
    },
  },
];

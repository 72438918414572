import React, { useContext } from "react";
import ContentPage from "components/contentPage/ContentPage";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { IBranch } from "types/Branch";
import { WastageTable } from "components/wastageTable/WastageTable";
import styles from "./Wastage.module.css";
import {
  BranchSelector,
  IBranchSelectorProps,
} from "components/branchSelector/BranchSelector";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddWastageModal } from "components/addWastageModal/AddWastageModal";
import { useLocalBranch } from "hooks/LocalStorage";
import { AddFavouriteModalHelper } from "components/addFavouriteModalHelper/AddFavouriteModalHelper";
import { AddFavouritesModal } from "components/addFavouritesModal/AddFavouritesModal";
import { FavouriteEntity } from "types/Favourite";
import { getSettings } from "api/GetSettings";
import WastageIcon from "assets/WastageIcon";

const Wastage: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  // // TODO handle no branches error
  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch!}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={<MainSection selectedBranch={selectedBranch} />}
      rightSection={
        <RightSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
          selectedBranch={selectedBranch}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Wastage"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch }> = (props) => (
  <>
    <WastageTable selectedBranch={props.selectedBranch} />
  </>
);

const RightSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => (
  <>
    <ModalWithButton title={"Add Wastage"} icon={<WastageIcon />}>
      {" "}
      <AddWastageModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
    <AddFavouriteModalHelper>
      {" "}
      <AddFavouritesModal
        selectedBranch={props.selectedBranch}
        materials={
          props.materialContextValues?.allMaterialsArrForFoodAnalysis ?? []
        }
        favouriteEntity={FavouriteEntity.WASTAGE}
        onClose={getSettings}
      />{" "}
    </AddFavouriteModalHelper>
  </>
);

export default Wastage;

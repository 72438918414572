import React, { ReactNode, useContext } from "react";
import styles from "./ModalWithButton.module.css";
import { Avatar, Dialog } from "@material-ui/core";
import ModalContextProvider, { ModalContext } from "contexts/ModalContext";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IModalWithButton {
  title: string;
  icon?: string | ReactNode;
  buttonStyle?: string;
  onBackDropClick?: () => void;
  onEscapeKeyDown?: () => void;
  maxWidth?: "md" | "lg";
}

export const ModalWithButton: React.FC<IModalWithButton> = (props) => {
  const { primaryColor } = useContext(FMSThemeContext);
  return (
    <ModalContextProvider>
      <>
        <ModalContext.Consumer>
          {(modalProps) => (
            <>
              <button
                type="button"
                className={props.buttonStyle ?? styles.button}
                onClick={modalProps.showModal}
              >
                {props.icon && (
                  <Avatar
                    className={styles.icon}
                    style={{ backgroundColor: primaryColor }}
                  >
                    {props.icon}
                  </Avatar>
                )}
                <div className={styles.buttonTitle}>{props.title}</div>
              </button>
              <Dialog
                maxWidth={props.maxWidth ?? "md"}
                className={styles.modal}
                scroll={"body"}
                open={modalProps.show}
                onClose={() => {
                  modalProps.hideModal();
                }}
                disableBackdropClick
                onEscapeKeyDown={props.onEscapeKeyDown}
              >
                <>{props.children}</>
              </Dialog>
            </>
          )}
        </ModalContext.Consumer>
      </>
    </ModalContextProvider>
  );
};

import ContentPage from "components/contentPage/ContentPage";
import GrnComponent from "components/grnComponent/GrnComponent";
import React from "react";
import { useParams } from "react-router-dom";

const GenerateGRN: React.FC = React.memo(() => {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection purchaseOrderId={purchaseOrderId} />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = (props) => {
  return <></>;
};

const MainSection: React.FC<{ purchaseOrderId: string }> = (props) => {
  const { purchaseOrderId } = props;
  return <GrnComponent purchaseOrderId={purchaseOrderId} />;
};

const RightSection: React.FC = () => {
  return <></>;
};

export default GenerateGRN;

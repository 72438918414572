import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { IWastageEntryView, IWastageForAllBranches } from "types/WastageFood";
import { getWastage, getWastageEntries } from "api/GetWastage";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  wastages: IWastageForAllBranches[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryWastages = (
  startDate: Date,
  endDate: Date
): IQueryBranch => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: wastages, isFetching } = useQuery(
    ["wastages", start, end],
    async (key: string, start: string, end: string) => {
      return await getWastage(start, end);
    }
  );
  return { status, wastages, isFetching };
};

export const invalidateWastagesCache = () => {
  queryCache.invalidateQueries("wastages");
};

export const invalidateWastagesEntriesCache = () => {
  queryCache.invalidateQueries("wastageEntries");
  queryCache.invalidateQueries("wastages");
};

interface IQueryWastageEntries {
  status: QueryStatus;
  wastageEntriesFetched: IWastageEntryView[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryWastageEntries = (
  branchId: string,
  materialId: string,
  startDate: Date,
  endDate: Date
): IQueryWastageEntries => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: wastageEntriesFetched, isFetching } = useQuery(
    ["wastageEntries", branchId, materialId, start, end],
    async (
      key: string,
      branchId: string,
      materialId: string,
      start: string,
      end: string
    ) => {
      return await getWastageEntries(branchId, materialId, start, end);
    }
  );
  return { status, wastageEntriesFetched, isFetching };
};

import styles from "./AddBranchModal.module.css";
import { createArrayWithNumbers } from "lib/Arrays";
import React, { useContext, useEffect, useState } from "react";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { IBranch, IExternalBranch } from "types/Branch";
import { addBranch, updateBranch } from "api/PostBranch";
import { useMutation } from "react-query";
import { ModalContext } from "contexts/ModalContext";
import Toast from "components/Toast/Toast";
import { invalidateBranchesCache } from "hooks/Branches";

interface IProps {
  onClose: (branchAdded?: boolean, message?: string) => void;
  title: "Add Branch" | "Update Branch";
  buttonText: "Add" | "Update";
  selectedBranch?: IBranch;
}

export const AddBranchModal: React.FC<IProps> = (props) => {
  const { title, buttonText, selectedBranch, onClose } = props;
  const { register, handleSubmit, errors } = useForm<IExternalBranch>();
  const modalProps = useContext(ModalContext);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [mutate] = useMutation(
    (branch: IExternalBranch) => {
      if (selectedBranch) {
        return updateBranch(selectedBranch.id, branch);
      } else {
        return addBranch(branch);
      }
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateBranchesCache();
          const successMessage = `Branch ${
            selectedBranch ? "updated" : "added"
          } successfully!`;
          if (!selectedBranch) {
            modalProps.hideModal(true, successMessage);
          }
          props.onClose(true, successMessage);
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const onSubmit = async (branch: IExternalBranch) => {
    setSubmitting(true);
    branch.deliveryAddresses = branch.deliveryAddresses?.filter(
      (deliveryAddress) => deliveryAddress.length > 0
    );
    mutate(branch);
  };

  const [size, setSize] = useState(1);
  const deliveryAddresses = selectedBranch?.deliveryAddresses;
  useEffect(() => {
    if (deliveryAddresses && deliveryAddresses.length > 0) {
      setSize(deliveryAddresses.length);
    }
  }, [setSize, deliveryAddresses]);

  return (
    <ModalPopup
      className={styles.modal}
      header={<>{title}</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      showDatePicker={false}
      submitText={buttonText}
      hideModal={selectedBranch ? onClose : undefined}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      <div className={styles.modalBody}>
        <TextField
          InputProps={{
            style: {
              minWidth: 500,
            },
          }}
          error={errors.name !== undefined}
          margin={"normal"}
          autoFocus={true}
          type={"text"}
          inputRef={register({ required: true })}
          fullWidth
          label="Name*"
          variant="outlined"
          name="name"
          defaultValue={selectedBranch?.name ?? ""}
        />
        <TextField
          InputProps={{
            style: {
              minWidth: 500,
            },
          }}
          error={errors.firmName !== undefined}
          margin={"normal"}
          type={"text"}
          inputRef={register({ required: true })}
          fullWidth
          label="Firm Name*"
          variant="outlined"
          name="firmName"
          defaultValue={selectedBranch?.firmName ?? ""}
        />
        <TextField
          InputProps={{
            style: {
              minWidth: 500,
            },
          }}
          error={errors.gstNumber !== undefined}
          margin={"normal"}
          type={"text"}
          inputRef={register({ required: true })}
          fullWidth
          label="GST Number*"
          variant="outlined"
          name="gstNumber"
          defaultValue={selectedBranch?.gstNumber ?? ""}
        />
        <TextField
          InputProps={{
            style: {
              minWidth: 500,
              minHeight: 75,
            },
          }}
          error={errors.address !== undefined}
          type={"text"}
          margin={"normal"}
          multiline={true}
          fullWidth
          label="Address*"
          inputRef={register({ required: true })}
          variant="outlined"
          name="address"
          defaultValue={selectedBranch?.address ?? ""}
        />
        <TextField
          InputProps={{
            style: {
              minWidth: 500,
              minHeight: 75,
            },
          }}
          error={errors.billingAddress !== undefined}
          type={"text"}
          margin={"normal"}
          multiline={true}
          fullWidth
          label="Billing Address*"
          inputRef={register({ required: true })}
          variant="outlined"
          name="billingAddress"
          defaultValue={selectedBranch?.billingAddress ?? ""}
        />
        {createArrayWithNumbers(size).map((index: number) => {
          return (
            <TextField
              InputProps={{
                style: {
                  minWidth: 500,
                  minHeight: 75,
                },
              }}
              error={errors.deliveryAddresses !== undefined}
              type={"text"}
              margin={"normal"}
              multiline={true}
              fullWidth
              label="Delivery Address*"
              inputRef={register({ required: index === 0 })}
              variant="outlined"
              name={`deliveryAddresses[${index}]`}
              defaultValue={deliveryAddresses ? deliveryAddresses[index] : ""}
            />
          );
        })}
        <button
          type="button"
          className={styles.addMore}
          onClick={() => setSize(size + 1)}
        >
          {" "}
          Add More Delivery Address{" "}
        </button>
      </div>
    </ModalPopup>
  );
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { IExternalBranch } from "types/Branch";
import { FMSError } from "types/Error";

export const addBranch = async (
  branch: IExternalBranch
): Promise<FMSError | null> => {
  try {
    const response = await post<{ branch: IExternalBranch }>(
      `${BACKEND_URL_V1}/branches`,
      {
        name: branch.name,
        address: branch.address,
        billingAddress: branch.billingAddress,
        deliveryAddresses: branch.deliveryAddresses,
        firmName: branch.firmName,
        gstNumber: branch.gstNumber,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to add branch";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateBranch = async (
  branchId: string,
  branch: IExternalBranch
): Promise<FMSError | null> => {
  try {
    const response = await post<{ branch: IExternalBranch }>(
      `${BACKEND_URL_V1}/branches/${branchId}`,
      {
        name: branch.name,
        address: branch.address,
        billingAddress: branch.billingAddress,
        deliveryAddresses: branch.deliveryAddresses,
        firmName: branch.firmName,
        gstNumber: branch.gstNumber,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to update branch";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

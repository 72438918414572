import React from "react";

function CalendarIconComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.217}
      height={16}
      viewBox="0 0 16.217 16"
    >
      <defs>
        <style>
          {
            ".calendarIconComponent__a{fill:none;stroke:#cecece;stroke-linecap:round;stroke-linejoin:round}"
          }
        </style>
      </defs>
      <g transform="translate(.5 .5)">
        <path
          className="calendarIconComponent__a"
          d="M12.12 1.522h1.97a1.128 1.128 0 011.128 1.128v6.59M8.533 1.522h2.246M4.547 1.522h2.246M15.217 11.413v2.459A1.128 1.128 0 0114.089 15H1.127a1.128 1.128 0 01-1.128-1.128V2.649a1.128 1.128 0 011.128-1.128h1.662M.652 4.348h13.913"
        />
        <rect
          className="calendarIconComponent__a"
          width={1.304}
          height={3.043}
          rx={0.652}
          transform="translate(2.826)"
        />
        <rect
          className="calendarIconComponent__a"
          width={1.304}
          height={3.043}
          rx={0.652}
          transform="translate(6.812)"
        />
        <rect
          className="calendarIconComponent__a"
          width={1.304}
          height={3.043}
          rx={0.652}
          transform="translate(10.797)"
        />
        <path
          className="calendarIconComponent__a"
          d="M1.957 6.087h1.739v1.739H1.957zM5 6.087h1.739v1.739H5zM8.044 6.087h1.739v1.739H8.044zM11.087 6.087h1.739v1.739h-1.739z"
        />
        <g>
          <path
            className="calendarIconComponent__a"
            d="M1.957 9.04h1.739v1.739H1.957zM5 9.04h1.739v1.739H5zM8.044 9.04h1.739v1.739H8.044zM11.087 9.04h1.739v1.739h-1.739z"
          />
        </g>
        <g>
          <path
            className="calendarIconComponent__a"
            d="M1.957 11.993h1.739v1.739H1.957zM5 11.993h1.739v1.739H5zM8.044 11.993h1.739v1.739H8.044z"
          />
        </g>
      </g>
    </svg>
  );
}
const CalendarIcon = React.memo(CalendarIconComponent);
export default CalendarIcon;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function SettingsIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
    >
      <path
        d="M9.975 3.9l.825-1.575-1.05-1.05-1.575.825a2.687 2.687 0 00-.825-.3L6.75 0h-1.5l-.6 1.725a3.134 3.134 0 00-.75.3L2.325 1.2 1.2 2.325 2.025 3.9a3.134 3.134 0 00-.3.75L0 5.25v1.5l1.725.6c.075.3.225.525.3.825L1.2 9.75l1.05 1.05 1.575-.825a2.687 2.687 0 00.825.3L5.25 12h1.5l.6-1.725c.3-.075.525-.225.825-.3l1.575.825 1.05-1.05-.825-1.575a2.687 2.687 0 00.3-.825L12 6.75v-1.5l-1.725-.6a3.134 3.134 0 00-.3-.75zM6 8.25A2.21 2.21 0 013.75 6 2.21 2.21 0 016 3.75 2.21 2.21 0 018.25 6 2.21 2.21 0 016 8.25z"
        fill={primaryTextColor}
      />
    </svg>
  );
}
const SettingsIcon = React.memo(SettingsIconComponent);
export default SettingsIcon;

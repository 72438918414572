export enum Namespace {
  WADESHWAR = "wadeshwar",
  NISARG = "nisarg",
}

export function currentNamespace(namespaceFromEnv: string) {
  switch (namespaceFromEnv) {
    case "wadeshwar":
      return Namespace.WADESHWAR;
    case "nisarg":
      return Namespace.NISARG;
    default:
      throw new Error("Invalid namespace in environment " + namespaceFromEnv);
  }
}

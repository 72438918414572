import { BACKEND_URL_V1, get } from "lib/Api";
import { IBranchWiseMaterialPrediction } from "types/BranchWiseMaterialPrediction";
import { IDatedBranchWiseMaterialPrediction } from "types/DatedBranchWiseMaterialPrediction";
import { FMSError } from "types/Error";
import { IMaterial } from "types/Material";

export interface IGetSemiPreparedMaterialConsumptionResponse {
  predictionViews: IDatedBranchWiseMaterialPrediction[];
  applicableMaterialsForKitchenForecast: IMaterial[];
}

interface IGetRawMaterialConsumptionResponse {
  branchWiseMaterialPredictionViews: IBranchWiseMaterialPrediction[];
}

export const getConsumptionEstimatesForRawMaterials = async (
  start: string,
  end: string
): Promise<IBranchWiseMaterialPrediction[] | FMSError> => {
  try {
    const response = await get<IGetRawMaterialConsumptionResponse>(
      `${BACKEND_URL_V1}/predictions/consumption/rawMaterials?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.branchWiseMaterialPredictionViews) {
        return response.parsedBody.branchWiseMaterialPredictionViews;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Predictions for raw materials could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getConsumptionEstimatesForSemiPreparedMaterials = async (
  start: string,
  end: string
): Promise<IGetSemiPreparedMaterialConsumptionResponse | FMSError> => {
  try {
    const response = await get<IGetSemiPreparedMaterialConsumptionResponse>(
      `${BACKEND_URL_V1}/predictions/consumption/semiPrepared?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.predictionViews) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Predictions for semi prepared materials could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getConsumptionEstimatesForPreparedMaterials = async (
  start: string,
  end: string
): Promise<IBranchWiseMaterialPrediction[] | FMSError> => {
  try {
    const response = await get<IGetRawMaterialConsumptionResponse>(
      `${BACKEND_URL_V1}/predictions/consumption/prepared?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.branchWiseMaterialPredictionViews) {
        return response.parsedBody.branchWiseMaterialPredictionViews;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Predictions for raw materials could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};


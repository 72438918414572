import {
  Button,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { getSupplierUploadTemplate } from "api/GetSupplier";
import { uploadSupplier } from "api/PostSupplier";
import ExcelIcon from "assets/ExcelIcon";
import { Loading } from "components/loading/Loading";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { invalidateSuppliersCache } from "hooks/Suppliers";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { getMaterialIds, ISupplier, ISupplierPost } from "types/Supplier";
import styles from "./SupplierForm.module.css";

interface IProps {
  onEnterSupplier: (supplier: ISupplierPost, showForm: boolean) => void;
  supplier?: ISupplier;
  allowEdit: boolean;
}

export const SupplierForm: React.FC<IProps> = (props) => {
  const { supplier, onEnterSupplier, allowEdit } = props;
  const { handleSubmit, control, errors } = useForm<ISupplierPost>();

  const isValidEmail = (email?: string) => {
    if (email && email.length > 0) {
      // if email is provided, no further validation required
      // FIXME: do some basic email validation
      return true;
    }
    return false;
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [isDownloading, setDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const handleDownloadTemplate = async (supplierData: ISupplierPost) => {
    setDownloading(true);
    const error = await getSupplierUploadTemplate(supplierData);
    setDownloading(false);
    if (error) {
      setShowToast({
        message: error.message ?? "Something went wrong!",
        open: true,
        type: "error",
      });
    }
  };

  const onSubmit = (data: ISupplierPost) => {
    if (supplier) {
      data = {
        ...data,
        id: supplier.id,
        materialIds: getMaterialIds(supplier),
      };
    }
    onEnterSupplier(data, true);
  };

  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  const history = useHistory();
  const [mutateForFile] = useMutation(
    (files: File[]) => {
      setIsUploading(true);
      return uploadSupplier(files);
    },
    {
      onSuccess: (error) => {
        setIsUploading(false);
        if (!error) {
          invalidateSuppliersCache();
          history.goBack();
          return;
        }

        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const handleUploadFile = useCallback(
    (files: File[]) => {
      mutateForFile(files);
    },
    [mutateForFile]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleUploadFile(acceptedFiles);
    },
    [handleUploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headText}>{"Supplier Details"}</div>
        </div>

        <div className={styles.formBody}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={"name"}
                defaultValue={supplier ? supplier.name : ""}
                rules={{
                  required: true,
                  maxLength: 255,
                }}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>Supplier Name*</div>
                    <FormControl
                      className={styles.textBox}
                      variant="outlined"
                      error={errors.name !== undefined}
                    >
                      <InputLabel htmlFor={"name"}>
                        Enter supplier name
                      </InputLabel>

                      <OutlinedInput
                        id={"name"}
                        name={"name"}
                        type={"string"}
                        onChange={onChange}
                        labelWidth={140}
                        defaultValue={supplier ? supplier.name : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Controller
                control={control}
                name={"email"}
                defaultValue={supplier ? supplier.email : ""}
                rules={{
                  required: true,
                  maxLength: 255,
                  validate: isValidEmail,
                }}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>Email ID*</div>
                    <FormControl
                      className={styles.textBox}
                      variant="outlined"
                      error={errors.email !== undefined}
                    >
                      <InputLabel htmlFor={"name"}>Enter Email</InputLabel>
                      <OutlinedInput
                        id={"email"}
                        name={"email"}
                        type={"string"}
                        onChange={onChange}
                        labelWidth={80}
                        defaultValue={supplier ? supplier.email : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <Controller
                control={control}
                name={"address"}
                defaultValue={supplier ? supplier.address : ""}
                rules={{
                  required: true,
                  maxLength: 255,
                }}
                render={({ onChange }) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "32px",
                        fontWeight: "bold",
                      }}
                    >
                      Address*
                    </div>
                    <FormControl
                      variant="outlined"
                      style={{ marginLeft: "77px" }}
                    >
                      <TextField
                        name={"address"}
                        InputProps={{
                          style: {
                            minHeight: 100,
                            minWidth: 940,
                          },
                        }}
                        onChange={onChange}
                        error={errors.address !== undefined}
                        type={"text"}
                        label="Enter Address"
                        multiline={true}
                        maxRows={6}
                        margin="normal"
                        variant="outlined"
                        defaultValue={supplier ? supplier.address : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={"contactPerson"}
                defaultValue={supplier ? supplier.contactPerson : ""}
                rules={{
                  required: true,
                }}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>Contact Person*</div>
                    <FormControl
                      error={errors.contactPerson !== undefined}
                      className={styles.textBox}
                      variant="outlined"
                    >
                      <InputLabel htmlFor={"contactPerson"}>
                        Enter Contact Person
                      </InputLabel>
                      <OutlinedInput
                        id={"contactPerson"}
                        type={"string"}
                        onChange={onChange}
                        labelWidth={150}
                        defaultValue={supplier ? supplier.contactPerson : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Controller
                control={control}
                name={"contactNumber"}
                defaultValue={supplier ? supplier.contactNumber : ""}
                rules={{
                  required: true,
                }}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>Contact Number*</div>
                    <FormControl
                      error={errors.contactNumber !== undefined}
                      className={styles.textBox}
                      variant="outlined"
                    >
                      <InputLabel htmlFor={"contactNumber"}>
                        Enter Contact Number
                      </InputLabel>
                      <OutlinedInput
                        id={"contactNumber"}
                        type={"number"}
                        onChange={onChange}
                        labelWidth={150}
                        defaultValue={supplier ? supplier.contactNumber : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                control={control}
                name={"gstNumber"}
                defaultValue={supplier ? supplier.gstNumber : ""}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>GST No.</div>
                    <FormControl className={styles.textBox} variant="outlined">
                      <InputLabel htmlFor={"gstNumber"}>
                        Enter GST Number
                      </InputLabel>
                      <OutlinedInput
                        id={"gstNumber"}
                        type={"string"}
                        onChange={onChange}
                        labelWidth={130}
                        defaultValue={supplier ? supplier.gstNumber : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Controller
                control={control}
                name={"fssi"}
                defaultValue={supplier ? supplier.fssi : ""}
                render={({ onChange }) => (
                  <div className={styles.inputDiv}>
                    <div className={styles.inputLabel}>FSSI</div>
                    <FormControl className={styles.textBox} variant="outlined">
                      <InputLabel htmlFor={"fssi"}>
                        Enter FSSI Number
                      </InputLabel>
                      <OutlinedInput
                        id={"fssi"}
                        type={"string"}
                        onChange={onChange}
                        labelWidth={130}
                        defaultValue={supplier ? supplier.fssi : ""}
                      />
                    </FormControl>
                  </div>
                )}
              />
            </Grid>
          </Grid>
        </div>

        <div className={styles.footer}>
          <div className={styles.excelButtons}>
            <Button
              className={allowEdit ? styles.hidden : styles.excelButton}
              startIcon={
                <Icon>
                  <ExcelIcon />
                </Icon>
              }
              onClick={handleSubmit(handleDownloadTemplate)}
            >
              <Loading text={"Download Template"} isLoading={isDownloading} />
            </Button>

            <div
              style={{
                display: "inline",
                visibility: "visible",
              }}
              {...getRootProps({ className: styles.dropzone })}
            >
              <input {...getInputProps()} />
              <Button
                className={allowEdit ? styles.hidden : styles.excelButton}
                style={{ marginLeft: "10px" }}
                startIcon={
                  <Icon>
                    <ExcelIcon />
                  </Icon>
                }
              >
                <Loading
                  text={"Upload Supplier Detail"}
                  isLoading={isUploading}
                />
              </Button>
            </div>
          </div>

          <Button
            className={styles.selectItemsButton}
            onClick={handleSubmit(onSubmit)}
          >
            Select Items
          </Button>
        </div>
      </div>
    </>
  );
};

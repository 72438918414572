import React, { ReactNode } from "react";
import styles from "./ContentPage.module.css";

interface IProps {
  header: ReactNode;
  rightSection?: ReactNode;
  mainContent: ReactNode;
}

const ContentPage: React.FC<IProps> = (props) => {
  const { mainContent: MainContent, rightSection } = props;
  return (
    // TODO: Refactor with renderProps
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>{props.header}</div>
        {MainContent}
      </div>
      <div className={styles.rightSection}>{rightSection}</div>
    </div>
  );
};

export default ContentPage;

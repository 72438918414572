import styles from "./AddStockTransferModal.module.css";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { useMutation } from "react-query";
import { IBranch } from "types/Branch";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalContext } from "contexts/ModalContext";
import { createArrayWithNumbers } from "lib/Arrays";
import { IMaterial } from "types/Material";
import { IStockTransferPost } from "types/StockTransfer";
import { addStockTransfer, uploadStockTranfer } from "api/PostStockTransfer";
import { invalidateStockTransferCache } from "hooks/StockTransfers";
import { useQueryBranches } from "hooks/Branches";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Toast from "components/Toast/Toast";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { getStockTransferUploadTemplate } from "api/GetStockTransfers";
import { isUnitGmsOrMl, decorateDisplayUnit } from "lib/MaterialHelper";
import { ErrorBox } from "components/errorBox/ErrorBox";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

const extractStockTransfersForPost = (stockTransfers: any) => {
  const postStockTransferArr: IStockTransferPost[] = [];
  if (!stockTransfers) {
    return postStockTransferArr;
  }

  stockTransfers.forEach((stockTransfer: any) => {
    const materialId = stockTransfer.materialId as string | undefined;
    const quantity = stockTransfer.quantity as number | undefined;
    const receiverBranchId = stockTransfer.receiverBranchId as
      | string
      | undefined;

    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (!quantity || quantity === 0 || isNaN(quantity)) {
      return;
    }

    if (!receiverBranchId || receiverBranchId.trim().length === 0) {
      return;
    }

    const postMaterial: IStockTransferPost = {
      materialId: materialId,
      quantity: quantity,
      receiverBranchId: receiverBranchId,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };

    postStockTransferArr.push(postMaterial);
  });

  return postStockTransferArr;
};

interface IStockTransferRow {
  index: number;
  name: string;
  materialsArr: IMaterial[];
  control: any;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
  otherBranches: IBranch[];
}

const StockTransferRow: React.FC<IStockTransferRow> = (props) => {
  const { index, name, materialsArr, control, getMaterial, otherBranches } =
    props;
  const defaultMaterial = getMaterial(name, index);
  const [material, setMaterial] = useState(defaultMaterial);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        name={`${name}.materialId`}
        control={control}
        defaultValue={defaultMaterial?.id}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            value={getMaterial(name, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${name}.quantity`}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"quantity-input" + name}>Quantity</InputLabel>
            <OutlinedInput
              id={"quantity-input" + name}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              disabled={material ? false : true}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const shouldConvertToGmsOrMl = material
                  ? isUnitGmsOrMl(material.unitOfMeasure)
                  : false;
                onChange(shouldConvertToGmsOrMl ? value * 1000 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />
      <Controller
        name={`${name}.receiverBranchId`}
        control={control}
        render={({ onChange }) => (
          <Autocomplete
            options={otherBranches}
            getOptionLabel={(option) => option.name}
            onChange={(_event, value) => {
              if (value) {
                onChange(value.id);
              }
            }}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Branch" variant="outlined" />
            )}
          />
        )}
      />
    </div>
  );
};

export const AddStockTransferModal: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => {
  const { selectedBranch } = props;
  const { handleSubmit, control, getValues } = useForm<IStockTransferPost[]>();
  const [insufficientMaterials, setInsufficientMaterials] = useState<
    IMaterial[] | undefined
  >(undefined);
  const modalProps = useContext(ModalContext);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [isDownloading, setDownloading] = useState(false);

  const [mutate] = useMutation(
    (transferRecords: IStockTransferPost[]) => {
      return addStockTransfer(transferRecords, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStockTransferCache();
          modalProps.hideModal(true, "Stock transfer added successfully!");
          return;
        }

        if (error.insufficientMaterials) {
          setInsufficientMaterials(error.insufficientMaterials);
        } else {
          const message = error.message ?? "Something went wrong!";
          setToastMessage(message);
          setShowToast(true);
        }
      },
    }
  );

  const [stockTransfers, setStockTransfers] = useState<IStockTransferPost[]>(
    []
  );
  const onSubmit = async (data: any) => {
    const transferRecords = extractStockTransfersForPost(data.transferRecords);
    if (transferRecords.length === 0) {
      setToastMessage("Atleast one transfer item is required!");
      setShowToast(true);
      return;
    }

    setStockTransfers(transferRecords);
  };

  const [mutateForFile] = useMutation(
    (files: File[]) => {
      return uploadStockTranfer(files, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStockTransferCache();
          modalProps.hideModal(true, "Stock transfer added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const handleUploadFile = async (files: File[]) => {
    setSubmitting(true);
    mutateForFile(files);
  };

  const { allMaterialsArrForFoodAnalysis, allMaterialsMapForFoodAnalysis } =
    props.materialContextValues;

  const favourites: IMaterial[] = useMemo(() => {
    const favouriteStockTransfer: IMaterial[] = [];
    if (
      selectedBranch.favourites &&
      selectedBranch.favourites.stockTransfer?.length > 0
    ) {
      selectedBranch.favourites.stockTransfer.forEach((favouriteMaterial) => {
        const material = allMaterialsMapForFoodAnalysis.get(
          favouriteMaterial.id
        );
        if (!material) {
          return;
        }

        favouriteStockTransfer.push(material);
      });
    }

    return favouriteStockTransfer;
  }, [allMaterialsMapForFoodAnalysis, selectedBranch.favourites]);

  const [size, setSize] = useState(5);

  useEffect(() => {
    if (favourites.length > size) {
      setSize(favourites.length);
    }
  }, [size, setSize, favourites]);

  const { status, branches } = useQueryBranches();

  if (
    allMaterialsArrForFoodAnalysis.length === 0 ||
    status !== "success" ||
    !branches ||
    branches.length === 0
  ) {
    return <></>;
  }

  const getMaterial = (fieldName: string, index: number): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`) as string;
    if (materialIdFromValues) {
      const material = allMaterialsMapForFoodAnalysis.get(materialIdFromValues);
      return material ? material : null;
    }

    if (favourites.length > 0 && index <= favourites.length - 1) {
      const favouriteMaterial = favourites[index];
      return favouriteMaterial;
    }

    return null;
  };

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    const error = await getStockTransferUploadTemplate(
      selectedBranch.id,
      selectedDate!
    );
    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setToastMessage(message);
      setShowToast(true);
    }
  };

  const otherBranches = branches.filter(
    (branch) => branch.id !== selectedBranch.id
  );

  const branchesMap = new Map<string, IBranch>(
    branches.map((branch) => [branch.id, branch])
  );

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Stock Transfer</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
      isUploadDownloadApplicable={true}
      isDownloading={isDownloading}
      handleDownloadTemplate={handleDownloadTemplate}
      handleUploadFile={handleUploadFile}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      {stockTransfers.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={allMaterialsMapForFoodAnalysis}
          entries={stockTransfers}
          confirmationType={"Stock Transfer"}
          onClose={() => {
            setStockTransfers([]);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(stockTransfers);
            setStockTransfers([]);
          }}
          displayForRow={(index) => {
            const stockTransfer = stockTransfers[index];
            const material = allMaterialsMapForFoodAnalysis.get(
              stockTransfer.getMaterialId()
            );
            const branch = branchesMap.get(stockTransfer.receiverBranchId);
            if (!material || !branch) {
              return <></>;
            }

            const shouldConvertToKgOrLts = isUnitGmsOrMl(
              material.unitOfMeasure
            );
            const quantity = shouldConvertToKgOrLts
              ? stockTransfer.getQuantity() / 1000
              : stockTransfer.getQuantity();

            return (
              <div key={index}>
                <li>
                  {material.name} - {quantity} {decorateDisplayUnit(material)} -{" "}
                  {branch.name}
                </li>
              </div>
            );
          }}
        />
      )}

      {insufficientMaterials !== undefined && (
        <ErrorBox
          errorMaterialsArr={insufficientMaterials}
          open={true}
          onClose={() => {
            setInsufficientMaterials(undefined);
          }}
          selectedBranch={selectedBranch}
        />
      )}

      <div className={styles.modalBody}>
        <>
          <>
            {createArrayWithNumbers(size).map(
              (value: number, index: number) => {
                const fieldName = `transferRecords[${index}]`;
                return (
                  <StockTransferRow
                    key={index}
                    index={index}
                    name={fieldName}
                    control={control}
                    materialsArr={allMaterialsArrForFoodAnalysis}
                    getMaterial={getMaterial}
                    otherBranches={otherBranches}
                  />
                );
              }
            )}
          </>

          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            Add More
          </button>
        </>
      </div>
    </ModalPopup>
  );
};

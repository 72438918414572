import { IMaterial } from "./Material";

export class FMSError {
  message: string;
  insufficientMaterials: IMaterial[] | undefined;
  details?: any;
  constructor(message: string, insufficientMaterials?: Array<IMaterial>, details?: any) {
    this.message = message;
    this.insufficientMaterials = insufficientMaterials;
    this.details = details;
  }
}

import { Button, createTheme, FormControl, FormControlLabel, InputAdornment, InputLabel, Radio, RadioGroup, Tab, Tabs, ThemeProvider } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "assets/CalendarIcon";
import classNames from "classnames";
import { DataTable } from "components/dataTable/DataTable";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { useQuerySemiPredictionsPreparedMaterial } from "hooks/PredictionsSemiPreparedMaterial";
import { dateAddingDaysFromDate } from "lib/Date";
import { getDateArrayBetweenTwoDates, getSalesPredictionsForBranch, IShiftMaterialPrediction } from "lib/SalesPredictionUtils";
import React, { useContext, useEffect, useState } from "react";
import { IBranch } from "types/Branch";
import { FMSError } from "types/Error";
import { displayQuantity } from "types/MeasurementUnit";
import styles from "./PredictionsSemiPreparedMaterialTable.module.css";

export const PredictionsSemiPreparedMaterialTable: React.FC<{ selectedBranch: IBranch, setApplicableMaterials: any }> = (props) => {
    const today = new Date();
    const dateSevenDaysLater = dateAddingDaysFromDate(today, 7);

    const [datesForQuery, setDatesForQuery] = useState<{
        start: Date;
        end: Date;
    }>({ start: today, end: dateSevenDaysLater });

    const [startDate, handleStartDateChange] = useState<MaterialUiPickersDate>(
        datesForQuery.start
    );
    const [endDate, handleEndDateChange] = useState<MaterialUiPickersDate>(
        datesForQuery.end
    );

    const [dateTab, setDateTab] = useState<string[]>([]);
    const [tabIndex, setTabIndex] = React.useState(0);

    const { status, getSemiPreparedMaterialConsumptionResponse, isFetching } = useQuerySemiPredictionsPreparedMaterial(datesForQuery.start, datesForQuery.end);
    const [tableData, setTableData] = useState<IShiftMaterialPrediction[]>([]);

    const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
    const materialTheme = createTheme({
        palette: {
            primary: {
                main: primaryColor,
                contrastText: primaryTextColor,
            },
        },
    });

    useEffect(() => {
        if (status === "success") {
            if (!getSemiPreparedMaterialConsumptionResponse || getSemiPreparedMaterialConsumptionResponse instanceof FMSError) {
                const message = getSemiPreparedMaterialConsumptionResponse?.message ?? "Something went wrong!";
                setShowToast({
                    open: true,
                    message: message,
                    type: "error",
                });
            } else {
                if (getSemiPreparedMaterialConsumptionResponse.predictionViews !== null) {
                    const datedMaterialPredictionList = getSalesPredictionsForBranch(getSemiPreparedMaterialConsumptionResponse.predictionViews, props.selectedBranch);
                    const datedMaterialPrediction = datedMaterialPredictionList.find((o) => o.date === dateTab[tabIndex]);
                    if (datedMaterialPrediction !== undefined && datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap !== undefined) {
                        const shiftMaterialPredictionList = datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap.get(props.selectedBranch.id);
                        setTableData(shiftMaterialPredictionList ? shiftMaterialPredictionList : []);
                    }
                }
                if (getSemiPreparedMaterialConsumptionResponse.applicableMaterialsForKitchenForecast !== null) {
                    props.setApplicableMaterials(getSemiPreparedMaterialConsumptionResponse.applicableMaterialsForKitchenForecast)
                }
            }
        }
    }, [status, getSemiPreparedMaterialConsumptionResponse, props, dateTab, tabIndex]);

    useEffect(() => {
        setDateTab(getDateArrayBetweenTwoDates(datesForQuery.start, datesForQuery.end));
    }, [datesForQuery.start, datesForQuery.end]);

    const handleSubmit = () => {
        if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
            setShowToast({
                open: true,
                message: "Start date cannot be after end date",
                type: "error",
            });
            return;
        } else if (startDate && endDate && startDate) {
            setDatesForQuery({ start: startDate, end: endDate });
        }
    };

    const handleToastClose = () => {
        setShowToast({
            open: false,
            message: showToast.message,
            type: showToast.type,
        });
    };

    const [showToast, setShowToast] = useState<IToastBasicProps>({
        open: false,
        message: "",
        type: "error",
    });

    const handleChange = (event: any, newValue: number) => {
        setTabIndex(newValue);
        if (getSemiPreparedMaterialConsumptionResponse !== undefined && !(getSemiPreparedMaterialConsumptionResponse instanceof FMSError)) {
            if (getSemiPreparedMaterialConsumptionResponse.predictionViews !== null) {
                const datedMaterialPredictionList = getSalesPredictionsForBranch(getSemiPreparedMaterialConsumptionResponse.predictionViews, props.selectedBranch);
                const datedMaterialPrediction = datedMaterialPredictionList.find((o) => o.date === dateTab[newValue]);
                if (datedMaterialPrediction !== undefined && datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap !== undefined) {
                    const shiftMaterialPredictionList = datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap.get(props.selectedBranch.id);
                    setTableData(shiftMaterialPredictionList ? shiftMaterialPredictionList : []);
                } else {
                    setTableData([])
                }
            }
        }
    };

    const [predictionMode, setPreictionMode] = useState<"morning" | "evening" | "full_day">("full_day");

    const columns = React.useMemo(
        () => [
            {
                Header: "Sales Prediction",
                columns: [
                    {
                        Header: "Item",
                        accessor: "materialName",
                        width: 210,
                        disableFilters: true,
                        sortType: (rowA: any, rowB: any) => {
                            return rowA.original.materialName.toUpperCase() >
                                rowB.original.materialName.toUpperCase()
                                ? 1
                                : -1;
                        },
                        Cell: (props: any) => {
                            const materialPrediction = props.row
                                .original as IShiftMaterialPrediction;
                            return (
                                <Button
                                    style={{
                                        display: "block",
                                        textAlign: "left",
                                        textTransform: "capitalize",
                                    }}
                                >
                                    {materialPrediction.materialName}
                                </Button>
                            );
                        },
                    },
                    {
                        Header: `${predictionMode === "morning" ? "Morning 1 Prediction" : predictionMode === "evening" ? "Evening 1 Prediction" : "Total Morning Prediction"} `,
                        width: 210,
                        disableFilters: true,
                        accessor: (row: IShiftMaterialPrediction) => displayQuantity(predictionMode === "morning" ? row.morningShiftOneQuantity : predictionMode === "evening" ? row.eveningShiftOneQuantity : row.totalMorningQuantity),
                    },
                    {
                        Header: `${predictionMode === "morning" ? "Morning 2 Prediction" : predictionMode === "evening" ? "Evening 2 Prediction" : "Total Evening Prediction"}`,
                        width: 210,
                        disableFilters: true,
                        accessor: (row: IShiftMaterialPrediction) => displayQuantity(
                            predictionMode === "morning" ? row.morningShiftTwoQuantity : predictionMode === "evening" ? row.eveningShiftTwoQuantity : row.totalEveningQuantity
                        ),
                    },
                    {
                        Header: `Total ${predictionMode === "morning" ? "(Morning Shift)" : predictionMode === "evening" ? "(Evening Shift)" : ""}`,
                        width: 210,
                        disableFilters: true,
                        accessor: (row: IShiftMaterialPrediction) => displayQuantity(predictionMode === "morning" ? row.totalMorningQuantity : predictionMode === "evening" ? row.totalEveningQuantity : row.totalFullDayQuantity),
                    },
                ],
            },
        ],
        [predictionMode]
    );

    const handleChangeShift = (e) => {
        const val = e.target.value as "morning" | "evening" | "full_day";
        setPreictionMode(val);
    }

    return (
        <>
            <Toast
                open={showToast.open}
                message={showToast.message}
                type={showToast.type}
                onClose={handleToastClose}
            />

            <div className={styles.container}>
                <div className={styles.header}>
                    <InputLabel
                        style={{
                            marginRight: "10px",
                        }}
                    >
                        From:
                    </InputLabel>
                    <ThemeProvider theme={materialTheme}>
                        <DatePicker
                            views={["date"]}
                            value={startDate}
                            placeholder={"From"}
                            onChange={(startDate) => {
                                handleStartDateChange(startDate);
                            }}
                            autoOk={true}
                            variant={"inline"}
                            disableFuture={false}
                            inputVariant="outlined"
                            format="MMM dd"
                            InputProps={{
                                className: classNames(styles.input, styles.widthDates),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <InputLabel
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                            }}
                        >
                            To:
                        </InputLabel>
                        <DatePicker
                            views={["date"]}
                            value={endDate}
                            placeholder={"To"}
                            onChange={(endDate) => {
                                handleEndDateChange(endDate);
                            }}
                            autoOk={true}
                            variant={"inline"}
                            disableFuture={false}
                            inputVariant="outlined"
                            format="MMM dd"
                            InputProps={{
                                className: classNames(styles.input, styles.widthDates),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CalendarIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </ThemeProvider>
                    <Button
                        className={styles.submitButton}
                        style={{
                            marginLeft: "10px",
                            color: primaryTextColor,
                            backgroundColor: primaryColor,
                        }}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </div>
                <div style={{ display: "flex" }}>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="shift-radio-buttons-group"
                            name="shift-radio-buttons-group"
                            defaultValue={"full_day"}
                            onChange={handleChangeShift}
                            color={primaryColor}
                        >
                            <FormControlLabel value="morning" control={<Radio />} label="Morning" />
                            <FormControlLabel value="evening" control={<Radio />} label="Evening" />
                            <FormControlLabel value="full_day" control={<Radio />} label="Full Day" />
                        </RadioGroup>
                    </FormControl>
                </div>
                <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="on"
                    onChange={handleChange}
                >
                    {dateTab.map((date, index) => (
                        <Tab key={index} label={date} />
                    ))}
                </Tabs>
                <DataTable
                    columns={columns}
                    data={tableData}
                    tableClass={styles.table}
                    isLoading={isFetching}
                    tableHeight={550}
                    marginBottom={20}
                    searchable={true}
                    initialSortBy="materialName"
                />
            </div>
        </>
    );
};

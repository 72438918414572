import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { ISalesForAllBranches } from "types/Sales";
import { getSaleEntries, getSales } from "api/GetSales";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  sales: ISalesForAllBranches[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQuerySales = (startDate: Date, endDate: Date): IQueryBranch => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: sales, isFetching } = useQuery(
    ["sales", start, end],
    async (key: string, start: string, end: string) => {
      return await getSales(start, end);
    }
  );
  return { status, sales, isFetching };
};

export const invalidateSalesCache = () => {
  queryCache.invalidateQueries("sales");
};

export const useQuerySaleEntries = (
  branchId: string,
  materialId: string,
  startDate: Date,
  endDate: Date
) => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: saleEntriesFetched, isFetching } = useQuery(
    ["saleEntries", start, end],
    async (key: String, start: string, end: string) => {
      return await getSaleEntries(start, end, branchId, materialId);
    }
  );
  return { status, saleEntriesFetched, isFetching };
};

export const invalidateSaleEntriesCache = () => {
  queryCache.invalidateQueries("saleEntries");
  queryCache.invalidateQueries("sales");
};

import styles from "./AddRecipeModal.module.css";
import React, { useContext, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ModalContext } from "contexts/ModalContext";
import { createArrayWithNumbers } from "lib/Arrays";
import {
  IMaterial,
  IMaterialPost,
  IngredientToQuantityMap,
} from "types/Material";
import { addNewMaterial } from "api/PostMaterial";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import classNames from "classnames";
import { MeasurementUnitList } from "types/MeasurementUnit";
import { MaterialType } from "types/MaterialType";
import { invalidateMaterialsCache } from "hooks/Materials";
import Toast from "components/Toast/Toast";

interface IProps {
  materialContextValues: IMaterialContextInterface;
}

interface IRecipeRow {
  index: number;
  name: string;
  materialsArr: IMaterial[];
  control: any;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
}

const RecipeRow: React.FC<IRecipeRow> = (props) => {
  const { index, name, materialsArr, control, getMaterial } = props;
  const defaultMaterial = getMaterial(name, index);
  const [material, setMaterial] = useState(defaultMaterial);
  return (
    <div key={index} className={styles.ingredients}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        name={`${name}.materialId`}
        control={control}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            value={getMaterial(name, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${name}.quantity`}
        render={({ onChange }) => (
          <FormControl className={styles.quantity} variant="outlined">
            <InputLabel htmlFor={"quantity-input" + name}>Quantity</InputLabel>
            <OutlinedInput
              id={"quantity-input" + name}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                onChange(value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material?.displayUnitOfMeasure ?? ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export const AddRecipeModal: React.FC<IProps> = (props) => {
  const { handleSubmit, control, getValues, register, errors } =
    useForm<IMaterialPost>();
  const modalProps = useContext(ModalContext);

  const [mutate] = useMutation(
    (material: IMaterialPost) => {
      return addNewMaterial([material]);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateMaterialsCache();
          modalProps.hideModal(true, "Recipe added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const onSubmit = async (data: any, e: any) => {
    setSubmitting(true);
    const ingredientsArr: { materialId: string; quantity: number }[] =
      data.ingredientsArr;
    let numberOfIngredients = 0;
    if (ingredientsArr?.length > 0) {
      const ingredients: IngredientToQuantityMap = {};
      ingredientsArr.forEach((value) => {
        if (
          value.materialId?.length > 0 &&
          value.quantity &&
          !isNaN(value.quantity) &&
          value.quantity > 0
        ) {
          ingredients[value.materialId] = value.quantity;
          numberOfIngredients++;
        }
      });
      data.ingredients = ingredients;
      data.ingredientsArr = null;
    }

    if (numberOfIngredients === 0) {
      setToastMessage("Atleast one ingredient is required!");
      setShowToast(true);
      setSubmitting(false);
      return;
    }

    mutate(data);
  };

  const [size, setSize] = useState(4);

  const { allMaterialsArrForFoodAnalysis, allMaterialsMapForFoodAnalysis } =
    props.materialContextValues;
  const getMaterial = (fieldName: string): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`) as string;
    if (materialIdFromValues) {
      let material;
      if (allMaterialsMapForFoodAnalysis.has(materialIdFromValues)) {
        material = allMaterialsMapForFoodAnalysis.get(materialIdFromValues);
      }

      return material ?? null;
    }

    return null;
  };

  const [type, setType] = React.useState(MaterialType.SEMI_PREPARED);

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Recipe</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      showDatePicker={false}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      <div className={styles.modalBody}>
        <>
          <div className={styles.inputOptions}>
            <TextField
              autoFocus={true}
              style={{ marginRight: 8 }}
              error={errors.name !== undefined}
              type={"text"}
              label="Name*"
              fullWidth
              margin="normal"
              variant="outlined"
              name="name"
              inputRef={register({ required: true })}
            />

            <TextField
              style={{ marginRight: 8 }}
              error={errors.quantity !== undefined}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              label="Recipe Quantity*"
              fullWidth
              margin="normal"
              variant="outlined"
              name="quantity"
              inputRef={register({ required: true })}
            />

            <Controller
              control={control}
              name="unitOfMeasure"
              rules={{
                required: true,
              }}
              render={({ onChange }) => (
                <>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    classes={{
                      root: classNames(styles.materialUnit),
                    }}
                  >
                    <InputLabel
                      id="unit-of-measure-label"
                      style={{
                        color: errors.unitOfMeasure ? "#f44336" : "",
                      }}
                    >
                      Unit of Measure*
                    </InputLabel>
                    <Select
                      labelId="unit-of-measure-label"
                      label="Unit of Measure*"
                      error={errors.unitOfMeasure !== undefined}
                      value={getValues("unitOfMeasure") ?? ""}
                      onChange={(event) => {
                        onChange(event.target.value as string);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {MeasurementUnitList.map((measureUnit, index) => (
                        <MenuItem value={measureUnit} key={index}>
                          {measureUnit}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            />
          </div>

          <>
            <Controller
              name={`type`}
              control={control}
              defaultValue={type}
              render={({ onChange }) => (
                <FormControl
                  component="fieldset"
                  classes={{
                    root: classNames(styles.radioSelection),
                  }}
                >
                  <FormLabel component="legend">Select State</FormLabel>
                  <RadioGroup
                    value={type}
                    row
                    onChange={(event) => {
                      const type = (event.target as HTMLInputElement)
                        .value as MaterialType;
                      setType(type);
                      onChange(type);
                    }}
                  >
                    <FormControlLabel
                      value={MaterialType.SEMI_PREPARED}
                      style={{
                        marginRight: 80,
                      }}
                      control={<Radio />}
                      label="Semi Prepared"
                    />
                    <FormControlLabel
                      value={MaterialType.SELLABLE}
                      control={<Radio />}
                      label="Prepared"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </>

          <>
            {createArrayWithNumbers(size).map(
              (value: number, index: number) => {
                const fieldName = `ingredientsArr[${index}]`;
                return (
                  <RecipeRow
                    key={index}
                    index={index}
                    name={fieldName}
                    control={control}
                    materialsArr={allMaterialsArrForFoodAnalysis}
                    getMaterial={getMaterial}
                  />
                );
              }
            )}
          </>

          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            Add More
          </button>
        </>
      </div>
    </ModalPopup>
  );
};

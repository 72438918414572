import React from "react";

function CommentIconComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.333}
      height={11.758}
      viewBox="0 0 14.333 11.758"
    >
      <path
        d="M10.409.25H3.924A3.538 3.538 0 00.25 3.625v2.25A3.5 3.5 0 003.519 9.23v1.9a.376.376 0 00.25.346.43.43 0 00.442-.081L6.524 9.25h3.885a3.538 3.538 0 003.674-3.375v-2.25A3.538 3.538 0 0010.409.25zm2.864 5.625A2.755 2.755 0 0110.409 8.5H6.356a.422.422 0 00-.287.11L4.33 10.22V8.875a.391.391 0 00-.405-.375 2.755 2.755 0 01-2.864-2.625v-2.25A2.755 2.755 0 013.924 1h6.484a2.755 2.755 0 012.864 2.625z"
        fill="#4d4d4d"
        stroke="#4d4d4d"
        strokeWidth={0.5}
      />
    </svg>
  );
}
const CommentIcon = React.memo(CommentIconComponent);
export default CommentIcon;

import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";
import { IStockPost } from "types/Stock";
import { StockType } from "types/StockType";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

export const addStock = async (
  stockRecords: IStockPost[],
  date: Date,
  type: StockType,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/stocks/branches/${branch.id}`,
      {
        stockRecords,
        type,
        date,
      }
    );
    if (response.status === 202) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Stock could not be added";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const uploadStock = async (
  files: File[],
  date: Date,
  type: StockType,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V1}/stocks/branches/${
        branch.id
      }/uploadReport?date=${formatDate(date)}&stockRequestType=${type}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );

    if (response.status === 202) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Stock_Errors.xlsx"
      );
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ?? "Unable to upload Stock";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateStockEntry = async (
  stockId: string,
  type: StockType,
  quantity: Number
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/stocks/${stockId}/type/${type}`,
      {
        quantity,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Stock could not be updated";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

import { Button } from "@material-ui/core";
import ContentPage from "components/contentPage/ContentPage";
import React from "react";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import styles from "./ViewSupplier.module.css";
import { ViewSuppliersTable } from "components/viewSuppliersTable/ViewSuppliersTable";

const ViewSuppliers: React.FC = React.memo(() => {
  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = () => {
  const history = useHistory();

  return (
    <>
      <Button
        onClick={() => {
          history.goBack();
        }}
        classes={{
          root: styles.backButton,
        }}
      >
        <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
      </Button>
      <div className={styles.pageName}>{"View Suppliers"}</div>
    </>
  );
};

const MainSection: React.FC = () => <ViewSuppliersTable />;

const RightSection: React.FC = () => <></>;

export default ViewSuppliers;

import moment from "moment";
import { IComplimentaryFoodView } from "types/ComplimentaryFood";
import {
  displayNameForDashboardStatus,
  IDashboardViewForMaterial,
} from "types/DashboardView";
import {
  displayNameForUnitOfMeasure,
  displayQuantity,
} from "types/MeasurementUnit";
import { ISaleFood } from "types/Sales";
import { IStaffFood } from "types/StaffFood";
import { IStockFood } from "types/Stock";
import { IStockTransfer } from "types/StockTransfer";
import { IWastageFood } from "types/WastageFood";

export function getDashboardDataForExport(
  dashboardViews: IDashboardViewForMaterial[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    expectedConsumption: string;
    actualConsumption: string;
    difference: string;
    status: string;
  }[] = [];

  dashboardViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  dashboardViews.forEach((dashboardView) => {
    const row = {
      itemName: dashboardView.materialName,
      expectedConsumption: displayQuantity(
        dashboardView.expectedConsumptionDisplayQuantity
      ),
      actualConsumption: displayQuantity(
        dashboardView.actualConsumptionDisplayQuantity
      ),
      difference: displayQuantity(dashboardView.differenceDisplayQuantity),
      status: displayNameForDashboardStatus(dashboardView.status),
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Expected Consumption", key: "expectedConsumption" },
      { label: "Actual Consumption", key: "actualConsumption" },
      { label: "Difference", key: "difference" },
      { label: "Status", key: "status" },
    ],
    rows,
  };
}

export function getStockDataForExport(
  stockViews: IStockFood[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    openingStock: string;
    purchased: string;
    closingStock: string;
    consumption: string;
  }[] = [];

  stockViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  stockViews.forEach((stockView) => {
    const row = {
      itemName: stockView.materialName,
      openingStock: displayQuantity(stockView.displayOpeningQuantity),
      purchased: displayQuantity(stockView.displayQuantity),
      closingStock: displayQuantity(stockView.displayClosingQuantity),
      consumption: displayQuantity(stockView.displayConsumptionQuantity),
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Opening Stock", key: "openingStock" },
      { label: "Purchased", key: "purchased" },
      { label: "Closing Stock", key: "closingStock" },
      { label: "Consumption", key: "consumption" },
    ],
    rows,
  };
}

export function getSalesDataForExport(
  salesViews: ISaleFood[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    unitOfMeasure: string;
    quantity: string;
  }[] = [];

  salesViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  salesViews.forEach((salesView) => {
    const row = {
      itemName: salesView.materialName,
      unitOfMeasure: displayNameForUnitOfMeasure(salesView.measurementUnit),
      quantity: salesView.quantity + "",
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Unit of Measure", key: "unitOfMeasure" },
      { label: "Quantity", key: "quantity" },
    ],
    rows,
  };
}

export function getWastageDataForExport(
  wastageViews: IWastageFood[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    quantity: string;
  }[] = [];

  wastageViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  wastageViews.forEach((wastageView) => {
    const row = {
      itemName: wastageView.materialName,
      quantity: displayQuantity(wastageView.displayQuantity),
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Quantity", key: "quantity" },
    ],
    rows,
  };
}

export function getStaffFoodDataForExport(
  staffFoodViews: IStaffFood[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    servings: string;
    quantity: string;
    mealType: string;
  }[] = [];

  staffFoodViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  staffFoodViews.forEach((staffFoodView) => {
    const row = {
      itemName: staffFoodView.materialName,
      servings: staffFoodView.servings + "",
      quantity: displayQuantity(staffFoodView.displayQuantity),
      mealType: staffFoodView.mealType,
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Servings", key: "servings" },
      { label: "Quantity", key: "quantity" },
      { label: "Meal Type", key: "mealType" },
    ],
    rows,
  };
}

export function getStockTransferDataForExport(
  stockTransferViews: IStockTransfer[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    quantity: string;
    inOut: string;
    toFrom: string;
    date: string;
    status: string;
  }[] = [];

  stockTransferViews.sort((a, b) => {
    return a.materialName.toUpperCase() > b.materialName.toUpperCase() ? 1 : -1;
  });

  stockTransferViews.forEach((stockTransferView) => {
    const row = {
      itemName: stockTransferView.materialName,
      quantity: displayQuantity(stockTransferView.displayQuantity),
      inOut: stockTransferView.inOut,
      toFrom: stockTransferView.toFrom,
      date: moment(stockTransferView.requestInitiationDate).format(
        "DD-MM-yyyy"
      ),
      status: stockTransferView.status,
    };
    rows.push(row);
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Quantity", key: "quantity" },
      { label: "In / Out", key: "inOut" },
      { label: "To / From", key: "toFrom" },
      { label: "Date", key: "date" },
      { label: "Status", key: "status" },
    ],
    rows,
  };
}

export function getComplimentaryFoodDataForExport(
  complimentaryFoods: IComplimentaryFoodView[]
): { headers: { label: string; key: string }[]; rows: any[] } {
  const rows: {
    itemName: string;
    quantity: string;
    date: string;
    status: string;
    comment: string;
  }[] = [];

  complimentaryFoods.forEach((complimentaryFood) => {
    complimentaryFood.foodItems.forEach((foodItem) => {
      const row = {
        itemName: foodItem.materialName,
        quantity: displayQuantity(foodItem.displayQuantity),
        date: moment(complimentaryFood.date).format("DD-MM-yyyy"),
        status: complimentaryFood.status,
        comment: complimentaryFood.comment,
      };
      rows.push(row);
    });
  });

  return {
    headers: [
      { label: "Item", key: "itemName" },
      { label: "Quantity", key: "quantity" },
      { label: "Date", key: "date" },
      { label: "Status", key: "status" },
      { label: "Comment", key: "comment" },
    ],
    rows,
  };
}

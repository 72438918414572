import { useQuery, QueryStatus } from "react-query";
import { getReport } from "api/GetReports";
import { IDateWiseReport } from "types/EntryReport";
import { formatStartEndDates } from "lib/Date";
import { FMSError } from "types/Error";

interface IQueryEntryReport {
  status: QueryStatus;
  entryReport: IDateWiseReport | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryReport = (
  reportType: string,
  startDate: Date,
  endDate: Date
): IQueryEntryReport => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: entryReport, isFetching } = useQuery(
    ["entryReport", start, end],
    async (key: string, start: string, end: string) => {
      return await getReport(reportType, start, end);
    }
  );

  return { status, entryReport, isFetching };
};

import { IBranch } from "types/Branch";

export const getFromLocalStorage = (key: string) => {
  const item = window.localStorage.getItem(key);
  if (item) {
    return JSON.parse(item, (key: string, value: any) => {
      if (typeof value === "object" && value !== null) {
        if (value.dataType === "Map") {
          return new Map(value.value);
        }
      }
      return value;
    });
  }
  return null;
};

export const setInLocalStorage = (key: string, value: any) => {
  window.localStorage.setItem(
    key,
    JSON.stringify(value, (key: string, value: any) => {
      return value instanceof Map
        ? { dataType: "Map", value: Array.from(value.entries()) }
        : value;
    })
  );
};

export const getBranchFromLocalStorage = () => {
  const selectedBranch = getFromLocalStorage(
    "selectedBranch"
  ) as IBranch | null;

  return selectedBranch;
};

export const setBranchInLocalStorage = (value: IBranch) => {
  setInLocalStorage("selectedBranch", value);
};

export function clearLocalStorage() {
  window.localStorage.clear();
}

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function StockIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.183}
      height={14.91}
      viewBox="0 0 11.183 14.91"
    >
      <defs>
        <style>
          {".stockIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke:" +
            primaryTextColor +
            ";stroke-width:.4px}"}
        </style>
      </defs>
      <path
        className="stockIconComponent__a"
        d="M10.951 13.723l-.147-.547-.192-.718a35.787 35.787 0 000-10.006l.339-1.265a.78.78 0 00-.959-.954l-1.25.335a14.009 14.009 0 00-6.308 0L1.192.233a.78.78 0 00-.955.955l.339 1.265a35.787 35.787 0 000 10.006l-.339 1.265a.779.779 0 00.955.955l1.25-.335a14.009 14.009 0 006.308 0l1.25.335a.792.792 0 00.205.027.781.781 0 00.749-.982zm-1.182-1.129l.215.8.115.43-1.236-.331a.424.424 0 00-.208 0 13.177 13.177 0 01-6.127 0 .425.425 0 00-.208 0l-1.236.331.33-1.232a.426.426 0 00.01-.17 34.936 34.936 0 010-9.937.426.426 0 00-.01-.17l-.329-1.23 1.236.331a.425.425 0 00.208 0 13.177 13.177 0 016.127 0 .425.425 0 00.208 0l1.235-.331-.33 1.232a.425.425 0 00-.01.17 34.936 34.936 0 010 9.937.424.424 0 00.01.17z"
      />
      <path
        className="stockIconComponent__a"
        d="M8.261 4.702H7.215a2.046 2.046 0 00-1.623.8 2.046 2.046 0 00-1.623-.8H2.923a.425.425 0 00-.425.425v.769a2.051 2.051 0 002.048 2.048h.621v1.838a.425.425 0 10.85 0V7.944h.621a2.051 2.051 0 002.048-2.048v-.77a.425.425 0 00-.425-.424zM3.347 5.896v-.345h.621a1.2 1.2 0 011.2 1.2v.345h-.621a1.2 1.2 0 01-1.2-1.2zm4.49 0a1.2 1.2 0 01-1.2 1.2h-.621v-.345a1.2 1.2 0 011.2-1.2h.621z"
      />
    </svg>
  );
}
const StockIcon = React.memo(StockIconComponent);
export default StockIcon;

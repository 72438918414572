import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import { capitalizeFirstLetter } from "lib/StringHelpers";
import React from "react";
import { Controller } from "react-hook-form";

interface ISingleInputDropdownProps {
  name: string;
  label: string;
  values: { key: string; value: string }[];
  value: string;
  setValue: (value: string) => void;
  control: any;
  errors: any;
  rules?: { required?: boolean; validate?: (data: any) => boolean };
  class?: string;
  disabled?: boolean;
  setDefaultValue?: boolean;
}

export const SingleInputDropdown: React.FC<ISingleInputDropdownProps> = (
  props
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.setDefaultValue === true ? props.value : null}
      render={({ onChange }) => (
        <>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classNames(props.class),
            }}
            style={{
              margin: 8,
            }}
          >
            <InputLabel
              id={`${props.name}-label`}
              style={{
                color: props.errors[`${props.name}`] ? "#f44336" : "",
              }}
            >
              {props.label}
            </InputLabel>
            <Select
              disabled={props.disabled}
              labelId={`${props.name}-label`}
              label={props.label}
              error={props.errors[`${props.name}`] !== undefined}
              value={props.value}
              onChange={(event) => {
                const value = event.target.value as string;
                props.setValue(value);
                onChange(value);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {props.values.map((value, index) => (
                <MenuItem value={value.key} key={index}>
                  {capitalizeFirstLetter(value.value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    />
  );
};

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function ComplimentaryFoodIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14.5}
      height={14.5}
      viewBox="0 0 14.5 14.5"
    >
      <defs>
        <style>
          {".complimentaryFoodIconComponent__a{fill: " + primaryTextColor + "}"}
        </style>
      </defs>
      <path
        className="complimentaryFoodIconComponent__a"
        d="M10.059 5.05l-.144-.475a1.033 1.033 0 00-1.956 0l-.144.475a2.67 2.67 0 00-.074 1.238 1.164 1.164 0 00.6.811l-.162 3.616a.712.712 0 00.18.554.786.786 0 001.11.05q.026-.024.05-.05a.7.7 0 00.18-.539l-.163-3.632a1.164 1.164 0 00.6-.811 2.67 2.67 0 00-.077-1.237zM5.566 11.523h-.041a.759.759 0 01-.722-.8l.192-3.834-.6-.6a.222.222 0 01-.07-.164V4.1a.225.225 0 01.225-.225H5V5.15a.225.225 0 00.45 0V3.875h.225V5.15a.225.225 0 00.45 0V3.875h.45A.225.225 0 016.8 4.1v2.025a.227.227 0 01-.066.159l-.6.6.191 3.843a.684.684 0 010 .074.757.757 0 01-.759.722z"
      />
      <path
        d="M7.25.5A6.75 6.75 0 1014 7.25 6.75 6.75 0 007.25.5zm0 13.05a6.3 6.3 0 116.3-6.3 6.3 6.3 0 01-6.3 6.3z"
        stroke={primaryTextColor}
        fill={primaryTextColor}
      />
    </svg>
  );
}
const ComplimentaryFoodIcon = React.memo(ComplimentaryFoodIconComponent);
export default ComplimentaryFoodIcon;

import ContentPage from "components/contentPage/ContentPage";
import React from "react";
import { useParams } from "react-router-dom";
import ViewPurchaseOrder from "components/viewPurchaseOrder/ViewPurchaseOrder";

const ViewPurchaseOrderContainer: React.FC = React.memo(() => {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection purchaseOrderId={purchaseOrderId} />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = () => {
  return <></>;
};

const MainSection: React.FC<{ purchaseOrderId: string }> = (props) => {
  return <ViewPurchaseOrder purchaseOrderId={props.purchaseOrderId} />;
};

const RightSection: React.FC = () => {
  return <></>;
};

export default ViewPurchaseOrderContainer;

import React, { useState } from "react";
import ContentPage from "components/contentPage/ContentPage";
import styles from "./Report.module.css";
import { DailyEntryReportTable } from "components/entryReportTables/DailyEntryReportTable";
import { InputLabel, Select, MenuItem } from "@material-ui/core";
import { OtherEntryReportTable } from "components/entryReportTables/OtherEntryReportTable";
import { ReportsWithDateRange } from "components/reportsWithDateRange/ReportsWithDateRange";
import { Auth } from "lib/Auth";

const Report: React.FC = React.memo(() => {
  return (
    <ContentPage
      header={<div className={styles.pageName}>{""}</div>}
      mainContent={<MainSection />}
    />
  );
});

const reportTypeConfig = [
  {
    value: "daily",
    label: "Daily Entry",
  },
  {
    value: "other",
    label: "Other Entry",
  },
  {
    value: "unitWisePO",
    label: "PO Materials Delivered",
  },
  {
    value: "unitWisePOForSupplier",
    label: "PO Materials Delivered For Supplier",
    adminAndSupervisorOnly: true,
  },
  {
    value: "allPOExport",
    label: "All PO Export",
    adminAndSupervisorOnly: true,
  },
  {
    value: "branchWiseFoodAnalysis",
    label: "Branch Wise Food Analysis",
    adminAndSupervisorOnly: true,
  },
  {
    value: "storeForecast",
    label: "Store Forecast",
    adminAndSupervisorOnly: true,
  },
  {
    value: "kitchenForecast",
    label: "Kitchen Forecast",
    adminAndSupervisorOnly: true,
  },
  {
    value: "salesForecast",
    label: "Sales Forecast",
    adminAndSupervisorOnly: true,
  }
]

const MainSection: React.FC = () => {
  const [reportType, setReportType] = useState<string>("daily");
  const handleReportChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setReportType(event.target.value as string);
  };

  const isCurrentUserAdminOrSupervisor = Auth.getInstance().isAdminOrSupervisor();

  return (
    <>
      <div>
        <InputLabel
          style={{
            float: "left",
            marginTop: "10px",
            marginRight: "10px",
          }}
        >
          Report type:
        </InputLabel>
        <Select
          id="report"
          value={reportType}
          style={{ display: "flex", width: "300px", marginRight: "10px" }}
          onChange={handleReportChange}
        >
          {reportTypeConfig.filter((config) => {
            if (config.adminAndSupervisorOnly) {
              return isCurrentUserAdminOrSupervisor;
            }
            return true;
          }).map((config) => (
            <MenuItem key={config.value} value={config.value}>
              {config.label}
            </MenuItem>
          ))}
        </Select>
      </div>
      {reportType === "daily" && <DailyEntryReportTable />}
      {reportType === "other" && <OtherEntryReportTable />}
      {reportType === "unitWisePO" && (
        <ReportsWithDateRange reportType={"unitWisePO"} disableFutureDates={true} />
      )}
      {reportType === "unitWisePOForSupplier" && (
        <ReportsWithDateRange reportType={"unitWisePOForSupplier"} disableFutureDates={true} />
      )}
      {reportType === "allPOExport" && (
        <ReportsWithDateRange reportType={"allPOExport"} disableFutureDates={true} />
      )}
      {reportType === "branchWiseFoodAnalysis" && (
        <ReportsWithDateRange reportType={"branchWiseFoodAnalysis"} disableFutureDates={true} />
      )}
      {reportType === "storeForecast" && (
        <ReportsWithDateRange reportType={"storeForecast"} disableFutureDates={false} />
      )}
      {reportType === "kitchenForecast" && (
        <ReportsWithDateRange reportType={"kitchenForecast"} disableFutureDates={false} />
      )}
      {reportType === "salesForecast" && (
        <ReportsWithDateRange reportType={"salesForecast"} disableFutureDates={false} />
      )}
    </>
  );
};

export default Report;

import { Button, Chip, MenuItem, Select } from "@material-ui/core";
import {
  DataTable,
  IReactTableInitialStateFilters,
} from "components/dataTable/DataTable";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { useQueryPurchaseOrders } from "hooks/purchaseOrders";
import { Auth } from "lib/Auth";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { HeaderGroup } from "react-table";
import { IExternalBranch } from "types/Branch";
import { FMSError } from "types/Error";
import {
  displayNameForPurchaseOrderStatus,
  IPurchaseOrder,
  PurchaseOrderStatus,
  sortPurchaseOrdersByDate,
} from "types/PurchaseOrder";
import { IPurchaseOrderDateRange } from "types/PurchaseOrderDateRanges";
import { ISupplier } from "types/Supplier";
import styles from "./PurchaseOrderTable.module.css";
import queryString from "query-string";

interface IFilterProps {
  column: HeaderGroup;
}

function getUniqueFilterOptions(purchaseOrders: IPurchaseOrder[]) {
  const branchesMap = new Map<string, IExternalBranch>();
  const suppliersMap = new Map<string, ISupplier>();

  purchaseOrders.forEach((purchaseOrder) => {
    const branch = purchaseOrder.branch;
    if (!branchesMap.has(branch.id)) {
      branchesMap.set(branch.id, branch);
    }

    const supplier = purchaseOrder.supplierView;
    if (!suppliersMap.has(supplier.id)) {
      suppliersMap.set(supplier.id, supplier);
    }
  });

  const uniqueBranches = [...branchesMap.values()].sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  const uniqueSuppliers = [...suppliersMap.values()].sort((a, b) =>
    a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
  );

  return { uniqueBranches, uniqueSuppliers };
}

function filterPurchaseOrdersForStatus(rows: [any], status: string) {
  if (status.length === 0) {
    return rows;
  }

  const selectedStatus = status as PurchaseOrderStatus;
  return rows.filter((row) => {
    const purchaseOrder = row.original as IPurchaseOrder;
    return purchaseOrder.purchaseOrderStatus === selectedStatus;
  });
}

function filterPurchaseOrdersForBranch(rows: [any], branchId: string) {
  if (branchId.length === 0) {
    return rows;
  }

  return rows.filter((row) => {
    const purchaseOrder = row.original as IPurchaseOrder;
    return purchaseOrder.branch.id === branchId;
  });
}

function filterPurchaseOrdersForSupplier(rows: [any], supplierId: string) {
  if (supplierId.length === 0) {
    return rows;
  }

  return rows.filter((row) => {
    const purchaseOrder = row.original as IPurchaseOrder;
    return purchaseOrder.supplierView.id === supplierId;
  });
}

export const PurchaseOrderTable: React.FC<{
  selectedDateRange: IPurchaseOrderDateRange;
}> = (props) => {
  const { start, end } = props.selectedDateRange.dateRange();
  const { status, purchaseOrdersResponse, isFetching } = useQueryPurchaseOrders(
    start,
    end
  );
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([]);
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const [filterOptions, setFilterOptions] = useState<{
    uniqueBranches: IExternalBranch[];
    uniqueSuppliers: ISupplier[];
  }>();

  const location = useLocation();
  const currentPath = location.pathname;
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (status === "success") {
      if (
        !purchaseOrdersResponse ||
        purchaseOrdersResponse instanceof FMSError
      ) {
        const message =
          purchaseOrdersResponse?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setPurchaseOrders(
          sortPurchaseOrdersByDate(purchaseOrdersResponse.purchaseOrders)
        );
        setFilterOptions(
          getUniqueFilterOptions(purchaseOrdersResponse.purchaseOrders)
        );
      }
    }
  }, [status, purchaseOrdersResponse]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Date",
            width: 70,
            accessor: (row: IPurchaseOrder) =>
              moment(row.creationTime).format("DD-MM-yyyy"),
            disableFilters: true,
          },
          {
            Header: "Branch",
            width: 250,
            accessor: (row: IPurchaseOrder) => row.branch.name,
            Filter: (props: IFilterProps) => {
              return (
                <Select
                  value={props.column.filterValue ?? ""}
                  displayEmpty
                  onChange={(e) => {
                    const branchId = e.target.value ?? "";
                    props.column.setFilter(branchId);

                    var branch = filterOptions?.uniqueBranches.find(
                      (branch) => {
                        return branch.id === branchId;
                      }
                    );

                    let searchParams = "";
                    if (branch) {
                      params.branch = branch.id;
                      searchParams = queryString.stringify(params);
                    } else {
                      searchParams = queryString.exclude(location.search, [
                        "branch",
                      ]);
                    }
                    history.push({
                      pathname: currentPath,
                      search: searchParams,
                    });
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {filterOptions?.uniqueBranches.map((branch, index) => (
                    <MenuItem key={index} value={branch.id}>
                      {branch.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            },
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterPurchaseOrdersForBranch(rows, filterValue);
            },
          },
          {
            Header: "P.O.",
            accessor: "purchaseOrderNumber",
            width: 100,
            disableFilters: true,
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              let viewPurchaseOrderLink: string;
              if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.ORDERED ||
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED
              ) {
                viewPurchaseOrderLink = `/purchase-orders/${purchaseOrder.id}/grn`;
              } else {
                viewPurchaseOrderLink = `/purchase-orders/${purchaseOrder.id}`;
              }

              return (
                <Link to={viewPurchaseOrderLink}>
                  {purchaseOrder.vanityNumber ?? "View PO"}
                </Link>
              );
            },
          },
          {
            Header: "Supplier",
            width: 225,
            accessor: (row: IPurchaseOrder) => row.supplierView.name,
            Filter: (props: IFilterProps) => {
              return (
                <Select
                  value={props.column.filterValue ?? ""}
                  displayEmpty
                  onChange={(e) => {
                    const supplierId = e.target.value ?? "";
                    props.column.setFilter(supplierId);

                    var supplier = filterOptions?.uniqueSuppliers.find(
                      (supplier) => {
                        return supplier.id === supplierId;
                      }
                    );

                    let searchParams = "";
                    if (supplier) {
                      params.supplier = supplier?.id;
                      searchParams = queryString.stringify(params);
                    } else {
                      searchParams = queryString.exclude(location.search, [
                        "supplier",
                      ]);
                    }
                    history.push({
                      pathname: currentPath,
                      search: searchParams,
                    });
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {filterOptions?.uniqueSuppliers.map((supplier, index) => (
                    <MenuItem key={index} value={supplier.id}>
                      {supplier.name}
                    </MenuItem>
                  ))}
                </Select>
              );
            },
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterPurchaseOrdersForSupplier(rows, filterValue);
            },
          },
          {
            Header: "GRN",
            accessor: "grnNumber",
            width: 80,
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.ORDERED
              ) {
                return (
                  <Button
                    style={{
                      backgroundColor: primaryColor,
                      color: primaryTextColor,
                    }}
                    onClick={() => {
                      history.push(`/purchase-orders/${purchaseOrder.id}/grn`);
                    }}
                    className={styles.addSubmitBtn}
                  >
                    {"Generate GRN"}
                  </Button>
                );
              } else if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED
              ) {
                return (
                  <Link to={`/purchase-orders/${purchaseOrder.id}/grn`}>
                    {"View GRN"}
                  </Link>
                );
              } else {
                return "-";
              }
            },
            disableFilters: true,
          },
          {
            Header: "Status",
            width: 150,
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              const isAdminOrSupervisor = Auth.getInstance().isAdminOrSupervisor();
              if (
                isAdminOrSupervisor &&
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.PENDING
              ) {
                return (
                  <Button
                    style={{
                      backgroundColor: "#FC4059",
                      color: primaryTextColor,
                    }}
                    onClick={() => {
                      history.push(`/purchase-orders/${purchaseOrder.id}`);
                    }}
                    className={styles.addSubmitBtn}
                  >
                    {"Approve"}
                  </Button>
                );
              }

              let chipStyle: string | undefined;
              if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED
              ) {
                chipStyle = styles.green;
              } else if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.APPROVED
              ) {
                chipStyle = styles.blue;
              } else if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.PENDING
              ) {
                chipStyle = styles.lightRed;
              } else {
                // ordered
                chipStyle = styles.yellow;
              }

              return (
                <Chip
                  style={{
                    width: 100,
                    height: 23,
                  }}
                  label={displayNameForPurchaseOrderStatus(
                    purchaseOrder.purchaseOrderStatus
                  )}
                  className={chipStyle}
                />
              );
            },
            Filter: (props: IFilterProps) => {
              return (
                <Select
                  value={props.column.filterValue ?? ""}
                  displayEmpty
                  onChange={(e) => {
                    const poStatus = e.target.value ?? "";
                    props.column.setFilter(poStatus);

                    const purchaseOrderStatusList =
                      Object.values(PurchaseOrderStatus);

                    var status = purchaseOrderStatusList.find(
                      (purchaseOrderStatus) => {
                        return purchaseOrderStatus === poStatus;
                      }
                    );

                    let searchParams = "";
                    if (status) {
                      params.poStatus = status;
                      searchParams = queryString.stringify(params);
                    } else {
                      searchParams = queryString.exclude(location.search, [
                        "poStatus",
                      ]);
                    }
                    history.push({
                      pathname: currentPath,
                      search: searchParams,
                    });
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {Object.values(PurchaseOrderStatus).map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {displayNameForPurchaseOrderStatus(status)}
                    </MenuItem>
                  ))}
                </Select>
              );
            },
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterPurchaseOrdersForStatus(rows, filterValue);
            },
          },
        ],
      },
    ],
    [
      primaryColor,
      primaryTextColor,
      history,
      filterOptions,
      currentPath,
      params,
      location.search,
    ]
  );

  const initialStateFilters: IReactTableInitialStateFilters[] = [];

  // set initial filters from params in url
  if (params.branch) {
    initialStateFilters.push({ id: "Branch", value: params.branch });
  }
  if (params.supplier) {
    initialStateFilters.push({ id: "Supplier", value: params.supplier });
  }
  if (params.poStatus) {
    initialStateFilters.push({ id: "Status", value: params.poStatus });
  }

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <DataTable
        columns={columns}
        data={purchaseOrders}
        tableClass={styles.table}
        marginTop={30}
        searchable={false}
        isLoading={isFetching}
        tableHeight={600}
        initialStateFilters={initialStateFilters}
      />
    </>
  );
};

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IEventCategories, IEventCategoriesView } from "types/Event";

export const getEventCategories = async (): Promise<
  IEventCategories | FMSError
> => {
  try {
    const response = await get<IEventCategoriesView>(
      `${BACKEND_URL_V1}/events/eventCategories`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody?.eventCategoriesView;
      }
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Event categories entries could not be retrived";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import { useAsyncDebounce } from "react-table";
import { TextField, InputAdornment } from "@material-ui/core";
import styles from "./GlobalFilter.module.css";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import classNames from "classnames";

interface IGlobalFilter {
  preGlobalFilteredRows: any;
  globalFilter: any;
  setGlobalFilter: any;
}

// Define a default UI for filtering
export const GlobalFilter: React.FC<IGlobalFilter> = (props) => {
  const onChange = useAsyncDebounce((value) => {
    props.setGlobalFilter(value || undefined);
  }, 200);

  const [filterValueState, setFilterValueState] = useState("");

  useEffect(() => {
    setFilterValueState(props.globalFilter ?? "");
  }, [props.globalFilter])
  
  return (
    <TextField
      classes={{
        root: classNames(styles.input, styles.inline, styles.searchRoot),
      }}
      InputProps={{
        className: classNames(styles.input, styles.widthSearch),
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fontSize={"small"} />
          </InputAdornment>
        ),
      }}
      type={"text"}
      placeholder="Search"
      value={filterValueState}
      fullWidth
      label=""
      variant="outlined"
      name="search"
      onChange={(e: any) => {
        const value = e.target.value as string;
        setFilterValueState(value);
        onChange(value.trim());
      }}
    />
  );
};

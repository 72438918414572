import { get, BACKEND_URL_V2, BACKEND_URL_V1, post } from "lib/Api";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";
import { BusniessUnit } from "types/Branch";
import { IDateWiseReport, IEntryReport } from "types/EntryReport";
import { FMSError } from "types/Error";

export const getReport = async (
  reportType: string,
  start: string,
  end: string
): Promise<IDateWiseReport | FMSError> => {
  try {
    const response = await get<IEntryReport>(
      `${BACKEND_URL_V2}/reports/reportType/${reportType}?start=${start}&end=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody.entryReport;
      }
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Entry report could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const downloadUnitWisePurchaseOrdersForSupplier = async (
  supplierId: string,
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/reports/purchaseOrders/suppliers/${supplierId}/unitWise?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Purchase_Orders.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order report could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const downloadUnitWisePurchaseOrders = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/reports/purchaseOrders/unitWise?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Purchase_Orders.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order report could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const downloadExportPurchaseOrdersReport = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/reports/purchaseOrders/export?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Purchase_Orders.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order report could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const emailBranchWiseFoodAnalysisReport = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | string> => {
  try {
    const response = await post<{ message: string }>(
      `${BACKEND_URL_V1}/materials/statistics/triggerEmailReport?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody.message;
      }

      return "Report will be emailed to you shortly!";
    }

    const errorMessage =
      response.serverError?.error.message ?? "Report could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const emailPreparedMaterialPredictionReport = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | string> => {
  try {
    const response = await get<{ message: string }>(
      `${BACKEND_URL_V1}/reports/predictions/preparedMaterials?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody.message;
      }

      return "Report will be emailed to you shortly!";
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Sales forecast could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const emailSemiPreparedMaterialPredictionReport = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | string> => {
  try {
    const response = await get<{ message: string }>(
      `${BACKEND_URL_V1}/reports/predictions/semiPreparedMaterials?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody.message;
      }

      return "Report will be emailed to you shortly!";
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Kitchen forecast could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const emailRawMaterialPredictionReport = async (
  start: Date,
  end: Date,
  businessUnit: BusniessUnit
): Promise<FMSError | string> => {
  try {
    const response = await get<{ message: string }>(
      `${BACKEND_URL_V1}/reports/predictions/rawMaterials?start=${formatDate(
        start
      )}&end=${formatDate(end)}&businessUnit=${businessUnit}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody.message;
      }

      return "Report will be emailed to you shortly!";
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Store forecast could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

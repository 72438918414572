import { get, BACKEND_URL_V1 } from "lib/Api";
import { formatStartEndDates } from "lib/Date";
import { FMSError } from "types/Error";
import {
  IMaterial,
  IMaterialAssociatedEntitiesStatisticsView,
  IMaterialUpdateRequestView,
} from "types/Material";

interface IGetMaterials {
  materials: IMaterial[];
}

export const getMaterials = async (): Promise<IMaterial[] | FMSError> => {
  try {
    const response = await get<IGetMaterials>(`${BACKEND_URL_V1}/materials`);

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.materials) {
        return response.parsedBody.materials;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Materials could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getMaterial = async (
  materialId: string
): Promise<IMaterial | FMSError> => {
  try {
    const response = await get<IMaterial>(
      `${BACKEND_URL_V1}/materials/${materialId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      return response.parsedBody ?? new FMSError("Something went wrong");
    }

    const errorMessage =
      response.serverError?.error.message ?? "Recipe could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getMaterialConsumptionStats = async (
  materialId: string,
  start: Date,
  end: Date,
  branchId: string
): Promise<IMaterialAssociatedEntitiesStatisticsView | FMSError> => {
  const [startDate, endDate] = formatStartEndDates(start, end);
  try {
    const response = await get<IMaterialAssociatedEntitiesStatisticsView>(
      `${BACKEND_URL_V1}/materials/${materialId}/statistics?start=${startDate}&end=${endDate}&branchId=${branchId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      return response.parsedBody ?? new FMSError("Something went wrong");
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Consumption statistics could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getMaterialUpdateStatus = async (materialUpdateRequestId: string): Promise<IMaterialUpdateRequestView | FMSError> => {
  try {
    const response = await get<IMaterialUpdateRequestView>(
      `${BACKEND_URL_V1}/materials/updates/${materialUpdateRequestId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      return response.parsedBody ?? new FMSError("Something went wrong");
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Material update status could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
}

import { post, BACKEND_URL_V1 } from "lib/Api";
import { Auth } from "lib/Auth";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { FMSError } from "types/Error";

export const validatePhone = async (
  phoneNumber: string
): Promise<FMSError | undefined> => {
  if (!isPossiblePhoneNumber(phoneNumber)) {
    return new FMSError("Invalid phone number");
  }

  try {
    const response = await post<{ phoneNumber: string }>(
      `${BACKEND_URL_V1}/validate/login/${phoneNumber}`
    );

    if (response.ok) {
      return Auth.getInstance().signInWithPhoneNumber(phoneNumber);
    }

    if (response.status === 400) {
      return new FMSError(
        "Phone number is not onboarded, please contact your administrator"
      );
    }
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }

  return new FMSError("Something went wrong");
};

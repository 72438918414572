import React, { useContext } from "react";
import styles from "./SideBarNav.module.css";
import { List, ListItem, Button, ButtonProps, Avatar } from "@material-ui/core";
import classNames from "classnames";
import { LinkProps, Link, useLocation } from "react-router-dom";
import { IPage } from "types/Page";
import queryString from "query-string";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IProps {
  pages: IPage[];
}

interface IButtonProps {
  buttonPath: string;
  backgroundColor: string;
}

const LinkButton: React.FC<ButtonProps & LinkProps & IButtonProps> = (
  props
) => {
  const { buttonPath, backgroundColor, ...rest } = props;
  return (
    <Button
      className={styles.button}
      style={{ backgroundColor: backgroundColor }}
      component={Link}
      {...(rest as any)}
    />
  );
};

const SideBarNav: React.FC<IProps> = (props) => {
  const queryStr = queryString.parse(useLocation().search);
  const { primaryTextColor, selectedColor, primaryColor } = useContext(
    FMSThemeContext
  );
  const currentPath = useLocation().pathname;
  return (
    <div className={classNames(styles.root, styles.nav)}>
      <List classes={{ root: styles.list }}>
        {props.pages
          .filter((page) => !page.hidden)
          .map((page) => {
            const queryPath = queryString.stringifyUrl({
              url: page.href,
              query: queryStr,
            });
            let selectedButtonStyle = false;
            const buttonPath = page.href;
            if (currentPath === "/" || buttonPath === "/") {
              // NOTE: Might need to have some special handling for dashboard stats when available
              selectedButtonStyle = currentPath === buttonPath;
            } else if (currentPath.includes(buttonPath)) {
              selectedButtonStyle = true;
            }
            return (
              <ListItem disableGutters key={page.title} className={styles.item}>
                <LinkButton
                  buttonPath={buttonPath}
                  backgroundColor={selectedButtonStyle ? selectedColor : ""}
                  to={queryPath}
                >
                  <Avatar
                    className={styles.icon}
                    style={{
                      backgroundColor: selectedButtonStyle
                        ? selectedColor
                        : primaryColor,
                    }}
                  >
                    {page.icon}
                  </Avatar>
                  <div
                    className={styles.title}
                    style={{ color: primaryTextColor }}
                  >
                    {page.title}
                  </div>
                </LinkButton>
              </ListItem>
            );
          })}
      </List>
    </div>
  );
};

export default SideBarNav;

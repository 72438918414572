import React, { useContext, useState } from "react";
import { IBranch } from "types/Branch";
import styles from "./AddSalesModal.module.css";
import { uploadSales, addSales } from "api/PostSales";
import { ModalContext } from "contexts/ModalContext";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ISalePost } from "types/Sales";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";
import { invalidateSalesCache } from "hooks/Sales";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { createArrayWithNumbers } from "lib/Arrays";
import { IMaterial } from "types/Material";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { getSalesUploadTemplate } from "api/GetSales";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

const extractSalesForPost = (sales: any) => {
  const postSalesArr = new Array<ISalePost>();
  if (!sales) {
    return postSalesArr;
  }

  sales.forEach((sale: any) => {
    const materialId = sale.materialId as string | undefined;
    const quantity = sale.quantity as number | undefined;

    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (!quantity || quantity === 0 || isNaN(quantity)) {
      return;
    }

    const postMaterial: ISalePost = {
      materialId: materialId,
      quantity: quantity,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };

    postSalesArr.push(postMaterial);
  });

  return postSalesArr;
};

interface IRowProps {
  index: number;
  materialsArr: IMaterial[];
  control: any;
}

const RowComponent: React.FC<IRowProps> = (props) => {
  const { index, materialsArr, control } = props;

  const fieldName = `materials[${index}]`;
  const [material, setMaterial] = useState<IMaterial | null>(null);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        control={control}
        name={`${fieldName}.materialId`}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${fieldName}.quantity`}
        style={{ marginLeft: "10px", width: "450px" }}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"Quantity" + fieldName}>Quantity</InputLabel>
            <OutlinedInput
              id={"Quantity" + fieldName}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              disabled={material ? false : true}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                onChange(value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material?.displayUnitOfMeasure ?? ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export const AddSalesModal: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => {
  const { handleSubmit, control } = useForm<ISalePost[]>();
  const { selectedBranch } = props;
  const modalProps = useContext(ModalContext);
  const [size, setSize] = useState(5);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  const [isDownloading, setDownloading] = useState(false);
  const [sales, setSales] = useState<ISalePost[]>([]);

  const [mutate] = useMutation(
    (sales: ISalePost[]) => {
      return addSales(sales, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateSalesCache();
          modalProps.hideModal(true, "Sales added successfully!");
          return;
        }

        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const onSubmit = async (data: any) => {
    const sales = extractSalesForPost(data.materials);
    if (sales.length === 0) {
      setShowToast({
        open: true,
        message: "Atleast one sale item is required!",
        type: "error",
      });
      return;
    }

    setSales(sales);
  };

  const [mutateForFile] = useMutation(
    (files: File[]) => {
      return uploadSales(selectedBranch, files, selectedDate!);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateSalesCache();
          modalProps.hideModal(true, "Sales added successfully!");
          return;
        }

        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const handleUploadFile = async (files: File[]) => {
    setSubmitting(true);
    mutateForFile(files);
  };

  const {
    allMaterialsArrForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
    sellableMaterialsMapForFoodAnalysis,
  } = props.materialContextValues;

  if (allMaterialsArrForFoodAnalysis.length === 0) {
    return <></>;
  }

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    const error = await getSalesUploadTemplate(
      selectedBranch.id,
      selectedDate!
    );
    setDownloading(false);
    if (error) {
      setShowToast({
        message: error.message ?? "Something went wrong!",
        open: true,
        type: "error",
      });
    }
  };

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Sales</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
      isDownloading={isDownloading}
      isUploadDownloadApplicable={true}
      handleDownloadTemplate={handleDownloadTemplate}
      handleUploadFile={handleUploadFile}
    >
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />

      {sales.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={sellableMaterialsMapForFoodAnalysis}
          entries={sales}
          confirmationType={"Sales"}
          onClose={() => {
            setSales([]);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(sales);
            setSales([]);
          }}
        />
      )}

      <div className={styles.modalBody}>
        <div>
          {createArrayWithNumbers(size).map((index: number) => {
            return (
              <RowComponent
                key={index}
                index={index}
                control={control}
                materialsArr={sellableMaterialsArrForFoodAnalysis}
              />
            );
          })}
          <div />
          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            {" "}
            Add More{" "}
          </button>
        </div>
      </div>
    </ModalPopup>
  );
};

import React, { useContext } from "react";
import styles from "./SideBar.module.css";
import classNames from "classnames";
import SideBarNav from "components/sideBarNav/SideBarNav";
import { IPage } from "types/Page";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IProps {
  pages: IPage[];
}

const SideBar: React.FC<IProps> = (props) => {
  const { primaryColor, logoLight } = useContext(FMSThemeContext);
  return (
    <div
      className={classNames(styles.root)}
      style={{ backgroundColor: primaryColor }}
    >
      <div className={styles.header}>
        <Button component={Link} to={"/"}>
          <img src={logoLight} alt={"logo"} className={styles.img} />
        </Button>
      </div>
      <SideBarNav pages={props.pages} />
    </div>
  );
};

export default SideBar;

import { InputBase, withStyles } from "@material-ui/core";

// TODO: Figure out how to apply custom styles

export const CustomSelectInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 612,
    color: "#4d4d4d",
    padding: "18.5px 14px 18.5px 12px",
    margin: "0 auto",
    marginTop: 12,
    marginBottom: 12,
  },
}))(InputBase);

export const FavoritesSelectInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    border: "1px solid #ced4da",
    fontSize: 16,
    width: 209,
    color: "#4d4d4d",
    padding: "18.5px 14px 18.5px 12px",
    margin: "0 auto",
    marginTop: 12,
    marginBottom: 12,
    marginRight: 25,
  },
}))(InputBase);

export const BorderLessSelectInput = withStyles((theme) => ({
  input: {
    fontSize: 16,
    textAlign: "right",
    width: 209,
    color: "#4d4d4d",
    padding: "18.5px 14px 18.5px 12px",
    margin: "0 auto",
    marginTop: 12,
    marginBottom: 12,
    marginRight: 25,
  },
}))(InputBase);

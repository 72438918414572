export const removeUnderscore = (str: string) => str.replace(/_/g, " ");

export const capitalizeFirstLetter = (str: string) =>
  str
    .toLowerCase()
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");

export const sortStrings = (data: string[]): string[] => {
  data.sort((a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }

    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }

    return 0;
  });

  return data;
};

export const equalsIgnoreCase = (str1: string, str2: string) =>
  str1.toLowerCase() === str2.toLowerCase();

import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./StaffFoodTable.module.css";
import { invalidateStaffFoodCache, useQueryStaffFoods } from "hooks/StaffFoods";
import { IStaffFoodForAllBranches, IStaffFood } from "types/StaffFood";
import { IBranch } from "types/Branch";
import { DataTableWithDates } from "components/dataTableWithDates/DataTableWithDates";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  displayNameForUnitOfMeasure,
  displayQuantity,
  MeasurementUnit,
} from "types/MeasurementUnit";
import { useHistory, useLocation } from "react-router-dom";
import CSVDownload from "components/utils/CSVDownload";
import moment from "moment";
import { getStaffFoodDataForExport } from "lib/CSVExportUtils";
import DeleteIcon from "assets/DeleteIcon";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { deleteStaffFood } from "api/DeleteStaffFood";
import CreateIcon from "@material-ui/icons/Create";
import { IModifyStaffFoodProps, updateStaffFoodEntry } from "api/PostStaffForm";
import { useMutation } from "react-query";
import { MealTypeList } from "types/MealType";
import { Auth } from "lib/Auth";

export const staffFoodForBranch = (
  staffFoodWithBranches: IStaffFoodForAllBranches[],
  selectedBranch: IBranch
): IStaffFood[] => {
  for (let i = 0; i < staffFoodWithBranches.length; i++) {
    if (staffFoodWithBranches[i].branchId === selectedBranch.id) {
      return staffFoodWithBranches[i].staffFoodList;
    }
  }
  return [];
};

export const StaffFoodTable: React.FC<{ selectedBranch: IBranch }> = (
  props
) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();

  useEffect(() => {
    resetState();
  }, [props.selectedBranch]);

  const resetState = () => {
    setPostObject({
      servings: 0,
      quantity: 0,
      mealType: "",
    });
    setSelectedStaffFoodEntry(undefined);
    setSelectedRowId("");
  };

  const { status, staffFoods, isFetching } = useQueryStaffFoods(start, end);
  const [staffFood, setStaffFood] = useState<IStaffFood[]>([]);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [selectedStaffFoodEntry, setSelectedStaffFoodEntry] =
    useState<IStaffFood>();
  const [selectedRowId, setSelectedRowId] = useState("");
  const [selectedStaffFoodEntryToDelete, setSelectedStaffFoodEntryToDelete] =
    useState<IStaffFood>();
  const [isEditing, setIsEditing] = useState(false);

  const [postObject, setPostObject] = useState<IModifyStaffFoodProps>({
    servings: 0,
    quantity: 0,
    mealType: "",
  });

  const servingsInputRef = useRef<HTMLLinkElement>(null);
  const quantityInputRef = useRef<HTMLLinkElement>(null);

  useEffect(() => {
    if (status === "success") {
      if (!staffFoods || staffFoods instanceof FMSError) {
        const message = staffFoods?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setStaffFood(staffFoodForBranch(staffFoods, props.selectedBranch));
      }
    }
  }, [status, staffFoods, props.selectedBranch]);

  const [mutateForStaffFoodEntry] = useMutation(
    (post: IModifyStaffFoodProps) => {
      const value: number = post.quantity;
      const unit = selectedStaffFoodEntry?.displayQuantity.unit;
      const staffFoodId = selectedStaffFoodEntry?.id ?? "";
      let shouldConvertToGmsOrMl =
        unit === MeasurementUnit.KILOGRAMS || unit === MeasurementUnit.LITRE;

      const convertedQuantity = shouldConvertToGmsOrMl ? value * 1000 : value;
      setIsEditing(true);

      return updateStaffFoodEntry(staffFoodId, {
        ...postObject,
        quantity: convertedQuantity,
      });
    },
    {
      onSuccess: (error) => {
        setIsEditing(false);
        if (!error) {
          setShowToast({
            open: true,
            message: "Wastage quantity updated successfully",
            type: "success",
          });
          invalidateStaffFoodCache();
        } else {
          const message = error.message ?? "Something went wrong!";
          setShowToast({
            open: true,
            message: message,
            type: "error",
          });
        }
      },
    }
  );

  const onDeleteStaffFood = async (staffFoodId: string) => {
    setIsDeleting(true);
    const error = await deleteStaffFood(staffFoodId);
    setIsDeleting(false);
    setShowDeleteConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Deleted successfully",
        type: "success",
      });
      invalidateStaffFoodCache();
      setSelectedStaffFoodEntryToDelete(undefined);
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };
  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const { primaryColor } = useContext(FMSThemeContext);
  const columns = React.useMemo(() => {
    if (!Auth.getInstance().isAdminOrSupervisor()) {
      return [
        {
          Header: "Branch Table",
          columns: [
            {
              Header: "Item",
              accessor: "materialName",
              width: 343,
              disableFilters: true,
              sortType: (rowA: any, rowB: any) => {
                return rowA.original.materialName.toUpperCase() >
                  rowB.original.materialName.toUpperCase()
                  ? 1
                  : -1;
              },
              Cell: (props: any) => {
                const staffFood = props.row.original as IStaffFood;
                const history = useHistory();
                const queryStr = useLocation().search;
                return (
                  <Button
                    style={{
                      display: "block",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/staff-food-stats/${staffFood.materialId}`,
                        search: queryStr,
                      })
                    }
                  >
                    {staffFood.materialName}
                  </Button>
                );
              },
            },
            {
              Header: "Servings",
              accessor: "servings",
              width: 180,
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: "Total",
              sortType: (rowA: any, rowB: any) => {
                return rowA.original.total > rowB.original.total ? 1 : -1;
              },
              accessor: (row: IStaffFood) => {
                const unit = displayNameForUnitOfMeasure(
                  row.displayQuantity.unit
                );
                return `${row.displayQuantity.quantity} ${unit}`;
              },
              width: 180,
              disableGlobalFilter: true,
              disableFilters: true,
            },
            {
              Header: "Meal",
              accessor: "mealType",
              width: 180,
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
          ],
        },
      ];
    } else {
      return [
        {
          Header: "Branch Table",
          columns: [
            {
              Header: "Item",
              accessor: "materialName",
              width: 180,
              disableFilters: true,
              sortType: (rowA: any, rowB: any) => {
                const staffFoodEntryA = rowA.original as IStaffFood;
                const staffFoodEntryB = rowB.original as IStaffFood;
                if (
                  staffFoodEntryA.materialName.toUpperCase() ===
                  staffFoodEntryB.materialName.toUpperCase()
                ) {
                  return staffFoodEntryA.date > staffFoodEntryB.date ? 1 : -1;
                } else {
                  return staffFoodEntryA.materialName.toUpperCase() >
                    staffFoodEntryB.materialName.toUpperCase()
                    ? 1
                    : -1;
                }
              },
              Cell: (props: any) => {
                const staffFood = props.row.original as IStaffFood;
                const history = useHistory();
                const queryStr = useLocation().search;
                return (
                  <Button
                    style={{
                      display: "block",
                      textAlign: "left",
                      textTransform: "capitalize",
                    }}
                    onClick={() =>
                      history.push({
                        pathname: `/staff-food-stats/${staffFood.materialId}`,
                        search: queryStr,
                      })
                    }
                  >
                    {staffFood.materialName}
                  </Button>
                );
              },
            },
            {
              Header: "Servings",
              accessor: "servings",
              width: 100,
              Cell: (props) => {
                const row = props.row.original as IStaffFood;

                return (
                  <>
                    {selectedRowId === row.id ? (
                      <Input
                        id="servings"
                        type="number"
                        className={styles.quantityText}
                        value={postObject.servings}
                        ref={servingsInputRef}
                        autoFocus={
                          servingsInputRef.current?.innerHTML ===
                          document.activeElement?.outerHTML
                        }
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          const quantity = value;
                          setPostObject({
                            ...postObject,
                            servings: quantity,
                          });
                        }}
                      />
                    ) : (
                      `${row.servings}`
                    )}
                  </>
                );
              },
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: "Quantity",
              Cell: (props) => {
                const row = props.row.original as IStaffFood;
                const unit = displayNameForUnitOfMeasure(
                  row.displayQuantity.unit
                );
                const quantity = row.displayQuantity.quantity / row.servings;

                return (
                  <>
                    {selectedRowId === row.id ? (
                      <>
                        <Input
                          id="quantity"
                          type="number"
                          className={styles.quantityText}
                          value={postObject.quantity}
                          ref={quantityInputRef}
                          autoFocus={
                            quantityInputRef.current?.innerHTML ===
                            document.activeElement?.outerHTML
                          }
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            setPostObject({
                              ...postObject,
                              quantity: value,
                            });
                          }}
                        />{" "}
                        {unit}
                      </>
                    ) : (
                      `${quantity} ${unit}`
                    )}
                  </>
                );
              },
              width: 150,
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: "Total",
              sortType: (rowA: any, rowB: any) => {
                return rowA.original.total > rowB.original.total ? 1 : -1;
              },
              Cell: (props) => {
                const row = props.row.original as IStaffFood;
                const unit = displayNameForUnitOfMeasure(
                  row.displayQuantity.unit
                );
                return (
                  <>
                    {row.displayQuantity.quantity} {unit}
                  </>
                );
              },
              width: 150,
              disableGlobalFilter: true,
              disableFilters: true,
              disableSortBy: true,
            },
            {
              Header: "Meal",
              accessor: "mealType",
              width: 180,
              Cell: (props) => {
                const row = props.row.original as IStaffFood;
                return (
                  <>
                    {selectedRowId === row.id ? (
                      <>
                        <FormControl fullWidth>
                          <Select
                            value={row.mealType}
                            onChange={(e) => {
                              setPostObject({
                                ...postObject,
                                mealType: e.target.value as String,
                              });
                            }}
                          >
                            {MealTypeList.map((val, ind) => {
                              return (
                                <MenuItem key={ind} value={val}>
                                  {val}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    ) : (
                      `${row.mealType}`
                    )}
                  </>
                );
              },
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: " ",
              width: 70,
              Cell: (props: any) => {
                const staffFoodEntry = props.row.original as IStaffFood;
                const row = props.row.original as IStaffFood;
                return (
                  <>
                    {selectedRowId === row.id ? (
                      <>
                        {isEditing && selectedRowId === row.id && (
                          <CircularProgress
                            style={{
                              marginLeft: 5,
                            }}
                            size={15}
                            color={"inherit"}
                            className={styles.loading}
                          />
                        )}
                        <button
                          onClick={async () => {
                            setSelectedRowId(row.id);
                            mutateForStaffFoodEntry(postObject);
                            setSelectedRowId("");
                          }}
                        >
                          ✓
                        </button>
                        <button
                          onClick={() => {
                            setSelectedRowId("");
                          }}
                        >
                          X
                        </button>
                      </>
                    ) : (
                      <>
                        <Avatar
                          className={styles.icon}
                          style={{ backgroundColor: primaryColor }}
                          onClick={() => {
                            setSelectedRowId(row.id);
                            setSelectedStaffFoodEntry(staffFoodEntry);
                            setPostObject({
                              servings: staffFoodEntry.servings,
                              quantity:
                                row.displayQuantity.quantity / row.servings,
                              mealType: staffFoodEntry.mealType,
                            });
                          }}
                        >
                          <CreateIcon style={{ fontSize: 15 }} />
                        </Avatar>
                      </>
                    )}
                  </>
                );
              },
              disableGlobalFilter: true,
              disableSortBy: true,
              disableFilters: true,
            },
            {
              Header: "  ",
              width: 50,
              Cell: (props: any) => {
                const row = props.row.original as IStaffFood;
                return (
                  <Avatar
                    className={styles.icon}
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => {
                      setSelectedStaffFoodEntryToDelete(row);
                      setShowDeleteConfirmation(true);
                    }}
                  >
                    <DeleteIcon />
                  </Avatar>
                );
              },
              disableFilters: true,
            },
          ],
        },
      ];
    }
  }, [
    primaryColor,
    isEditing,
    mutateForStaffFoodEntry,
    selectedRowId,
    postObject,
  ]);

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        buttonText={"Delete"}
        bodyText={`Do you want to delete ${selectedStaffFoodEntryToDelete?.materialName
          } of quantity ${displayQuantity(
            selectedStaffFoodEntryToDelete?.displayQuantity
          )}?`}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onApprove={() => {
          if (selectedStaffFoodEntryToDelete) {
            onDeleteStaffFood(selectedStaffFoodEntryToDelete.id);
          }
        }}
        isApproving={isDeleting}
      />
      <div
        style={{
          textAlign: "end",
        }}
      >
        <CSVDownload
          filename={`Staff-Food-${props.selectedBranch.name}-${moment(
            start
          ).format("DD MMM")}-to-${moment(end).format("DD MMM")}`}
          data={getStaffFoodDataForExport(staffFood)}
        />
      </div>
      <DataTableWithDates
        isLoading={isFetching}
        columns={columns}
        data={staffFood}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        initialSortBy={"materialName"}
      />
    </>
  );
};

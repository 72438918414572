import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function SalesIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={12}
      viewBox="0 0 12 12"
    >
      <path
        d="M9.75 12a.709.709 0 01-.75-.75V4.5a.709.709 0 01.751-.75h1.5A.709.709 0 0112 4.5v6.75a.709.709 0 01-.75.75zm-4.5 0a.708.708 0 01-.75-.75V.75A.709.709 0 015.25 0h1.5a.709.709 0 01.75.75v10.5a.708.708 0 01-.75.75zm-4.5 0a.709.709 0 01-.75-.75v-3a.709.709 0 01.75-.75h1.5a.709.709 0 01.75.75v3a.709.709 0 01-.75.75z"
        fill={primaryTextColor}
      />
    </svg>
  );
}
const SalesIcon = React.memo(SalesIconComponent);
export default SalesIcon;

import styles from "./AddRawOrClosingMaterialModal.module.css";
import React, { useContext, useState } from "react";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { useForm } from "react-hook-form";
import { Tabs, Tab } from "@material-ui/core";
import {
  MaterialClassificationListForFoodAnalysis,
  MaterialClassification,
} from "types/MaterialClassification";
import { RawMaterialInputTab } from "./RawOrClosingMaterialInputTab";
import { IBranch } from "types/Branch";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { IMaterial } from "types/Material";
import { useMutation } from "react-query";
import { IStockPost } from "types/Stock";
import { addStock, uploadStock } from "api/PostStock";
import { StockType } from "types/StockType";
import { ModalContext } from "contexts/ModalContext";
import { invalidateStockCache } from "hooks/Stock";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Toast from "components/Toast/Toast";
import { getStockUploadTemplate } from "api/GetStock";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

interface IProps {
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
  type: StockType;
}

const extractMaterialsForPost = (materials: any, type: StockType) => {
  const postMaterialsArr = new Array<IStockPost>();
  if (!materials) {
    return postMaterialsArr;
  }

  materials.forEach((material: any) => {
    const materialId = material.materialId as string | undefined;
    const quantity = material.measurementQuantity as number | undefined;
    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (quantity === undefined || isNaN(quantity)) {
      return;
    }

    if (type === StockType.ADDITION && quantity === 0) {
      return;
    }

    const postMaterial: IStockPost = {
      materialId: materialId,
      measurementQuantity: quantity,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };
    postMaterialsArr.push(postMaterial);
  });

  return postMaterialsArr;
};

export const AddRawOrClosingMaterialModal: React.FC<IProps> = (props) => {
  const { selectedBranch, type } = props;
  const { handleSubmit, getValues, control } = useForm();
  const [tabValue, setTabValue] = React.useState(0);
  const { rawMaterialsArrForFoodAnalysis, rawMaterialsMapForFoodAnalysis } =
    props.materialContextValues;

  const modalProps = useContext(ModalContext);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [stockRecords, setStockRecords] = useState<IStockPost[]>([]);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [isDownloading, setDownloading] = useState(false);

  const [mutate] = useMutation(
    (stockRecords: IStockPost[]) => {
      return addStock(stockRecords, selectedDate!, type, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStockCache();
          const stockType = type === StockType.ADDITION ? "Raw" : "Closing";
          modalProps.hideModal(true, stockType + " stock added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const onSubmit = async (data: any) => {
    const vegetables: IStockPost[] = extractMaterialsForPost(
      data.vegetables.materials,
      type
    );
    const grocery: IStockPost[] = extractMaterialsForPost(
      data.grocery.materials,
      type
    );
    const milkAndMilkProducts: IStockPost[] = extractMaterialsForPost(
      data.milkAndMilkProducts.materials,
      type
    );
    const breadAndChat: IStockPost[] = extractMaterialsForPost(
      data.breadAndChat.materials,
      type
    );

    const packaging: IStockPost[] = extractMaterialsForPost(
      data.packaging.materials,
      type
    );

    const others: IStockPost[] = extractMaterialsForPost(
      data.others?.materials,
      type
    );
    const stockRecords = vegetables
      .concat(grocery)
      .concat(milkAndMilkProducts)
      .concat(breadAndChat)
      .concat(packaging)
      .concat(others);

    if (stockRecords.length === 0) {
      setToastMessage("Atleast one stock item is required!");
      setShowToast(true);
      return;
    }

    setStockRecords(stockRecords);
  };

  const [mutateForFile] = useMutation(
    (files: File[]) => {
      return uploadStock(files, selectedDate!, type, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStockCache();
          const stockType = type === StockType.ADDITION ? "Raw" : "Closing";
          modalProps.hideModal(true, stockType + " stock added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const handleUploadFile = async (files: File[]) => {
    setSubmitting(true);
    mutateForFile(files);
  };

  const handleChange = (_: any, newTabValue: number) => {
    setTabValue(newTabValue);
  };

  const vegetablesArr: IMaterial[] = [];
  const vegetablesMap = new Map<string, IMaterial>();
  const breadAndChatArr: IMaterial[] = [];
  const breadAndChatMap = new Map<string, IMaterial>();
  const milkProductsArr: IMaterial[] = [];
  const milkProductsMap = new Map<string, IMaterial>();
  const groceryArr: IMaterial[] = [];
  const groceryMap = new Map<string, IMaterial>();
  const packagingArr: IMaterial[] = [];
  const packagingMap = new Map<string, IMaterial>();
  const othersArr: IMaterial[] = [];
  const othersMap = new Map<string, IMaterial>();

  rawMaterialsArrForFoodAnalysis.forEach((material) => {
    if (
      type === StockType.CLOSING ||
      (material.eligibleForManualStockEntry && type === StockType.ADDITION)
    ) {
      switch (material.classification) {
        case MaterialClassification.VEGETABLES:
          vegetablesArr.push(material);
          vegetablesMap.set(material.id, material);
          break;
        case MaterialClassification.BREAD_AND_CHAAT:
          breadAndChatArr.push(material);
          breadAndChatMap.set(material.id, material);
          break;
        case MaterialClassification.MILK_PRODUCTS:
          milkProductsArr.push(material);
          milkProductsMap.set(material.id, material);
          break;
        case MaterialClassification.GROCERY:
          groceryArr.push(material);
          groceryMap.set(material.id, material);
          break;
        case MaterialClassification.PACKAGING:
          packagingArr.push(material);
          packagingMap.set(material.id, material);
          break;
        default:
          othersArr.push(material);
          othersMap.set(material.id, material);
          break;
      }
    }
  });

  const favouriteVegetables: IMaterial[] = [];
  const favouriteBreadAndChat: IMaterial[] = [];
  const favouriteMilkProducts: IMaterial[] = [];
  const favouriteGrocery: IMaterial[] = [];
  const favouritePackaging: IMaterial[] = [];
  const favouriteOthers: IMaterial[] = [];
  if (
    selectedBranch.favourites &&
    selectedBranch.favourites.rawStock?.length > 0
  ) {
    selectedBranch.favourites.rawStock.forEach((favouriteMaterial) => {
      if (
        type === StockType.CLOSING ||
        (favouriteMaterial.eligibleForManualStockEntry &&
          type === StockType.ADDITION)
      ) {
        const material = rawMaterialsMapForFoodAnalysis.get(
          favouriteMaterial.id
        );
        if (!material) {
          return;
        }

        switch (material.classification) {
          case MaterialClassification.VEGETABLES:
            favouriteVegetables.push(material);
            break;
          case MaterialClassification.BREAD_AND_CHAAT:
            favouriteBreadAndChat.push(material);
            break;
          case MaterialClassification.MILK_PRODUCTS:
            favouriteMilkProducts.push(material);
            break;
          case MaterialClassification.GROCERY:
            favouriteGrocery.push(material);
            break;
          case MaterialClassification.PACKAGING:
            favouritePackaging.push(material);
            break;
          default:
            favouriteOthers.push(material);
            break;
        }
      }
    });
  }

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    const error = await getStockUploadTemplate(
      selectedBranch.id,
      selectedDate!,
      type
    );
    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setToastMessage(message);
      setShowToast(true);
    }
  };

  return (
    <ModalPopup
      className={styles.modal}
      header={
        <>
          {type === StockType.ADDITION && <>Add Raw Material</>}
          {type === StockType.CLOSING && <>Add Closing Material</>}
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
      isUploadDownloadApplicable={true}
      isDownloading={isDownloading}
      handleDownloadTemplate={handleDownloadTemplate}
      handleUploadFile={handleUploadFile}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      {stockRecords.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={rawMaterialsMapForFoodAnalysis}
          entries={stockRecords}
          confirmationType={`${type === StockType.ADDITION ? "Raw" : "Closing"
            } Material`}
          onClose={() => {
            setStockRecords([]);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(stockRecords);
            setStockRecords([]);
          }}
        />
      )}

      <div className={styles.modalBody}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
        >
          {MaterialClassificationListForFoodAnalysis.map((val, index) => {
            return <Tab
              label={val}
              key={index}
              style={{
                minWidth: 100
              }}
            />;
          })}

          {packagingArr.length > 0 && (
            <Tab
              label={"Packaging"}
              style={{
                minWidth: 100
              }}
            />
          )}

          {othersArr.length > 0 && (
            <Tab
              label={"Other"}
              style={{
                minWidth: 100
              }}
            />
          )}
        </Tabs>

        <RawMaterialInputTab
          tabValue={tabValue}
          index={0}
          name="vegetables"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={vegetablesArr}
          materialsMap={vegetablesMap}
          favourites={favouriteVegetables}
        />

        <RawMaterialInputTab
          tabValue={tabValue}
          index={1}
          name="grocery"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={groceryArr}
          materialsMap={groceryMap}
          favourites={favouriteGrocery}
        />

        <RawMaterialInputTab
          tabValue={tabValue}
          index={2}
          name="milkAndMilkProducts"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={milkProductsArr}
          materialsMap={milkProductsMap}
          favourites={favouriteMilkProducts}
        />

        <RawMaterialInputTab
          tabValue={tabValue}
          index={3}
          name="breadAndChat"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={breadAndChatArr}
          materialsMap={breadAndChatMap}
          favourites={favouriteBreadAndChat}
        />

        <RawMaterialInputTab
          tabValue={tabValue}
          index={4}
          name="packaging"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={packagingArr}
          materialsMap={packagingMap}
          favourites={favouritePackaging}
        />

        <RawMaterialInputTab
          tabValue={tabValue}
          index={5}
          name="others"
          getValues={getValues}
          control={control}
          type={type}
          materialsArr={othersArr}
          materialsMap={othersMap}
          favourites={favouriteOthers}
        />
      </div>
    </ModalPopup>
  );
};

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IComplimentaryFoodForAllBranches } from "types/ComplimentaryFood";
import { Status } from "types/Status";

interface IGetComplimentaryFood {
  complimentaryFoodList: IComplimentaryFoodForAllBranches[];
}

export const getComplimentaryFood = async (
  start: string,
  end: string
): Promise<IComplimentaryFoodForAllBranches[] | FMSError> => {
  try {
    const response = await get<IGetComplimentaryFood>(
      `${BACKEND_URL_V1}/complimentaryFood?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.complimentaryFoodList) {
        return response.parsedBody.complimentaryFoodList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Complimentary food could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getPendingComplimentaryFood = async (): Promise<
  IComplimentaryFoodForAllBranches[] | FMSError
> => {
  try {
    const response = await get<IGetComplimentaryFood>(
      `${BACKEND_URL_V1}/complimentaryFood?status=${Status.PENDING}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.complimentaryFoodList) {
        return response.parsedBody.complimentaryFoodList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Complimentary food could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import React, { useState, useEffect, useCallback, useContext } from "react";
import styles from "./MaterialConsumptionStatsModal.module.css";
import { Dialog, IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { FMSError } from "types/Error";
import { getMaterialConsumptionStats } from "api/GetMaterials";
import { IBranch } from "types/Branch";
import { Loading } from "components/loading/Loading";
import {
  displayNameForUnitOfMeasure,
  IDisplayQuantity,
} from "types/MeasurementUnit";
import {
  IMaterialAssociatedEntitiesCompositionView,
  IMaterialEntityCompositionView,
} from "types/Material";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import moment from "moment";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { DataTable } from "components/dataTable/DataTable";

interface IProps {
  materialId: string;
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
  start: Date;
  end: Date;
  expectedConsumptionDisplayQuantity?: IDisplayQuantity;
  actualConsumptionDisplayQuantity?: IDisplayQuantity;
  onClose: () => void;
}

const CompositionCellComponent: React.FC<{
  composition: IMaterialEntityCompositionView;
}> = (props) => {
  if (!props.composition) {
    return <></>;
  }

  const ingredientQuantity = `${
    props.composition.entityRawMaterialContributionQuantity.quantity
  } ${displayNameForUnitOfMeasure(
    props.composition.entityRawMaterialContributionQuantity.unit
  )}`;

  const materialQuantity = `${
    props.composition.entityQuantity.quantity
  } ${displayNameForUnitOfMeasure(props.composition.entityQuantity.unit)}`;

  return (
    <>
      <div>{ingredientQuantity}</div>
      <div style={{ color: "#4d4d4d85" }}>{materialQuantity}</div>
    </>
  );
};

export const MaterialConsumptionStatsModal: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(true);
  const {
    materialId,
    materialContextValues,
    onClose,
    selectedBranch,
    start,
    end,
    expectedConsumptionDisplayQuantity,
    actualConsumptionDisplayQuantity,
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState<
    Array<IMaterialAssociatedEntitiesCompositionView>
  >([]);

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const fetchMaterialConsumptionStats = useCallback(async () => {
    setIsLoading(true);
    const result = await getMaterialConsumptionStats(
      materialId,
      start,
      end,
      selectedBranch.id
    );
    setIsLoading(false);
    if (result instanceof FMSError) {
      const message = result.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }

    result.compositionViews.sort((a, b) => {
      return a.materialName.localeCompare(b.materialName);
    });

    setTableData(result.compositionViews);
  }, [materialId, start, end, selectedBranch]);

  useEffect(() => {
    fetchMaterialConsumptionStats();
  }, [fetchMaterialConsumptionStats]);

  const hideModal = () => {
    setShowModal(false);
    onClose();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Composition Table",
        columns: [
          {
            Header: "Item Name",
            accessor: "materialName",
            width: 250,
            disableFilters: true,
          },
          {
            Header: "Sale",
            accessor: "salesCompositionView",
            width: 140,
            Cell: (props: any) => {
              const sale = props.value as IMaterialEntityCompositionView;
              return <CompositionCellComponent composition={sale} />;
            },
            disableFilters: true,
          },
          {
            Header: "Wastage",
            accessor: "wastageCompositionView",
            width: 140,
            Cell: (props: any) => {
              const wastage = props.value as IMaterialEntityCompositionView;
              return <CompositionCellComponent composition={wastage} />;
            },
            disableFilters: true,
          },
          {
            Header: "Staff Food",
            accessor: "staffFoodCompositionView",
            width: 140,
            Cell: (props: any) => {
              const staffFood = props.value as IMaterialEntityCompositionView;
              return <CompositionCellComponent composition={staffFood} />;
            },
            disableFilters: true,
          },
          {
            Header: "Complimentary Food",
            accessor: "complimentaryFoodCompositionView",
            width: 140,
            Cell: (props: any) => {
              const complimentaryFood =
                props.value as IMaterialEntityCompositionView;
              return (
                <CompositionCellComponent composition={complimentaryFood} />
              );
            },
            disableFilters: true,
          },
          {
            Header: "In-flow / Out-flow",
            accessor: "outgoingTransfersCompositionView",
            width: 140,
            Cell: (props: any) => {
              const inOutFlow = props.value as IMaterialEntityCompositionView;
              return <CompositionCellComponent composition={inOutFlow} />;
            },
            disableFilters: true,
          },
          {
            Header: "Total",
            accessor: "totalCompositionView",
            width: 140,
            Cell: (props: any) => {
              const total = props.value as IMaterialEntityCompositionView;
              return <CompositionCellComponent composition={total} />;
            },
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const material =
    materialContextValues.allMaterialsMapForFoodAnalysis.get(materialId);
  if (!material) {
    return <></>;
  }

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <Dialog
        className={styles.modalRoot}
        open={showModal}
        maxWidth={"xl"}
        onClose={hideModal}
        scroll={"body"}
        disableBackdropClick
      >
        <>
          <div className={styles.modal}>
            {isLoading && (
              <div style={{ position: "relative", height: "662px" }}>
                <div style={{ position: "absolute", top: "45%", left: "45%" }}>
                  <Loading isLoading={true} />
                </div>
              </div>
            )}
            {!isLoading && (
              <>
                <div
                  className={styles.modalTitleBox}
                  style={{
                    backgroundColor: primaryColor,
                    color: primaryTextColor,
                  }}
                >
                  <div className={styles.date}>
                    <div style={{ height: "20px" }}>
                      From:&nbsp;{moment(start).format("MMMM Do YYYY")}
                    </div>
                    <div style={{ height: "20px" }}>
                      To:&nbsp;{moment(end).format("MMMM Do YYYY")}
                    </div>
                  </div>
                  <div className={styles.modalTitleText}>
                    <div className={styles.branch}>{selectedBranch.name}</div>
                    {material.name}
                    <div className={styles.quantity}>
                      Expected:&nbsp;
                      {`${
                        expectedConsumptionDisplayQuantity?.quantity
                      } ${displayNameForUnitOfMeasure(
                        expectedConsumptionDisplayQuantity?.unit
                      )}`}{" "}
                      | Actual:&nbsp;
                      {actualConsumptionDisplayQuantity?.quantity
                        ? `${
                            actualConsumptionDisplayQuantity.quantity
                          } ${displayNameForUnitOfMeasure(
                            actualConsumptionDisplayQuantity.unit
                          )}`
                        : "Unknown"}
                    </div>
                  </div>
                  <div className={styles.modalCloseIcon}>
                    <IconButton
                      name="close"
                      color="inherit"
                      onClick={hideModal}
                    >
                      <CloseRoundedIcon />
                    </IconButton>
                  </div>
                </div>
                <DataTable
                  columns={columns}
                  data={tableData}
                  isLoading={isLoading}
                  tableHeight={400}
                  itemHeight={70}
                />
              </>
            )}
          </div>
        </>
      </Dialog>
    </>
  );
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IMaterialPost, IMaterialUpdatePost, IUpdateMaterialResponse } from "types/Material";

export const addNewMaterial = async (
  materials: IMaterialPost[]
): Promise<FMSError | null> => {
  try {
    const response = await post<{ material: IMaterialPost[] }>(
      `${BACKEND_URL_V1}/materials/`,
      {
        materials,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Material could not be added";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

export const updateMaterial = async (
  materialId: string,
  material: IMaterialUpdatePost,
  makeBaseRecipe: boolean
): Promise<FMSError | IUpdateMaterialResponse> => {
  try {
    const response = await post<IUpdateMaterialResponse>(
      `${BACKEND_URL_V1}/materials/${materialId}`,
      {
        name: material.name,
        quantity: material.quantity,
        ingredients: material.ingredients,
        activationDateForNewRecipe: material.activationDateForNewRecipe,
        replaceOtherRecipeVersions: makeBaseRecipe,
        forceUpdate: material.forceUpdate
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ?? "Material could not be updated";

    if (response.status === 428) {
      return new FMSError(
        errorMessage,
        undefined,
        response.serverError?.error.details
      );
    }

    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

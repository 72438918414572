export enum MaterialType {
  RAW = "RAW",
  SEMI_PREPARED = "SEMI_PREPARED",
  SELLABLE = "SELLABLE",
}

export const displayNameForMaterialType = (materialType: MaterialType) => {
  if (!materialType) {
    return "";
  }

  switch (materialType) {
    case MaterialType.RAW:
      return "Raw";
    case MaterialType.SEMI_PREPARED:
      return "Semi Prepared";
    case MaterialType.SELLABLE:
      return "Prepared";
    default:
      throw new Error("Unimplemented case " + materialType);
  }
};

import { BACKEND_URL_V1, post } from "lib/Api";
import { downloadFile } from "lib/FileUtils";
import { IExternalBranch } from "types/Branch";
import { FMSError } from "types/Error";
import { IMaterial, IngredientToQuantityMap } from "types/Material";
import { IDisplayQuantity } from "types/MeasurementUnit";
import { IPostPurchaseOrder } from "types/PurchaseOrder";
import { ISupplier } from "types/Supplier";

export interface IPurchaseOrderPost {
  deliveryAddress: string;
  preferredDeliveryDate: string;
  materialIdToQuantityMap: IngredientToQuantityMap;
}

export interface IConsolidatePoForSupplierRequest {
  supplierId: string;
  purchaseOrderIds: string[];
}

interface ConsolidatedMaterial {
  materialView: IMaterial;
  branchWiseMaterialStatistics: [
    {
      branchView: IExternalBranch;
      displayQuantity: IDisplayQuantity;
    }
  ];
}

export type BranchIdToQuantityMap = {
  [branchId: string]: number;
};

export type BranchIdToDeliveryAddressMap = {
  [branchId: string]: string;
};

export interface ConsolidatePreviewResponse {
  data: {
    supplier: ISupplier;
    involvedBranches: IExternalBranch[];
    branchIdToMaterialCount: BranchIdToQuantityMap;
    branchIdToDeliveryAddressMap: BranchIdToDeliveryAddressMap;
    consolidatedMaterialData: ConsolidatedMaterial[];
  };
}

export const updatePurchaseOrder = async (
  postPurchaseOrderId: string,
  updatePurchaseOrderRequest: IPurchaseOrderPost
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/purchaseOrders/${postPurchaseOrderId}`,
      updatePurchaseOrderRequest
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order could not be updated";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const approvePurchaseOrder = async (
  postPurchaseOrderId: string
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/purchaseOrders/${postPurchaseOrderId}/approve`
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order could not be approved";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const postPurchaseOrder = async (
  branchId: string,
  postPurchaseOrderRequest: IPostPurchaseOrder
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/branches/${branchId}/purchaseOrders`,
      postPurchaseOrderRequest
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order could not be created";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const uploadPurchaseOrder = async (
  files: File[]
): Promise<FMSError | null> => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V1}/purchaseOrders/uploadTemplate`,
      undefined,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Purchase_Order_Errors.xlsx"
      );
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ??
        "Unable to upload purchase order";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const previewPurchaseOrdersForSupplier = async (
  consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest
): Promise<FMSError | ConsolidatePreviewResponse> => {
  try {
    const response = await post<ConsolidatePreviewResponse>(
      `${BACKEND_URL_V1}/purchaseOrders/consolidate/preview`,
      consolidatePoForSupplierRequest
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Purchase orders could not be consolidated";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const processPurchaseOrdersForSupplier = async (
  consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/purchaseOrders/consolidate/process`,
      consolidatePoForSupplierRequest
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Purchase orders could not be consolidated";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

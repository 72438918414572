import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
} from "@material-ui/core";
import classNames from "classnames";
import { capitalizeFirstLetter } from "lib/StringHelpers";
import React from "react";
import { Controller } from "react-hook-form";

interface IMultiInputDropdownProps {
  name: string;
  headerLabel: string;
  label: string;
  values: { key: string; value: string }[];
  value: string[];
  setValue: (value: string[]) => void;
  control: any;
  errors: any;
  maxSelectableItems?: number;
  rules?: { required?: boolean; validate?: (data: any) => boolean };
  class?: string;
  setDefaultValue?: boolean;
  disabled?: boolean;
}

export const MultiInputDropdown: React.FC<IMultiInputDropdownProps> = (
  props
) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.setDefaultValue === true ? props.value : null}
      render={({ onChange }) => (
        <>
          <FormControl
            variant="outlined"
            fullWidth
            classes={{
              root: classNames(props.class),
            }}
            style={{
              margin: 8,
            }}
          >
            <InputLabel
              id={`${props.name}-label`}
              style={{
                color: props.errors[`${props.name}`] ? "#f44336" : "",
              }}
            >
              {props.headerLabel}
            </InputLabel>
            <Select
              labelId={`${props.name}-label`}
              label={props.headerLabel}
              multiple
              disabled={props.disabled ?? false}
              error={props.errors[`${props.name}`] !== undefined}
              value={props.value}
              onChange={(event) => {
                const value = event.target.value as string[];
                const maxSelectableItems = props.maxSelectableItems;
                if (maxSelectableItems) {
                  if (value.length > maxSelectableItems) {
                    return;
                  }
                }
                props.setValue(value);
                onChange(value);
              }}
              renderValue={(selected) => {
                const selectedValues = selected as string[];
                return `${selectedValues.length} ${props.label} selected`;
              }}
              MenuProps={{
                getContentAnchorEl: null,
              }}
            >
              {props.values.map((value, index) => (
                <MenuItem value={value.key} key={index}>
                  <Checkbox
                    color="primary"
                    checked={props.value.indexOf(value.key) > -1}
                  />
                  <ListItemText primary={capitalizeFirstLetter(value.value)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    />
  );
};

import { Button, Checkbox, Chip } from "@material-ui/core";
import { Loading } from "components/loading/Loading";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import React, { useContext, useEffect, useMemo, useState } from "react";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import styles from "./ConsolidatePreview.module.css";
import { Link, useHistory } from "react-router-dom";
import {
  displayNameForPurchaseOrderStatus,
  IPurchaseOrder,
  PurchaseOrderStatus,
  sortPurchaseOrdersByDate,
} from "types/PurchaseOrder";
import moment from "moment";
import { DataTable } from "components/dataTable/DataTable";
import { useQueryPurchaseOrdersForSupplier } from "hooks/purchaseOrders";
import { FMSError } from "types/Error";
import { ISupplier } from "types/Supplier";
import { useQuerySupplier } from "hooks/Suppliers";
import {
  previewPurchaseOrdersForSupplier,
  ConsolidatePreviewResponse,
  IConsolidatePoForSupplierRequest,
} from "api/PostPurchaseOrder";
import { useMutation } from "react-query";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";

export const ConsolidatePreview: React.FC<{
  supplierId: string;
  onPreviewSubmit: (
    consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest,
    previewResponse: ConsolidatePreviewResponse
  ) => void;
}> = React.memo((props) => {
  const { supplierId, onPreviewSubmit } = props;
  const history = useHistory();
  const [supplier, setSupplier] = useState<ISupplier>();
  const [purchaseOrders, setPurchaseOrders] = useState<IPurchaseOrder[]>([]);

  const [checkedPurchaseOrders, setCheckedPurchaseOrders] = useState<string[]>(
    []
  );
  const [checkedRowIndexes, setCheckedRowIndexes] = useState<number[]>([]);

  const supplierQuery = useQuerySupplier(supplierId);

  const purchaseOrderStatusReq = [
    PurchaseOrderStatus.PENDING,
    PurchaseOrderStatus.APPROVED,
  ];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { status, purchaseOrdersResponse, isFetching } =
    useQueryPurchaseOrdersForSupplier(supplierId, purchaseOrderStatusReq);

  useEffect(() => {
    if (supplierQuery.status === "success") {
      if (
        !supplierQuery.supplierResponse ||
        supplierQuery.supplierResponse instanceof FMSError
      ) {
        const message =
          supplierQuery.supplierResponse?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSupplier(supplierQuery.supplierResponse);
      }
    }
  }, [supplierQuery]);

  useEffect(() => {
    if (status === "success") {
      if (
        !purchaseOrdersResponse ||
        purchaseOrdersResponse instanceof FMSError
      ) {
        const message =
          purchaseOrdersResponse?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        if (purchaseOrdersResponse.purchaseOrders.length === 0) {
          setShowConfirmationDialog(true);
        } else {
          setPurchaseOrders(
            sortPurchaseOrdersByDate(purchaseOrdersResponse.purchaseOrders)
          );
        }
      }
    }
  }, [status, purchaseOrdersResponse]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const { primaryTextColor } = useContext(FMSThemeContext);

  const handleChange = useMemo(() => {
    const handleChange = (
      isChecked: boolean,
      purchaseOrderId: string,
      index: number
    ) => {
      let newState = [...checkedPurchaseOrders];
      let newCheckedRowIndexes = [...checkedRowIndexes];
      if (isChecked) {
        newState.push(purchaseOrderId);
        newCheckedRowIndexes.push(index);
      } else {
        newState = newState.filter((x) => x !== purchaseOrderId);
        newCheckedRowIndexes = newCheckedRowIndexes.filter((x) => x !== index);
      }
      setCheckedPurchaseOrders([...newState]);
      setCheckedRowIndexes([...newCheckedRowIndexes]);
    };
    return handleChange;
  }, [checkedPurchaseOrders, checkedRowIndexes]);

  const [mutate] = useMutation(
    async (
      consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest
    ) => {
      setIsSubmitting(true);
      return await previewPurchaseOrdersForSupplier(
        consolidatePoForSupplierRequest
      );
    },
    {
      onSuccess: (response) => {
        setIsSubmitting(false);
        if (response instanceof FMSError) {
          const message = response.message ?? "Something went wrong!";
          setShowToast({ open: true, message: message, type: "error" });
        } else {
          const consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest =
            {
              purchaseOrderIds: checkedPurchaseOrders,
              supplierId: supplierId,
            };
          onPreviewSubmit(consolidatePoForSupplierRequest, response);
        }
      },
    }
  );

  const handleConsolidateSubmit = async () => {
    if (checkedPurchaseOrders.length > 0 && supplierId) {
      const consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest =
        {
          purchaseOrderIds: checkedPurchaseOrders,
          supplierId: supplierId,
        };

      mutate(consolidatePoForSupplierRequest);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "",
            width: 50,
            accessor: " ",
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              return (
                <>
                  {purchaseOrder.purchaseOrderStatus ===
                    PurchaseOrderStatus.APPROVED && (
                    <Checkbox
                      name={purchaseOrder.id}
                      checked={
                        checkedPurchaseOrders.indexOf(purchaseOrder.id) > -1
                      }
                      onChange={(e) => {
                        handleChange(
                          e.target.checked,
                          purchaseOrder.id,
                          props.row.index
                        );
                      }}
                    />
                  )}
                </>
              );
            },
            disableFilters: true,
          },
          {
            Header: "Date",
            width: 70,
            accessor: (row: IPurchaseOrder) =>
              moment(row.creationTime).format("DD-MM-yyyy"),
            disableFilters: true,
          },
          {
            Header: "Branch",
            width: 300,
            accessor: (row: IPurchaseOrder) => row.branch.name,
            disableFilters: true,
          },
          {
            Header: "P.O.",
            accessor: "purchaseOrderNumber",
            width: 50,
            disableFilters: true,
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              return (
                <Link to={`/purchase-orders/${purchaseOrder.id}`}>
                  {purchaseOrder.vanityNumber ?? "View PO"}
                </Link>
              );
            },
          },
          {
            Header: "Delivery",
            width: 400,
            accessor: "deliveryAddress",
            disableFilters: true,
          },
          {
            Header: "Status",
            accessor: "status",
            width: 150,
            Cell: (props: any) => {
              const purchaseOrder = props.row.original as IPurchaseOrder;
              if (
                purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.PENDING
              ) {
                return (
                  <Button
                    onClick={() => {
                      history.push(`/purchase-orders/${purchaseOrder.id}`);
                    }}
                    className={styles.approveBtn}
                  >
                    {"Approve"}
                  </Button>
                );
              } else {
                let chipStyle: string | undefined;
                if (
                  purchaseOrder.purchaseOrderStatus ===
                  PurchaseOrderStatus.DELIVERED
                ) {
                  chipStyle = styles.green;
                } else if (
                  purchaseOrder.purchaseOrderStatus ===
                  PurchaseOrderStatus.APPROVED
                ) {
                  chipStyle = styles.blue;
                } else {
                  // ordered
                  chipStyle = styles.yellow;
                }

                return (
                  <Chip
                    style={{
                      width: 100,
                      height: 23,
                    }}
                    label={displayNameForPurchaseOrderStatus(
                      purchaseOrder.purchaseOrderStatus
                    )}
                    className={chipStyle}
                  />
                );
              }
            },
            disableFilters: true,
          },
        ],
      },
    ],
    [history, handleChange, checkedPurchaseOrders]
  );

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        buttonText={"Okay"}
        bodyText={`No purchase orders present for the supplier: ${supplier?.name}`}
        open={showConfirmationDialog}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />
      <div className={styles.header}>
        <div style={{ display: "flex" }}>
          <Button
            className={styles.backButton}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
          </Button>
          <div className={styles.pageName}>
            {supplier?.name ? `Purchase Order for ${supplier?.name}` : ""}
          </div>
        </div>
        <Button
          className={styles.consolidateButton}
          style={{
            backgroundColor: "#1D68ED",
            color: primaryTextColor,
            fontWeight: "bold",
          }}
          onClick={handleConsolidateSubmit}
        >
          <Loading text={`Consolidate`} isLoading={isSubmitting} />
        </Button>
      </div>
      <DataTable
        columns={columns}
        data={purchaseOrders}
        tableClass={styles.table}
        searchable={false}
        isLoading={isFetching}
        tableHeight={600}
        highlightRows={[...checkedRowIndexes]}
      />
    </>
  );
});

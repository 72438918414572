import AddIcon from "assets/AddIcon";
import ContentPage from "components/contentPage/ContentPage";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  IPurchaseOrderDateRange,
  purchaseOrderDateRanges,
} from "types/PurchaseOrderDateRanges";
import { Auth } from "lib/Auth";
import { Avatar, Button, TextField } from "@material-ui/core";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import {
  DatePickerHeaderForPurchaseOrders,
  IPurchaseOrderDatePickerHeaderProps,
} from "components/datePickerHeaderForPurchaseOrders/DatePickerHeaderForPurchaseOrders";
import styles from "./PurchaseOrders.module.css";
import { PurchaseOrderTable } from "components/purchaseOrderTable/PurchaseOrderTable";
import RemindersIcon from "assets/RemindersIcon";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ISupplier } from "types/Supplier";
import { useQuerySuppliers } from "hooks/Suppliers";
import { FMSError } from "types/Error";
import Toast, { IToastBasicProps } from "components/Toast/Toast";

const PurchaseOrders: React.FC = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const params = queryString.parse(location.search);

  const purchaseOrderDateRange = params.timePeriod
    ? purchaseOrderDateRanges.find(
      (dateRange) => dateRange.title === params.timePeriod
    )
    : null;

  const [selectedDateRange, setSelectedDateRange] = useState(
    purchaseOrderDateRange ?? purchaseOrderDateRanges[0]
  );
  const onTimePeriodChange = (dateRange: IPurchaseOrderDateRange) => {
    params.timePeriod = dateRange.title;
    history.push({
      pathname: currentPath,
      search: queryString.stringify(params),
    });

    setSelectedDateRange(dateRange);
  };
  return (
    <ContentPage
      header={
        <Header
          dateRanges={purchaseOrderDateRanges}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={(dateRange) => onTimePeriodChange(dateRange)}
        />
      }
      mainContent={<MainSection selectedDateRange={selectedDateRange} />}
      rightSection={<RightSection />}
    />
  );
});

const SelectSupplierDialog: React.FC<{ onClose: () => void }> = (props) => {
  const { onClose } = props;
  const [supplier, setSupplier] = useState<ISupplier | null>();
  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);
  const { status, supplierList } = useQuerySuppliers();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (status === "success") {
      if (!supplierList || supplierList instanceof FMSError) {
        const message = supplierList?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSuppliers(supplierList.filter((obj) => !obj.isDeactivated));
      }
    }
  }, [status, supplierList]);

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const onSubmit = () => {
    if (supplier) {
      history.push({
        pathname: `/purchase-orders/suppliers/${supplier.id}/consolidate`,
        search: queryString.stringify(params),
      });
    }
  };

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  return (
    <ConfirmationDialog
      open={true}
      body={
        <>
          <Toast
            open={showToast.open}
            message={showToast.message}
            type={showToast.type}
            onClose={handleToastClose}
          />
          <Autocomplete
            options={suppliers}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setSupplier(value);
            }}
            defaultValue={null}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.inputField}
                label="Select Supplier"
                variant="outlined"
              />
            )}
          />
        </>
      }
      onClose={onClose}
      onApprove={onSubmit}
      buttonText={"Okay"}
      isApproving={false}
    />
  );
};

const Header: React.FC<IPurchaseOrderDatePickerHeaderProps> = (props) => {
  const [showSupplierSelectionDialog, setShowSupplierSelectionDialog] =
    useState<boolean>(false);

  return (
    <>
      <div className={styles.pageName}>{"Purchase Order"}</div>
      <DatePickerHeaderForPurchaseOrders {...props} />
      {Auth.getInstance().isAdminOrSupervisor() && (
        <Button
          style={{ color: "#ffffff" }}
          onClick={() => {
            setShowSupplierSelectionDialog(true);
          }}
          className={styles.genConsolidatedPoBtn}
        >
          {"Generate consolidated P.O."}
        </Button>
      )}
      {showSupplierSelectionDialog && (
        <SelectSupplierDialog
          onClose={() => {
            setShowSupplierSelectionDialog(false);
          }}
        />
      )}
    </>
  );
};

const MainSection: React.FC<{ selectedDateRange: IPurchaseOrderDateRange }> = (
  props
) => <PurchaseOrderTable selectedDateRange={props.selectedDateRange} />;

const RightSection: React.FC = () => {
  const { primaryColor } = useContext(FMSThemeContext);
  const history = useHistory();

  return (
    <>
      <div>
        <button
          type="button"
          className={styles.button}
          onClick={() => {
            history.push("/purchase-orders/create");
          }}
        >
          <Avatar
            className={styles.icon}
            style={{ backgroundColor: primaryColor }}
          >
            {<RemindersIcon />}
          </Avatar>
          <div className={styles.buttonTitle}>{"Generate Purchase Order"}</div>
        </button>
      </div>
      {(Auth.getInstance().isAdminOrSupervisor() || Auth.getInstance().isManager()) && (
        <div>
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              history.push("/purchase-orders/suppliers");
            }}
          >
            <Avatar
              className={styles.icon}
              style={{ backgroundColor: primaryColor }}
            >
              {<AddIcon />}
            </Avatar>
            <div className={styles.buttonTitle}>{"View Suppliers"}</div>
          </button>
        </div>
      )}
      {Auth.getInstance().isAdminOrSupervisor() && (
        <div>
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              history.push("/purchase-orders/suppliers/create");
            }}
          >
            <Avatar
              className={styles.icon}
              style={{ backgroundColor: primaryColor }}
            >
              {<AddIcon />}
            </Avatar>
            <div className={styles.buttonTitle}>{"Add supplier"}</div>
          </button>
        </div>
      )}
    </>
  );
};

export default PurchaseOrders;

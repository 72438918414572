import React, { useContext } from "react";
import { IMaterial } from "types/Material";
import styles from "./EntryConfirmationDialog.module.css";
import { IconButton, Dialog, Button } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { decorateDisplayUnit, isUnitGmsOrMl } from "lib/MaterialHelper";
import { IEntry } from "types/Entry";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IProps {
  materialsMap: Map<string, IMaterial>;
  entries: IEntry[];
  onClose: () => void;
  onConfirm: () => void;
  confirmationType: string;
  displayForRow?: (index: number) => JSX.Element;
}

export const EntryConfirmationDialog: React.FC<IProps> = (props) => {
  const { materialsMap, entries, confirmationType, onConfirm, displayForRow } =
    props;

  const { primaryTextColor, primaryColor } = useContext(FMSThemeContext);

  return (
    <Dialog open={true}>
      <header className={styles.modalTitleText}>
        Confirm {confirmationType}
      </header>
      <div className={styles.modalCloseIcon}>
        <IconButton name="close" color="inherit" onClick={props.onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className={styles.body}>
        <div style={{ borderBottom: "1.5px solid rgb(231, 230, 230)" }}>
          <ul>
            {entries.map((entry, index) => {
              if (displayForRow) {
                return displayForRow(index);
              }

              const material = materialsMap.get(entry.getMaterialId());
              if (!material) {
                return <></>;
              }

              const shouldConvertToKgOrLts = isUnitGmsOrMl(
                material.unitOfMeasure
              );
              const quantity = shouldConvertToKgOrLts
                ? entry.getQuantity() / 1000
                : entry.getQuantity();

              return (
                <div key={index}>
                  <li>
                    {material.name} - {quantity} {decorateDisplayUnit(material)}
                  </li>
                </div>
              );
            })}
          </ul>
        </div>

        <Button
          className={styles.button}
          style={{ backgroundColor: primaryColor, color: primaryTextColor }}
          onClick={onConfirm}
        >
          Confirm
        </Button>
      </div>
    </Dialog>
  );
};

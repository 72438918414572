import { IDisplayQuantity, MeasurementUnit } from "./MeasurementUnit";
import { MaterialType } from "./MaterialType";
import { MaterialClassification } from "./MaterialClassification";

export type IngredientJson = {
  [name: string]: IMaterial;
};

export interface IMaterial {
  id: string;
  name: string;
  unitOfMeasure: MeasurementUnit;
  type: MaterialType;
  quantity: number;
  displayQuantity: IDisplayQuantity;
  ingredients: IngredientJson;
  considerAsRawMaterialInBranchIds?: string[];
  classification: MaterialClassification;
  scopedToBranchIds: string[] | undefined;
  displayUnitOfMeasure?: string;
  eligibleForManualStockEntry: boolean;
}

export type IngredientToQuantityMap = {
  [name: string]: number;
};

export interface IMaterialPost {
  name: string;
  unitOfMeasure: MeasurementUnit;
  type: MaterialType;
  quantity: number;
  ingredients: IngredientToQuantityMap;
  classification: MaterialClassification;
}

export interface IMaterialUpdatePost {
  name: string;
  quantity: number;
  ingredients: IngredientToQuantityMap;
  activationDateForNewRecipe: Date;
  forceUpdate: boolean;
}

export interface IMaterialAssociatedEntitiesStatisticsView {
  compositionViews: Array<IMaterialAssociatedEntitiesCompositionView>;
  rawMaterialView: IMaterial;
}

export interface IMaterialAssociatedEntitiesCompositionView {
  materialId: string;
  materialName: string;
  salesCompositionView: IMaterialEntityCompositionView;
  wastageCompositionView: IMaterialEntityCompositionView;
  staffFoodCompositionView: IMaterialEntityCompositionView;
  complimentaryFoodCompositionView: IMaterialEntityCompositionView;
  outgoingTransfersCompositionView: IMaterialEntityCompositionView;
  totalCompositionView: IMaterialEntityCompositionView;
}

export interface IMaterialEntityCompositionView {
  entityQuantity: IDisplayQuantity;
  entityRawMaterialContributionQuantity: IDisplayQuantity;
}

export interface IUpdateMaterialResponse {
  shouldPoll: boolean;
  materialUpdateRequestId: string;
}

export enum MaterialUpdateStatus {
  PENDING = "PENDING",
  COMPLETED = "COMPLETED"
}

export interface IMaterialUpdateViewWithStatus {
  materialId: string;
  status: MaterialUpdateStatus;
}

export interface IMaterialUpdateRequestView {
  id: string;
  overallStatus: MaterialUpdateStatus;
  materialUpdateViewsWithStatus: IMaterialUpdateViewWithStatus[];
}

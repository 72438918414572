import { themeConfig } from "configs/ConfigProvider";
import { Config } from "lib/Config";
import React, { createContext } from "react";

export const FMSThemeContext = createContext<ReturnType<typeof themeConfig>>(
  undefined!
);

export const FMSThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const config = themeConfig(Config.getInstance().getNamespace());
  return (
    <FMSThemeContext.Provider value={config}>
      {children}
    </FMSThemeContext.Provider>
  );
};

import { Avatar } from "@material-ui/core";
import AddIcon from "assets/AddIcon";
import ReminderIcon from "assets/RemindersIcon";
import { AddReminderModal } from "components/addReminderModal/AddReminderModal";
import ContentPage from "components/contentPage/ContentPage";
import {
  DatePickerHeaderForReminders,
  IDatePickerHeaderProps,
} from "components/datePickerHeaderForReminders/DatePickerHeaderForReminders";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { RemindersTable } from "components/remindersTable/RemindersTable";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { Auth } from "lib/Auth";
import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  IReminderDateRange,
  reminderDateRanges,
} from "types/ReminderDateRange";
import styles from "./Reminders.module.css";

const Reminders: React.FC = React.memo(() => {
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const params = queryString.parse(location.search);

  const reminderDateRange = params.timePeriod
    ? reminderDateRanges.find(
        (dateRange) => dateRange.title === params.timePeriod
      )
    : null;

  const [selectedDateRange, setSelectedDateRange] = useState(
    reminderDateRange ?? reminderDateRanges[3]
  );

  const onTimePeriodChange = (dateRange: IReminderDateRange) => {
    params.timePeriod = dateRange.title;
    history.push({
      pathname: currentPath,
      search: queryString.stringify(params),
    });

    setSelectedDateRange(dateRange);
  };
  return (
    <ContentPage
      header={
        <Header
          dateRanges={reminderDateRanges}
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={(dateRange) => onTimePeriodChange(dateRange)}
        />
      }
      mainContent={<MainSection selectedDateRange={selectedDateRange} />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC<IDatePickerHeaderProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Reminders"}</div>
    <DatePickerHeaderForReminders {...props} />
  </>
);

const MainSection: React.FC<{ selectedDateRange: IReminderDateRange }> = (
  props
) => (
  <>
    <RemindersTable selectedDateRange={props.selectedDateRange} />
  </>
);

const RightSection: React.FC = () => {
  const { primaryColor } = useContext(FMSThemeContext);
  const history = useHistory();

  return (
    <>
      <ModalWithButton
        title={"Set Reminder"}
        icon={<ReminderIcon />}
        maxWidth={"lg"}
      >
        {" "}
        <AddReminderModal />{" "}
      </ModalWithButton>
      {Auth.getInstance().isAdmin() && (
        <>
          <button
            type="button"
            className={styles.button}
            onClick={() => {
              history.replace("/reminders/add-event-categories");
            }}
          >
            <Avatar
              className={styles.icon}
              style={{ backgroundColor: primaryColor }}
            >
              {<AddIcon />}
            </Avatar>
            <div className={styles.buttonTitle}>{"Add / Edit Master"}</div>
          </button>
        </>
      )}
    </>
  );
};

export default Reminders;

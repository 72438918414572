import { useContext, useEffect } from "react";
import "./App.css";
import { Login } from "./components/login/Login";
import { Router, Route, Switch } from "react-router-dom";
import Dashboard from "./containers/dashboard/Dashboard";
import Stock from "./containers/stock/Stock";
import SalesReport from "./containers/salesReport/SalesReport";
import Wastage from "./containers/wastage/Wastage";
import Receipe from "./containers/receipe/Receipe";
import StaffFood from "./containers/staffFood/StaffFood";
import ComplimentaryFood from "./containers/complimentaryFood/ComplimentaryFood";
import Settings from "./containers/settings/Settings";
import StockTransfers from "./containers/stockTransfers/StockTransfers";
import PrivateRouteWithLayout from "./components/privateRouteWithLayout/PrivateRouteWithLayout";
import Logout from "./containers/logout/logout";
import { Auth } from "./lib/Auth";
import WastageStats from "containers/wastageStats/WastageStats";
import StaffFoodStats from "containers/staffFoodStats/StaffFoodStats";
import StockStats from "containers/stockStats/StockStats";
import SalesStats from "containers/salesStats/SalesStats";
import Report from "containers/report/Report";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { LoggerContext } from "contexts/LoggerContext";
import { createBrowserHistory } from "history";
import Reminders from "containers/reminders/Reminders";
import AddEventCategories from "containers/AddEventCategories/AddEventCategories";
import PurchaseOrders from "containers/purchaseOrders/PurchaseOrders";
import ViewSuppliers from "containers/viewSuppliers/ViewSuppliers";
import AddSupplier from "containers/addSupplier/AddSupplier";
import EditSupplier from "containers/editSupplier/EditSupplier";
import GeneratePurchaseOrder from "containers/generatePurchaseOrder/GeneratePurchaseOrder";
import GenerateGRN from "containers/generateGRN/GenerateGRN";
import ViewPurchaseOrderContainer from "containers/viewPurchaseOrder/ViewPurchaseOrderContainer";
import { Consolidate } from "containers/consolidate/Consolidate";
import StoreForecast from "containers/predictions/storeForecast/StoreForecast";
import KitchenForecast from "containers/predictions/kitchenForecast/KitchenForecast";
import SalesForecast from "containers/predictions/salesForecast/SalesForecast";

function App() {
  useEffect(() => {
    // initializes firebase
    Auth.getInstance();
  });

  const { favIcon } = useContext(FMSThemeContext);
  const element = document.getElementById("favicon");
  if (element && element instanceof HTMLLinkElement) {
    element.href = favIcon;
  }

  let prevPath: string | null = null;
  const history = createBrowserHistory();
  const { logPageView } = useContext(LoggerContext);
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      logPageView();
    }
  });

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path="/login" component={Login} />
          <PrivateRouteWithLayout path="/stock" component={Stock} />
          <PrivateRouteWithLayout
            path="/stock-stats/:id"
            component={StockStats}
          />

          <PrivateRouteWithLayout path="/sales" component={SalesReport} />
          <PrivateRouteWithLayout
            path="/sales-stats/:id"
            component={SalesStats}
          />

          <PrivateRouteWithLayout path="/wastage" component={Wastage} />
          <PrivateRouteWithLayout
            path="/wastage-stats/:id"
            component={WastageStats}
          />

          <PrivateRouteWithLayout path="/recipe" component={Receipe} />

          <PrivateRouteWithLayout path="/staff-food" component={StaffFood} />
          <PrivateRouteWithLayout
            path="/staff-food-stats/:id"
            component={StaffFoodStats}
          />

          <PrivateRouteWithLayout
            path="/complimentary-food"
            component={ComplimentaryFood}
          />
          <PrivateRouteWithLayout
            path="/in-out-flow"
            component={StockTransfers}
          />
          <PrivateRouteWithLayout path="/report" component={Report} />
          <PrivateRouteWithLayout
            path="/store-forecast"
            component={StoreForecast}
          />
          <PrivateRouteWithLayout
            path="/kitchen-forecast"
            component={KitchenForecast}
          />
          <PrivateRouteWithLayout
            path="/sales-forecast"
            component={SalesForecast}
          />
          <PrivateRouteWithLayout path="/settings" component={Settings} />
          <PrivateRouteWithLayout
            path="/reminders/add-event-categories"
            component={AddEventCategories}
          />
          <PrivateRouteWithLayout path="/reminders" component={Reminders} />

          <PrivateRouteWithLayout
            exact
            path="/purchase-orders"
            component={PurchaseOrders}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/create"
            component={GeneratePurchaseOrder}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/suppliers"
            component={ViewSuppliers}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/:purchaseOrderId"
            component={ViewPurchaseOrderContainer}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/:purchaseOrderId/grn"
            component={GenerateGRN}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/suppliers/create"
            component={AddSupplier}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/suppliers/:supplierId"
            component={EditSupplier}
          />
          <PrivateRouteWithLayout
            exact
            path="/purchase-orders/suppliers/:supplierId/consolidate"
            component={Consolidate}
          />
          <PrivateRouteWithLayout path="/logout" component={Logout} />
          <PrivateRouteWithLayout path="/" component={Dashboard} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

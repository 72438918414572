export enum StockType {
  ADDITION = "ADDITION",
  CLOSING = "CLOSING",
}

export const displayNameForStockType = (stockType: StockType) => {
  switch (stockType) {
    case StockType.ADDITION:
      return "Raw stock";
    case StockType.CLOSING:
      return "Closing stock";
  }
};

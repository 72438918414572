import styles from "./AddWastageModal.module.css";
import React, { useContext, useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { useMutation } from "react-query";
import { IBranch } from "types/Branch";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { useForm } from "react-hook-form";
import { ModalContext } from "contexts/ModalContext";
import { IWastageFoodPost } from "types/WastageFood";
import { addWastage, uploadWastage } from "api/PostWastage";
import { invalidateWastagesCache } from "hooks/WastageFoods";
import { WastageInputTab } from "./WastageInputTab";
import { IMaterial } from "types/Material";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Toast from "components/Toast/Toast";
import { getWastageUploadTemplate } from "api/GetWastage";
import { ErrorBox } from "components/errorBox/ErrorBox";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

const extractWastageForPost = (wastage: any) => {
  const postWastageArr = new Array<IWastageFoodPost>();
  if (!wastage) {
    return postWastageArr;
  }

  wastage.forEach((waste: any) => {
    const materialId = waste.materialId as string | undefined;
    const quantity = waste.quantity as number | undefined;

    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (!quantity || quantity === 0 || isNaN(quantity)) {
      return;
    }

    const postMaterial: IWastageFoodPost = {
      materialId: materialId,
      quantity: quantity,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };

    postWastageArr.push(postMaterial);
  });

  return postWastageArr;
};

export const AddWastageModal: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => {
  const { handleSubmit, getValues, control } = useForm<IWastageFoodPost[]>();
  const { selectedBranch } = props;
  const modalProps = useContext(ModalContext);
  const [insufficientMaterials, setInsufficientMaterials] = useState<
    IMaterial[] | undefined
  >(undefined);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [isDownloading, setDownloading] = useState(false);

  const handleToastClose = () => {
    setShowToast(false);
  };

  const [mutate] = useMutation(
    (wasteMaterials: IWastageFoodPost[]) => {
      return addWastage(wasteMaterials, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateWastagesCache();
          modalProps.hideModal(true, "Wastage added successfully!");
          return;
        }

        if (error.insufficientMaterials) {
          setInsufficientMaterials(error.insufficientMaterials);
        } else {
          const message = error.message ?? "Something went wrong!";
          setToastMessage(message);
          setShowToast(true);
        }
      },
    }
  );

  const [wastage, setWastage] = useState<IWastageFoodPost[]>([]);
  const onSubmit = async (data: any) => {
    const rawMaterials = extractWastageForPost(data.rawMaterials.materials);
    const semiPreparedMaterials = extractWastageForPost(
      data.semiPreparedMaterials.materials
    );
    const sellableMaterials = extractWastageForPost(
      data.sellableMaterials.materials
    );
    const wasteMaterials = rawMaterials
      .concat(semiPreparedMaterials)
      .concat(sellableMaterials);

    if (wasteMaterials.length === 0) {
      setToastMessage("Atleast one wastage item is required!");
      setShowToast(true);
      return;
    }

    setWastage(wasteMaterials);
  };

  const [mutateForFile] = useMutation(
    (files: File[]) => {
      return uploadWastage(files, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateWastagesCache();
          modalProps.hideModal(true, "Wastage added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const handleUploadFile = async (files: File[]) => {
    setSubmitting(true);
    mutateForFile(files);
  };

  const {
    allMaterialsArrForFoodAnalysis,
    allMaterialsMapForFoodAnalysis,
    rawMaterialsArrForFoodAnalysis,
    semiPreparedMaterialsArrForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
    rawMaterialsMapForFoodAnalysis,
    semiPreparedMaterialsMapForFoodAnalysis,
    sellableMaterialsMapForFoodAnalysis,
  } = props.materialContextValues;
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
  };

  if (allMaterialsArrForFoodAnalysis.length === 0) {
    return <></>;
  }

  const favouriteRawMaterials: IMaterial[] = [];
  const favouriteSemiPreparedMaterials: IMaterial[] = [];
  const favouriteSellableMaterials: IMaterial[] = [];
  if (
    selectedBranch.favourites &&
    selectedBranch.favourites.wastage?.length > 0
  ) {
    selectedBranch.favourites.wastage.forEach((favouriteMaterial) => {
      const material = allMaterialsMapForFoodAnalysis.get(favouriteMaterial.id);
      if (!material) {
        return;
      }

      if (rawMaterialsMapForFoodAnalysis.has(material.id)) {
        favouriteRawMaterials.push(material);
      } else if (semiPreparedMaterialsMapForFoodAnalysis.has(material.id)) {
        favouriteSemiPreparedMaterials.push(material);
      } else if (sellableMaterialsMapForFoodAnalysis.has(material.id)) {
        favouriteSellableMaterials.push(material);
      }
    });
  }

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    const error = await getWastageUploadTemplate(
      selectedBranch.id,
      selectedDate!
    );
    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setToastMessage(message);
      setShowToast(true);
    }
  };

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Wastage</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      isUploadDownloadApplicable={true}
      branchName={props.selectedBranch.name}
      isDownloading={isDownloading}
      handleDownloadTemplate={handleDownloadTemplate}
      handleUploadFile={handleUploadFile}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      {wastage.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={allMaterialsMapForFoodAnalysis}
          entries={wastage}
          confirmationType={"Wastage"}
          onClose={() => {
            setWastage([]);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(wastage);
            setWastage([]);
          }}
        />
      )}

      {insufficientMaterials !== undefined && (
        <ErrorBox
          errorMaterialsArr={insufficientMaterials}
          open={true}
          onClose={() => {
            setInsufficientMaterials(undefined);
          }}
          selectedBranch={selectedBranch}
        />
      )}

      <div className={styles.modalBody}>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Raw Material" />
          <Tab label="Semi Prepared" />
          <Tab label="Final Dish" />
        </Tabs>

        <WastageInputTab
          tabValue={tabValue}
          index={0}
          name="rawMaterials"
          materialsMap={rawMaterialsMapForFoodAnalysis}
          materialsArr={rawMaterialsArrForFoodAnalysis}
          getValues={getValues}
          control={control}
          favourites={favouriteRawMaterials}
        />

        <WastageInputTab
          tabValue={tabValue}
          index={1}
          name="semiPreparedMaterials"
          materialsMap={semiPreparedMaterialsMapForFoodAnalysis}
          materialsArr={semiPreparedMaterialsArrForFoodAnalysis}
          getValues={getValues}
          control={control}
          favourites={favouriteSemiPreparedMaterials}
        />

        <WastageInputTab
          tabValue={tabValue}
          index={2}
          name="sellableMaterials"
          materialsMap={sellableMaterialsMapForFoodAnalysis}
          materialsArr={sellableMaterialsArrForFoodAnalysis}
          getValues={getValues}
          control={control}
          favourites={favouriteSellableMaterials}
        />
      </div>
    </ModalPopup>
  );
};

import { ConsolidateComponent } from "components/consolidateComponent/ConsolidateComponent";
import ContentPage from "components/contentPage/ContentPage";
import React from "react";
import { useParams } from "react-router-dom";

export const Consolidate: React.FC = React.memo(() => {
  const { supplierId } = useParams<{ supplierId: string }>();

  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection supplierId={supplierId} />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = () => {
  return <></>;
};

const MainSection: React.FC<{ supplierId: string }> = (props) => {
  const { supplierId } = props;
  return <ConsolidateComponent supplierId={supplierId} />;
};

const RightSection: React.FC = () => {
  return <></>;
};

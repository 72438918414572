import React, { useEffect, useState } from "react";
import styles from "./ReceipeTable.module.css";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { IMaterial } from "types/Material";
import { DataTable } from "components/dataTable/DataTable";
import { ReceipeModal } from "components/receipeModal/ReceipeModal";
import { MaterialType, displayNameForMaterialType } from "types/MaterialType";
import { Auth } from "lib/Auth";
import { Button } from "@material-ui/core";
import Toast, { IToastBasicProps } from "components/Toast/Toast";

export const ReceipeTable: React.FC<{
  materialContextValues: IMaterialContextInterface;
}> = (props) => {
  const {
    semiPreparedMaterialsArrForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
  } = props.materialContextValues;
  const { materialContextValues } = props;
  const [receipes, setReceipes] = useState<{
    materials: IMaterial[];
    state: "processing" | "completed";
  }>({ materials: [], state: "processing" });

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [selectedMaterial, setSelectedMaterial] = useState<IMaterial>();
  useEffect(() => {
    if (
      semiPreparedMaterialsArrForFoodAnalysis &&
      sellableMaterialsArrForFoodAnalysis
    ) {
      const materials = semiPreparedMaterialsArrForFoodAnalysis
        .concat(sellableMaterialsArrForFoodAnalysis)
        .sort((receipe1, receipe2) =>
          receipe1.name.localeCompare(receipe2.name)
        );
      setReceipes({ materials: materials, state: "completed" });
    }
  }, [
    semiPreparedMaterialsArrForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "name",
            width: 704,
            Cell: (props: any) => {
              const material = props.row.original as IMaterial;
              if (material.type === MaterialType.RAW) {
                return material.name;
              }
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setSelectedMaterial(material);
                  }}
                >
                  {material.name}
                </Button>
              );
            },
            disableFilters: true,
          },
          {
            Header: "State",
            accessor: (row: IMaterial) => displayNameForMaterialType(row.type),
            width: 192,
            disableGlobalFilter: true,
            disableFilters: true,
          },
        ],
      },
    ],
    [setSelectedMaterial]
  );

  return (
    <>
      {selectedMaterial && (
        <ReceipeModal
          receipe={selectedMaterial}
          materialContextValues={materialContextValues}
          allowEdit={Auth.getInstance().isAdmin()}
          onClose={(successMessage?: string) => {
            setSelectedMaterial(undefined);
            if (successMessage) {
              setShowToast({
                open: true,
                message: successMessage,
                type: "success",
              });
            }
          }}
        />
      )}

      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />

      <DataTable
        columns={columns}
        data={receipes.materials}
        tableClass={styles.table}
        searchable={true}
        isLoading={receipes.state === "processing"}
        tableHeight={600}
        initialSortBy="name"
      />
    </>
  );
};

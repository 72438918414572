import { format } from "date-fns";
import moment from "moment";

export const formatStartEndDates = (
  start: Date,
  end: Date,
  allowEndAfterToday: boolean = false
): [string, string] => {
  if (!allowEndAfterToday && moment().isBefore(end)) {
    // end date is after today's date, so just set end to be today
    end = moment().toDate();
  }

  return [format(start, "yyyy-MM-dd"), format(end, "yyyy-MM-dd")];
};

export const formatDate = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const today = () => {
  const today = new Date();
  today.setHours(0, 0, 0);
  return today;
};

export const dateAddingDaysFromDate = (
  fromDate: Date,
  numberOfDays: number
) => {
  const date = new Date();
  date.setDate(fromDate.getDate() + numberOfDays);
  date.setHours(23, 59, 59);
  return date;
};

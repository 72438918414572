import React, { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import styles from "./PhoneNumber.module.css";
import { InputAdornment } from "@material-ui/core";
import phoneImg from "./phoneImg.svg";

const phoneInput = (props: any, ref: any) => (
  <TextField
    onChange={props.onChange}
    InputProps={{
      className: styles.input,
      startAdornment: (
        <InputAdornment position="start">
          <img
            src={phoneImg}
            className={styles.phoneImg}
            alt={"Phone number"}
          />
        </InputAdornment>
      ),
    }}
    autoFocus={true}
    type={"tel"}
    placeholder="Phone Number"
    inputRef={ref}
    fullWidth
    size="small"
    label=""
    variant="outlined"
    name="phone"
  />
);
export default forwardRef(phoneInput);

import { Avatar, makeStyles } from "@material-ui/core";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";
import styles from "./ScrollableList.module.css";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { EventCategoryType } from "types/Event";

const useStyles = makeStyles((theme) => ({
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

interface IProps {
  header: string;
  data: string[];
  onAdd: (eventCategoryType: EventCategoryType) => void;
  onDelete: (value: string, eventCategoryType: EventCategoryType) => void;
  eventCategoryType: EventCategoryType;
}

const ScorllableList: React.FC<IProps> = (props) => {
  const { data, header, onAdd, onDelete, eventCategoryType } = props;
  const { primaryColor } = useContext(FMSThemeContext);

  const sortedData = data.sort((a, b) => {
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }

    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }

    return 0;
  });
  const classes = useStyles();

  return (
    <>
      <div className={styles.scrollableList}>
        <div className={styles.header}>
          <div className={styles.headerText}>{header}</div>
          <div
            className={styles.addEntry}
            onClick={() => {
              onAdd(eventCategoryType);
            }}
          >
            <Avatar
              style={{
                backgroundColor: primaryColor,
                position: "absolute",
                bottom: "35px",
                right: "20px",
                fontSize: "30px",
                fontWeight: "bold",
              }}
              className={classes.large}
            >
              +
            </Avatar>
          </div>
        </div>
        <div className={styles.list}>
          {sortedData.map((value) => {
            return (
              <div className={styles.listItem}>
                <div className={styles.listItemText}>{value}</div>
                <span
                  className={styles.deleteIcon}
                  onClick={() => {
                    onDelete(value, eventCategoryType);
                  }}
                >
                  <DeleteOutlineIcon />
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ScorllableList;

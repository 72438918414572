export interface IDisplayQuantity {
  unit: MeasurementUnit;
  quantity: number;
}

export enum MeasurementUnit {
  BOTTLE = "BOTTLE",
  CUP = "CUP",
  GLASS = "GLASS",
  GRAMS = "GRAMS",
  LAADI = "LAADI",
  LOAFS = "LOAFS",
  MILLI_LITRE = "MILLI_LITRE",
  NOS = "NOS",
  PACKET = "PACKET",
  PLATE = "PLATE",
  SACHET = "SACHET",
  SLICES = "SLICES",
  KILOGRAMS = "KILOGRAMS",
  LITRE = "LITRE",
}

export const displayNameForUnitOfMeasure = (
  unitOfMeasure?: MeasurementUnit
) => {
  if (!unitOfMeasure) {
    return "";
  }

  switch (unitOfMeasure) {
    case MeasurementUnit.BOTTLE:
      return "bottle";
    case MeasurementUnit.GRAMS:
      return "gms";
    case MeasurementUnit.LAADI:
      return "laadi";
    case MeasurementUnit.LOAFS:
      return "loafs";
    case MeasurementUnit.MILLI_LITRE:
      return "ml";
    case MeasurementUnit.NOS:
      return "nos";
    case MeasurementUnit.PACKET:
      return "pkts";
    case MeasurementUnit.SACHET:
      return "sachet";
    case MeasurementUnit.CUP:
      return "cup";
    case MeasurementUnit.GLASS:
      return "glass";
    case MeasurementUnit.SLICES:
      return "slices";
    case MeasurementUnit.PLATE:
      return "plate";
    case MeasurementUnit.KILOGRAMS:
      return "kgs";
    case MeasurementUnit.LITRE:
      return "ltrs";
    default:
      throw new Error("Unimplemented case " + unitOfMeasure);
  }
};

export const displayQuantity = (displayQuantity?: IDisplayQuantity) => {
  if (displayQuantity?.quantity === undefined) {
    return "-";
  }

  const unit = displayNameForUnitOfMeasure(displayQuantity.unit);
  return `${displayQuantity.quantity.toFixed(2)} ${unit}`;
};

export const addDisplayQuantity = (q1: IDisplayQuantity, q2: IDisplayQuantity): IDisplayQuantity | undefined => {
  if (q1.unit === q2.unit) {
    return { unit: q1.unit, quantity: q1.quantity + q2.quantity } as IDisplayQuantity;
  } else if (
    (q1.unit === MeasurementUnit.LITRE && q2.unit === MeasurementUnit.MILLI_LITRE) ||
    (q1.unit === MeasurementUnit.MILLI_LITRE && q2.unit === MeasurementUnit.LITRE) ||
    (q1.unit === MeasurementUnit.KILOGRAMS && q2.unit === MeasurementUnit.GRAMS) ||
    (q1.unit === MeasurementUnit.GRAMS && q2.unit === MeasurementUnit.KILOGRAMS)
  ) {

    let totalQuantity = 0;
    if (q1.unit === MeasurementUnit.LITRE || q1.unit === MeasurementUnit.KILOGRAMS) {
      totalQuantity = q1.quantity + (q2.quantity / 1000);
    } else {
      totalQuantity = (q1.quantity / 1000) + q2.quantity;
    }

    const commonUnit = q1.unit === MeasurementUnit.LITRE || q2.unit === MeasurementUnit.LITRE ?
      MeasurementUnit.LITRE : MeasurementUnit.KILOGRAMS;
    return { unit: commonUnit, quantity: totalQuantity } as IDisplayQuantity;
  }
}


export const MeasurementUnitList = Object.values(MeasurementUnit);

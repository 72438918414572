import { Button, CircularProgress } from "@material-ui/core";
import { SupplierForm } from "components/supplierForm/SupplierForm";
import SupplierMaterialsForm from "components/supplierMaterialsForm/SupplierMaterialsForm";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ISupplier, ISupplierPost } from "types/Supplier";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import styles from "./EditSupplierComponent.module.css";
import { useQuerySupplier } from "hooks/Suppliers";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";

interface IProps {
  supplierId: string;
}

const EditSupplierComponent: React.FC<IProps> = (props) => {
  const history = useHistory();

  const [supplier, setSupplier] = useState<ISupplier>();
  const [postSupplier, setPostSupplier] = useState<ISupplierPost>();
  const [showMaterialsForm, setShowMaterialsForm] = useState(false);

  const { status, supplierResponse, isFetching } = useQuerySupplier(
    props.supplierId
  );

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  useEffect(() => {
    if (status === "success") {
      if (!supplierResponse || supplierResponse instanceof FMSError) {
        const message = supplierResponse?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSupplier(supplierResponse);
      }
    }
  }, [status, supplierResponse]);

  return (
    <>
      <div className={styles.header}>
        <div style={{ display: "flex" }}>
          <Button
            className={styles.backButton}
            onClick={() => {
              if (showMaterialsForm) {
                setShowMaterialsForm(false);
              } else {
                history.goBack();
              }
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
          </Button>
          <div className={styles.pageName}>
            {`Edit Supplier : Step ${!showMaterialsForm ? "1" : "2"}`}
          </div>
        </div>
      </div>

      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />

      {isFetching && (
        <div className={styles.loadingDiv}>
          <CircularProgress
            size={30}
            color={"inherit"}
            className={styles.loading}
          />
        </div>
      )}

      {!isFetching && (
        <>
          {!showMaterialsForm && supplier && (
            <SupplierForm
              onEnterSupplier={(supplier, showForm) => {
                setPostSupplier(supplier);
                setShowMaterialsForm(showForm);
              }}
              supplier={supplier}
              allowEdit={true}
            />
          )}
          {showMaterialsForm && postSupplier && (
            <SupplierMaterialsForm supplier={postSupplier} />
          )}
        </>
      )}
    </>
  );
};

export default EditSupplierComponent;

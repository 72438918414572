import React, { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import HomeLayout from "containers/homeLayout/HomeLayout";
import { Auth } from "lib/Auth";
import BranchFavouriteProvider from "contexts/BranchFavouriteContext";
import MaterialProvider from "contexts/MaterialsContext";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { LoggerContext } from "contexts/LoggerContext";
import { Config } from "lib/Config";
import { Environment } from "configs/Environment";

interface IProps {
  component: React.FC;
  path: string;
  exact?: boolean;
}

const PrivateRouteWithLayout: React.FC<IProps & RouteProps> = (props) => {
  const { setUser } = useContext(LoggerContext);
  const user = Auth.getInstance().getUser();
  if (user) {
    setUser(user);
    return (
      <BranchFavouriteProvider>
        <MaterialProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <RouteWithLayout
              path={props.path}
              exact={props.exact || false}
              component={props.component}
            />
          </MuiPickersUtilsProvider>
        </MaterialProvider>
      </BranchFavouriteProvider>
    );
  } else {
    setUser(undefined);
    return (
      <Redirect
        to={{ pathname: "/login", state: { from: props.location?.pathname } }}
      />
    );
  }
};

const RouteWithLayout: React.FC<IProps & RouteProps> = (props) => {
  const { component: Component } = props;
  let pages = Auth.getInstance().getPagesForCurrentUser();
  if (Config.getInstance().getEnvironment() === Environment.PRODUCTION) {
    pages = pages.filter((page) => !(page.disabledInProd ?? false));
  }
  const isRouteAllowed = pages.find((page) => page.href === props.path);
  return isRouteAllowed ? (
    <Route
      path={props.path}
      exact={props.exact || false}
      render={(matchProps) => (
        <HomeLayout pages={pages} userName={Auth.getInstance().getUser()!.name}>
          <Component {...(matchProps as any)} />
        </HomeLayout>
      )}
    />
  ) : (
    <Redirect
      to={{ pathname: "/", state: { from: props.location?.pathname } }}
    />
  );
};
export default PrivateRouteWithLayout;

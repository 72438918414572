import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import {
  IStockEntryMaterialView,
  IStockEntryView,
  IStockForAllBranches,
} from "types/Stock";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

interface IStock {
  stockList: IStockForAllBranches[];
}

interface IStockEntryResponse {
  stockEntryView: IStockEntryView;
}

export const getStock = async (
  start: string,
  end: string
): Promise<IStockForAllBranches[] | FMSError> => {
  try {
    const response = await get<IStock>(
      `${BACKEND_URL_V1}/stocks?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.stockList) {
        return response.parsedBody.stockList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Stock could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStockUploadTemplate = async (
  branchId: string,
  date: Date,
  stockRequestType: string
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/stocks/branches/${branchId}/uploadReportFormat?date=${formatDate(
        date
      )}&stockRequestType=${stockRequestType}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Stock_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStockEntries = async (
  start: string,
  end: string,
  branchId: string,
  materialId: string
): Promise<IStockEntryMaterialView[] | FMSError> => {
  try {
    const response = await get<IStockEntryResponse>(
      `${BACKEND_URL_V1}/stocks/branches/${branchId}/materials/${materialId}?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.stockEntryView) {
        return response.parsedBody.stockEntryView.stockList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Sales could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

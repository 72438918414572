import { BACKEND_URL_V1, deletee, post } from "lib/Api";
import { FMSError } from "types/Error";
import { IEventPost, IUpdateEventStatusPost } from "types/Event";

export const addEvent = async (event: IEventPost): Promise<FMSError | null> => {
  try {
    const response = await post<{ event: IEventPost }>(
      `${BACKEND_URL_V1}/events`,
      event
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to add reminder";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateEventStatus = async (
  eventId: string,
  request: IUpdateEventStatusPost
): Promise<FMSError | null> => {
  try {
    const response = await post<{ event: IEventPost }>(
      `${BACKEND_URL_V1}/events/${eventId}/status`,
      request
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to update reminder";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateEvent = async (
  eventId: string,
  request: IEventPost,
  updatePeriodicEvent: boolean
): Promise<FMSError | null> => {
  try {
    const response = await post<{ event: IEventPost }>(
      `${BACKEND_URL_V1}/events/${eventId}?updatePeriodicEvent=${updatePeriodicEvent}`,
      request
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to update reminder";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const deleteEvent = async (
  eventId: string,
  deletePeriodicEvent: boolean
): Promise<FMSError | null> => {
  try {
    const response = await deletee(
      `${BACKEND_URL_V1}/events/${eventId}?deletePeriodicEvent=${deletePeriodicEvent}`
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Unable to delete reminder";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

import React, { ReactNode } from "react";
import SideBar from "components/sideBar/SideBar";
import styles from "./HomeLayout.module.css";
import { IPage } from "types/Page";
import moment from "moment";

interface IProps {
  children: ReactNode;
  pages: IPage[];
  userName: string;
}

const HomeLayout: React.FC<IProps> = (props) => (
  <div className={styles.root}>
    <SideBar pages={props.pages} />
    <div className={styles.content}>
      <div className={styles.header}>
        <div className={styles.date}>
          {moment(new Date()).format("dddd, MMMM Do YYYY")}
        </div>
        <div className={styles.welcome}>Welcome, {props.userName}</div>
      </div>
      {props.children}
    </div>
  </div>
);

export default HomeLayout;

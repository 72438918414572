import { IMaterial } from "types/Material";
import {
  displayNameForUnitOfMeasure,
  MeasurementUnit,
} from "types/MeasurementUnit";

export const isUnitGmsOrMl = (unitOfMeasure: MeasurementUnit) => {
  if (
    unitOfMeasure === MeasurementUnit.GRAMS ||
    unitOfMeasure === MeasurementUnit.MILLI_LITRE
  ) {
    return true;
  }

  return false;
};

export const isUnitKgsOrLtr = (unit: MeasurementUnit) => {
  return unit === MeasurementUnit.KILOGRAMS || unit === MeasurementUnit.LITRE;
};

export const decorateDisplayUnit = (material: IMaterial) => {
  if (!isUnitGmsOrMl(material.unitOfMeasure)) {
    if (material.displayUnitOfMeasure) {
      return material.displayUnitOfMeasure;
    } else {
      return displayNameForUnitOfMeasure(material.unitOfMeasure);
    }
  }

  if (material.unitOfMeasure === MeasurementUnit.GRAMS) {
    return "kgs";
  }

  if (material.unitOfMeasure === MeasurementUnit.MILLI_LITRE) {
    return "ltrs";
  }

  return "";
};

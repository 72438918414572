import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import styles from "./ViewPurchaseOrder.module.css";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import moment from "moment";
import {
  IPurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderStatus,
} from "types/PurchaseOrder";
import { invalidatePurchaseOrdersCache } from "hooks/purchaseOrders";
import { FMSError } from "types/Error";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import { DeleteOutline, Edit } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import { deletePurchaseOrder } from "api/DeletePurchaseOrder";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { useMutation } from "react-query";
import {
  approvePurchaseOrder,
  IPurchaseOrderPost,
  updatePurchaseOrder,
} from "api/PostPurchaseOrder";
import { IMaterial, IngredientToQuantityMap } from "types/Material";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { isUnitGmsOrMl } from "lib/MaterialHelper";
import { Auth } from "lib/Auth";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MaterialType } from "types/MaterialType";
import { DatePicker } from "@material-ui/pickers";
import CalendarIcon from "assets/CalendarIcon";
import { getPurchaseOrder } from "api/GetPurchaseOrders";

interface IProps {
  purchaseOrderId: string;
}

interface IRowProps {
  index: number;
  poItem: PurchaseOrderItem;
  editable: boolean;
  status: PurchaseOrderStatus;
  control: any;
  getValues: any;
}

// returns true if status1 >= status2
const isPurchaseOrderStatusGreaterOrEqualToStatus = (
  status1: PurchaseOrderStatus,
  status2: PurchaseOrderStatus
) => {
  const statusOrder = [
    PurchaseOrderStatus.PENDING,
    PurchaseOrderStatus.APPROVED,
    PurchaseOrderStatus.ORDERED,
    PurchaseOrderStatus.DELIVERED,
  ];
  const ind1 = statusOrder.findIndex((val) => val === status1);
  const ind2 = statusOrder.findIndex((val) => val === status2);
  return ind1 >= ind2;
};

const RowComponent: React.FC<IRowProps> = (props) => {
  const { index, poItem, editable, status, control, getValues } = props;

  const fieldName = `materials[${index}]`;

  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <div className={styles.inputContainer}>
        <Controller
          control={control}
          name={`${fieldName}.materialName`}
          style={{ marginLeft: "10px", width: "450px" }}
          defaultValue={poItem.materialName}
          render={() => (
            <FormControl className={styles.materialUnit} variant="outlined">
              <InputLabel htmlFor={"materialName" + fieldName}>
                Item Name
              </InputLabel>
              <OutlinedInput
                style={{
                  color: "#000000",
                }}
                id={"materialName" + fieldName}
                type={"text"}
                defaultValue={poItem.materialName}
                disabled
                labelWidth={59}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.quantityReceived`}
          style={{ marginLeft: "10px", width: "450px" }}
          defaultValue={poItem.displayQuantity.quantity}
          render={({ onChange }) => (
            <FormControl className={styles.materialUnit} variant="outlined">
              <InputLabel htmlFor={"quantityReceived" + fieldName}>
                Quantity Ordered
              </InputLabel>
              <OutlinedInput
                id={"quantityReceived" + fieldName}
                style={{
                  color: isPurchaseOrderStatusGreaterOrEqualToStatus(
                    status,
                    PurchaseOrderStatus.ORDERED
                  )
                    ? "#000000"
                    : undefined,
                }}
                type={"text"}
                disabled={!editable}
                value={
                  !editable
                    ? poItem.displayQuantity.quantity
                    : getValues(`${fieldName}.quantityReceived`)
                }
                endAdornment={
                  <InputAdornment position="end">
                    {displayNameForUnitOfMeasure(poItem.displayQuantity.unit)}
                  </InputAdornment>
                }
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  const quantity = isNaN(value) ? 0 : value;
                  onChange(quantity);
                }}
                labelWidth={115}
              />
            </FormControl>
          )}
        />
        <FormControl className={styles.materialUnit} variant="outlined">
          <InputLabel htmlFor={"existingStockQuantity" + fieldName}>
            Existing Stock
          </InputLabel>
          <OutlinedInput
            id={"existingStockQuantity" + fieldName}
            type={"text"}
            disabled={true}
            value={poItem.existingStockDisplayQuantity?.quantity ?? "-"}
            endAdornment={
              <InputAdornment position="end">
                {poItem.existingStockDisplayQuantity
                  ? displayNameForUnitOfMeasure(poItem.existingStockDisplayQuantity.unit)
                  : ""
                }
              </InputAdornment>
            }
            labelWidth={95}
          />
        </FormControl>
      </div>
    </div>
  );
};

interface IQuantityReceived {
  materialName: string;
  quantityReceived: number;
}

interface IUpdatePurchaseOrderFormData {
  deliveryAddress: string;
  preferredDeliveryDate: string;
  materials: IQuantityReceived[];
}

const ViewPurchaseOrder: React.FC<IProps> = React.memo((props) => {
  const { purchaseOrderId } = props;
  const history = useHistory();
  const { handleSubmit, control, getValues, errors } =
    useForm<IUpdatePurchaseOrderFormData>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isApproving, setIsApproving] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();

  const [editInProgress, setEditInProgress] = useState<boolean>(false);

  const [showSuccessDialog, setShowSuccessDialog] = useState<{
    message: string;
    show: boolean;
  }>({ message: "", show: false });

  useEffect(() => {
    async function fetchPurchaseOrder() {
      setIsFetching(true);
      const response = await getPurchaseOrder(purchaseOrderId);
      setIsFetching(false);
      if (response instanceof FMSError) {
        const message = response?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setPurchaseOrder(response.purchaseOrderView);
      }
    }

    fetchPurchaseOrder();
  }, [purchaseOrderId]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);

  const handleEdit = () => {
    setEditInProgress(true);
  };

  const handleCancelEdit = () => {
    setEditInProgress(false);
  };

  const onUpdate = (data: IUpdatePurchaseOrderFormData) => {
    const materialIdToQuantityMap: IngredientToQuantityMap = {};
    data.materials
      .filter((element) => element.quantityReceived > 0)
      .forEach((element: IQuantityReceived) => {
        const fetchedMaterial = getMaterialFromName(element.materialName);

        if (fetchedMaterial) {
          let materialId = fetchedMaterial.id;
          const convertedQuantity = isUnitGmsOrMl(fetchedMaterial.unitOfMeasure)
            ? element.quantityReceived * 1000
            : element.quantityReceived;
          materialIdToQuantityMap[materialId] = convertedQuantity;
        }
      });

    const isValid = Object.values(materialIdToQuantityMap).length > 0;

    if (!isValid) {
      setShowToast({
        open: true,
        message:
          "At least one material with quantity greater than 0 is required",
        type: "error",
      });
      return;
    }

    const updatePoRequest: IPurchaseOrderPost = {
      deliveryAddress: data.deliveryAddress,
      preferredDeliveryDate: data.preferredDeliveryDate,
      materialIdToQuantityMap: materialIdToQuantityMap,
    };

    mutate(updatePoRequest);
  };

  const onApprove = (purchaseOrderId: string) => {
    mutateForApprovingPo(purchaseOrderId);
  };

  const materialContextValues = useContext(MaterialContext);
  let allBranchesRawMaterialsSet = new Set<IMaterial>();

  Array.from(materialContextValues.branchWiseMaterialsMap.values()).map(
    (value: IMaterialContextInterface) => {
      const rawMaterials = value.allMaterialsArr.filter(
        (material) => material.type === MaterialType.RAW
      );
      allBranchesRawMaterialsSet = new Set([
        ...allBranchesRawMaterialsSet,
        ...rawMaterials,
      ]);
      return null;
    }
  );

  const getMaterialFromName = (materialName: string): IMaterial | undefined => {
    let material: IMaterial | undefined = undefined;
    Array.from(allBranchesRawMaterialsSet).map((value: IMaterial) => {
      if (value.name === materialName) {
        material = value;
        return null;
      }
      return null;
    });
    return material;
  };

  const [mutate] = useMutation(
    (request: IPurchaseOrderPost) => {
      setIsUpdating(true);
      return updatePurchaseOrder(purchaseOrderId ?? "", request);
    },
    {
      onSuccess: (error) => {
        setIsUpdating(false);
        if (!error) {
          invalidatePurchaseOrdersCache();
          setShowSuccessDialog({
            message: "Purchase order updated successfully",
            show: true,
          });
        } else {
          setShowToast({
            open: true,
            message: error.message ?? "Something went wrong!",
            type: "error",
          });
        }
      },
    }
  );

  const [mutateForApprovingPo] = useMutation(
    (purchaseOrderId: string) => {
      setIsApproving(true);
      return approvePurchaseOrder(purchaseOrderId);
    },
    {
      onSuccess: (error) => {
        setIsApproving(false);
        if (!error) {
          setShowSuccessDialog({
            message: "Purchase order approved successfully",
            show: true,
          });
        } else {
          const message = error.message ?? "Something went wrong!";
          setShowToast({
            open: true,
            message: message,
            type: "error",
          });
        }
      },
    }
  );

  const onDeletePurchaseOrder = async (purchaseOrderId: string) => {
    setIsDeleting(true);
    const error = await deletePurchaseOrder(purchaseOrderId);

    setIsDeleting(false);
    setShowDeleteConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Deleted successfully",
        type: "success",
      });
      invalidatePurchaseOrdersCache();
      history.goBack();
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  if (purchaseOrder) {
    if (
      isPurchaseOrderStatusGreaterOrEqualToStatus(
        purchaseOrder.purchaseOrderStatus,
        PurchaseOrderStatus.ORDERED
      )
    ) {
      return (
        <Redirect
          to={{ pathname: `/purchase-orders/${purchaseOrder.id}/grn` }}
        />
      );
    }
  }

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        buttonText={"Delete"}
        bodyText={`Are you sure you want to delete this Purchase order?`}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onApprove={() => onDeletePurchaseOrder(purchaseOrderId)}
        isApproving={isDeleting}
      />
      <ConfirmationDialog
        buttonText={"Okay"}
        bodyText={showSuccessDialog.message}
        open={showSuccessDialog.show}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />

      {isFetching ? (
        <div className={styles.loading}>
          <Loading isLoading={isFetching} />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div style={{ display: "flex" }}>
              <Button
                className={styles.backButton}
                onClick={() => {
                  history.goBack();
                }}
              >
                <KeyboardBackspaceIcon style={{ marginRight: "10px" }} />{" "}
                {"Back"}
              </Button>
              <div
                className={styles.pageName}
              >{`Purchase Order - ${purchaseOrder?.branch.name}`}</div>
              {!editInProgress && (
                <div
                  className={
                    Auth.getInstance().isAdminOrSupervisor() ||
                      Auth.getInstance().isManager()
                      ? styles.modifyButtons
                      : styles.hidden
                  }
                >
                  {purchaseOrder &&
                    Auth.getInstance().isManager() &&
                    purchaseOrder.purchaseOrderStatus ===
                    PurchaseOrderStatus.PENDING && (
                      <Button
                        className={styles.modifyButton}
                        onClick={() => {
                          handleEdit();
                        }}
                        startIcon={<Edit />}
                      >
                        <Loading
                          text={"Edit Purchase Order"}
                          isLoading={false}
                        />
                      </Button>
                    )}

                  {purchaseOrder &&
                    Auth.getInstance().isAdminOrSupervisor() &&
                    !isPurchaseOrderStatusGreaterOrEqualToStatus(
                      purchaseOrder.purchaseOrderStatus,
                      PurchaseOrderStatus.ORDERED
                    ) && (
                      <>
                        <Button
                          className={styles.modifyButton}
                          onClick={() => {
                            handleEdit();
                          }}
                          startIcon={<Edit />}
                        >
                          <Loading
                            text={"Edit Purchase Order"}
                            isLoading={false}
                          />
                        </Button>
                        <Button
                          className={styles.modifyButton}
                          onClick={() => setShowDeleteConfirmation(true)}
                          startIcon={<DeleteOutline />}
                        >
                          <Loading
                            text={"Delete Purchase Order"}
                            isLoading={isDeleting}
                          />
                        </Button>
                      </>
                    )}
                  {purchaseOrder &&
                    Auth.getInstance().isAdminOrSupervisor() &&
                    purchaseOrder.purchaseOrderStatus ===
                    PurchaseOrderStatus.PENDING && (
                      <Button
                        className={styles.generateGrnButton}
                        style={{
                          backgroundColor: primaryColor,
                          color: primaryTextColor,
                          fontWeight: "bold",
                        }}
                        onClick={() => {
                          onApprove(purchaseOrderId);
                        }}
                        disabled={isApproving}
                      >
                        <Loading text={"Approve"} isLoading={isApproving} />
                      </Button>
                    )}
                </div>
              )}
              {editInProgress && (
                <div className={styles.modifyButtons}>
                  <Button
                    className={styles.modifyButton}
                    onClick={() => {
                      handleCancelEdit();
                    }}
                  >
                    <Loading text={"Cancel Edit"} isLoading={false} />
                  </Button>
                  <Button
                    className={styles.generateGrnButton}
                    style={{
                      backgroundColor: primaryColor,
                      color: primaryTextColor,
                      fontWeight: "bold",
                    }}
                    onClick={handleSubmit(onUpdate)}
                    disabled={isUpdating}
                  >
                    <Loading text={"Update"} isLoading={isUpdating} />
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.content}>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Supplier"}</div>
                <div className={styles.headSubText}>
                  {purchaseOrder?.supplierView.name}
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Billing Address"}</div>
                <div className={styles.headSubText}>
                  {purchaseOrder?.branch.billingAddress}
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Delivery Address"}</div>
                <div className={styles.headSubText}>
                  {editInProgress &&
                    purchaseOrder !== undefined &&
                    purchaseOrder.branch.deliveryAddresses !== undefined &&
                    purchaseOrder?.branch.deliveryAddresses?.length > 1 ? (
                    <>
                      <Controller
                        control={control}
                        name={"deliveryAddress"}
                        defaultValue={
                          purchaseOrder &&
                          purchaseOrder.branch &&
                          purchaseOrder.branch.deliveryAddresses &&
                          purchaseOrder.deliveryAddress
                        }
                        rules={{
                          required: true,
                          maxLength: 255,
                        }}
                        render={({ onChange }) => (
                          <Autocomplete
                            options={
                              purchaseOrder?.branch?.deliveryAddresses ?? []
                            }
                            getOptionLabel={(option) => option}
                            onChange={(event, value) => {
                              onChange(value);
                            }}
                            defaultValue={
                              purchaseOrder &&
                              purchaseOrder.branch &&
                              purchaseOrder.branch.deliveryAddresses &&
                              purchaseOrder.deliveryAddress
                            }
                            fullWidth
                            classes={{
                              root: styles.autocompleteRoot,
                              input: styles.autocomplete,
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={styles.inputField}
                                variant="outlined"
                                error={errors.deliveryAddress !== undefined}
                                placeholder={"Select Delivery Address"}
                              />
                            )}
                          />
                        )}
                      />
                    </>
                  ) : (
                    purchaseOrder?.deliveryAddress ?? "-"
                  )}
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>
                  {"Date: "}
                  <span className={styles.headDate}>
                    {moment(purchaseOrder?.creationTime).format("DD-MM-YYYY")}
                  </span>
                </div>
                <div className={styles.headSubHead}>
                  {"P.O. #: "}
                  <span className={styles.headDate}>
                    {purchaseOrder?.vanityNumber ?? "-"}
                  </span>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>
                  {"Preferred Delivery Date"}
                </div>
                <div className={styles.headSubText}>
                  {editInProgress ? (
                    <>
                      <Controller
                        control={control}
                        name={"preferredDeliveryDate"}
                        defaultValue={
                          purchaseOrder?.preferredDeliveryDate
                            ? new Date(purchaseOrder?.preferredDeliveryDate)
                            : null
                        }
                        render={({ onChange }) => (
                          <DatePicker
                            views={["date"]}
                            autoOk={true}
                            emptyLabel={"Select Date"}
                            onChange={(date) => {
                              onChange(date);
                            }}
                            value={
                              purchaseOrder?.preferredDeliveryDate ===
                                undefined &&
                                getValues("preferredDeliveryDate") === undefined
                                ? null
                                : getValues("preferredDeliveryDate")
                            }
                            variant={"inline"}
                            disablePast
                            inputVariant="outlined"
                            format="MMM dd"
                            fullWidth
                            InputProps={{
                              className: styles.datePicker,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <CalendarIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </>
                  ) : (
                    <>
                      {purchaseOrder?.preferredDeliveryDate
                        ? moment(purchaseOrder?.preferredDeliveryDate).format(
                          "DD-MM-YYYY"
                        )
                        : "-"}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.orderDetails}>
            <div className={styles.orderDetailsHeader}>
              <div className={styles.cardHeadText}>{"Order Details"}</div>
            </div>
          </div>
          <div className={styles.orderDetailColumns}>
            <div>{"Item Name"}</div>
            <div>{"Quantity Ordered"}</div>
            <div>{"Existing Stock"}</div>
          </div>
          <div className={styles.materials}>
            {purchaseOrder?.purchaseOrderItems.map(
              (poItem: PurchaseOrderItem, index: number) => {
                return (
                  <RowComponent
                    key={index}
                    index={index}
                    status={purchaseOrder.purchaseOrderStatus}
                    poItem={poItem}
                    editable={editInProgress}
                    control={control}
                    getValues={getValues}
                  />
                );
              }
            )}
          </div>
        </>
      )}
    </>
  );
});

export default ViewPurchaseOrder;

import moment from "moment";
import { IBranch } from "types/Branch";
import { IBranchWiseMaterialPrediction } from "types/BranchWiseMaterialPrediction";
import { IDatedBranchWiseMaterialPrediction } from "types/DatedBranchWiseMaterialPrediction";
import { IDisplayQuantity, addDisplayQuantity } from "types/MeasurementUnit";

export interface IShiftMaterialPrediction {
  materialName: string;
  morningShiftOneQuantity?: IDisplayQuantity,
  morningShiftTwoQuantity?: IDisplayQuantity,
  eveningShiftOneQuantity?: IDisplayQuantity,
  eveningShiftTwoQuantity?: IDisplayQuantity,
  totalMorningQuantity?: IDisplayQuantity;
  totalEveningQuantity?: IDisplayQuantity;
  totalFullDayQuantity?: IDisplayQuantity;
}

export interface IDatedMaterialPrediction {
  date: string;
  branchIdToshiftMaterialPredictionListMap: Map<String, IShiftMaterialPrediction[]>;
}

const shiftQuantityKey = ["morningShiftOneQuantity", "morningShiftTwoQuantity", "eveningShiftOneQuantity", "eveningShiftTwoQuantity"]

function updateBranchIdToshiftMaterialPredictionListMap(selectedBranchId: string, shiftType: string, datedMaterialPrediction: IDatedMaterialPrediction, shiftPredictionViewList: IBranchWiseMaterialPrediction[]) {
  for (let l = 0; l < shiftPredictionViewList.length; l++) {
    if (shiftPredictionViewList[l].branchId === selectedBranchId) {
      for (let m = 0; m < shiftPredictionViewList[l].materialPredictionViews.length; m++) {
        const materialPrediction = shiftPredictionViewList[l].materialPredictionViews[m];
        let updatedMaterialPredictionList = datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap.get(selectedBranchId);
        if (updatedMaterialPredictionList === undefined) {
          updatedMaterialPredictionList = [];
        }
        const abc = updatedMaterialPredictionList.find((o) => o.materialName === materialPrediction.materialName);
        if (abc !== undefined) {
          let index = updatedMaterialPredictionList.findIndex((obj) => obj.materialName === abc.materialName);
          updatedMaterialPredictionList[index][shiftType] = materialPrediction.expectedConsumptionDisplayQuantity;
          if (shiftType === "morningShiftOneQuantity" || shiftType === "morningShiftTwoQuantity") {
            if (updatedMaterialPredictionList[index].totalMorningQuantity) {
              updatedMaterialPredictionList[index].totalMorningQuantity = addDisplayQuantity(updatedMaterialPredictionList[index].totalMorningQuantity!, materialPrediction.expectedConsumptionDisplayQuantity);
            } else {
              updatedMaterialPredictionList[index].totalMorningQuantity = { ...materialPrediction.expectedConsumptionDisplayQuantity };
            }
          } else {
            if (updatedMaterialPredictionList[index].totalEveningQuantity) {
              updatedMaterialPredictionList[index].totalEveningQuantity = addDisplayQuantity(updatedMaterialPredictionList[index].totalEveningQuantity!, materialPrediction.expectedConsumptionDisplayQuantity);
            } else {
              updatedMaterialPredictionList[index].totalEveningQuantity = { ...materialPrediction.expectedConsumptionDisplayQuantity };
            }
          }
          if (updatedMaterialPredictionList[index].totalFullDayQuantity !== undefined) {
            updatedMaterialPredictionList[index].totalFullDayQuantity = addDisplayQuantity(updatedMaterialPredictionList[index].totalFullDayQuantity!, materialPrediction.expectedConsumptionDisplayQuantity);
          }
        } else {
          const shiftMaterialPrediction = {} as IShiftMaterialPrediction;
          shiftMaterialPrediction.materialName = materialPrediction.materialName;
          shiftMaterialPrediction[shiftType] = materialPrediction.expectedConsumptionDisplayQuantity;
          shiftMaterialPrediction.totalFullDayQuantity = { ...materialPrediction.expectedConsumptionDisplayQuantity };
          if (shiftType === "morningShiftOneQuantity" || shiftType === "morningShiftTwoQuantity") {
            shiftMaterialPrediction.totalMorningQuantity = { ...materialPrediction.expectedConsumptionDisplayQuantity };
          } else {
            shiftMaterialPrediction.totalEveningQuantity = { ...materialPrediction.expectedConsumptionDisplayQuantity };
          }
          updatedMaterialPredictionList?.push(shiftMaterialPrediction);
        }
        if (updatedMaterialPredictionList !== undefined) {
          datedMaterialPrediction.branchIdToshiftMaterialPredictionListMap.set(selectedBranchId, updatedMaterialPredictionList);
        }
      }
    }
  }
}

export function getSalesPredictionsForBranch(
  datedBranchWiseMaterialPredictionList: IDatedBranchWiseMaterialPrediction[],
  selectedBranch: IBranch
): IDatedMaterialPrediction[] {
  const datedMaterialPredictionListForBranch: IDatedMaterialPrediction[] = [];
  datedBranchWiseMaterialPredictionList.forEach((element) => {
    const datedMaterialPrediction = {
      branchIdToshiftMaterialPredictionListMap: new Map()
    } as IDatedMaterialPrediction;
    updateBranchIdToshiftMaterialPredictionListMap(selectedBranch.id, shiftQuantityKey[0], datedMaterialPrediction, element.morningShiftOnePredictionView)
    updateBranchIdToshiftMaterialPredictionListMap(selectedBranch.id, shiftQuantityKey[1], datedMaterialPrediction, element.morningShiftTwoPredictionView)
    updateBranchIdToshiftMaterialPredictionListMap(selectedBranch.id, shiftQuantityKey[2], datedMaterialPrediction, element.eveningShiftOnePredictionView)
    updateBranchIdToshiftMaterialPredictionListMap(selectedBranch.id, shiftQuantityKey[3], datedMaterialPrediction, element.eveningShiftTwoPredictionView)
    datedMaterialPrediction.date = moment(element.date).format("DD-MMM-YYYY");
    datedMaterialPredictionListForBranch.push(datedMaterialPrediction);
  });
  return datedMaterialPredictionListForBranch;
}

export function getDateArrayBetweenTwoDates(
  startDate: Date,
  endDate: Date
): string[] {
  const dates: string[] = [];
  let currentDate: Date = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );
  while (currentDate <= endDate) {
    const date = moment(currentDate);
    dates.push(date.format("DD-MMM-YYYY"));
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
  }
  return dates;
}

import React, { useState, useCallback, useContext } from "react";
import styles from "./Login.module.css";
import "react-phone-number-input/style.css";
import PhoneInput, { Country } from "react-phone-number-input";
import { Button, FormHelperText } from "@material-ui/core";
import { validatePhone } from "./Validate";
import { OtpInput } from "components/otpInput/OtpInput";
import PhoneNumber from "components/phoneNumber/PhoneNumber";
import { Redirect } from "react-router-dom";
import { Auth } from "lib/Auth";
import { getSettings } from "api/GetSettings";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { Config } from "lib/Config";
import { FMSError } from "types/Error";

export const Login: React.FC = () => {
  const [isLoggedIn, setLoggedIn] = useState(
    !(Auth.getInstance().getUser() === undefined)
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [loginButtonClicked, setLoginButtonClicked] = useState(false);
  const [otpCode, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const supportedCountries = Config.getInstance()
    .getSupportedCountries()
    .map((country) => {
      return country.countryCode as Country;
    });

  const validate = useCallback(
    async (phone) => {
      if (nextButtonClicked) return;
      setNextButtonClicked(true);
      const error = await validatePhone(phone);
      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("");
        setOtpSent(true);
      }
      setNextButtonClicked(false);
    },
    [nextButtonClicked, setNextButtonClicked, setOtpSent]
  );

  const login = useCallback(
    async (otpCode) => {
      if (loginButtonClicked) {
        return;
      }

      setLoginButtonClicked(true);
      const error = await Auth.getInstance().verifyOtp(otpCode);
      if (error) {
        setErrorMessage(error.message);
      } else {
        const employeeOrError = await getSettings();
        if (employeeOrError instanceof FMSError) {
          setErrorMessage(employeeOrError.message);
        } else {
          setLoggedIn(true);
        }
      }
      setLoginButtonClicked(false);
    },
    [loginButtonClicked]
  );

  const { primaryColor, primaryTextColor, logoDark } =
    useContext(FMSThemeContext);

  if (isLoggedIn) {
    return <Redirect to={"/"} />;
  }

  return (
    <div
      className={styles.root}
      style={{
        background: `linear-gradient(355deg, #f6f6f6 55%, ${primaryColor} 55%)`,
      }}
    >
      <div className={styles.loginDiv}>
        <img src={logoDark} alt="logo" className={styles.logo} />
        <form
          className={styles.form}
          onSubmit={(event) => {
            event.preventDefault();
            validate(phoneNumber);
          }}
        >
          {!otpSent && (
            <>
              <div className={styles.mainHeader}> {"Login"} </div>
              <div className={styles.subHeader}>
                {" "}
                {"Welcome! Please login to continue."}{" "}
              </div>
              <PhoneInput
                countries={supportedCountries}
                addInternationalOption={false}
                placeholder="Phone number"
                value={phoneNumber}
                onChange={(val: string) => {
                  setPhoneNumber(val);
                  setErrorMessage("");
                }}
                inputComponent={PhoneNumber}
                autoFocus={true}
              />
              <FormHelperText error={true}>{` ${errorMessage}`}</FormHelperText>
              <Button
                id={"validate"}
                classes={{ root: styles.validate }}
                style={{
                  backgroundColor: primaryColor,
                  color: primaryTextColor,
                }}
                onClick={() => validate(phoneNumber)}
              >
                <Loading text="Next" isLoading={nextButtonClicked} />
              </Button>
            </>
          )}
          {otpSent && (
            <>
              <div className={styles.mainHeader}> {"Enter OTP"} </div>
              <div className={styles.subHeader}>
                {" "}
                {`OTP has been sent on your number ${phoneNumber}`}{" "}
              </div>
              <OtpInput
                setOtp={setOtp}
                onEnterPressed={() => {
                  login(otpCode);
                }}
              />
              <FormHelperText error={true}>{` ${errorMessage}`}</FormHelperText>
              <Button
                id={"validate"}
                classes={{ root: styles.validate }}
                style={{
                  backgroundColor: primaryColor,
                  color: primaryTextColor,
                }}
                onClick={() => login(otpCode)}
              >
                <Loading text="Login" isLoading={loginButtonClicked} />
              </Button>
            </>
          )}
        </form>
      </div>
    </div>
  );
};

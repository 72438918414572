import React, {
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { IMaterial, IngredientJson } from "types/Material";
import styles from "./ReceipeModal.module.css";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Button, Dialog, IconButton, Collapse } from "@material-ui/core";
import classNames from "classnames";
import { objToStrMap } from "lib/MapHelpers";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { UpdateRecipeModal } from "components/updateRecipeModal/UpdateRecipeModal";
import { displayQuantity } from "types/MeasurementUnit";
import { Loading } from "components/loading/Loading";
import { getMaterial } from "api/GetMaterials";
import { FMSError } from "types/Error";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import AddIcon from "assets/AddIcon";
import SubtractIcon from "assets/SubtractIcon";
import EditIcon from "assets/EditIcon";

interface IProps {
  receipe: IMaterial;
  materialContextValues: IMaterialContextInterface;
  allowEdit: boolean;
  onClose: (successMessage?: string) => void;
}

interface IIngredientsProps {
  ingredients: IngredientJson;
}

interface IIngredientProps {
  ingredient: IMaterial;
  level: number;
  index: number;
  children?: ReactNode;
}

interface ICollapsibleDiv {
  level: number;
  rootClassName: string;
  detailClassName: string;
  nameClassName: string;
  qtyClassName: string;
  ingredient: IMaterial;
  children: ReactNode;
  index: number;
}

const CollapsibleDiv: React.FC<ICollapsibleDiv> = (props) => {
  const [divOpen, setDivOpen] = useState(false);
  const {
    index,
    level,
    rootClassName,
    detailClassName,
    nameClassName,
    qtyClassName,
    ingredient,
  } = props;
  const showDotted = level === 1 && !!props.children;

  return (
    <div className={rootClassName}>
      <div
        className={classNames(
          level === 0 ? detailClassName : "",
          styles.detail
        )}
      >
        <div
          className={classNames(nameClassName, !showDotted ? styles.grow : "")}
          style={{ marginLeft: 32 * level + 11 }}
        >
          {level === 0 && (
            <span className={styles.index}>{`${index + 1}.`}</span>
          )}{" "}
          {ingredient.name}
        </div>
        {showDotted && (
          <div className={classNames(styles.line, styles.grow)}></div>
        )}

        <div className={styles.right}>
          <div className={qtyClassName}>
            {displayQuantity(ingredient.displayQuantity)}
          </div>
          <div className={styles.toggleButton}>
            {props.children && (
              <Button onClick={() => setDivOpen(!divOpen)}>
                {divOpen ? <SubtractIcon /> : <AddIcon />}
              </Button>
            )}
          </div>
        </div>
      </div>
      {props.children && (
        <div className={styles.innerDiv}>
          <Collapse in={divOpen} timeout="auto" unmountOnExit>
            {props.children}
          </Collapse>
        </div>
      )}
    </div>
  );
};

const Ingredient: React.FC<IIngredientProps> = ({
  ingredient,
  level = 0,
  children,
  index,
}) => {
  return (
    <CollapsibleDiv
      level={level}
      rootClassName={styles.levelZero}
      nameClassName={classNames(
        styles.levelZeroName,
        styles.levelZeroText,
        level > 1 ? styles.levelTwoOrGreater : ""
      )}
      detailClassName={styles.levelZeroDetail}
      qtyClassName={classNames(
        styles.levelZeroText,
        level > 1 ? styles.levelTwoOrGreater : ""
      )}
      ingredient={ingredient}
      index={index}
    >
      {children}
    </CollapsibleDiv>
  );
};

const getIngredientsView = (ingredients: IMaterial[], level: number) => {
  const children: ReactNode[] = [];
  const sortedIngredients = ingredients.sort((mat1, mat2) =>
    mat1.name.localeCompare(mat2.name)
  );
  sortedIngredients.forEach((ingredient, index) => {
    if (ingredient.ingredients) {
      const ingredientMap = objToStrMap<IMaterial>(ingredient.ingredients);
      const previousChildren = getIngredientsView(
        [...ingredientMap.values()],
        level + 1
      );
      children.push(
        <Ingredient
          ingredient={ingredient}
          key={index}
          level={level}
          index={index}
        >
          {previousChildren}
        </Ingredient>
      );
    } else {
      children.push(
        <Ingredient
          ingredient={ingredient}
          key={index}
          level={level}
          index={index}
        />
      );
    }
  });
  return children;
};

const Ingredients: React.FC<IIngredientsProps> = (props) => {
  const { ingredients } = props;

  if (!ingredients) {
    return <></>;
  }
  const ingredientMap = objToStrMap<IMaterial>(ingredients);
  const children = getIngredientsView([...ingredientMap.values()], 0);
  return <>{children}</>;
};

export const ReceipeModal: React.FC<IProps> = (props) => {
  const [showModal, setShowModal] = useState(true);
  const [edit, setEdit] = useState(false);
  const { receipe, materialContextValues, allowEdit, onClose } = props;

  const [expandedRecipe, setExpandedRecipe] = useState<IMaterial>(receipe);
  const [isLoading, setIsLoading] = useState(true);

  const fetchRecipeDetails = useCallback(async () => {
    setIsLoading(true);
    const result = await getMaterial(receipe.id);
    if (result instanceof FMSError) {
      // TODO: Error handling
      return;
    }

    setExpandedRecipe(result);
    setIsLoading(false);
  }, [setIsLoading, setExpandedRecipe, receipe]);

  useEffect(() => {
    fetchRecipeDetails();
  }, [fetchRecipeDetails]);

  const hideModal = (successMessage?: string) => {
    setShowModal(false);
    onClose(successMessage);
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);

  return (
    <>
      <Dialog
        className={styles.modalRoot}
        open={showModal}
        maxWidth={"md"}
        onClose={(_, reason) => {
          if (reason === "backdropClick") {
            return;
          }
          hideModal();
        }}
        scroll={"body"}
      >
        <>
          {edit && (
            <>
              <UpdateRecipeModal
                materialContextValues={materialContextValues}
                material={expandedRecipe}
                hideModal={hideModal}
              />
            </>
          )}
          {!edit && (
            <>
              <div className={styles.modal}>
                {isLoading && (
                  <div style={{ position: "relative", height: "662px" }}>
                    <div
                      style={{ position: "absolute", top: "45%", left: "45%" }}
                    >
                      <Loading isLoading={true} />
                    </div>
                  </div>
                )}
                {!isLoading && (
                  <>
                    <div
                      className={styles.modalTitleBox}
                      style={{ backgroundColor: primaryColor }}
                    >
                      {allowEdit && (
                        <div
                          className={styles.edit}
                          onClick={() => {
                            setEdit(true);
                          }}
                        >
                          <EditIcon />
                        </div>
                      )}
                      <div
                        className={styles.modalTitleText}
                        style={{ color: primaryTextColor }}
                      >
                        {expandedRecipe.name}
                        <div className={styles.quantity}>
                          {`${displayQuantity(expandedRecipe.displayQuantity)}`}
                        </div>
                      </div>
                      <div className={styles.modalCloseIcon}>
                        <IconButton
                          name="close"
                          color="inherit"
                          onClick={() => { hideModal() }}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      </div>
                    </div>
                    <div className={styles.content}>
                      <div className={styles.modalBody}>
                        <Ingredients ingredients={expandedRecipe.ingredients} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </Dialog>
    </>
  );
};

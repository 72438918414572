import React, { useContext, useEffect, useState } from "react";
import { IBranch } from "types/Branch";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import styles from "./ModifyStockModal.module.css";
import {
  Avatar,
  Dialog,
  IconButton,
  Button,
  Input,
  CircularProgress,
} from "@material-ui/core";
import { DataTable } from "components/dataTable/DataTable";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import moment from "moment";
import CreateIcon from "@material-ui/icons/Create";
import { useMutation } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import {
  displayNameForUnitOfMeasure,
  displayQuantity,
  IDisplayQuantity,
  MeasurementUnit,
} from "types/MeasurementUnit";
import { FMSError } from "types/Error";
import { useQueryStockEntries } from "hooks/Stock";
import { IStockEntryMaterialView } from "types/Stock";
import { displayNameForStockType, StockType } from "types/StockType";
import { updateStockEntry } from "api/PostStock";
import DeleteIcon from "assets/DeleteIcon";
import { deleteStock } from "api/DeleteStock";
import { Auth } from "lib/Auth";
import { isUnitGmsOrMl, isUnitKgsOrLtr } from "lib/MaterialHelper";
import { IMaterial } from "types/Material";

interface IProps {
  startDate: Date;
  endDate: Date;
  material: IMaterial;
  selectedBranch: IBranch;
  onClose: (isStockEntryUpdated: boolean) => void;
}

interface IPostStockEntry {
  id: string;
  quantity: number;
  unit: MeasurementUnit;
  type: StockType;
}

const isUserAuthorizedToModify = () => {
  const auth = Auth.getInstance();
  return auth.isAdminOrSupervisor();
};

const EditableQuantity = (props: {
  material: IMaterial;
  stockEntry: IStockEntryMaterialView;
  showToast: (props: IToastBasicProps) => void;
  quantityUpdated: (newQuantity: IDisplayQuantity) => void;
}) => {
  const { showToast, stockEntry, quantityUpdated, material } = props;
  const initialQuantity = stockEntry.displayQuantity;
  const [showEditView, setShowEditView] = useState(false);
  const [quantityObj, setQuantityObj] = useState(initialQuantity);
  const [isEditing, setIsEditing] = useState(false);

  const [mutateForUpdateStockEntry] = useMutation(
    (post: IPostStockEntry) => {
      const value: number = post.quantity;

      let shouldConvertToGmsOrMl = false;
      if (material.unitOfMeasure !== post.unit) {
        shouldConvertToGmsOrMl =
          isUnitGmsOrMl(material.unitOfMeasure) && isUnitKgsOrLtr(post.unit);
      }

      const convertedQuantity = shouldConvertToGmsOrMl ? value * 1000 : value;
      setIsEditing(true);
      return updateStockEntry(post.id, post.type, convertedQuantity);
    },
    {
      onSuccess: (error) => {
        setIsEditing(false);
        if (!error) {
          showToast({
            open: true,
            message: "Stock quantity updated successfully",
            type: "success",
          });
          initialQuantity.quantity = quantityObj.quantity;
          quantityUpdated(quantityObj);
          setShowEditView(false);
        } else {
          setQuantityObj(initialQuantity);
          const message = error.message ?? "Something went wrong!";
          showToast({
            open: true,
            message: message,
            type: "error",
          });
        }
      },
    }
  );

  return (
    <>
      {showEditView && (
        <>
          <Input
            type="number"
            className={styles.quantityText}
            value={quantityObj.quantity}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              const quantity = isNaN(value) ? 0 : value;
              setQuantityObj({
                unit: quantityObj.unit,
                quantity: quantity,
              });
            }}
          />
          <button
            onClick={async () => {
              if (quantityObj.quantity > 0) {
                if (quantityObj.quantity === initialQuantity.quantity) {
                  setShowEditView(false);
                } else {
                  mutateForUpdateStockEntry({
                    ...quantityObj,
                    id: stockEntry.id,
                    type: stockEntry.type,
                  });
                }
              } else {
                setQuantityObj(initialQuantity);
                showToast({
                  open: true,
                  message: "Enter a valid quantity (positive number)",
                  type: "error",
                });
              }
            }}
          >
            ✓
          </button>
          <button
            onClick={() => {
              setQuantityObj(initialQuantity);
              setShowEditView(false);
            }}
          >
            X
          </button>
          {"  " + displayNameForUnitOfMeasure(quantityObj.unit)}
          {isEditing && (
            <CircularProgress
              style={{
                marginLeft: 5,
              }}
              size={15}
              color={"inherit"}
              className={styles.loading}
            />
          )}
        </>
      )}

      {!showEditView && (
        <span
          onClick={() => {
            setShowEditView(true);
          }}
        >
          {displayQuantity(quantityObj)}
          {isUserAuthorizedToModify() && (
            <CreateIcon style={{ fontSize: 15 }} />
          )}
        </span>
      )}
    </>
  );
};

export const ModifyStockModal: React.FC<IProps> = (props) => {
  const { startDate, endDate, material, selectedBranch, onClose } = props;

  const [showModal, setShowModal] = useState(true);
  const [selectedStockEntry, setSelectedStockEntry] =
    useState<IStockEntryMaterialView>();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const { status, stockEntriesFetched, isFetching } = useQueryStockEntries(
    props.selectedBranch.id,
    material.id,
    startDate,
    endDate
  );
  const [stockEntries, setStockEntries] = useState<IStockEntryMaterialView[]>(
    []
  );

  useEffect(() => {
    if (status === "success") {
      if (!stockEntriesFetched || stockEntriesFetched instanceof FMSError) {
        const message = stockEntriesFetched?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        stockEntriesFetched.sort((a, b) => {
          if (a.date > b.date) {
            return -1;
          } else {
            return 1;
          }
        });
        setStockEntries(stockEntriesFetched);
      }
    }
  }, [status, stockEntriesFetched, props.selectedBranch]);

  const hideModal = (isStockEntryUpdated: boolean = false) => {
    setShowModal(false);
    onClose(isUpdated || isStockEntryUpdated);
  };

  const onDeleteStock = async (stockId: string, type: StockType) => {
    setIsDeleting(true);
    const error = await deleteStock(stockId, type);
    setIsDeleting(false);
    setShowDeleteConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Deleted successfully",
        type: "success",
      });

      const entries = stockEntries.filter((e: IStockEntryMaterialView) => {
        return e.id !== stockId;
      });

      if (entries.length === 0) {
        hideModal(true);
      } else {
        setStockEntries(entries);
        setIsUpdated(true);
      }
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const columns = React.useMemo(
    () => [
      {
        Header: "Stock Entry Table",
        columns: [
          {
            Header: "Added By",
            accessor: "employeeView.name",
            width: 200,
            disableFilters: true,
          },
          {
            Header: "Quantity",
            width: 200,
            Cell: (props: any) => {
              const stockEntry = props.row.original as IStockEntryMaterialView;
              return (
                <>
                  <EditableQuantity
                    material={material}
                    stockEntry={stockEntry}
                    showToast={setShowToast}
                    quantityUpdated={(newQuantity) => {
                      setIsUpdated(true);
                      stockEntry.displayQuantity = newQuantity;
                    }}
                  />
                </>
              );
            },
            disableFilters: true,
          },
          {
            Header: "Type",
            accessor: (row: IStockEntryMaterialView) =>
              `${displayNameForStockType(row.type)}`,
            width: 200,
            disableFilters: true,
          },
          {
            Header: "Date added",
            width: 200,
            Cell: (value: any) =>
              moment(value.row.original.date).format("DD/MM/YYYY"),
            disableFilters: true,
          },
          {
            Header: " ",
            accessor: "id",
            width: 50,
            Cell: (props: any) => {
              const stockEntry = props.row.original as IStockEntryMaterialView;
              if (isUserAuthorizedToModify()) {
                return (
                  <Avatar
                    className={styles.icon}
                    style={{ backgroundColor: primaryColor }}
                    onClick={() => {
                      setSelectedStockEntry(stockEntry);
                      setShowDeleteConfirmation(true);
                    }}
                  >
                    <DeleteIcon />
                  </Avatar>
                );
              } else {
                return <></>;
              }
            },
            disableFilters: true,
          },
        ],
      },
    ],
    [primaryColor, material]
  );

  const history = useHistory();
  const queryStr = useLocation().search;
  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <ConfirmationDialog
        buttonText={"Delete"}
        bodyText={`Do you want to delete ${selectedStockEntry?.materialName
          } of quantity ${displayQuantity(selectedStockEntry?.displayQuantity)}?`}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onApprove={() => {
          if (selectedStockEntry) {
            onDeleteStock(selectedStockEntry.id, selectedStockEntry.type);
          }
        }}
        isApproving={isDeleting}
      />
      <Dialog
        className={styles.modalRoot}
        open={showModal}
        maxWidth={"xl"}
        onClose={() => hideModal()}
        scroll={"body"}
        disableBackdropClick
      >
        <div
          className={styles.modalTitleBox}
          style={{
            backgroundColor: primaryColor,
            color: primaryTextColor,
          }}
        >
          <div className={styles.date}>
            <div style={{ height: "20px" }}>
              From:&nbsp;{moment(startDate).format("DD/MM/YYYY")}
            </div>
            <div style={{ height: "20px" }}>
              To:&nbsp;{moment(endDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className={styles.modalTitleText}>
            <div className={styles.branch}>{selectedBranch.name}</div>
            {material.name}
            <div className={styles.text}>Individual Stock Entries</div>
          </div>
          <div className={styles.modalCloseIcon}>
            <IconButton
              name="close"
              color="inherit"
              onClick={() => hideModal()}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.toGraphBtn}>
          <Button
            className={styles.submitButton}
            style={{
              marginLeft: "10px",
              color: primaryTextColor,
              backgroundColor: primaryColor,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `/stock-stats/${material.id}`,
                search: queryStr,
              });
            }}
          >
            View Graphs
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={stockEntries}
          isLoading={isFetching}
          tableHeight={350}
        />
      </Dialog>
    </>
  );
};

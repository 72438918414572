import { BACKEND_URL_V1, deletee } from "lib/Api";
import { FMSError } from "types/Error";

export const deletePurchaseOrder = async (
  purchaseOrderId: string
): Promise<FMSError | null> => {
  try {
    const response = await deletee(
      `${BACKEND_URL_V1}/purchaseOrders/${purchaseOrderId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
  } catch (err) {
    return new FMSError("Something went wrong");
  }
  return new FMSError("Something went wrong");
};

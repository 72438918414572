import { today } from "lib/Date";
import { IEmployee } from "./Employee";

export interface IEventsResponse {
  events: IEvent[];
  overdueEventViews: IEvent[];
}

export interface IEventCategoriesView {
  eventCategoriesView: IEventCategories;
}

export interface IEventCategories {
  id: string;
  businessUnits: string[];
  types: string[];
  items: string[];
}

export interface IEvent {
  date: Date;
  id: string;
  status: EventStatus;
  frequency: EventFrequency;
  namespaceId: string;
  createdByEmployee: IEmployee;
  creationTimestamp: Date;
  businessUnit: string;
  type: string;
  item: string;
  ownerViews: IEmployee[];
  parentPeriodicEventId?: string;
  parentPeriodicEventEndDate?: Date;
  targetEmployeesForReminders?: IEmployee[];
  remindersOn?: EventReminderFrequency[];
  paymentMethod?: PaymentMethod;
  amount?: number;
  remark?: string;
  comment?: string;
  uploadedDocumentUris?: string[];
  completedByEmployeeView?: IEmployee;
  completionTimestamp?: Date;
  reviewedByEmployeeView?: IEmployee;
  reviewedTimestamp?: Date;

  derivedStatus: DerivedEventStatus;
}

export interface IEventPost {
  businessUnit: string;
  type: string;
  item: string;
  ownerIds: string[];
  remark: string;
  paymentMethod?: PaymentMethod;
  amount?: number;
  sendReminderToUserIds: string[];
  eventFrequency: EventFrequency;
  startDate: Date;
  endDate: Date;
}

export interface IUpdateEventStatusPost {
  status: EventStatus;
  comment?: string;
  paymentMethod?: PaymentMethod;
  uploadedDocumentUris?: string[];
}

export enum EventStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  REVIEWED = "REVIEWED",
}

export enum DerivedEventStatus {
  PENDING = "PENDING",
  COMPLETE = "COMPLETE",
  REVIEWED = "REVIEWED",
  OVERDUE = "OVERDUE",
}

export const derivedEventStatusFromEventStatus = (
  eventStatus: EventStatus,
  eventDate: Date
) => {
  switch (eventStatus) {
    case EventStatus.PENDING:
      if (eventDate < today()) {
        return DerivedEventStatus.OVERDUE;
      }
      return DerivedEventStatus.PENDING;
    case EventStatus.COMPLETE:
      return DerivedEventStatus.COMPLETE;
    case EventStatus.REVIEWED:
      return DerivedEventStatus.REVIEWED;
    default:
      throw new Error("Unimplemented case " + eventStatus);
  }
};

export const displayNameForDerivedEventStatus = (
  eventStatus: DerivedEventStatus
) => {
  switch (eventStatus) {
    case DerivedEventStatus.OVERDUE:
      return "Overdue";
    case DerivedEventStatus.PENDING:
      return "Pending";
    case DerivedEventStatus.COMPLETE:
      return "Complete";
    case DerivedEventStatus.REVIEWED:
      return "Reviewed";
    default:
      throw new Error("Unimplemented case " + eventStatus);
  }
};

export enum EventReminderFrequency {
  ON_THE_DAY = "ON_THE_DAY",
  TWO_DAYS_BEFORE = "TWO_DAYS_BEFORE",
  ONE_WEEK_BEFORE = "ONE_WEEK_BEFORE",
  ONE_MONTH_BEFORE = "ONE_MONTH_BEFORE",
}

export const displayNameForEventReminderFrequency = (
  eventReminderFrequency?: EventReminderFrequency
) => {
  if (!eventReminderFrequency) {
    return "";
  }

  switch (eventReminderFrequency) {
    case EventReminderFrequency.ON_THE_DAY:
      return "On the day of event";
    case EventReminderFrequency.TWO_DAYS_BEFORE:
      return "2 days before event";
    case EventReminderFrequency.ONE_WEEK_BEFORE:
      return "1 week before event";
    case EventReminderFrequency.ONE_MONTH_BEFORE:
      return "1 month before event";

    default:
      throw new Error("Unimplemented case " + eventReminderFrequency);
  }
};

export enum PaymentMethod {
  ONLINE = "ONLINE",
  CHEQUE = "CHEQUE",
  CASH = "CASH",
}

export const displayNameForPaymentMethod = (paymentMethod?: PaymentMethod) => {
  if (!paymentMethod) {
    return "";
  }

  switch (paymentMethod) {
    case PaymentMethod.CASH:
      return "Cash";
    case PaymentMethod.CHEQUE:
      return "Cheque";
    case PaymentMethod.ONLINE:
      return "Online";
    default:
      throw new Error("Unimplemented case " + paymentMethod);
  }
};

export enum EventFrequency {
  ONE_TIME = "ONE_TIME",
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  HALF_YEARLY = "HALF_YEARLY",
  YEARLY = "YEARLY",
}

export const displayNameForEventFrequency = (
  eventFrequency?: EventFrequency
) => {
  if (!eventFrequency) {
    return "";
  }

  switch (eventFrequency) {
    case EventFrequency.ONE_TIME:
      return "One Time";
    case EventFrequency.DAILY:
      return "Daily";
    case EventFrequency.WEEKLY:
      return "Weekly";
    case EventFrequency.MONTHLY:
      return "Monthly";
    case EventFrequency.QUARTERLY:
      return "Every 3 months";
    case EventFrequency.HALF_YEARLY:
      return "Every 6 months";
    case EventFrequency.YEARLY:
      return "Yearly";
    default:
      throw new Error("Unimplemented case " + eventFrequency);
  }
};

export enum EventCategoryType {
  BUSINESS_UNIT = "Business Unit Master",
  ITEMS = "Item Master",
  TYPES = "Type Master",
}

export const displayNameForEventCategoryType = (
  eventCategoryType?: EventCategoryType
) => {
  if (!eventCategoryType) {
    return "";
  }

  switch (eventCategoryType) {
    case EventCategoryType.BUSINESS_UNIT:
      return "Business Unit";
    case EventCategoryType.ITEMS:
      return "Item";
    case EventCategoryType.TYPES:
      return "Type";
  }
};

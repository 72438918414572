import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import format from "date-fns/format";
import { getComplimentaryFood } from "api/GetComplimentaryFood";
import { IComplimentaryFoodForAllBranches } from "types/ComplimentaryFood";

interface IQueryComplimentaryFoods {
  status: QueryStatus;
  complimentaryFoods: IComplimentaryFoodForAllBranches[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryComplimentaryFoods = (
  startDate: Date,
  endDate: Date
): IQueryComplimentaryFoods => {
  const start = format(startDate, "yyyy-MM-dd");
  const end = format(endDate, "yyyy-MM-dd");
  const { status, data: complimentaryFoods, isFetching } = useQuery(
    ["complimentaryFoods", start, end],
    async (key: string, start: string, end: string) => {
      return await getComplimentaryFood(start, end);
    }
  );
  return { status, complimentaryFoods, isFetching };
};

export const invalidateComplimentaryFoodCache = () => {
  queryCache.invalidateQueries("complimentaryFoods");
};

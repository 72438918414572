import React, { useEffect, useState } from "react";
import styles from "./StockTransferTable.module.css";
import { IBranch } from "types/Branch";
import { DataTableWithDates } from "components/dataTableWithDates/DataTableWithDates";
import { IStockTransfer, IStockTransfersByBranch } from "types/StockTransfer";
import {
  useQueryStockTransfers,
  invalidateStockTransferCache,
} from "hooks/StockTransfers";
import {
  approveStockTransfer,
  commentOnStockTransfer,
} from "api/PostStockTransfer";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { ApprovalDialogWithComment } from "components/approvalDialogWithComment/ApprovalDialogWithComment";
import { StatusWithComments } from "./StatusWithComments";
import { FMSError } from "types/Error";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import { Status } from "types/Status";
import { getPendingStockTransfers } from "api/GetStockTransfers";
import moment from "moment";
import CSVDownload from "components/utils/CSVDownload";
import { getStockTransferDataForExport } from "lib/CSVExportUtils";

export const stockTransferForBranch = (
  allStockTransfers: IStockTransfersByBranch[],
  selectedBranch: IBranch
): IStockTransfer[] => {
  let stockTransfers: IStockTransfer[] = [];
  for (let i = 0; i < allStockTransfers.length; i++) {
    if (allStockTransfers[i].branchId === selectedBranch.id) {
      stockTransfers = allStockTransfers[i].stockTransfers;
      break;
    }
  }

  for (let i = 0; i < stockTransfers.length; i++) {
    const stockTransfer = stockTransfers[i];
    if (stockTransfer.senderBranchId === selectedBranch.id) {
      stockTransfer.inOut = "Outward";
      stockTransfer.toFrom = stockTransfer.receiverBranchName;
    } else {
      stockTransfer.inOut = "Inward";
      stockTransfer.toFrom = stockTransfer.senderBranchName;
    }
  }

  return stockTransfers;
};

export const StockTransferTable: React.FC<{ selectedBranch: IBranch }> = (
  props
) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();
  const { status, stockTransfers, isFetching } = useQueryStockTransfers(
    start,
    end
  );
  const [stockTransfer, setStockTransfers] = useState<IStockTransfer[]>([]);
  useEffect(() => {
    if (status === "success") {
      if (!stockTransfers || stockTransfers instanceof FMSError) {
        const message = stockTransfers?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setStockTransfers(
          stockTransferForBranch(stockTransfers, props.selectedBranch)
        );
      }
    }
  }, [status, stockTransfers, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const [showApprove, setShowApprove] = useState(false);
  const [transferIdUnderApproval, setTransferIdUnderApproval] = useState<
    string | null
  >(null);
  const openApprove = (stockTransferId: string) => {
    setShowApprove(true);
    setTransferIdUnderApproval(stockTransferId);
  };

  const [isApproving, setApproving] = useState(false);
  const onApprove = async (stockTransferId: string) => {
    setApproving(true);
    const error = await approveStockTransfer(stockTransferId);
    setApproving(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }
    setShowApprove(false);
    setShowToast({
      open: true,
      message: "The item has been approved",
      type: "success",
    });

    if (showPending) {
      // this will refresh the pending stock list
      handleShowPendingButtonClicked(true);
    }

    invalidateStockTransferCache();
  };

  const [isCommenting, setCommenting] = useState(false);
  const onComment = async (stockTransferId: string, comment: string) => {
    setCommenting(true);
    const error = await commentOnStockTransfer(stockTransferId, comment);
    setCommenting(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }
    setShowApprove(false);
    setShowToast({
      open: true,
      message: "Comment added on stock transfer",
      type: "success",
    });

    if (showPending) {
      // this will refresh the pending stock list
      handleShowPendingButtonClicked(true);
    }

    invalidateStockTransferCache();
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.materialName.toUpperCase() >
                rowB.original.materialName.toUpperCase()
                ? 1
                : -1;
            },
            width: 270,
            disableFilters: true,
          },
          {
            Header: "Quantity",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.quantity > rowB.original.quantity ? 1 : -1;
            },
            accessor: (row: IStockTransfer) => {
              const unit = displayNameForUnitOfMeasure(
                row.displayQuantity.unit
              );
              return `${row.displayQuantity.quantity} ${unit}`;
            },
            width: 120,
            disableGlobalFilter: true,
            disableFilters: true,
          },
          {
            Header: "In / Out",
            accessor: "inOut",
            width: 120,
            disableGlobalFilter: true,
            disableFilters: true,
          },
          {
            Header: "To / From",
            accessor: "toFrom",
            width: 120,
            disableGlobalFilter: true,
            disableFilters: true,
          },
          {
            Header: "Date",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.requestInitiationDate >
                rowB.original.requestInitiationDate
                ? 1
                : -1;
            },
            accessor: (row: IStockTransfer) =>
              moment(row.requestInitiationDate).format("DD-MM-yyyy"),
            width: 120,
            disableGlobalFilter: true,
            disableFilters: true,
          },
          {
            Header: "Status",
            sortType: (rowA: any, rowB: any) => {
              const a = rowA.original;
              const b = rowB.original;
              if (a.status === b.status) {
                return a.materialName.toUpperCase() >
                  b.materialName.toUpperCase()
                  ? -1
                  : 1;
              }

              if (a.status === Status.PENDING) {
                return 1;
              }

              if (b.status === Status.PENDING) {
                return -1;
              }

              return 0;
            },
            accessor: "status",
            width: 140,
            disableGlobalFilter: true,
            disableFilters: true,
            Cell: (props: any) => {
              const stockTransfer = props.row.original as IStockTransfer;
              return (
                <StatusWithComments
                  stockTransder={stockTransfer}
                  openApprove={openApprove}
                  setShowToast={setShowToast}
                />
              );
            },
          },
        ],
      },
    ],
    []
  );

  const [showPending, setShowPending] = useState(false);
  const [pendingStockTransfers, setPendingStockTransfers] = useState<
    IStockTransfer[]
  >([]);
  const [pendingStatus, setPendingStatus] = useState<"loading" | "complete">();

  useEffect(() => {
    handleShowPendingButtonClicked(showPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBranch]);

  const handleShowPendingButtonClicked = async (showPending: boolean) => {
    setShowPending(showPending);
    if (showPending) {
      setPendingStatus("loading");
      const result = await getPendingStockTransfers();
      setPendingStatus("complete");
      if (result instanceof FMSError) {
        const message = result.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
        return;
      }

      setPendingStockTransfers(
        stockTransferForBranch(result, props.selectedBranch)
      );
    }
  };

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <div
        style={{
          textAlign: "end",
        }}
      >
        <CSVDownload
          filename={`InFlow-OutFlow-${props.selectedBranch.name}-${moment(
            start
          ).format("DD MMM")}-to-${moment(end).format("DD MMM")}`}
          data={getStockTransferDataForExport(
            showPending ? pendingStockTransfers : stockTransfer
          )}
        />
      </div>
      <DataTableWithDates
        isLoading={isFetching || pendingStatus === "loading"}
        columns={columns}
        data={showPending ? pendingStockTransfers : stockTransfer}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        showPendingButton={true}
        handleShowPendingButtonClicked={handleShowPendingButtonClicked}
        initialSortBy={"status"}
        initialSortOrder={"desc"}
      />
      {showApprove && transferIdUnderApproval !== null && (
        <ApprovalDialogWithComment
          open={showApprove}
          onClose={() => setShowApprove(false)}
          onApprove={() => onApprove(transferIdUnderApproval)}
          isApproving={isApproving}
          onComment={(comment) => onComment(transferIdUnderApproval, comment)}
          isCommenting={isCommenting}
        />
      )}
    </>
  );
};

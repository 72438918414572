import { getEvents } from "api/GetEvents";
import { formatStartEndDates } from "lib/Date";
import { queryCache, QueryStatus, useQuery } from "react-query";
import { IEventCategories, IEventsResponse } from "types/Event";
import { FMSError } from "types/Error";
import { getEventCategories } from "api/getEventCategories";

interface IQueryReminders {
  status: QueryStatus;
  getEventsResponse: IEventsResponse | FMSError | undefined;
  isFetching: boolean;
}

interface IQueryEventCategories {
  status: QueryStatus;
  eventCategoriesFetched: IEventCategories | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryReminders = (
  startDate: Date,
  endDate: Date
): IQueryReminders => {
  const [start, end] = formatStartEndDates(startDate, endDate, true);
  const { status, data: response, isFetching } = useQuery(
    ["reminders", start, end],
    async (key: string, start: string, end: string) => {
      return await getEvents(start, end);
    }
  );
  return { status, getEventsResponse: response, isFetching };
};

export const useQueryEventCategories = (): IQueryEventCategories => {
  const { status, data: eventCategoriesFetched, isFetching } = useQuery(
    ["eventCategories"],
    async (key: string) => {
      return await getEventCategories();
    }
  );
  return { status, eventCategoriesFetched, isFetching };
};

export const invalidateRemindersCache = () => {
  queryCache.invalidateQueries("reminders");
};

export const invalidateEventCategories = () => {
  queryCache.invalidateQueries("eventCategories");
};

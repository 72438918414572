import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import styles from "./GeneratePurchaseOrderComponent.module.css";
import { GeneratePurchaseOrderForm } from "components/generatePurchaseOrderForm/GeneratePurchaseOrderForm";
import GeneratePurchaseOrderMaterialsForm from "components/generatePurchaseOrderMaterialForm/GeneratePurchaseOrderMaterialsForm";
import { ISupplier } from "types/Supplier";
import { IBranch } from "types/Branch";

const GeneratePurchaseOrderComponent: React.FC = () => {
  const history = useHistory();

  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState<{
    selectedSupplier: ISupplier;
    selectedBranch: IBranch;
    deliveryAddress: string;
    preferredDeliveryDate: string;
  }>();
  const [showMaterialSelection, setShowMaterialSelection] = useState(false);

  return (
    <>
      <div className={styles.header}>
        <div style={{ display: "flex" }}>
          <Button
            className={styles.backButton}
            onClick={() => {
              if (showMaterialSelection) {
                setShowMaterialSelection(false);
              } else {
                history.goBack();
              }
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
          </Button>
          <div className={styles.pageName}>
            {`Generate Purchase Order : Step ${
              !showMaterialSelection ? "1" : "2"
            }`}
          </div>
        </div>
      </div>
      {!showMaterialSelection && (
        <GeneratePurchaseOrderForm
          onEnterPurchaseOrder={(
            showMaterialSelection,
            selectedSupplier,
            selectedBranch,
            deliveryAddress,
            preferredDeliveryDate
          ) => {
            setPurchaseOrderDetails({
              selectedSupplier,
              selectedBranch,
              deliveryAddress,
              preferredDeliveryDate,
            });
            setShowMaterialSelection(showMaterialSelection);
          }}
        />
      )}
      {showMaterialSelection && purchaseOrderDetails && (
        <GeneratePurchaseOrderMaterialsForm
          selectedSupplier={purchaseOrderDetails.selectedSupplier}
          selectedBranch={purchaseOrderDetails.selectedBranch}
          deliveryAddress={purchaseOrderDetails.deliveryAddress}
          preferredDeliveryDate={purchaseOrderDetails.preferredDeliveryDate}
        />
      )}
    </>
  );
};

export default GeneratePurchaseOrderComponent;

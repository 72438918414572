import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import {
  IWastageEntryView,
  IWastageEntriesResponse,
  IWastageForAllBranches,
} from "types/WastageFood";
import { downloadFile } from "lib/FileUtils";
import { formatDate } from "lib/Date";

interface IWastages {
  wastageFoodList: IWastageForAllBranches[];
}

export const getWastage = async (
  start: string,
  end: string
): Promise<IWastageForAllBranches[] | FMSError> => {
  try {
    const response = await get<IWastages>(
      `${BACKEND_URL_V1}/wastage?start=${start}&end=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.wastageFoodList) {
        return response.parsedBody.wastageFoodList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Wastage could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getWastageUploadTemplate = async (
  branchId: string,
  date: Date
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/wastage/branches/${branchId}/uploadReportFormat?date=${formatDate(
        date
      )}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Wastage_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Wastage template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getWastageEntries = async (
  branchId: string,
  materialId: string,
  start: string,
  end: string
): Promise<IWastageEntryView[] | FMSError> => {
  try {
    const response = await get<IWastageEntriesResponse>(
      `${BACKEND_URL_V1}/wastage/branches/${branchId}/materials/${materialId}?start=${start}&start=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.wastageFoodList[0].wastageFoodList) {
        return response.parsedBody?.wastageFoodList[0].wastageFoodList;
      }
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Wastage entries could not be retrived";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function EditIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      viewBox="0 0 40 40"
    >
      <defs>
        <style>{".editIconComponent__a{fill:" + primaryTextColor + "}"}</style>
      </defs>
      <path
        className="editIconComponent__a"
        d="M22.446 12.914l2.627-2.627 5.787 5.787-2.627 2.627zM20.63 14.732L10.287 25.075v5.787h5.787l10.343-10.343z"
      />
      <g stroke={primaryTextColor} strokeWidth={2} fill="none">
        <rect width={40} height={40} rx={5} stroke="none" />
        <rect x={1} y={1} width={38} height={38} rx={4} />
      </g>
    </svg>
  );
}
const EditIcon = React.memo(EditIconComponent);
export default EditIcon;

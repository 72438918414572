import { IStockTransfer } from "types/StockTransfer";
import React, { useState } from "react";
import { Chip, IconButton } from "@material-ui/core";
import { Status } from "types/Status";
import styles from "./StockTransferTable.module.css";
import { CommentsList } from "components/commentsList/CommentsList";
import { IToastBasicProps } from "components/Toast/Toast";
import { Auth } from "lib/Auth";
import { Role } from "types/Employee";
import CommentIcon from "assets/CommentIcon";

interface IStatusWithCommentsProps {
  stockTransder: IStockTransfer;
  openApprove: (id: string) => void;
  setShowToast: (value: React.SetStateAction<IToastBasicProps>) => void;
}

export const StatusWithComments: React.FC<IStatusWithCommentsProps> = (
  props
) => {
  const [showComment, setShowComment] = useState(false);
  const { stockTransder, openApprove, setShowToast } = props;
  return (
    <>
      <Chip
        style={{ float: "left", margin: "auto" }}
        label={stockTransder.status}
        className={
          stockTransder.status === Status.PENDING ? styles.pink : styles.green
        }
        onClick={() => {
          if (stockTransder.status === Status.PENDING) {
            if (Auth.getInstance().getUser()?.role === Role.DATA_ENTRY) {
              setShowToast({
                open: true,
                message: "You do not have access to approve transfer",
                type: "error",
              });
            } else {
              openApprove(stockTransder.id);
            }
          }
        }}
      />
      {stockTransder.commentsList?.length > 0 && (
        <IconButton
          color="inherit"
          onClick={() => setShowComment(!showComment)}
        >
          <CommentIcon />
        </IconButton>
      )}
      {showComment && (
        <CommentsList
          status={stockTransder.status}
          stockTransferId={stockTransder.id}
          comments={stockTransder.commentsList}
          onClose={() => setShowComment(false)}
          setShowToast={setShowToast}
        />
      )}
    </>
  );
};

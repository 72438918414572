import React, { useState, useEffect } from "react";
import { useQueryBranches } from "hooks/Branches";
import { IBranch } from "types/Branch";
import { DataTable } from "components/dataTable/DataTable";
import styles from "./BranchTable.module.css";
import { AddBranchModal } from "components/addBranchModal/AddBranchModal";
import CreateIcon from "@material-ui/icons/Create";
import { Dialog } from "@material-ui/core";
import Toast, { IToastBasicProps } from "components/Toast/Toast";

export const BranchTable: React.FC = () => {
  const { status, branches } = useQueryBranches();
  const [allBranches, setBranches] = useState<IBranch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<IBranch>();

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  useEffect(() => {
    if (status === "success" && branches) {
      setBranches(branches);
    }
  }, [status, branches, setBranches]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Branch Name",
            accessor: "name",
            width: 403,
            disableFilters: true,
          },
          {
            Header: "Address",
            accessor: "address",
            width: 489,
            disableFilters: true,
          },
          {
            Header: "",
            accessor: "edit",
            width: 30,
            Cell: (props: any) => {
              const branch = props.row.original as IBranch;
              return (
                <CreateIcon
                  color="action"
                  onClick={() => setSelectedBranch(branch)}
                />
              );
            },
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <Dialog
        open={selectedBranch !== undefined}
        disableBackdropClick
        scroll={"body"}
        maxWidth={"lg"}
      >
        <AddBranchModal
          title={"Update Branch"}
          buttonText={"Update"}
          selectedBranch={selectedBranch}
          onClose={(success, message) => {
            setSelectedBranch(undefined);
            if (message) {
              setShowToast({
                open: true,
                message: message,
                type: success ? "success" : "error",
              });
            }
          }}
        />
      </Dialog>
      <DataTable
        columns={columns}
        data={allBranches}
        tableHeight={250}
        marginBottom={26}
        isLoading={status === "loading"}
        tableClass={styles.table}
      />
    </div>
  );
};

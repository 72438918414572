import { dateAddingDaysFromDate, today } from "lib/Date";

export interface IPurchaseOrderDateRange {
  title: string;
  dateRange: () => { start: Date; end: Date };
}

export const purchaseOrderDateRanges: IPurchaseOrderDateRange[] = [
  {
    title: "This Month",
    dateRange: () => {
      const start = today();
      start.setDate(1); // start of the month
      start.setHours(0, 0, 0); // start of the day
      const end = today();
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Last 7 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -7);
      start.setHours(0, 0, 0); // start of the day
      const end = today();
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Last 30 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -30);
      start.setHours(0, 0, 0); // start of the day
      const end = today();
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
  {
    title: "Last 90 days",
    dateRange: () => {
      const start = dateAddingDaysFromDate(today(), -90);
      start.setHours(0, 0, 0); // start of the day
      const end = today();
      end.setHours(23, 59, 59); // EOD
      return { start, end };
    },
  },
];

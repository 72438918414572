import React, { useContext } from "react";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { IBranch } from "types/Branch";
import ContentPage from "components/contentPage/ContentPage";
import {
  IBranchSelectorProps,
  BranchSelector,
} from "components/branchSelector/BranchSelector";
import styles from "./DashboardForAdmin.module.css";
import { DashboardTable } from "components/dashboardTable/DashboardTable";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddSalesModal } from "components/addSalesModal/AddSalesModal";
import { AddRawOrClosingMaterialModal } from "components/addRawMaterialModal/AddRawOrClosingMaterialModal";
import { StockType } from "types/StockType";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { AddWastageModal } from "components/addWastageModal/AddWastageModal";
import { AddStaffFoodModal } from "components/addStaffFoodModal/AddStaffFoodModal";
import { useLocalBranch } from "hooks/LocalStorage";
import { AddFavouriteModalHelper } from "components/addFavouriteModalHelper/AddFavouriteModalHelper";
import { AddFavouritesModal } from "components/addFavouritesModal/AddFavouritesModal";
import { FavouriteEntity } from "types/Favourite";
import { getSettings } from "api/GetSettings";
import SalesIcon from "assets/SalesIcon";
import WastageIcon from "assets/WastageIcon";
import StaffFoodIcon from "assets/StaffFoodIcon";
import ClosingStockIcon from "assets/ClosingStockIcon";
import StockIcon from "assets/StockIcon";

const DashboardForAdmin: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }

  const branchMaterialContextValues =
    materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!;
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={
        <MainSection
          selectedBranch={selectedBranch}
          materialContextValues={branchMaterialContextValues}
        />
      }
      rightSection={
        <RightSection
          selectedBranch={selectedBranch}
          materialContextValues={branchMaterialContextValues}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Dashboard"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    <DashboardTable
      selectedBranch={props.selectedBranch}
      materialContextValues={props.materialContextValues}
    />
  </>
);

const RightSection: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    <ModalWithButton title={"Add Sales"} icon={<SalesIcon />}>
      {" "}
      <AddSalesModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
    <ModalWithButton title={"Add Raw Material"} icon={<StockIcon />}>
      {" "}
      <AddRawOrClosingMaterialModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
        type={StockType.ADDITION}
      />{" "}
    </ModalWithButton>
    <ModalWithButton title={"Add Wastage"} icon={<WastageIcon />}>
      {" "}
      <AddWastageModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
    <ModalWithButton title={"Add Staff Food"} icon={<StaffFoodIcon />}>
      {" "}
      <AddStaffFoodModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
    <ModalWithButton title={"Add Closing Material"} icon={<ClosingStockIcon />}>
      {" "}
      <AddRawOrClosingMaterialModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
        type={StockType.CLOSING}
      />{" "}
    </ModalWithButton>
    <AddFavouriteModalHelper>
      {" "}
      <AddFavouritesModal
        selectedBranch={props.selectedBranch}
        materials={props.materialContextValues?.rawMaterialsArrForFoodAnalysis}
        favouriteEntity={FavouriteEntity.DASHBOARD}
        onClose={getSettings}
      />{" "}
    </AddFavouriteModalHelper>
  </>
);

export default DashboardForAdmin;

import React, { useContext } from "react";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import {
  MaterialContext,
  IMaterialContextInterface,
} from "contexts/MaterialsContext";
import { useLocalBranch } from "hooks/LocalStorage";
import { IBranch } from "types/Branch";
import ContentPage from "components/contentPage/ContentPage";
import {
  IBranchSelectorProps,
  BranchSelector,
} from "components/branchSelector/BranchSelector";
import styles from "./ComplimentaryFood.module.css";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddComplimentaryFoodModal } from "components/addComplimentaryFoodModal/AddComplimentaryFoodModal";
import { ComplimentaryFoodTable } from "components/complimentaryFoodTable/ComplimentaryFoodTable";
import ComplimentaryFoodIcon from "assets/ComplimentaryFoodIcon";

const ComplimentaryFood: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={<MainSection selectedBranch={selectedBranch} />}
      rightSection={
        <RightSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
          selectedBranch={selectedBranch}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Complimentary Food"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch }> = (props) => (
  <>
    <ComplimentaryFoodTable selectedBranch={props.selectedBranch} />
  </>
);

const RightSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => (
  <>
    <ModalWithButton
      title={"Add Complimentary Food"}
      icon={<ComplimentaryFoodIcon />}
    >
      {" "}
      <AddComplimentaryFoodModal
        materialContextValues={props.materialContextValues}
        selectedBranch={props.selectedBranch}
      />{" "}
    </ModalWithButton>
  </>
);

export default ComplimentaryFood;

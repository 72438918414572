import { BACKEND_URL_V1, deletee } from "lib/Api";
import { FMSError } from "types/Error";

export const deactivateSupplier = async (
  supplierId: string
): Promise<FMSError | null> => {
  try {
    const response = await deletee(
      `${BACKEND_URL_V1}/suppliers/${supplierId}/`
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Supplier could not be deactivated";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

import React from "react";
import { CircularProgress } from "@material-ui/core";

interface IProps {
  isLoading: boolean;
  text?: string;
  spinnerSize?: number;
}

export const Loading: React.FC<IProps> = (props) => {
  const { isLoading, text, spinnerSize = 30 } = props;
  return (
    <>
      {!isLoading && `${text || ""}`}
      {isLoading && <CircularProgress size={spinnerSize} color={"inherit"} />}
    </>
  );
};

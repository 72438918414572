import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import { IReminderDateRange } from "types/ReminderDateRange";
import styles from "./DatePickerHeaderForReminders.module.css";

export interface IDatePickerHeaderProps {
  dateRanges: IReminderDateRange[];
  selectedDateRange: IReminderDateRange;
  setSelectedDateRange: (dateRange: IReminderDateRange) => void;
}

export const DatePickerHeaderForReminders: React.FC<IDatePickerHeaderProps> = (
  props
) => {
  const { dateRanges, selectedDateRange, setSelectedDateRange } = props;
  const dateRangeMap = new Map<String, IReminderDateRange>();
  dateRanges.forEach((dateRange) =>
    dateRangeMap.set(dateRange.title, dateRange)
  );

  return (
    <Select
      className={styles.select}
      disableUnderline
      classes={{ root: styles.root }}
      value={selectedDateRange.title}
      onChange={(event) => {
        const title = event.target.value as string;
        setSelectedDateRange(dateRangeMap.get(title)!);
      }}
    >
      {dateRanges.map((dateRange, index) => (
        <MenuItem value={dateRange.title} key={index}>
          {dateRange.title}
        </MenuItem>
      ))}
    </Select>
  );
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { downloadFile } from "lib/FileUtils";
import { FMSError } from "types/Error";
import { ISupplierPost } from "types/Supplier";

export const addSupplier = async (
  supplier: ISupplierPost
): Promise<FMSError | null> => {
  try {
    const response = await post(`${BACKEND_URL_V1}/suppliers/`, supplier);

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Supplier could not be added";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateSupplier = async (
  supplier: ISupplierPost
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/suppliers/${supplier.id}`,
      supplier
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Supplier could not be updated";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const uploadSupplier = async (
  filesToUpload: File[]
): Promise<FMSError | null> => {
  const formData = new FormData();
  for (let i = 0; i < filesToUpload.length; i++) {
    formData.append("uploadedFile", filesToUpload[i]);
  }
  try {
    const response = await post(
      `${BACKEND_URL_V1}/suppliers/upload`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(response.headers, await response.blob(), "errors.xlsx");
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ?? "Unable to upload supplier";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const activateSupplier = async (supplierId: string): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/suppliers/${supplierId}/activate`);

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage = response.serverError?.error.message ?? "Supplier could not be enabled";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
}

import { getConsumptionEstimatesForRawMaterials } from "api/GetPredictions";
import { formatStartEndDates } from "lib/Date";
import { QueryStatus, useQuery } from "react-query";
import { IBranchWiseMaterialPrediction } from "types/BranchWiseMaterialPrediction";
import { FMSError } from "types/Error";

interface IQueryPredictionsRawMaterial {
  status: QueryStatus;
  branchWiseMaterialPredictionViews: IBranchWiseMaterialPrediction[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryPredictionsRawMaterial = (
  startDate: Date,
  endDate: Date
): IQueryPredictionsRawMaterial => {
  const [start, end] = formatStartEndDates(startDate, endDate, true);
  const {
    status,
    data: branchWiseMaterialPredictionViews,
    isFetching,
  } = useQuery(
    ["predictionsRawMaterial", start, end],
    async (_: string, start: string, end: string) => {
      return await getConsumptionEstimatesForRawMaterials(start, end);
    }
  );
  return { status, branchWiseMaterialPredictionViews, isFetching };
};

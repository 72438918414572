import { BACKEND_URL_V1, get } from "lib/Api";
import { IEventsResponse } from "types/Event";
import { FMSError } from "types/Error";
import { downloadFile } from "lib/FileUtils";

export const getEvents = async (
  start: string,
  end: string
): Promise<IEventsResponse | FMSError> => {
  try {
    const response = await get<IEventsResponse>(
      `${BACKEND_URL_V1}/events?start=${start}&end=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ?? "Reminders could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const downloadEvents = async (): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/events/download/eventsInfo`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Events_Export.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Events could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import React, { createContext, useState } from "react";
import Toast from "components/Toast/Toast";

export const ModalContext = createContext({
  hideModal: (success?: boolean, message?: string) => {},
  show: false,
  showModal: () => {},
});

const ModalContextProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const hideModal = (success?: boolean, message?: string) => {
    setOpen(false);
    if (success && message) {
      setToastMessage(message);
      setShowToast(true);
    }
  };
  return (
    <ModalContext.Provider
      value={{ hideModal: hideModal, show: open, showModal: showModal }}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={() => {
          setShowToast(false);
          setToastMessage("");
        }}
        type="success"
      />
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;

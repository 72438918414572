import { Environment } from "./Environment";
import { Namespace } from "./Namespace";
import nisargConfig from "./nisarg.json";
import wadeshwarConfig from "./wadeshwar.json";

export function jsonConfig(namespace: Namespace, environment: Environment) {
  switch (namespace) {
    case Namespace.NISARG:
      return nisargConfig[environment];
    case Namespace.WADESHWAR:
      return wadeshwarConfig[environment];
    default:
      throw new Error("Invalid Namespace " + namespace);
  }
}

export function themeConfig(namespace: Namespace) {
  switch (namespace) {
    case Namespace.NISARG:
      return nisargConfig.theme;
    case Namespace.WADESHWAR:
      return wadeshwarConfig.theme;
    default:
      throw new Error("Invalid Namespace " + namespace);
  }
}

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";

interface IGetBranches {
  branches: IBranch[];
}

export const getBranches = async (): Promise<IBranch[] | FMSError> => {
  try {
    const response = await get<IGetBranches>(`${BACKEND_URL_V1}/branches`);

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.branches) {
        return response.parsedBody.branches;
      }
      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Branches could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { ISaleFood, ISalesForAllBranches } from "types/Sales";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

interface ISales {
  saleFoodList: ISalesForAllBranches[];
}

export const getSales = async (
  start: string,
  end: string
): Promise<ISalesForAllBranches[] | FMSError> => {
  try {
    const response = await get<ISales>(
      `${BACKEND_URL_V1}/sales?start=${start}&end=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.saleFoodList) {
        return response.parsedBody.saleFoodList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Sales could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getSalesUploadTemplate = async (
  branchId: string,
  date: Date
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/sales/branches/${branchId}/uploadReportFormat?date=${formatDate(
        date
      )}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Sales_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Sales template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

interface ISaleEntries {
  saleMaterialViews: ISaleFood[];
}

export const getSaleEntries = async (
  start: string,
  end: string,
  branchId: string,
  materialId: string
): Promise<ISaleFood[] | FMSError> => {
  try {
    const response = await get<ISaleEntries>(
      `${BACKEND_URL_V1}/sales/branches/${branchId}/materials/${materialId}?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.saleMaterialViews) {
        return response.parsedBody.saleMaterialViews;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ?? "Sales could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import React, { useContext } from "react";
import classNames from "classnames";
import {
  createTheme,
  InputAdornment,
  Link,
  ThemeProvider,
} from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import styles from "./DatePickersForTableHeader.module.css";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { format } from "date-fns";
import CalendarIcon from "assets/CalendarIcon";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IDatePickersForTableHeadersProps {
  selectedDate: Date;
  selectedDateFrom: Date | null;
  selectedDateTo: Date | null;
  handleDateChangeFrom: (date: MaterialUiPickersDate) => void;
  handleDateChangeTo: (date: MaterialUiPickersDate) => void;
  handleDateChange: (date: MaterialUiPickersDate) => void;
  hideCentralDatePicker?: boolean;
  fromToClassName?: string;
  centralDatePickerFormat?: "month" | "date";
  disableFuture?: boolean;
}

export const DatePickersForTableHeaders: React.FC<
  IDatePickersForTableHeadersProps
> = (props) => {
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
  });

  const {
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
    hideCentralDatePicker,
    fromToClassName,
    centralDatePickerFormat = "date",
    disableFuture = true,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname;
  const params = queryString.parse(location.search);
  return (
    <>
      <div className={fromToClassName ?? styles.fromTo}>
        <ThemeProvider theme={materialTheme}>
          <DatePicker
            views={["date"]}
            value={selectedDateFrom}
            placeholder={"From"}
            onChange={(startDate) => {
              if (startDate != null) {
                params.startDate = format(startDate, "yyyy-MM-dd");
                history.push({
                  pathname: currentPath,
                  search: queryString.stringify(params),
                });
              }
              handleDateChangeFrom(startDate);
              if (selectedDateTo) {
                handleDateChange(null);
              }
            }}
            autoOk={true}
            initialFocusedDate={selectedDateTo}
            variant={"inline"}
            disableFuture={disableFuture}
            inputVariant="outlined"
            format="MMM dd"
            InputProps={{
              className: classNames(styles.input, styles.widthDates),
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          />
          <DatePicker
            views={["date"]}
            value={selectedDateTo}
            placeholder={"To"}
            onChange={(endDate) => {
              if (endDate != null) {
                params.endDate = format(endDate, "yyyy-MM-dd");
                history.push({
                  pathname: currentPath,
                  search: queryString.stringify(params),
                });
              }
              handleDateChangeTo(endDate);
              if (selectedDateFrom) {
                handleDateChange(null);
              }
            }}
            autoOk={true}
            initialFocusedDate={selectedDateFrom}
            variant={"inline"}
            disableFuture={disableFuture}
            inputVariant="outlined"
            format="MMM dd"
            InputProps={{
              className: classNames(styles.input, styles.widthDates),
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          />
        </ThemeProvider>

        <Link
          className={styles.clearButton}
          href="#"
          onClick={() => {
            handleDateChangeFrom(null);
            handleDateChangeTo(null);
            params.startDate = null;
            params.endDate = null;
            history.push({
              pathname: currentPath,
              search: queryString.stringify(params, { skipNull: true }),
            });
            handleDateChange(new Date());
          }}
        >
          Clear
        </Link>
      </div>
      {!hideCentralDatePicker && (
        <ThemeProvider theme={materialTheme}>
          <KeyboardDatePicker
            views={
              centralDatePickerFormat === "month"
                ? ["year", "month"]
                : undefined
            }
            value={selectedDate}
            openTo={centralDatePickerFormat}
            autoOk={true}
            disableFuture={disableFuture}
            inputVariant="standard"
            format={
              centralDatePickerFormat === "date" ? "dd MMM yyyy" : "MMM yyyy"
            }
            onChange={(date) => {
              handleDateChangeFrom(null);
              handleDateChangeTo(null);
              history.push({
                pathname: currentPath,
              });
              handleDateChange(date);
            }}
            variant={"inline"}
            style={{ float: "left", left: "15%", width: "150px" }}
            InputProps={{
              className: styles.mainDate,
            }}
          />
        </ThemeProvider>
      )}
    </>
  );
};

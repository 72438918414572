import {
  Button,
  FormControl,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./GrnComponent.module.css";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import moment from "moment";
import CalendarIcon from "assets/CalendarIcon";
import { Controller, useForm } from "react-hook-form";
import {
  IPostGrn,
  IPurchaseOrder,
  PurchaseOrderItem,
  PurchaseOrderStatus,
} from "types/PurchaseOrder";
import { invalidatePurchaseOrdersCache } from "hooks/purchaseOrders";
import { FMSError } from "types/Error";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { DatePicker } from "@material-ui/pickers";
import classNames from "classnames";
import { IMaterial, IngredientToQuantityMap } from "types/Material";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { useMutation } from "react-query";
import { postGrn } from "api/PostGrn";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import { isUnitGmsOrMl, isUnitKgsOrLtr } from "lib/MaterialHelper";
import ExcelIcon from "assets/ExcelIcon";
import {
  downloadedPurchaseOrder,
  getPurchaseOrder,
} from "api/GetPurchaseOrders";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { MaterialType } from "types/MaterialType";

interface IProps {
  purchaseOrderId: string;
}

interface IRowProps {
  index: number;
  poItem: PurchaseOrderItem;
  control: any;
  readOnly: boolean;
  receivedPoItem?: PurchaseOrderItem;
}

interface IQuantityReceived {
  name: string;
  quantity: number;
  quantityReceived: number | null;
}

interface IGrnFormData {
  dateOfReceipt: Date;
  gateRegisteredDate: Date;
  gateRegisteredNo: string;
  materials: IQuantityReceived[];
  billNumber: string;
  billDate: Date;
}

const RowComponent: React.FC<IRowProps> = (props) => {
  const { index, control, poItem, readOnly, receivedPoItem } = props;

  const fieldName = `materials[${index}]`;

  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <div className={styles.inputContainer}>
        <Controller
          control={control}
          name={`${fieldName}.name`}
          defaultValue={poItem.materialName}
          style={{ marginLeft: "10px", width: "450px" }}
          render={({ onChange }) => (
            <FormControl className={styles.materialUnit} variant="outlined">
              <InputLabel htmlFor={"materialName" + fieldName}>
                Item Name
              </InputLabel>
              <OutlinedInput
                style={{
                  color: readOnly ? "#000000" : undefined,
                }}
                id={"materialName" + fieldName}
                type={"text"}
                defaultValue={poItem.materialName}
                disabled
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  onChange(value);
                }}
                labelWidth={75}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.quantity`}
          style={{ marginLeft: "10px", width: "450px" }}
          defaultValue={poItem.displayQuantity.quantity}
          render={({ onChange }) => (
            <FormControl className={styles.materialUnit} variant="outlined">
              <InputLabel htmlFor={"Quantity" + fieldName}>
                Quantity Ordered
              </InputLabel>
              <OutlinedInput
                style={{
                  color: readOnly ? "#000000" : undefined,
                }}
                id={"quantity" + fieldName}
                type={"number"}
                inputProps={{
                  step: "0.01",
                }}
                defaultValue={poItem.displayQuantity.quantity}
                disabled
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  onChange(value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    {displayNameForUnitOfMeasure(poItem.displayQuantity.unit)}
                  </InputAdornment>
                }
                labelWidth={115}
              />
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name={`${fieldName}.quantityReceived`}
          style={{ marginLeft: "10px", width: "450px" }}
          defaultValue={null}
          render={({ onChange }) => (
            <FormControl className={styles.materialUnit} variant="outlined">
              <InputLabel htmlFor={"quantityReceived" + fieldName}>
                Quantity Received
              </InputLabel>
              <OutlinedInput
                style={{
                  color: "#000000",
                }}
                id={"quantityReceived" + fieldName}
                type={"number"}
                inputProps={{
                  step: "0.01",
                }}
                defaultValue={
                  readOnly && receivedPoItem
                    ? receivedPoItem.displayQuantity.quantity
                    : poItem.displayQuantity.quantity
                }
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (isNaN(value)) {
                    onChange(0);
                  } else {
                    onChange(value);
                  }
                }}
                disabled={readOnly}
                endAdornment={
                  <InputAdornment position="end">
                    {displayNameForUnitOfMeasure(
                      readOnly && receivedPoItem
                        ? receivedPoItem.displayQuantity.unit
                        : poItem.displayQuantity.unit
                    )}
                  </InputAdornment>
                }
                labelWidth={120}
              />
            </FormControl>
          )}
        />
      </div>
    </div>
  );
};

const GrnComponent: React.FC<IProps> = React.memo((props) => {
  const { purchaseOrderId } = props;
  const history = useHistory();

  const [isFetching, setIsFetching] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState<IPurchaseOrder>();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isDownloading, setDownloading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [showCreatePendingPOConfirmation, setShowCreatePendingPOConfirmation] =
    useState<{
      show: boolean;
      postData?: IPostGrn;
      pendingMaterialNameToDisplayQuantityList?: {
        key: string;
        value: string;
      }[];
    }>({
      show: false,
    });

  useEffect(() => {
    async function fetchPurchaseOrder() {
      setIsFetching(true);
      const response = await getPurchaseOrder(purchaseOrderId);
      setIsFetching(false);
      if (response instanceof FMSError) {
        const message = response?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setPurchaseOrder(response.purchaseOrderView);
      }
    }

    fetchPurchaseOrder();
  }, [purchaseOrderId]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const materialContextValues = useContext(MaterialContext);
  let allBranchesRawMaterialsSet = new Set<IMaterial>();

  Array.from(materialContextValues.branchWiseMaterialsMap.values()).map(
    (value: IMaterialContextInterface) => {
      const rawMaterials = value.allMaterialsArr.filter(
        (material) => material.type === MaterialType.RAW
      );
      allBranchesRawMaterialsSet = new Set([
        ...allBranchesRawMaterialsSet,
        ...rawMaterials,
      ]);
      return null;
    }
  );

  const getMaterialFromName = (materialName: string): IMaterial | undefined => {
    let material: IMaterial | undefined = undefined;
    Array.from(allBranchesRawMaterialsSet).map((value: IMaterial) => {
      if (value.name === materialName) {
        material = value;
        return null;
      }
      return null;
    });
    return material;
  };

  const [mutate] = useMutation(
    (request: IPostGrn) => {
      setSubmitting(true);
      return postGrn(purchaseOrderId, request);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidatePurchaseOrdersCache();
          setShowSuccessDialog(true);
        } else {
          setShowToast({
            open: true,
            message: error.message ?? "Something went wrong!",
            type: "error",
          });
        }
      },
    }
  );

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);

  const { handleSubmit, control, errors } = useForm<IGrnFormData>();
  const [dateOfReceipt, setDateOfReceipt] =
    useState<MaterialUiPickersDate>(null);
  const [gateRegisteredDate, setGateRegisteredDate] =
    useState<MaterialUiPickersDate>(null);
  const [billDate, setBillDate] = useState<MaterialUiPickersDate>(null);

  const generateGrn = (data: IGrnFormData) => {
    const materialIdToQuantityOverrideMap: IngredientToQuantityMap = {};

    data.materials.forEach((receivedMaterial: IQuantityReceived) => {
      let fetchedMaterial = getMaterialFromName(receivedMaterial.name);
      if (
        fetchedMaterial &&
        receivedMaterial.quantityReceived !== null &&
        !isNaN(receivedMaterial.quantityReceived)
      ) {
        let materialId = fetchedMaterial.id;
        // conversion
        const convertedQuantity = isUnitGmsOrMl(fetchedMaterial.unitOfMeasure)
          ? receivedMaterial.quantityReceived * 1000
          : receivedMaterial.quantityReceived;

        materialIdToQuantityOverrideMap[materialId] = convertedQuantity;
      }
    });

    const orderedItems = purchaseOrder?.purchaseOrderItems ?? [];
    const pendingMaterialNameToDisplayQuantityList: {
      key: string;
      value: string;
    }[] = [];
    orderedItems.forEach((item) => {
      const overrideQuantity = materialIdToQuantityOverrideMap[item.materialId];
      if (overrideQuantity === undefined) {
        return;
      }

      const isKgsOrLtr = isUnitKgsOrLtr(item.displayQuantity.unit);
      const orderedQuantity = isKgsOrLtr
        ? item.displayQuantity.quantity * 1000
        : item.displayQuantity.quantity;
      if (overrideQuantity < orderedQuantity) {
        const diff = orderedQuantity - overrideQuantity;
        const displayDiff = isKgsOrLtr ? diff / 1000 : diff;
        pendingMaterialNameToDisplayQuantityList.push({
          key: item.materialName,
          value: `${displayDiff} ${displayNameForUnitOfMeasure(
            item.displayQuantity.unit
          )}`,
        });
      }
    });

    const grn: IPostGrn = {
      dateOfReceipt: data.dateOfReceipt,
      gateRegisteredDate: data.gateRegisteredDate,
      gateRegisteredNo: data.gateRegisteredNo,
      materialIdToQuantityOverrideMap: materialIdToQuantityOverrideMap,
      billNumber: data.billNumber,
      billDate: data.billDate,
      generatePendingMaterialsPO: false,
    };

    if (pendingMaterialNameToDisplayQuantityList.length > 0) {
      setShowCreatePendingPOConfirmation({
        show: true,
        postData: grn,
        pendingMaterialNameToDisplayQuantityList,
      });
    } else {
      mutate(grn);
    }
  };

  const downloadGrn = async () => {
    if (!purchaseOrder) {
      return;
    }

    setDownloading(true);
    const error = await downloadedPurchaseOrder(purchaseOrder.id);
    setDownloading(false);
    if (error) {
      setShowToast({
        open: true,
        message: error.message ?? "Something went wrong!",
        type: "error",
      });
    }
  };

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        buttonText={"Okay"}
        bodyText={`GRN generated successfully`}
        open={showSuccessDialog}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />

      <ConfirmationDialog
        buttonText="Yes"
        secondButtonText="No"
        body={
          <div className={styles.confirmationBody}>
            <div className={styles.confirmationTitle}>
              Create new purchase order for pending materials?
            </div>
            {showCreatePendingPOConfirmation.pendingMaterialNameToDisplayQuantityList && (
              <div style={{ paddingBottom: "20px" }}>
                {showCreatePendingPOConfirmation.pendingMaterialNameToDisplayQuantityList.map(
                  (element, index) => {
                    return (
                      <div key={index}>
                        {element.key} - {element.value}
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        }
        open={showCreatePendingPOConfirmation.show}
        onClose={() => {
          setShowCreatePendingPOConfirmation({ show: false });
        }}
        onApprove={() => {
          if (showCreatePendingPOConfirmation.postData) {
            showCreatePendingPOConfirmation.postData.generatePendingMaterialsPO =
              true;
            mutate(showCreatePendingPOConfirmation.postData);
          }
          setShowCreatePendingPOConfirmation({ show: false });
        }}
        onDeny={() => {
          if (showCreatePendingPOConfirmation.postData) {
            showCreatePendingPOConfirmation.postData.generatePendingMaterialsPO =
              false;
            mutate(showCreatePendingPOConfirmation.postData);
          }
          setShowCreatePendingPOConfirmation({ show: false });
        }}
        isApproving={false}
      />

      {isFetching && <Loading isLoading={isFetching} />}

      {!isFetching && purchaseOrder && (
        <>
          <div className={styles.header}>
            <div style={{ display: "flex" }}>
              <Button
                className={styles.backButton}
                onClick={() => {
                  history.goBack();
                }}
              >
                <KeyboardBackspaceIcon style={{ marginRight: "10px" }} />{" "}
                {"Back"}
              </Button>
              <div
                className={styles.pageName}
              >{`GRN - ${purchaseOrder.branch.name}`}</div>

              {purchaseOrder.purchaseOrderStatus ===
              PurchaseOrderStatus.DELIVERED ? (
                <Button
                  className={styles.downloadButton}
                  startIcon={
                    <Icon>
                      <ExcelIcon />
                    </Icon>
                  }
                  onClick={downloadGrn}
                >
                  <Loading text={`Download GRN`} isLoading={isDownloading} />
                </Button>
              ) : (
                <Button
                  className={styles.generateGrnButton}
                  disabled={isSubmitting}
                  style={{
                    backgroundColor: primaryColor,
                    color: primaryTextColor,
                    fontWeight: "bold",
                  }}
                  onClick={handleSubmit(generateGrn)}
                >
                  <Loading text={`Generate GRN`} isLoading={isSubmitting} />
                </Button>
              )}
            </div>
          </div>
          <div className={styles.details}>
            <div className={styles.detailsHeader}>
              <div className={styles.cardHeadText}>
                {"P.O. Date: "}{" "}
                <span className={styles.headDate}>
                  {moment(purchaseOrder.creationTime).format("DD-MM-YYYY")}
                </span>
                {purchaseOrder.vanityNumber && (
                  <div style={{ paddingLeft: "20px" }}>
                    {"P.O. #: "}
                    {purchaseOrder.vanityNumber}
                  </div>
                )}
                {purchaseOrder.parentPurchaseOrderId && (
                  <div style={{ paddingLeft: "20px" }}>
                    {"Parent P.O.: "}
                    <Link
                      to={`/purchase-orders/${purchaseOrder.parentPurchaseOrderId}/grn`}
                    >
                      {purchaseOrder.parentPurhcaseOrderVanityNumber}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className={styles.content}>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Supplier"}</div>
                <div className={styles.headSubText}>
                  {purchaseOrder.supplierView.name}
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Billing Address"}</div>
                <div className={styles.headSubText}>
                  {purchaseOrder.branch.billingAddress}
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.headSubHead}>{"Delivery Address"}</div>
                <div className={styles.headSubText}>
                  {purchaseOrder.deliveryAddress}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.inputCard}>
              <div className={styles.headSubHead}>{"Date of Recipt"}</div>
              <div className={styles.date}>
                {purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED ? (
                  moment(purchaseOrder.dateOfReceipt).format("DD-MM-YYYY")
                ) : (
                  <Controller
                    control={control}
                    name={"dateOfReceipt"}
                    style={{ marginLeft: "10px", width: "450px" }}
                    defaultValue={dateOfReceipt}
                    rules={{
                      required: true,
                    }}
                    render={({ onChange }) => (
                      <DatePicker
                        views={["date"]}
                        autoOk={true}
                        value={dateOfReceipt}
                        emptyLabel={"Select Date"}
                        onChange={(date) => {
                          setDateOfReceipt(date);
                          onChange(date);
                        }}
                        variant={"inline"}
                        disableFuture
                        inputVariant="outlined"
                        format="MMM dd"
                        fullWidth
                        error={errors.dateOfReceipt !== undefined}
                        InputProps={{
                          className: classNames(
                            styles.input,
                            styles.widthDates
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className={styles.inputCard}>
              <div className={styles.headSubHead}>{"Gate Registered No."}</div>
              <div className={styles.headSubText}>
                {purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED ? (
                  purchaseOrder.gateRegisteredNo
                ) : (
                  <Controller
                    control={control}
                    name={"gateRegisteredNo"}
                    style={{ marginLeft: "10px", width: "450px" }}
                    defaultValue={null}
                    rules={{
                      required: true,
                      maxLength: 255,
                    }}
                    render={({ onChange }) => (
                      <TextField
                        placeholder="Gate Registered No."
                        variant="outlined"
                        fullWidth
                        error={errors.gateRegisteredNo !== undefined}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className={styles.inputCard}>
              <div className={styles.headSubHead}>{"Gate Registered Date"}</div>
              <div className={styles.date}>
                {purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED ? (
                  moment(purchaseOrder.gateRegisteredDate).format("DD-MM-YYYY")
                ) : (
                  <Controller
                    control={control}
                    name={"gateRegisteredDate"}
                    style={{ marginLeft: "10px", width: "450px" }}
                    defaultValue={gateRegisteredDate}
                    rules={{
                      required: true,
                    }}
                    render={({ onChange }) => (
                      <DatePicker
                        views={["date"]}
                        autoOk={true}
                        value={gateRegisteredDate}
                        emptyLabel={"Select Date"}
                        onChange={(date) => {
                          setGateRegisteredDate(date);
                          onChange(date);
                        }}
                        variant={"inline"}
                        disableFuture
                        inputVariant="outlined"
                        format="MMM dd"
                        fullWidth
                        error={errors.gateRegisteredDate !== undefined}
                        InputProps={{
                          className: classNames(
                            styles.input,
                            styles.widthDates
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className={styles.inputCard}>
              <div className={styles.headSubHead}>{"Bill No."}</div>
              <div className={styles.headSubText}>
                {purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED ? (
                  purchaseOrder.billNumber
                ) : (
                  <Controller
                    control={control}
                    name={"billNumber"}
                    style={{ marginLeft: "10px", width: "450px" }}
                    defaultValue={null}
                    rules={{
                      required: true,
                      maxLength: 255,
                    }}
                    render={({ onChange }) => (
                      <TextField
                        placeholder="Bill No."
                        variant="outlined"
                        fullWidth
                        error={errors.billNumber !== undefined}
                        onChange={(e) => {
                          onChange(e);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
            <div className={styles.inputCard}>
              <div className={styles.headSubHead}>{"Bill Date"}</div>
              <div className={styles.date}>
                {purchaseOrder.purchaseOrderStatus ===
                PurchaseOrderStatus.DELIVERED ? (
                  moment(purchaseOrder.billDate).format("DD-MM-YYYY")
                ) : (
                  <Controller
                    control={control}
                    name={"billDate"}
                    style={{ marginLeft: "10px", width: "450px" }}
                    defaultValue={billDate}
                    rules={{
                      required: true,
                    }}
                    render={({ onChange }) => (
                      <DatePicker
                        views={["date"]}
                        autoOk={true}
                        value={billDate}
                        emptyLabel={"Select Date"}
                        onChange={(date) => {
                          setBillDate(date);
                          onChange(date);
                        }}
                        variant={"inline"}
                        disableFuture
                        inputVariant="outlined"
                        format="MMM dd"
                        fullWidth
                        error={errors.billDate !== undefined}
                        InputProps={{
                          className: classNames(
                            styles.input,
                            styles.widthDates
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.orderDetails}>
            <div className={styles.orderDetailsHeader}>
              <div className={styles.cardHeadText}>{"Order Details"}</div>
            </div>
          </div>
          <div className={styles.orderDetailColumns}>
            <div>{"Item Name"}</div>
            <div>{"Quantity Ordered"}</div>
            <div>{"Quantity Received"}</div>
          </div>
          <div className={styles.materials}>
            {purchaseOrder.purchaseOrderItems.map(
              (poItem: PurchaseOrderItem, index: number) => {
                const isPurchaseOrderDelivered =
                  purchaseOrder.purchaseOrderStatus ===
                  PurchaseOrderStatus.DELIVERED;
                return (
                  <RowComponent
                    key={index}
                    index={index}
                    control={control}
                    readOnly={isPurchaseOrderDelivered}
                    poItem={poItem}
                    receivedPoItem={
                      isPurchaseOrderDelivered
                        ? purchaseOrder.receivedOverrideOrderItems.find(
                            (material) =>
                              material.materialId === poItem.materialId
                          )
                        : undefined
                    }
                  />
                );
              }
            )}
          </div>
        </>
      )}
    </>
  );
});

export default GrnComponent;

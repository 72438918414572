import React, { ReactNode } from "react";
import { getSettings } from "api/GetSettings";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import FavouritesIcon from "assets/FavouritesIcon";

interface IModalWithButton {
  children: ReactNode;
}

export const AddFavouriteModalHelper: React.FC<IModalWithButton> = (props) => (
  <ModalWithButton
    title={"Add Favourites"}
    icon={<FavouritesIcon />}
    onBackDropClick={getSettings}
    onEscapeKeyDown={getSettings}
  >
    {props.children}
  </ModalWithButton>
);

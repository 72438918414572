import { IExternalBranch, IBranch } from "types/Branch";
import { IMaterial } from "types/Material";

export function enrichBranchesWithFavouriteMap(
  branches: IExternalBranch[]
): IBranch[] {
  const newBranches: IBranch[] = [];
  for (let i = 0; i < branches.length; i++) {
    newBranches.push(enrichBranchWithFavouriteMap(branches[i]));
  }
  return newBranches;
}

export function enrichBranchWithFavouriteMap(branch: IExternalBranch): IBranch {
  const { favourites } = branch;

  const favouriteRawStockMap = new Map<string, IMaterial>();
  const favouriteWastageMap = new Map<string, IMaterial>();
  const favouriteStaffFoodMap = new Map<string, IMaterial>();
  const favouriteStockTransfer = new Map<string, IMaterial>();
  const favouriteDashboardMap = new Map<string, IMaterial>();

  if (favourites && favourites.rawStock) {
    for (let j = 0; j < favourites.rawStock.length; j++) {
      const material = favourites.rawStock[j];
      favouriteRawStockMap.set(material.id, material);
    }
  }

  if (favourites && favourites.wastage) {
    for (let j = 0; j < favourites.wastage.length; j++) {
      const material = favourites.wastage[j];
      favouriteWastageMap.set(material.id, material);
    }
  }

  if (favourites && favourites.staffFood) {
    for (let j = 0; j < favourites.staffFood.length; j++) {
      const material = favourites.staffFood[j];
      favouriteStaffFoodMap.set(material.id, material);
    }
  }

  if (favourites && favourites.stockTransfer) {
    for (let j = 0; j < favourites.stockTransfer.length; j++) {
      const material = favourites.stockTransfer[j];
      favouriteStockTransfer.set(material.id, material);
    }
  }

  if (favourites && favourites.dashboard) {
    for (let j = 0; j < favourites.dashboard.length; j++) {
      const material = favourites.dashboard[j];
      favouriteDashboardMap.set(material.id, material);
    }
  }

  const newBranch: IBranch = {
    id: branch.id,
    name: branch.name,
    address: branch.address,
    favourites: branch.favourites,
    favouriteRawStockMap,
    favouriteWastageMap,
    favouriteStaffFoodMap,
    favouriteStockTransfer,
    favouriteDashboardMap,
    billingAddress: branch.billingAddress,
    deliveryAddresses: branch.deliveryAddresses,
  };

  return newBranch;
}

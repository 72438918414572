import {
  Button,
  Grid,
  Icon,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { getPurchaseOrderTemplate } from "api/GetPurchaseOrders";
import { uploadPurchaseOrder } from "api/PostPurchaseOrder";
import CalendarIcon from "assets/CalendarIcon";
import ExcelIcon from "assets/ExcelIcon";
import { Loading } from "components/loading/Loading";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { invalidatePurchaseOrdersCache } from "hooks/purchaseOrders";
import { useQuerySuppliers } from "hooks/Suppliers";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { IBranch } from "types/Branch";
import { FMSError } from "types/Error";
import { ISupplier } from "types/Supplier";
import styles from "./GeneratePurchaseOrderForm.module.css";
import { DatePicker } from "@material-ui/pickers";

interface IFormData {
  supplierId: string;
  branchId: string;
  deliveryAddress: string;
  preferredDeliveryDate: string;
}

interface IProps {
  onEnterPurchaseOrder: (
    showMaterialSelection: boolean,
    selectedSupplier: ISupplier,
    selectedBranch: IBranch,
    deliveryAddress: string,
    preferredDeliveryDate: string
  ) => void;
}

export const GeneratePurchaseOrderForm: React.FC<IProps> = (props) => {
  const { onEnterPurchaseOrder } = props;

  const { control, errors, handleSubmit, getValues, setValue } =
    useForm<IFormData>();

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [isDownloading, setDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { branches } = useContext(BranchFavouriteContext);

  const [selectedBranch, setSelectedBranch] = useState<IBranch | null>(
    branches.length === 1 ? branches[0] : null
  );

  const [suppliers, setSuppliers] = useState<ISupplier[]>([]);

  const initialDeliveryAddress =
    branches.length === 1 && branches[0].deliveryAddresses?.length === 1
      ? branches[0].deliveryAddresses[0]
      : null;

  const [deliveryAddress, setDeliveryAddress] = useState<string | null>(
    initialDeliveryAddress
  );

  const [preferredDeliveryDate, setPreferredDeliveryDate] =
    useState<MaterialUiPickersDate>(null);

  const suppliersQuery = useQuerySuppliers();

  useEffect(() => {
    if (suppliersQuery.status === "success") {
      const supplierList = suppliersQuery.supplierList;
      if (!supplierList || supplierList instanceof FMSError) {
        const message = supplierList?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSuppliers(supplierList.filter((obj) => !obj.isDeactivated));
      }
    }
  }, [suppliersQuery.status, suppliersQuery.supplierList]);

  const handleDownloadTemplate = async (data: IFormData) => {
    setDownloading(true);
    const error = await getPurchaseOrderTemplate(
      data.branchId,
      data.supplierId,
      data.deliveryAddress
    );
    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
    }
  };

  const onSubmit = (data: IFormData) => {
    const selectedSupplier = suppliers.find(
      (supplier) => supplier.id === data.supplierId
    );
    const selectedBranch = branches.find(
      (branch) => branch.id === data.branchId
    );
    if (selectedSupplier && selectedBranch) {
      onEnterPurchaseOrder(
        true,
        selectedSupplier,
        selectedBranch,
        data.deliveryAddress,
        data.preferredDeliveryDate
      );
    }
  };

  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  const history = useHistory();
  const [mutateForFile] = useMutation(
    (files: File[]) => {
      setIsUploading(true);
      return uploadPurchaseOrder(files);
    },
    {
      onSuccess: (error) => {
        setIsUploading(false);
        if (!error) {
          invalidatePurchaseOrdersCache();
          history.goBack();
        } else {
          setShowToast({
            open: true,
            message: error.message ?? "Something went wrong!",
            type: "error",
          });
        }
      },
    }
  );

  const handleUploadFile = useCallback(
    (files: File[]) => {
      mutateForFile(files);
    },
    [mutateForFile]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      handleUploadFile(acceptedFiles);
    },
    [handleUploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headText}>{"Billing Details"}</div>
        </div>

        <div className={styles.formBody}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={styles.inputDiv}>
                <div className={styles.inputLabel}>Supplier</div>
                <Controller
                  control={control}
                  name={"supplierId"}
                  rules={{
                    required: true,
                  }}
                  defaultValue={null}
                  render={({ onChange }) => (
                    <Autocomplete
                      options={suppliers}
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      onChange={(event, value) => {
                        onChange(value?.id);
                      }}
                      classes={{
                        root: styles.autocompleteRoot,
                        input: styles.autocomplete,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.supplierId !== undefined}
                          className={styles.textBox}
                          placeholder="Select Supplier"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid item xs={4}>
              <div className={styles.inputDiv}>
                <div className={styles.inputLabel}>Branch</div>
                <Controller
                  control={control}
                  name={"branchId"}
                  rules={{
                    required: true,
                  }}
                  defaultValue={selectedBranch?.id ?? null}
                  render={({ onChange }) => (
                    <Autocomplete
                      options={branches}
                      value={selectedBranch}
                      getOptionLabel={(option) => option.name}
                      fullWidth
                      getOptionSelected={(option, value) =>
                        option.id === value.id
                      }
                      onChange={(event, value) => {
                        if (value) {
                          const deliveryAddresses = value.deliveryAddresses;
                          if (deliveryAddresses?.length === 1) {
                            setDeliveryAddress(deliveryAddresses[0]);
                            setValue("deliveryAddress", deliveryAddresses[0]);
                          } else {
                            setDeliveryAddress(null);
                          }
                          setSelectedBranch(value);
                          onChange(value.id);
                        }
                      }}
                      onAbort={() => {
                        setSelectedBranch(null);
                      }}
                      classes={{
                        root: styles.autocompleteRoot,
                        input: styles.autocomplete,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.branchId !== undefined}
                          className={styles.textBox}
                          placeholder="Select Branch"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.inputDiv}>
                <div className={styles.inputLabel}>Billing Address</div>
                <TextField
                  id={"billingAddress"}
                  name={"billingAddress"}
                  type={"string"}
                  disabled
                  value={selectedBranch?.billingAddress ?? "-"}
                  className={styles.textBox}
                  placeholder="Enter Address"
                  variant="outlined"
                />
              </div>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className={styles.inputDiv}>
                <div className={styles.inputLabel}>Delivery Address</div>
                <Controller
                  control={control}
                  name={"deliveryAddress"}
                  defaultValue={deliveryAddress}
                  rules={{
                    required: true,
                  }}
                  render={({ onChange }) => (
                    <Autocomplete
                      options={selectedBranch?.deliveryAddresses ?? []}
                      getOptionLabel={(option) => option}
                      fullWidth
                      value={deliveryAddress}
                      disabled={getValues("branch") === null}
                      getOptionSelected={(option, value) => option === value}
                      onChange={(event, value) => {
                        setDeliveryAddress(value);
                        onChange(value);
                      }}
                      classes={{
                        root: styles.autocompleteRoot,
                        input: styles.autocomplete,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.deliveryAddress !== undefined}
                          className={styles.textBox}
                          placeholder="Delivery Address"
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                />
              </div>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: "flex", justifyContent: "start" }}
            >
              <div className={styles.inputDiv}>
                <div className={styles.inputLabel}>Preferred Delivery Date</div>
                <Controller
                  control={control}
                  name={"preferredDeliveryDate"}
                  defaultValue={preferredDeliveryDate}
                  render={({ onChange }) => (
                    <DatePicker
                      views={["date"]}
                      autoOk={true}
                      emptyLabel={"Select Date"}
                      onChange={(date) => {
                        onChange(date);
                        setPreferredDeliveryDate(date);
                      }}
                      value={preferredDeliveryDate}
                      variant={"inline"}
                      disablePast
                      inputVariant="outlined"
                      format="MMM dd"
                      fullWidth
                      InputProps={{
                        className: styles.datePicker,
                        endAdornment: (
                          <InputAdornment position="end">
                            <CalendarIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={styles.footer}>
          <div className={styles.excelButtons}>
            <Button
              className={styles.excelButton}
              startIcon={
                <Icon>
                  <ExcelIcon />
                </Icon>
              }
              onClick={handleSubmit(handleDownloadTemplate)}
            >
              <Loading text={"Download Template"} isLoading={isDownloading} />
            </Button>

            <div
              style={{
                display: "inline",
                visibility: "visible",
              }}
              {...getRootProps({ className: styles.dropzone })}
            >
              <input {...getInputProps()} />
              <Button
                className={styles.excelButton}
                style={{ marginLeft: "10px" }}
                startIcon={
                  <Icon>
                    <ExcelIcon />
                  </Icon>
                }
              >
                <Loading
                  text={"Upload Supplier Detail"}
                  isLoading={isUploading}
                />
              </Button>
            </div>
          </div>

          <Button
            className={styles.selectItemsButton}
            onClick={handleSubmit(onSubmit)}
          >
            Select Items
          </Button>
        </div>
      </div>
    </>
  );
};

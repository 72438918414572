import { useState } from "react";
import "core-js";
import { getFromLocalStorage, setInLocalStorage } from "lib/LocalStorage";
import { IBranch } from "types/Branch";

function useLocalStorage<T>(key: string): [T, (s: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      return getFromLocalStorage(key);
    } catch (error) {
      console.log(error);
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      setInLocalStorage(key, valueToStore);
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
}

export function useLocalBranch(
  defaultValue?: IBranch
): [IBranch | null, (s: IBranch) => void] {
  const [branch, setBranch] = useLocalStorage<IBranch | null>("selectedBranch");
  if (!branch && defaultValue) {
    setBranch(defaultValue);
  }
  return [branch, setBranch];
}

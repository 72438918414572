import React, { createContext, useState, useEffect } from "react";
import { useQueryMaterials } from "hooks/Materials";
import { IMaterial } from "types/Material";
import { QueryStatus } from "react-query";
import { MaterialType } from "types/MaterialType";
import { FMSError } from "types/Error";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import { useQueryBranches } from "hooks/Branches";
import { MaterialClassification } from "types/MaterialClassification";

export interface IMaterialContextInterface {
  allMaterialsMap: Map<string, IMaterial>;
  allMaterialsArr: IMaterial[];
  allMaterialsMapForFoodAnalysis: Map<string, IMaterial>;
  allMaterialsArrForFoodAnalysis: IMaterial[];
  rawMaterialsMapForFoodAnalysis: Map<string, IMaterial>;
  rawMaterialsArrForFoodAnalysis: IMaterial[];
  semiPreparedMaterialsMapForFoodAnalysis: Map<string, IMaterial>;
  semiPreparedMaterialsArrForFoodAnalysis: IMaterial[];
  sellableMaterialsMapForFoodAnalysis: Map<string, IMaterial>;
  sellableMaterialsArrForFoodAnalysis: IMaterial[];
}

export interface IBranchMaterialContextInterface {
  branchWiseMaterialsMap: Map<string, IMaterialContextInterface>;
  fetchingStatus: QueryStatus | "not_started" | "completed";
}

export const MaterialContext = createContext<IBranchMaterialContextInterface>({
  branchWiseMaterialsMap: new Map<string, IMaterialContextInterface>(),
  fetchingStatus: "not_started",
});

const addMaterialToMaterialContext = (
  material: IMaterial,
  materialsContext: IMaterialContextInterface,
  branchId: string
) => {
  materialsContext.allMaterialsArr.push(material);
  materialsContext.allMaterialsMap.set(material.id, material);

  if (
    material.classification === MaterialClassification.HOUSEKEEPING
  ) {
    // Housekeeping materials are only for purchase order currently
    return;
  }

  materialsContext.allMaterialsArrForFoodAnalysis.push(material);
  materialsContext.allMaterialsMapForFoodAnalysis.set(material.id, material);
  switch (material.type) {
    case MaterialType.RAW:
      materialsContext.rawMaterialsMapForFoodAnalysis.set(
        material.id,
        material
      );
      materialsContext.rawMaterialsArrForFoodAnalysis.push(material);
      break;
    case MaterialType.SEMI_PREPARED:
      materialsContext.semiPreparedMaterialsMapForFoodAnalysis.set(
        material.id,
        material
      );
      materialsContext.semiPreparedMaterialsArrForFoodAnalysis.push(material);
      break;
    case MaterialType.SELLABLE:
      materialsContext.sellableMaterialsMapForFoodAnalysis.set(
        material.id,
        material
      );
      materialsContext.sellableMaterialsArrForFoodAnalysis.push(material);
      break;
    default:
      throw new FMSError("Code unreachable");
  }

  if (material.considerAsRawMaterialInBranchIds?.includes(branchId)) {
    materialsContext.rawMaterialsMapForFoodAnalysis.set(material.id, material);
    materialsContext.rawMaterialsArrForFoodAnalysis.push(material);
  }
};

const MaterialProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { status, materials } = useQueryMaterials();
  const { branches } = useQueryBranches();

  const [branchWiseMaterialsMap, setBranchWiseMaterialsMap] = useState(
    new Map<string, IMaterialContextInterface>()
  );

  const [fetchingStatus, setFetchingStatus] = useState<
    QueryStatus | "not_started" | "completed"
  >(status);

  useEffect(() => {
    if (status === "success" && materials && branches) {
      const branchWiseMaterials = new Map<string, IMaterialContextInterface>();
      branches.forEach((branch) => {
        branchWiseMaterials.set(branch.id, {
          allMaterialsArr: [],
          allMaterialsArrForFoodAnalysis: [],
          rawMaterialsArrForFoodAnalysis: [],
          semiPreparedMaterialsArrForFoodAnalysis: [],
          sellableMaterialsArrForFoodAnalysis: [],
          allMaterialsMap: new Map<string, IMaterial>(),
          allMaterialsMapForFoodAnalysis: new Map<string, IMaterial>(),
          rawMaterialsMapForFoodAnalysis: new Map<string, IMaterial>(),
          semiPreparedMaterialsMapForFoodAnalysis: new Map<string, IMaterial>(),
          sellableMaterialsMapForFoodAnalysis: new Map<string, IMaterial>(),
        });
      });

      for (let i = 0; i < materials.length; i++) {
        const material = materials[i];
        material.displayUnitOfMeasure = displayNameForUnitOfMeasure(
          material.unitOfMeasure
        );

        if (material.scopedToBranchIds === undefined) {
          branchWiseMaterials.forEach((materialsContext, branchId) => {
            addMaterialToMaterialContext(material, materialsContext, branchId);
          });
        } else {
          material.scopedToBranchIds.forEach((branchId) => {
            const materialsContext = branchWiseMaterials.get(branchId);
            if (materialsContext) {
              addMaterialToMaterialContext(
                material,
                materialsContext,
                branchId
              );
            } else {
              throw new FMSError("Unknown branchId " + branchId);
            }
          });
        }
      }

      setBranchWiseMaterialsMap(branchWiseMaterials);
      setFetchingStatus("completed");
    }
  }, [status, materials, branches]);
  return (
    <MaterialContext.Provider
      value={{
        branchWiseMaterialsMap,
        fetchingStatus: fetchingStatus,
      }}
    >
      {children}
    </MaterialContext.Provider>
  );
};

export default MaterialProvider;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function StockTransferIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={15.565}
      height={13.997}
      viewBox="0 0 15.565 13.997"
    >
      <defs>
        <style>
          {".stockTransferIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke:" +
            primaryTextColor +
            ";stroke-width:.8px}"}
        </style>
      </defs>
      <path
        className="stockTransferIconComponent__a"
        d="M3.482.55a.513.513 0 00-.726 0L.608 2.701a.514.514 0 000 .726l2.148 2.151a.513.513 0 00.726-.726L2.208 3.577h12.444a.513.513 0 100-1.026H2.208l1.274-1.275a.514.514 0 000-.726zm0 0M12.138 13.446a.513.513 0 00.726 0l2.151-2.151a.513.513 0 000-.726l-2.151-2.151a.513.513 0 00-.726.726l1.271 1.276H.968a.513.513 0 100 1.026h12.441l-1.275 1.275a.513.513 0 000 .726zm0 0"
      />
    </svg>
  );
}
const StockTransferIcon = React.memo(StockTransferIconComponent);
export default StockTransferIcon;

import { jsonConfig } from "configs/ConfigProvider";
import { Environment } from "configs/Environment";
import { currentNamespace, Namespace } from "configs/Namespace";

export class Config {
  private static instance: Config;
  private environment: Environment;
  private namespace: Namespace;
  private config: ReturnType<typeof jsonConfig>;

  private constructor() {
    const env = process.env.REACT_APP_ENV;
    this.namespace = currentNamespace("wadeshwar");
    if (env === "dev" ||  env === "stage") {
      this.environment = Environment.DEV;
    } else {
      this.environment = Environment.PRODUCTION;
    }

    this.config = jsonConfig(this.namespace, this.environment);
  }

  public static getInstance() {
    if (!Config.instance) {
      Config.instance = new Config();
    }
    return Config.instance;
  }

  public getFirebaseConfig() {
    return this.config.firebase;
  }

  public getEnvironment() {
    return this.environment;
  }

  public getHost() {
    return this.config.server.host;
  }

  public getNamespace() {
    return this.namespace;
  }

  public getFileStoragePath() {
    return `/${this.getNamespace()}/${this.environment}/`;
  }

  public getSupportedCountries() {
    // Order is important, do not change
    return [
      {
        countryCode: "IN",
        prefix: "+91",
      },
      {
        countryCode: "US",
        prefix: "+1",
      },
    ];
  }
}

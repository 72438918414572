import { useQuery, QueryStatus, queryCache } from "react-query";
import { getBranches } from "api/GetBranch";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";

interface IQueryBranch {
  status: QueryStatus;
  branches: IBranch[] | undefined;
}

export const useQueryBranches = (): IQueryBranch => {
  const { status, data: branches } = useQuery("branches", async () => {
    const allBranches = await getBranches();
    if (allBranches instanceof FMSError) {
      return [];
    }
    return allBranches.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
  });
  return { status, branches };
};

export const invalidateBranchesCache = () => {
  queryCache.invalidateQueries("branches");
};

/// <reference types="@types/segment-analytics" />

import React, { createContext } from "react";
import { IEmployee } from "types/Employee";

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

export interface ILoggerContextInterface {
  logPageView: () => void;
  setUser: (user?: IEmployee) => void;
}

export const LoggerContext = createContext<ILoggerContextInterface>(undefined!);

export const LoggerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <LoggerContext.Provider
      value={{
        logPageView: () => {
          window.analytics.page();
        },
        setUser: (user) => {
          if (user) {
            analytics.identify(user.id, {
              name: user.name,
              email: user.email,
            });
          } else {
            window.analytics.reset();
          }
        },
      }}
    >
      {children}
    </LoggerContext.Provider>
  );
};

import { useQuery, QueryStatus } from "react-query";
import { FMSError } from "types/Error";
import { getEmployees } from "components/employeesTable/Employees";
import { IApiUser } from "types/User";

interface IQueryEmployee {
  status: QueryStatus;
  employees: IApiUser[] | undefined;
}

export const useQueryEmployees = (): IQueryEmployee => {
  const { status, data: employees } = useQuery("employees", async () => {
    const allEmployees = await getEmployees();
    if (allEmployees instanceof FMSError) {
      return [];
    }
    return allEmployees;
  });
  return { status, employees };
};

import ContentPage from "components/contentPage/ContentPage";
import React from "react";
import AddSupplierComponent from "components/addSupplierComponent/AddSupplierComponent";

const AddSupplier: React.FC = React.memo(() => {
  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = (props) => {
  return <></>;
};

const MainSection: React.FC = (props) => {
  return <AddSupplierComponent />;
};

const RightSection: React.FC = () => {
  return <></>;
};

export default AddSupplier;

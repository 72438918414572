import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { getDashboardView } from "api/GetDashboard";
import { IDashboardViewForBranch } from "types/DashboardView";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  dashboardViews: IDashboardViewForBranch[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryDashboards = (
  startDate: Date | null,
  endDate: Date | null
): IQueryBranch => {
  const [start, end] = (startDate && endDate) ? formatStartEndDates(startDate, endDate) : [null, null];
  const { status, data: dashboardViews, isFetching } = useQuery(
    ["dashboards", start, end],
    async (_: string, start: string | null, end: string | null) => {
      if (start === null || end === null) {
        return [];
      }

      return await getDashboardView(start, end);
    }
  );
  return { status, dashboardViews, isFetching };
};

export const invalidateStockTransferCache = () => {
  queryCache.invalidateQueries("dashboards");
};

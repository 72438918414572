import React, { useContext, useEffect, useState } from "react";
import { IBranch } from "types/Branch";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import styles from "./ModifyWastageModal.module.css";
import {
  Avatar,
  Dialog,
  IconButton,
  Button,
  Input,
  CircularProgress,
} from "@material-ui/core";
import { DataTable } from "components/dataTable/DataTable";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import moment from "moment";
import { useQueryWastageEntries } from "hooks/WastageFoods";
import CreateIcon from "@material-ui/icons/Create";
import { useMutation } from "react-query";
import { updateWastageEntry } from "api/PostWastage";
import { deleteWastage } from "api/DeleteWastage";
import { useHistory, useLocation } from "react-router-dom";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import {
  displayNameForUnitOfMeasure,
  IDisplayQuantity,
  MeasurementUnit,
} from "types/MeasurementUnit";
import { FMSError } from "types/Error";
import { IWastageEntryView } from "types/WastageFood";
import DeleteIcon from "assets/DeleteIcon";

interface IProps {
  startDate: Date;
  endDate: Date;
  materialId: string;
  materialName: string;
  selectedBranch: IBranch;
  onClose: (isWastageEntryUpdated: boolean) => void;
}

interface IPostWastageEntry {
  id: string;
  quantity: number;
  unit: MeasurementUnit;
}

const EditableQuantity = (props: {
  wastageEntry: IWastageEntryView;
  showToast: (props: IToastBasicProps) => void;
  quantityUpdated: (newQuantity: IDisplayQuantity) => void;
}) => {
  const { showToast, wastageEntry, quantityUpdated } = props;
  const wastageId = wastageEntry.id;
  const initialQuantity = wastageEntry.displayQuantity;
  const [showEditView, setShowEditView] = useState(false);
  const [quantityObj, setQuantityObj] = useState(initialQuantity);
  const [isEditing, setIsEditing] = useState(false);

  const [mutateForUpdateWastageEntry] = useMutation(
    (post: IPostWastageEntry) => {
      const value: number = post.quantity;

      let shouldConvertToGmsOrMl =
        post.unit === MeasurementUnit.KILOGRAMS ||
        post.unit === MeasurementUnit.LITRE;

      const convertedQuantity = shouldConvertToGmsOrMl ? value * 1000 : value;
      setIsEditing(true);
      return updateWastageEntry(post.id, convertedQuantity);
    },
    {
      onSuccess: (error) => {
        setIsEditing(false);
        if (!error) {
          showToast({
            open: true,
            message: "Wastage quantity updated successfully",
            type: "success",
          });
          initialQuantity.quantity = quantityObj.quantity;
          quantityUpdated(quantityObj);
          setShowEditView(false);
        } else {
          setQuantityObj(initialQuantity);
          const message = error.message ?? "Something went wrong!";
          showToast({
            open: true,
            message: message,
            type: "error",
          });
        }
      },
    }
  );

  return (
    <>
      {showEditView && (
        <>
          <Input
            type="number"
            className={styles.quantityText}
            value={quantityObj.quantity}
            onChange={(e) => {
              const value = parseFloat(e.target.value);
              const quantity = isNaN(value) ? 0 : value;
              setQuantityObj({
                unit: quantityObj.unit,
                quantity: quantity,
              });
            }}
          />
          <button
            onClick={async () => {
              if (quantityObj.quantity > 0) {
                if (quantityObj.quantity === initialQuantity.quantity) {
                  setShowEditView(false);
                } else {
                  mutateForUpdateWastageEntry({
                    ...quantityObj,
                    id: wastageId,
                  });
                }
              } else {
                setQuantityObj(initialQuantity);
                showToast({
                  open: true,
                  message: "Enter a valid quantity (positive number)",
                  type: "error",
                });
              }
            }}
          >
            ✓
          </button>
          <button
            onClick={() => {
              setQuantityObj(initialQuantity);
              setShowEditView(false);
            }}
          >
            X
          </button>
          {"  " + displayNameForUnitOfMeasure(quantityObj.unit)}
          {isEditing && (
            <CircularProgress
              style={{
                marginLeft: 5,
              }}
              size={15}
              color={"inherit"}
              className={styles.loading}
            />
          )}
        </>
      )}

      {!showEditView && (
        <span
          onClick={() => {
            setShowEditView(true);
          }}
        >
          {`${quantityObj.quantity} ${displayNameForUnitOfMeasure(
            quantityObj.unit
          )}`}{" "}
          <CreateIcon style={{ fontSize: 15 }} />
        </span>
      )}
    </>
  );
};

export const ModifyWastageModal: React.FC<IProps> = (props) => {
  const {
    startDate,
    endDate,
    materialId,
    materialName,
    selectedBranch,
    onClose,
  } = props;

  const [showModal, setShowModal] = useState(true);
  const [selectedWastageEntry, setSelectedWastageEntry] = useState({
    id: "",
    materialName: "",
    quantity: 0,
    unit: undefined,
  });
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(
    false
  );
  const [isUpdated, setIsUpdated] = useState(false);
  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const { status, wastageEntriesFetched, isFetching } = useQueryWastageEntries(
    props.selectedBranch.id,
    props.materialId,
    startDate,
    endDate
  );
  const [wastageEntries, setWastageEntries] = useState<IWastageEntryView[]>([]);

  useEffect(() => {
    if (status === "success") {
      if (!wastageEntriesFetched || wastageEntriesFetched instanceof FMSError) {
        const message =
          wastageEntriesFetched?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setWastageEntries(wastageEntriesFetched);
      }
    }
  }, [status, wastageEntriesFetched, props.selectedBranch]);

  const hideModal = (isWastageEntryUpdated: boolean = false) => {
    setShowModal(false);
    onClose(isUpdated || isWastageEntryUpdated);
  };

  const onDeleteWastage = async (wastageId: string) => {
    setIsDeleting(true);
    const error = await deleteWastage(wastageId);
    setIsDeleting(false);
    setShowDeleteConfirmation(false);
    if (error == null) {
      setShowToast({
        open: true,
        message: "Deleted successfully",
        type: "success",
      });

      const entries = wastageEntries.filter((e: IWastageEntryView) => {
        return e.id !== wastageId;
      });

      if (entries.length === 0) {
        hideModal(true);
      } else {
        setWastageEntries(entries);
        setIsUpdated(true);
      }
    } else {
      setShowToast({
        open: true,
        message: error.message,
        type: "error",
      });
    }
  };

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const columns = React.useMemo(
    () => [
      {
        Header: "Wastage Entry Table",
        columns: [
          {
            Header: "Added By",
            accessor: "employeeView.name",
            width: 200,
            disableFilters: true,
          },
          {
            Header: "Quantity",
            width: 200,
            Cell: (props: any) => {
              const wastageEntry = props.row.original as IWastageEntryView;
              return (
                <>
                  <EditableQuantity
                    wastageEntry={wastageEntry}
                    showToast={setShowToast}
                    quantityUpdated={(newQuantity) => {
                      setIsUpdated(true);
                      wastageEntry.displayQuantity = newQuantity;
                      wastageEntry.lastUpdated = new Date();
                    }}
                  />
                </>
              );
            },
            disableFilters: true,
          },
          {
            Header: "Date added",
            width: 210,
            Cell: (value: any) =>
              moment(value.row.original.date).format("DD/MM/YYYY"),
            disableFilters: true,
          },
          {
            Header: " ",
            accessor: "id",
            width: 50,
            Cell: (value: any) => (
              <Avatar
                className={styles.icon}
                style={{ backgroundColor: primaryColor }}
                onClick={() => {
                  setSelectedWastageEntry({
                    id: value.row.original.id,
                    materialName: materialName,
                    quantity: value.row.original.displayQuantity.quantity,
                    unit: value.row.original.displayQuantity.unit,
                  });
                  setShowDeleteConfirmation(true);
                }}
              >
                <DeleteIcon />
              </Avatar>
            ),
            disableFilters: true,
          },
        ],
      },
    ],
    [materialName, primaryColor]
  );

  const history = useHistory();
  const queryStr = useLocation().search;
  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <ConfirmationDialog
        buttonText={"Delete"}
        bodyText={`Do you want to delete ${
          selectedWastageEntry.materialName
        } of quantity: ${
          selectedWastageEntry.quantity
        } ${displayNameForUnitOfMeasure(selectedWastageEntry.unit)}?`}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onApprove={() => onDeleteWastage(selectedWastageEntry.id)}
        isApproving={isDeleting}
      />
      <Dialog
        className={styles.modalRoot}
        open={showModal}
        maxWidth={"xl"}
        onClose={() => hideModal()}
        scroll={"body"}
        disableBackdropClick
      >
        <div
          className={styles.modalTitleBox}
          style={{
            backgroundColor: primaryColor,
            color: primaryTextColor,
          }}
        >
          <div className={styles.date}>
            <div style={{ height: "20px" }}>
              From:&nbsp;{moment(startDate).format("DD/MM/YYYY")}
            </div>
            <div style={{ height: "20px" }}>
              To:&nbsp;{moment(endDate).format("DD/MM/YYYY")}
            </div>
          </div>
          <div className={styles.modalTitleText}>
            <div className={styles.branch}>{selectedBranch.name}</div>
            {materialName}
            <div className={styles.text}>Individual Wastage Entries</div>
          </div>
          <div className={styles.modalCloseIcon}>
            <IconButton
              name="close"
              color="inherit"
              onClick={() => hideModal()}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.toGraphBtn}>
          <Button
            className={styles.submitButton}
            style={{
              marginLeft: "10px",
              color: primaryTextColor,
              backgroundColor: primaryColor,
            }}
            color="primary"
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `/wastage-stats/${materialId}`,
                search: queryStr,
              });
            }}
          >
            View Graphs
          </Button>
        </div>
        <DataTable
          columns={columns}
          data={wastageEntries}
          isLoading={isFetching}
          tableHeight={350}
        />
      </Dialog>
    </>
  );
};

import { IBranch } from "types/Branch";
import { IMaterial } from "types/Material";
import React, { useContext, useState } from "react";
import { ModalContext } from "contexts/ModalContext";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { IStockPost } from "types/Stock";
import { StockType } from "types/StockType";
import { addStock } from "api/PostStock";
import { useMutation } from "react-query";
import { invalidateStockCache } from "hooks/Stock";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import styles from "./AddRawOrClosingMaterialModal.module.css";
import { useForm } from "react-hook-form";
import Toast from "components/Toast/Toast";
import { RawMaterialInputTab } from "./RawOrClosingMaterialInputTab";
import { MaterialContext } from "contexts/MaterialsContext";

interface IProps {
  errorMaterialsArr: IMaterial[];
  selectedBranch: IBranch;
}

const removeEmpty = (stockRecords: IStockPost[]) => {
  return stockRecords.filter(
    (stockRecord) =>
      stockRecord.materialId &&
      stockRecord.materialId.length > 0 &&
      stockRecord.measurementQuantity &&
      stockRecord.measurementQuantity > 0
  );
};

export const AddInsufficientRawMaterialModal: React.FC<IProps> = (props) => {
  const { errorMaterialsArr, selectedBranch } = props;
  const { handleSubmit, getValues, control } = useForm();
  const materialContextValues = useContext(
    MaterialContext
  ).branchWiseMaterialsMap.get(selectedBranch.id)!;

  const modalProps = useContext(ModalContext);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [mutate] = useMutation(
    (stockRecords: IStockPost[]) => {
      return addStock(
        stockRecords,
        selectedDate!,
        StockType.ADDITION,
        selectedBranch
      );
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStockCache();
          modalProps.hideModal(true, "Raw stock added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const errorMaterialsMap = new Map<string, IMaterial>();
  const errorMaterialsFinalArr: IMaterial[] = [];
  errorMaterialsArr.forEach((material) => {
    materialContextValues.rawMaterialsArrForFoodAnalysis.forEach(
      (materials) => {
        if (materials.id === material.id) {
          errorMaterialsFinalArr.push(materials);
          errorMaterialsMap.set(materials.id, materials);
        }
      }
    );
  });

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const errorMaterial: IStockPost[] = data.errorMaterials.materials;
    const stockRecords = removeEmpty(errorMaterial);

    if (stockRecords.length === 0) {
      setToastMessage("Atleast one stock item is required!");
      setShowToast(true);
      setSubmitting(false);
      return;
    }

    mutate(stockRecords);
  };

  if (errorMaterialsArr.length === 0) {
    return <></>;
  }

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Raw Material</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />
      <div className={styles.modalBody}>
        <RawMaterialInputTab
          tabValue={0}
          index={0}
          name="errorMaterials"
          getValues={getValues}
          control={control}
          type={StockType.ADDITION}
          materialsArr={errorMaterialsFinalArr}
          materialsMap={errorMaterialsMap}
          favourites={errorMaterialsFinalArr}
        />
      </div>
    </ModalPopup>
  );
};

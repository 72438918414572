import { BACKEND_URL_V1, post } from "lib/Api";
import { FMSError } from "types/Error";
import { IPostGrn } from "types/PurchaseOrder";

export const postGrn = async (
  purchaseOrderId: string,
  postGrnRequest: IPostGrn
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/purchaseOrders/${purchaseOrderId}/receive`,
      postGrnRequest
    );

    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "GRN could not be generated";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

import React from "react";
export const TicksAndCrossesCell: React.FC<{
  bool: boolean;
}> = (props) => {
  let color: string = "";
  let symbol: string = "";

  if (props.bool === true) {
    color = "green";
    symbol = "\u2714";
  } else if (props.bool === false) {
    color = "red";
    symbol = "\u2716";
  }

  return (
    <>
      <div style={{ textAlign: "center", color: `${color}` }}> {symbol} </div>
    </>
  );
};

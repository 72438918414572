import { IMaterial } from "./Material";

export interface IFavourites {
  rawStock: IMaterial[];
  wastage: IMaterial[];
  staffFood: IMaterial[];
  stockTransfer: IMaterial[];
  dashboard: IMaterial[];
}

export interface IBranch extends IExternalBranch {
  favouriteRawStockMap: Map<string, IMaterial>;
  favouriteWastageMap: Map<string, IMaterial>;
  favouriteStaffFoodMap: Map<string, IMaterial>;
  favouriteStockTransfer: Map<string, IMaterial>;
  favouriteDashboardMap: Map<string, IMaterial>;
}

export interface IExternalBranch {
  id: string;
  name: string;
  firmName?: string;
  gstNumber?: string;
  address?: string;
  favourites?: IFavourites;
  billingAddress?: string;
  deliveryAddresses?: string[];
}

export enum BusniessUnit {
  WADESHWAR_RESTAURANTS = "WADESHWAR_RESTAURANTS",
  SMILE_STONE_MOTELS = "SMILE_STONE_MOTELS",
  KALPAK_CATERERS = "KALPAK_CATERERS",
  WADESHWAR_BHUVAN = "WADESHWAR_BHUVAN",
}

export const displayNameForBusinessUnit = (busniessUnit: BusniessUnit) => {
  switch (busniessUnit) {
    case BusniessUnit.WADESHWAR_RESTAURANTS:
      return "Wadeshwar Restaurants Pvt Ltd";
    case BusniessUnit.SMILE_STONE_MOTELS:
      return "Smile Stone Motels Pvt Ltd";
    case BusniessUnit.KALPAK_CATERERS:
      return "Kalpak Caterers Pvt Ltd";
    case BusniessUnit.WADESHWAR_BHUVAN:
      return "Shree Wadeshwar Bhuvan";
  }
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";
import { IStaffFoodPost } from "types/StaffFood";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

export const addStaffFood = async (
  staffFood: IStaffFoodPost[],
  date: Date,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/staffFood/branches/${branch.id}`,
      {
        staffFood,
        date,
      }
    );
    if (response.status === 202) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Staff Food could not be added";
    return new FMSError(
      errorMessage,
      response.serverError?.error.insufficientMaterials
    );
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const uploadStaffFood = async (
  files: File[],
  date: Date,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V1}/staffFood/branches/${
        branch.id
      }/uploadReport?date=${formatDate(date)}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );

    if (response.status === 202) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Staff_Food_Errors.xlsx"
      );
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ?? "Unable to upload Staff food";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export type IModifyStaffFoodProps = {
  servings: number;
  quantity: number;
  mealType: String;
};

export const updateStaffFoodEntry = async (
  staffFoodId: string,
  postObject: IModifyStaffFoodProps
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/staffFood/${staffFoodId}`,
      postObject
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Stock could not be updated";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

import { Button } from "@material-ui/core";
import { SupplierForm } from "components/supplierForm/SupplierForm";
import SupplierMaterialsForm from "components/supplierMaterialsForm/SupplierMaterialsForm";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ISupplierPost } from "types/Supplier";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import styles from "./AddSupplierComponent.module.css";

const AddSupplierComponent: React.FC = () => {
  const history = useHistory();

  const [supplier, setSupplier] = useState<ISupplierPost>();
  const [showMaterialsForm, setShowMaterialsForm] = useState(false);

  const onEnterSupplier = (supplier: ISupplierPost, showForm: boolean) => {
    setSupplier(supplier);
    setShowMaterialsForm(showForm);
  };

  return (
    <>
      <div className={styles.header}>
        <div style={{ display: "flex" }}>
          <Button
            className={styles.backButton}
            onClick={() => {
              if (showMaterialsForm) {
                setShowMaterialsForm(false);
              } else {
                history.goBack();
              }
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
          </Button>
          <div className={styles.pageName}>
            {`Add Supplier : Step ${!showMaterialsForm ? "1" : "2"}`}
          </div>
        </div>
      </div>
      {!showMaterialsForm && (
        <SupplierForm onEnterSupplier={onEnterSupplier} allowEdit={false} />
      )}
      {showMaterialsForm && supplier && (
        <SupplierMaterialsForm supplier={supplier} />
      )}
    </>
  );
};

export default AddSupplierComponent;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function AddIconComponent() {
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
    >
      <path
        d="M25 12.5A12.5 12.5 0 1112.5 0 12.5 12.5 0 0125 12.5z"
        fill={primaryColor}
      />
      <g fill={primaryTextColor}>
        <path d="M7.578 12.684v-.369H12.316V7.579h.369V12.316h4.737v.369H12.684v4.737h-.369V12.684H7.579z" />
        <path d="M6.578 12.685v-.37a1 1 0 011-1h3.738V7.579a1 1 0 011-1h.369a1 1 0 011 1v3.738h3.737a1 1 0 011 1v.369a1 1 0 01-1 1h-3.738v3.737a1 1 0 01-1 1h-.369a1 1 0 01-1-1v-3.738H7.579a1 1 0 01-1-1z" />
      </g>
    </svg>
  );
}

const AddIcon = React.memo(AddIconComponent);
export default AddIcon;

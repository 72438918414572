import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function WastageIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.502}
      height={14}
      viewBox="0 0 10.502 14"
    >
      <defs>
        <style>
          {".wastageIconComponent__a{fill:" + primaryTextColor + "}"}
        </style>
      </defs>
      <path
        className="wastageIconComponent__a"
        d="M4.123.815h2.256v.38h.815V.762A.762.762 0 006.432 0H4.069a.762.762 0 00-.761.762v.433h.815zm0 0M9.077 4.423H1.426a.359.359 0 00-.358.388l.64 7.908a.849.849 0 00.846.781h5.393a.849.849 0 00.846-.782l.64-7.908a.359.359 0 00-.358-.388zm-5.8 8.233h-.026a.407.407 0 01-.406-.382l-.4-6.492a.407.407 0 11.813-.05l.4 6.492a.407.407 0 01-.381.432zm2.384-.407a.407.407 0 01-.815 0V5.757a.407.407 0 11.815 0zm2.393-6.468l-.377 6.492a.407.407 0 01-.406.383h-.024a.407.407 0 01-.383-.431l.383-6.492a.407.407 0 01.813.048zm0 0M10.485 3.166l-.268-.8a.518.518 0 00-.492-.354H.777a.518.518 0 00-.491.354l-.268.8a.337.337 0 00.319.442h9.83a.332.332 0 00.178-.051.336.336 0 00.141-.391zm0 0"
      />
    </svg>
  );
}
const WastageIcon = React.memo(WastageIconComponent);
export default WastageIcon;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function StaffFoodIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.5}
      height={9.875}
      viewBox="0 0 18.5 9.875"
    >
      <defs>
        <style>
          {".staffFoodIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke:" +
            primaryTextColor +
            ";stroke-width:.5px}"}
        </style>
      </defs>
      <path
        className="staffFoodIconComponent__a"
        d="M15.25 4.375a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-1.875a.375.375 0 10.375.375.375.375 0 00-.375-.375zM17.688 8.125a.563.563 0 01-.562-.562v-.375a.939.939 0 00-.937-.937h-1.876a.563.563 0 010-1.125h1.875a2.064 2.064 0 012.062 2.062v.375a.563.563 0 01-.562.562zM3.25 4.375a1.5 1.5 0 111.5-1.5 1.5 1.5 0 01-1.5 1.5zm0-1.875a.375.375 0 10.375.375.375.375 0 00-.375-.375zM.813 8.125a.563.563 0 01-.563-.562v-.375a2.064 2.064 0 012.063-2.063h1.875a.563.563 0 010 1.125H2.313a.939.939 0 00-.937.938v.375a.563.563 0 01-.563.562zM9.25 4.75A2.25 2.25 0 1111.5 2.5a2.253 2.253 0 01-2.25 2.25zm0-3.375A1.125 1.125 0 1010.375 2.5 1.126 1.126 0 009.25 1.375zM12.813 9.625a.563.563 0 01-.562-.562V7.938a.939.939 0 00-.937-.937H7.188a.939.939 0 00-.937.938v1.125a.563.563 0 01-1.125 0V7.938a2.064 2.064 0 012.062-2.063h4.125a2.064 2.064 0 012.063 2.063v1.125a.563.563 0 01-.563.562z"
      />
    </svg>
  );
}
const StaffFoodIcon = React.memo(StaffFoodIconComponent);
export default StaffFoodIcon;

import React, { useEffect, useState, useContext } from "react";
import ScorllableList from "./ScorllableList";
import styles from "./AddEventCategoriesListView.module.css";
import {
  invalidateEventCategories,
  useQueryEventCategories,
} from "hooks/Reminders";
import {
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  IconButton,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { FMSError } from "types/Error";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import {
  displayNameForEventCategoryType,
  EventCategoryType,
  IEventCategories,
} from "types/Event";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { updateEventCategories } from "api/PostEventCategories";
import { useMutation } from "react-query";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { Loading } from "components/loading/Loading";

const AddEventCategoriesListView = () => {
  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [eventCategories, setEventCategories] = useState<IEventCategories>({
    id: "",
    businessUnits: [],
    types: [],
    items: [],
  });

  const flipModifiedFlag = () => {
    invalidateEventCategories();
  };

  const [mutateForEventCategory] = useMutation(
    (post: IEventCategories) => {
      setIsUpdatingEventCategories(true);
      return updateEventCategories(post);
    },
    {
      onSuccess: (error, response) => {
        setIsUpdatingEventCategories(false);
        if (!(error instanceof FMSError)) {
          setEventCategories(response);
          setShowToast({
            open: true,
            message: "Event Category modified successfully",
            type: "success",
          });
          flipModifiedFlag();
        } else {
          const message = "Something went wrong!";
          setShowToast({
            open: true,
            message: message,
            type: "error",
          });
        }
        hideModals();
      },
    }
  );

  const { status, eventCategoriesFetched, isFetching } =
    useQueryEventCategories();

  useEffect(() => {
    if (status === "success") {
      if (
        !eventCategoriesFetched ||
        eventCategoriesFetched instanceof FMSError
      ) {
        const message =
          eventCategoriesFetched?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setEventCategories(eventCategoriesFetched);
      }
    }
  }, [status, eventCategoriesFetched]);

  const [eventCategoryToDelete, setEventCategoryToDelete] =
    useState<string>("");
  const [selectedEventCategoryType, setSelectedEventCategoryType] =
    useState<EventCategoryType>(EventCategoryType.BUSINESS_UNIT);

  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [isUpdatingEventCategories, setIsUpdatingEventCategories] =
    useState<boolean>(false);

  const [eventCategoryText, setEventCategoryText] = useState<string>("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const hideModals = () => {
    setShowAddModal(false);
    setShowDeleteConfirmation(false);
  };

  const onAdd = (eventCategoryType: EventCategoryType) => {
    setSelectedEventCategoryType(eventCategoryType);
    setShowAddModal(true);
  };

  const addEventCategory = (
    value: string,
    eventCategoryType: EventCategoryType
  ) => {
    let eventCategoriesCopy = { ...eventCategories };
    let eventCategoryList;
    if (eventCategoryType === EventCategoryType.BUSINESS_UNIT) {
      eventCategoryList = eventCategoriesCopy.businessUnits;
    } else if (eventCategoryType === EventCategoryType.ITEMS) {
      eventCategoryList = eventCategoriesCopy.items;
    } else {
      eventCategoryList = eventCategoriesCopy.types;
    }

    if (!eventCategoryList.find((val) => val === value)) {
      eventCategoryList.push(value);
      mutateForEventCategory(eventCategoriesCopy);
    } else {
      setShowToast({
        open: true,
        message: `${eventCategoryType} entered is already present`,
        type: "error",
      });
      hideModals();
    }
  };

  const onDelete = (value: string, eventCategoryType: EventCategoryType) => {
    setEventCategoryToDelete(value);
    setSelectedEventCategoryType(eventCategoryType);
    setShowDeleteConfirmation(true);
  };

  const deleteEventCategory = (
    value: string,
    eventCategoryType: EventCategoryType
  ) => {
    let eventCategoriesCopy = { ...eventCategories };
    if (eventCategoryType === EventCategoryType.BUSINESS_UNIT) {
      let eventCategoryList = eventCategories.businessUnits.filter(
        (val) => val !== value
      );
      eventCategoriesCopy.businessUnits = eventCategoryList;
    } else if (eventCategoryType === EventCategoryType.ITEMS) {
      let eventCategoryList = eventCategories.items.filter(
        (val) => val !== value
      );
      eventCategoriesCopy.items = eventCategoryList;
    } else {
      let eventCategoryList = eventCategories.types.filter(
        (val) => val !== value
      );
      eventCategoriesCopy.types = eventCategoryList;
    }

    mutateForEventCategory(eventCategoriesCopy);
  };

  const [addEventCategoryError, setAddEventCategoryError] = useState(false);

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <div className={styles.container}>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />

      <ThemeProvider theme={materialTheme}>
        {!isFetching && (
          <div className={styles.scrollableListContainer}>
            <ScorllableList
              header={"Business Unit Master"}
              data={eventCategories.businessUnits}
              onAdd={onAdd}
              onDelete={onDelete}
              eventCategoryType={EventCategoryType.BUSINESS_UNIT}
            />
            <ScorllableList
              header={"Item Master"}
              data={eventCategories.items}
              onAdd={onAdd}
              onDelete={onDelete}
              eventCategoryType={EventCategoryType.ITEMS}
            />
            <ScorllableList
              header={"Type Master"}
              data={eventCategories.types}
              onAdd={onAdd}
              onDelete={onDelete}
              eventCategoryType={EventCategoryType.TYPES}
            />
          </div>
        )}

        {isFetching && (
          <div className={styles.circularProgress}>
            <CircularProgress color={"inherit"} />
          </div>
        )}

        <Dialog
          className={styles.modalRoot}
          open={showAddModal}
          maxWidth={"xl"}
          onClose={hideModals}
          scroll={"body"}
          disableBackdropClick
        >
          <div className={styles.addModalCloseIcon}>
            <IconButton name="close" color="inherit" onClick={hideModals}>
              <CloseRoundedIcon />
            </IconButton>
          </div>
          <div className={styles.addModalcontent}>
            <div className={styles.modalBody}>
              <TextField
                type={"text"}
                label={`Add ${displayNameForEventCategoryType(
                  selectedEventCategoryType
                )}*`}
                fullWidth
                margin="normal"
                variant="outlined"
                name="name"
                error={addEventCategoryError}
                onChange={(event) => {
                  setAddEventCategoryError(false);
                  setEventCategoryText(event.target.value);
                }}
              />
            </div>

            <Button
              style={{ backgroundColor: primaryColor, color: primaryTextColor }}
              onClick={() => {
                if (eventCategoryText.trim().length === 0) {
                  setAddEventCategoryError(true);
                  return;
                }
                addEventCategory(eventCategoryText, selectedEventCategoryType);
              }}
              className={styles.addSubmitBtn}
            >
              <Loading text={"Add"} isLoading={isUpdatingEventCategories} />
            </Button>
          </div>
        </Dialog>

        <ConfirmationDialog
          buttonText={"Delete"}
          bodyText={`Do you want to delete ${eventCategoryToDelete} from ${selectedEventCategoryType}?`}
          open={showDeleteConfirmation}
          onClose={hideModals}
          onApprove={() =>
            deleteEventCategory(
              eventCategoryToDelete,
              selectedEventCategoryType
            )
          }
          isApproving={isUpdatingEventCategories}
        />
      </ThemeProvider>
    </div>
  );
};

export default AddEventCategoriesListView;

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IApiUser } from "types/User";

interface IGetEmployees {
  employeeList: IApiUser[];
}

export const getEmployees = async (): Promise<IApiUser[] | FMSError> => {
  try {
    const response = await get<IGetEmployees>(`${BACKEND_URL_V1}/employees`);
    if (!response.parsedBody?.employeeList) {
      return new FMSError("Employees could not be retrieved");
    }
    return response.parsedBody.employeeList;
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

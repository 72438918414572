import moment from "moment";
import {
  IBranchWiseReport,
  IDateWiseReport,
  IEntryReportRow,
} from "types/EntryReport";
import { IBranch } from "types/Branch";

export function getEntryData(
  entryReport: IDateWiseReport,
  branchMap: Map<string, IBranch>
): { tabs: Array<string>; data: Map<string, Array<IEntryReportRow>> } {
  const dateWiseMap = new Map(Object.entries(entryReport));
  const dateArr = Array.from(dateWiseMap, ([name, value]) => name);

  const sortedDateArr = dateArr.sort((a, b) =>
    moment(a, "DD MMM YYYY").diff(moment(b, "DD MMM YYYY"))
  );

  const data = new Map<string, Array<IEntryReportRow>>();
  sortedDateArr.forEach((date) => {
    const branchWiseEntryReport = dateWiseMap.get(date) as IBranchWiseReport;
    if (!branchWiseEntryReport) {
      return;
    }

    const rows = new Array<IEntryReportRow>();
    const branchWiseReport = new Map(Object.entries(branchWiseEntryReport));
    branchWiseReport.forEach((value, branchId) => {
      const branch = branchMap.get(branchId);
      if (!branch) {
        return;
      }

      const row: IEntryReportRow = {
        staffFood: value.staffFood ? true : false,
        wastage: value.wastage ? true : false,
        sale: value.sale ? true : false,
        stockTransfer: value.stockTransfer ? true : false,
        stock: value.stock ? true : false,
        complimentaryFood: value.complimentaryFood ? true : false,
        closing: value.closing ? true : false,
        branchName: branch.name,
      };
      rows.push(row);
    });

    data.set(date, rows);
  });

  return {
    tabs: sortedDateArr,
    data,
  };
}

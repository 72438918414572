import Button from "@material-ui/core/Button";
import styles from "./PredictionsPreparedMaterialTable.module.css";
import { DataTableWithDates } from "components/dataTableWithDates/DataTableWithDates";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import React, { useEffect, useState } from "react";
import { IBranch } from "types/Branch";
import { IBranchWiseMaterialPrediction } from "types/BranchWiseMaterialPrediction";
import { FMSError } from "types/Error";
import { IMaterialPrediction } from "types/MaterialPrediction";
import { displayQuantity } from "types/MeasurementUnit";
import { useQueryPredictionsPreparedMaterial } from "hooks/PredictionsPreparedMaterial";

export const predictionsRawMaterialForBranch = (
  predictionsRawMaterialWithBranches: IBranchWiseMaterialPrediction[],
  selectedBranch: IBranch
): IMaterialPrediction[] => {
  for (let i = 0; i < predictionsRawMaterialWithBranches.length; i++) {
    if (predictionsRawMaterialWithBranches[i].branchId === selectedBranch.id) {
      return predictionsRawMaterialWithBranches[i].materialPredictionViews;
    }
  }
  return [];
};

export const PredictionsPreparedMaterialTable: React.FC<{
  selectedBranch: IBranch;
}> = (props) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();

  const { status, branchWiseMaterialPredictionViews, isFetching } =
    useQueryPredictionsPreparedMaterial(start, end);
  const [branchWiseMaterialPrediction, setBranchWiseMaterialPrediction] =
    useState<IMaterialPrediction[]>([]);

  useEffect(() => {
    if (status === "success") {
      if (
        !branchWiseMaterialPredictionViews ||
        branchWiseMaterialPredictionViews instanceof FMSError
      ) {
        const message =
          branchWiseMaterialPredictionViews?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setBranchWiseMaterialPrediction(
          predictionsRawMaterialForBranch(
            branchWiseMaterialPredictionViews,
            props.selectedBranch
          )
        );
      }
    }
  }, [status, branchWiseMaterialPredictionViews, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            width: 436,
            disableFilters: true,
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.materialName.toUpperCase() >
                rowB.original.materialName.toUpperCase()
                ? 1
                : -1;
            },
            Cell: (props: any) => {
              const materialPrediction = props.row
                .original as IMaterialPrediction;
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {materialPrediction.materialName}
                </Button>
              );
            },
          },
          {
            Header: "Expected Consumption",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.quantity > rowB.original.quantity ? 1 : -1;
            },
            accessor: (row: IMaterialPrediction) => displayQuantity(row.expectedConsumptionDisplayQuantity),
            width: 270,
            disableGlobalFilter: true,
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <div className={styles.container}>
        <DataTableWithDates
          isLoading={isFetching}
          columns={columns}
          data={branchWiseMaterialPrediction}
          tableClass={styles.table}
          selectedDate={selectedDate!}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          handleDateChange={handleDateChange}
          handleDateChangeFrom={handleDateChangeFrom}
          handleDateChangeTo={handleDateChangeTo}
          initialSortBy={"materialName"}
          disableFuture={false}
        />
      </div>
    </>
  );
};

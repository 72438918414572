import { IStats } from "types/Stats";
import moment from "moment";
import { ChartCoordinateData } from "types/Chart";

export function getStatsData(
  stats: IStats
): { dailyStats: ChartCoordinateData[]; monthlyStats: ChartCoordinateData[] } {
  const { DAILY, MONTHLY } = stats;
  const DAILYMap = new Map(Object.entries(DAILY));
  const dailyArr = Array.from(
    DAILYMap,
    ([name, value]) =>
      ({
        x: name,
        y: value,
      } as ChartCoordinateData)
  );
  const MonthlyMap = new Map(Object.entries(MONTHLY));
  const monthlyArr = Array.from(
    MonthlyMap,
    ([name, value]) =>
      ({
        x: name,
        y: value,
      } as ChartCoordinateData)
  );
  const sortedMonthlyArr = monthlyArr.sort((a, b) =>
    moment(a.x, "MMM YYYY").diff(moment(b.x, "MMM YYYY"))
  );
  const sortedDailyArr = dailyArr.sort((a, b) =>
    moment(a.x, "DD MMM YYYY").diff(moment(b.x, "DD MMM YYYY"))
  );

  return {
    dailyStats: sortedDailyArr,
    monthlyStats: sortedMonthlyArr,
  };
}

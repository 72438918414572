import styles from "./ConfirmationDialog.module.css";
import React, { ReactNode, useContext } from "react";
import classNames from "classnames";
import { Button, Dialog, IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IConfirmationDialog {
  bodyText?: string;
  body?: ReactNode;
  buttonText: string;
  secondButtonText?: string;
  className?: string;
  open: boolean;
  onClose: (reason?: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void;
  onApprove: () => void;
  onDeny?: () => void;
  isApproving: boolean;
}

export const ConfirmationDialog: React.FC<IConfirmationDialog> = (props) => {
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  return (
    <Dialog maxWidth="lg" open={props.open} onClose={(_, reason) => props.onClose(reason)}>
      <div className={styles.modalCloseIcon}>
        <IconButton name="close" color="inherit" onClick={() => { props.onClose("closeButtonClick") }} disabled={props.isApproving}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className={classNames(styles.approval, props.className)}>
        <div className={styles.body}>
          {props.bodyText && (
            <div className={styles.approvalText}>{props.bodyText}</div>
          )}
          {!props.bodyText && props.body}
        </div>
        <div
          style={{
            display: "inline",
            paddingBottom: "20px",
          }}
        >
          <Button
            onClick={props.onApprove}
            className={styles.approvalButton}
            style={{ backgroundColor: primaryColor, color: primaryTextColor }}
            disabled={props.isApproving}
          >
            <Loading text={props.buttonText} isLoading={props.isApproving} />
          </Button>
          {props.secondButtonText && (
            <Button
              onClick={props.onDeny}
              className={styles.approvalButton}
              style={{
                backgroundColor: "#d9d9d9",
                color: "#4d4d4d",
                marginLeft: "10px",
              }}
              disabled={props.isApproving}
            >
              <Loading
                text={props.secondButtonText}
                isLoading={false}
              />
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

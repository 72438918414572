import React, { useState, useEffect } from "react";
import { DataTable } from "components/dataTable/DataTable";
import styles from "./EmployeesTable.module.css";
import { IApiUser, IApiUserDisplay } from "types/User";
import { useQueryEmployees } from "hooks/Employees";
import { deleteUser } from "api/PostUser";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import DeleteIcon from "@material-ui/icons/Delete";
import { isStringRoleAdmin, Role } from "types/Employee";
import CreateIcon from "@material-ui/icons/Create";
import { AddUserModal } from "components/addUserModal/AddUserModal";
import { Dialog } from "@material-ui/core";
import { capitalizeFirstLetter, removeUnderscore } from "lib/StringHelpers";
import { Auth } from "lib/Auth";

function transformEmployees(employees: IApiUser[] | undefined) {
  if (!employees) {
    return [];
  }

  const employeeList: IApiUserDisplay[] = [];
  employees.forEach((employee) => {
    const { id, name, mobileNumber, role, branches, email } = employee;
    const branchNames: string[] = [];
    const branchIds: string[] = [];
    if (isStringRoleAdmin(employee.role)) {
      branchNames.push("All branches");
    } else if (branches && branches.length > 0) {
      branchNames.push(branches[0].name);
      if (branches.length > 1) {
        branchNames.push(`+ ${branches.length - 1} more branch(s)`);
      }

      branches.forEach((branch) => {
        branchIds.push(branch.id);
      });
    }

    if (role === Role.SUPER_ADMIN) {
      const currentRole = Auth.getInstance().getUser()!.role;
      if (currentRole !== Role.SUPER_ADMIN) {
        return;
      }
    }

    employeeList.push({
      id: id,
      name: name,
      mobileNumber: mobileNumber,
      email: email,
      displayRole: capitalizeFirstLetter(removeUnderscore(role)),
      role: role,
      branchName: branchNames.join(", "),
      branchIds: branchIds,
    });
  });
  return employeeList;
}

export const EmployeesTable: React.FC = () => {
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IApiUserDisplay>({
    id: "",
    name: "",
    mobileNumber: "",
    displayRole: "",
    role: "",
    email: "",
    branchName: "",
    branchIds: [],
  });
  const [isDeleting, setDeleting] = useState(false);
  const onEdit = (employee: IApiUserDisplay) => {
    setSelectedEmployee(employee);
    setShowEditDialog(true);
  };
  const openApprove = (employee: IApiUserDisplay) => {
    setShowDeleteConfirmation(true);
    setSelectedEmployee(employee);
  };
  const onDelete = async (employeeId: string) => {
    setDeleting(true);
    const error = await deleteUser(employeeId);
    if (!error) {
      setEmployees(
        allEmployees.filter((employee) => employee.id !== employeeId)
      );
    }
    setDeleting(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }
    setShowDeleteConfirmation(false);
    setShowToast({
      open: true,
      message: "The employee has been deleted",
      type: "success",
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Employees Table",
        columns: [
          {
            Header: "User Name",
            accessor: "name",
            width: 150,
            disableFilters: true,
          },
          {
            Header: "User Contact",
            accessor: "mobileNumber",
            width: 150,
            disableFilters: true,
          },
          {
            Header: "Email",
            accessor: "email",
            width: 186,
            disableFilters: true,
          },
          {
            Header: "User Role",
            accessor: "displayRole",
            width: 130,
            disableFilters: true,
          },
          {
            Header: "Branch",
            accessor: "branchName",
            width: 240,
            disableFilters: true,
          },
          {
            Header: "",
            accessor: "edit",
            width: 30,
            Cell: (props: any) => {
              const employee = props.row.original;
              return (
                <CreateIcon color="action" onClick={() => onEdit(employee)} />
              );
            },
            disableFilters: true,
          },
          {
            Header: "",
            accessor: "id",
            width: 30,
            Cell: (props: any) => {
              const employee = props.row.original;
              return (
                <DeleteIcon
                  color="action"
                  onClick={() => openApprove(employee)}
                />
              );
            },
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );
  const { status, employees } = useQueryEmployees();
  const [allEmployees, setEmployees] = useState<IApiUserDisplay[]>([]);
  useEffect(() => {
    if (status === "success" && employees) {
      setEmployees(transformEmployees(employees));
    }
  }, [status, employees, setEmployees]);
  return (
    <div>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <DataTable
        columns={columns}
        data={allEmployees}
        tableHeight={250}
        marginBottom={26}
        marginTop={13}
        tableClass={styles.table}
        isLoading={status === "loading"}
      />

      <ConfirmationDialog
        buttonText={"Delete"}
        bodyText={`Do you want to delete ${selectedEmployee.name}?`}
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onApprove={() => onDelete(selectedEmployee.id)}
        isApproving={isDeleting}
      />

      <Dialog open={showEditDialog} disableBackdropClick maxWidth={"lg"}>
        <AddUserModal
          title={"Update User"}
          buttonText={"Update"}
          selectedUser={selectedEmployee}
          onClose={(success, message) => {
            setShowEditDialog(false);
            if (message) {
              setShowToast({
                open: true,
                message: message,
                type: success ? "success" : "error",
              });
            }
          }}
        />
      </Dialog>
    </div>
  );
};

import React, { useContext } from "react";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import ContentPage from "components/contentPage/ContentPage";
import styles from "./Receipe.module.css";
import {
  MaterialContext,
  IMaterialContextInterface,
} from "contexts/MaterialsContext";
import { ReceipeTable } from "components/receipeTable/ReceipeTable";
import { AddRecipeModal } from "components/addRecipeModal/AddRecipeModal";
import { Auth } from "lib/Auth";
import { useLocalBranch } from "hooks/LocalStorage";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import {
  IBranchSelectorProps,
  BranchSelector,
} from "components/branchSelector/BranchSelector";
import RecipeIcon from "assets/RecipeIcon";

const Receipe: React.FC = React.memo(() => {
  const { branches } = useContext(BranchFavouriteContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  const materialContextValues = useContext(MaterialContext);

  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }

  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={
        <MainSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
        />
      }
      rightSection={
        <RightSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Recipes"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    <ReceipeTable materialContextValues={props.materialContextValues} />
  </>
);

const RightSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
}> = (props) => (
  <>
    {Auth.getInstance().isAdmin() && (
      <ModalWithButton title={"Add Recipe"} icon={<RecipeIcon />}>
        {" "}
        <AddRecipeModal
          materialContextValues={props.materialContextValues}
        />{" "}
      </ModalWithButton>
    )}
  </>
);

export default Receipe;

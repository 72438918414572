import React, { useContext, useState } from "react";
import styles from "./CommentsList.module.css";
import { IconButton, TextField, Button, Divider } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import moment from "moment";
import { IComment } from "types/Comment";
import { Loading } from "components/loading/Loading";
import {
  commentOnStockTransfer,
  approveStockTransfer,
} from "api/PostStockTransfer";
import { invalidateStockTransferCache } from "hooks/StockTransfers";
import { IToastBasicProps } from "components/Toast/Toast";
import { Status } from "types/Status";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface ICommentsListProps {
  stockTransferId: string;
  comments: IComment[];
  status: Status;
  onClose: () => void;
  setShowToast: (value: React.SetStateAction<IToastBasicProps>) => void;
}

interface ICommentProps {
  comment: IComment;
  index: number;
  onClose: () => void;
}

export const Comment: React.FC<ICommentProps> = (props) => {
  const { comment, index } = props;
  const dateToDisplay = moment(new Date(comment.date)).format(
    "h:mm a, MMMM Do"
  );
  return (
    <>
      <div className={styles.miniBody}>
        <div className={styles.employeeName}>{comment.employeeName}</div>
        {index === 0 && (
          <div className={styles.modalCloseIcon}>
            <IconButton
              name="close"
              color="inherit"
              onClick={props.onClose}
              classes={{ root: styles.iconButton }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        )}
      </div>
      <div className={styles.date}>{dateToDisplay}</div>
      <div className={styles.commentText}>{comment.text}</div>
      <Divider className={styles.divider} />
    </>
  );
};

export const CommentsList: React.FC<ICommentsListProps> = (props) => {
  const { comments, setShowToast, status } = props;
  const [showErrorOnComment, setShowErrorOnComment] = useState(false);
  const [commentEntered, setComment] = useState("");
  const [isCommenting, setCommenting] = useState(false);
  const onComment = async (stockTransferId: string, comment: string) => {
    if (comment.length === 0) {
      setShowErrorOnComment(true);
      return;
    }
    setCommenting(true);
    const error = await commentOnStockTransfer(stockTransferId, comment);
    setCommenting(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: false,
        message: message,
        type: "error",
      });
      return;
    }
    setShowToast({
      open: true,
      message: "Comment added on stock transfer",
      type: "success",
    });
    invalidateStockTransferCache();
  };

  const [isApproving, setApproving] = useState(false);
  const onApprove = async (stockTransferId: string) => {
    setApproving(true);
    const error = await approveStockTransfer(stockTransferId);
    setApproving(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setShowToast({
        open: true,
        message: message,
        type: "error",
      });
      return;
    }
    setShowToast({
      open: true,
      message: "The item has been approved",
      type: "success",
    });
    props.onClose();
    invalidateStockTransferCache();
  };
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  return (
    <div className={styles.commentParent}>
      <div className={styles.commentDiv}>
        <div className={styles.comment}>
          {comments.map((comment, index) => (
            <Comment
              comment={comment}
              key={index}
              onClose={props.onClose}
              index={index}
            />
          ))}
        </div>
        {status === Status.PENDING && (
          <>
            <div className={styles.commentBox}>
              <TextField
                style={{ margin: 8 }}
                InputProps={{
                  style: {
                    minHeight: 100,
                  },
                }}
                error={showErrorOnComment}
                type={"text"}
                label="Comment"
                fullWidth
                multiline={true}
                margin="normal"
                variant="outlined"
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </div>
            <Button
              onClick={() => onComment(props.stockTransferId, commentEntered)}
              className={styles.commentButton}
            >
              <Loading text="Reply" isLoading={isCommenting} />
            </Button>

            <Divider className={styles.dividerButton} />
            <Button
              onClick={() => onApprove(props.stockTransferId)}
              className={styles.approveButton}
              style={{ backgroundColor: primaryColor, color: primaryTextColor }}
            >
              <Loading text="Resolve" isLoading={isApproving} />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

import React, { useContext, useState, useEffect } from "react";
import ContentPage from "components/contentPage/ContentPage";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { IBranch } from "types/Branch";
import styles from "./SalesStats.module.css";
import { useLocalBranch } from "hooks/LocalStorage";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  IMaterialSelectorProps,
  MaterialSelector,
} from "components/materialSelector/MaterialSelector";
import { MaterialContext } from "contexts/MaterialsContext";
import { Loading } from "components/loading/Loading";
import SalesStatsGraph from "components/salesStatsGraphs/SalesStatsGraph";

const SalesStats: React.FC = React.memo(() => {
  const { id } = useParams<{ id: string }>();
  const { branches } = useContext(BranchFavouriteContext);
  const [selectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );
  const {
    sellableMaterialsMapForFoodAnalysis,
    sellableMaterialsArrForFoodAnalysis,
  } = useContext(MaterialContext).branchWiseMaterialsMap.get(
    selectedBranch!.id
  )!;
  const defaultMaterial =
    sellableMaterialsMapForFoodAnalysis.size > 0
      ? sellableMaterialsMapForFoodAnalysis.get(id)
      : undefined;
  const [selectedMaterial, setSelectedMaterial] = useState(defaultMaterial);
  const history = useHistory();
  const queryStr = useLocation().search;

  useEffect(() => {
    if (
      sellableMaterialsMapForFoodAnalysis.size > 0 &&
      sellableMaterialsMapForFoodAnalysis.has(id)
    ) {
      setSelectedMaterial(sellableMaterialsMapForFoodAnalysis.get(id));
    }
  }, [sellableMaterialsMapForFoodAnalysis, id, setSelectedMaterial]);

  useEffect(() => {
    if (
      selectedMaterial &&
      defaultMaterial &&
      selectedMaterial !== defaultMaterial
    ) {
      history.replace({
        pathname: `/sales-stats/${selectedMaterial?.id}`,
        search: queryStr,
      });
    }
  }, [selectedMaterial, defaultMaterial, history, queryStr]);
  // // TODO handle no branches error
  if (!selectedBranch || !selectedMaterial) {
    return <Loading isLoading={true} />;
  }
  return (
    <ContentPage
      header={
        <Header
          materials={sellableMaterialsArrForFoodAnalysis!}
          materialsMap={sellableMaterialsMapForFoodAnalysis}
          selectedMaterial={selectedMaterial}
          setSelectedMaterial={setSelectedMaterial}
        />
      }
      mainContent={
        <MainSection selectedBranch={selectedBranch} materialId={id} />
      }
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC<IMaterialSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Sales Stats"}</div>
    <MaterialSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch; materialId: string }> = (
  props
) => (
  <SalesStatsGraph
    selectedBranch={props.selectedBranch}
    materialId={props.materialId}
  />
);

const RightSection: React.FC = () => <></>;

export default SalesStats;

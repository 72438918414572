import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FMSThemeProvider } from "contexts/FMSThemeContext";
import { MuiThemeProvider } from "@material-ui/core";
import { ReactQueryConfigProvider } from "react-query";
import { LoggerProvider } from "contexts/LoggerContext";
import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Source Sans Pro",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      "sans-serif",
    ].join(","),
    button: {
      textTransform: "none",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <LoggerProvider>
      <ReactQueryConfigProvider
        config={{ queries: { refetchOnWindowFocus: false } }}
      >
        <MuiThemeProvider theme={theme}>
          <FMSThemeProvider>
            <App />
          </FMSThemeProvider>
        </MuiThemeProvider>
      </ReactQueryConfigProvider>
    </LoggerProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import { IMaterial } from "types/Material";
import styles from "./ErrorBox.module.css";
import { IconButton, Dialog } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { IBranch } from "types/Branch";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddInsufficientRawMaterialModal } from "components/addRawMaterialModal/AddInsufficientRawMaterialModal";

interface IErrorBoxProps {
  errorMaterialsArr: IMaterial[];
  open: boolean;
  onClose: () => void;
  selectedBranch: IBranch;
}

export const ErrorBox: React.FC<IErrorBoxProps> = (props) => {
  //TODO: Figure out how to do theming for the button
  return (
    <Dialog open={props.open}>
      <header className={styles.modalTitleText}>Insufficient Stock</header>
      <div className={styles.modalCloseIcon}>
        <IconButton name="close" color="inherit" onClick={props.onClose}>
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <div className={styles.error}>
        <div className={styles.body}>
          <div className={styles.errorText}>
            To process this consumption, ADD sufficient stock for the following:
          </div>
        </div>

        <div style={{ borderBottom: "1.5px solid rgb(231, 230, 230)" }}>
          <ul>
            {props.errorMaterialsArr.map((material) => {
              return (
                <div>
                  <li>{material.name}</li>
                </div>
              );
            })}
          </ul>
        </div>

        <div
          style={{
            alignSelf: "flex-end",
            marginRight: "15px",
          }}
        >
          <ModalWithButton
            title={"Go to 'Add Raw Materials'"}
            buttonStyle={styles.addButton}
          >
            {" "}
            <AddInsufficientRawMaterialModal
              errorMaterialsArr={props.errorMaterialsArr}
              selectedBranch={props.selectedBranch}
            />{" "}
          </ModalWithButton>
        </div>
      </div>
    </Dialog>
  );
};

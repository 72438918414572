import React, { useState, useEffect } from "react";
import {
  IDashboardViewForBranch,
  IDashboardViewForMaterial,
  displayNameForDashboardStatus,
  DashboardStatus,
} from "types/DashboardView";
import { IBranch } from "types/Branch";
import { useQueryDashboards } from "hooks/Dashboards";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";
import {
  DataTableWithDates,
  IReactTableInitialStateFilters,
} from "components/dataTableWithDates/DataTableWithDates";
import styles from "./DashboardTable.module.css";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { displayQuantity, IDisplayQuantity } from "types/MeasurementUnit";
import { Chip, Button, MenuItem, Select } from "@material-ui/core";
import { HeaderGroup } from "react-table";
import { MaterialConsumptionStatsModal } from "components/materialConsumptionStatsModal/MaterialConsumptionStatsModal";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import CSVDownload from "components/utils/CSVDownload";
import moment from "moment";
import { getDashboardDataForExport } from "lib/CSVExportUtils";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const statusFilterOptions = [
  { filterBy: undefined, label: "With Status" },
  {
    filterBy: DashboardStatus.NORMAL,
    label: displayNameForDashboardStatus(DashboardStatus.NORMAL),
  },
  {
    filterBy: DashboardStatus.TOO_HIGH,
    label: displayNameForDashboardStatus(DashboardStatus.TOO_HIGH),
  },
  {
    filterBy: DashboardStatus.TOO_LOW,
    label: displayNameForDashboardStatus(DashboardStatus.TOO_LOW),
  },
];

export const dashboardForBranch = (
  dashboardForAllBranches: IDashboardViewForBranch[],
  selectedBranch: IBranch
): IDashboardViewForMaterial[] => {
  for (let i = 0; i < dashboardForAllBranches.length; i++) {
    if (dashboardForAllBranches[i].branchId === selectedBranch.id) {
      return dashboardForAllBranches[i].materialStatisticViews;
    }
  }
  return [];
};

interface IFilterProps {
  column: HeaderGroup;
}

function SelectStatusFilter(props: IFilterProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const params = queryString.parse(location.search);
  return (
    <Select
      value={props.column.filterValue ?? ""}
      displayEmpty
      onChange={(e) => {
        const statusFilter = (e.target.value ?? "") as string;
        props.column.setFilter(statusFilter);
        let searchParams = "";
        if (statusFilter.length > 0) {
          params.statusFilter = statusFilter;
          searchParams = queryString.stringify(params);
        } else {
          searchParams = queryString.exclude(location.search, ["statusFilter"]);
        }

        history.push({
          pathname: currentPath,
          search: searchParams,
        });
      }}
    >
      <MenuItem value="">All</MenuItem>
      {statusFilterOptions.map((option, i) => (
        <MenuItem key={i} value={option.label}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

function filterStatus(rows: [any], id: any, filterValue: string) {
  const filterByOption = statusFilterOptions.filter((option) => {
    return option.label === filterValue;
  });

  if (filterByOption.length === 0) {
    return rows;
  }

  const filterBy = filterByOption[0].filterBy;
  return rows.filter((row) => {
    const status = row.values.status;
    if (!filterBy) {
      return status !== undefined;
    }

    return status !== undefined && status === filterBy;
  });
}

function SelectFavouritesFilter(props: IFilterProps) {
  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const params = queryString.parse(location.search);
  return (
    <Select
      value={props.column.filterValue ?? ""}
      displayEmpty
      onChange={(e) => {
        const materialFilter = (e.target.value ?? "") as string;
        props.column.setFilter(materialFilter);
        let searchParams = "";
        if (materialFilter.length > 0) {
          params.materialFilter = materialFilter;
          searchParams = queryString.stringify(params);
        } else {
          searchParams = queryString.exclude(location.search, [
            "materialFilter",
          ]);
        }

        history.push({
          pathname: currentPath,
          search: searchParams,
        });
      }}
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="favourites">Favourites</MenuItem>
    </Select>
  );
}

function filterFavourites(
  rows: [any],
  filterValue: string,
  selectedBranch: IBranch
) {
  if (filterValue === "favourites") {
    return rows.filter((row) => {
      const dashboardMaterialId = row.original.materialId as string;

      if (
        selectedBranch.favourites &&
        selectedBranch.favourites?.dashboard?.length > 0
      ) {
        return selectedBranch.favouriteDashboardMap.has(dashboardMaterialId);
      }
      return false;
    });
  }
  return rows;
}

export const DashboardTable: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
}> = (props) => {
  const {
    startV2,
    endV2,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates(false, "month", false);
  const { status, dashboardViews, isFetching } = useQueryDashboards(startV2, endV2);
  const [dashboardView, setDashboardView] = useState<
    IDashboardViewForMaterial[]
  >([]);

  const location = useLocation();
  const params = queryString.parse(location.search);

  useEffect(() => {
    if (status === "success") {
      if (!dashboardViews || dashboardViews instanceof FMSError) {
        const message = dashboardViews?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setDashboardView(
          dashboardForBranch(dashboardViews, props.selectedBranch)
        );
      }
    }
  }, [status, dashboardViews, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "success",
  });

  const [selectedMaterial, setSelectedMaterial] = useState<{
    materialId: string;
    expectedConsumptionDisplayQuantity?: IDisplayQuantity;
    actualConsumptionDisplayQuantity?: IDisplayQuantity;
  }>();

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.materialName.toUpperCase() >
                rowB.original.materialName.toUpperCase()
                ? 1
                : -1;
            },

            disableSortBy: true,
            width: 290,
            Filter: SelectFavouritesFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterFavourites(rows, filterValue, props.selectedBranch);
            },
            Cell: (props: any) => {
              const dashboardViewForMaterial = props.row
                .original as IDashboardViewForMaterial;
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setSelectedMaterial({
                      materialId: dashboardViewForMaterial.materialId,
                      expectedConsumptionDisplayQuantity:
                        dashboardViewForMaterial.expectedConsumptionDisplayQuantity,
                      actualConsumptionDisplayQuantity:
                        dashboardViewForMaterial.actualConsumptionDisplayQuantity,
                    });
                  }}
                >
                  {dashboardViewForMaterial.materialName}
                </Button>
              );
            },
          },
          {
            Header: "Expected Consumption",
            accessor: (row: IDashboardViewForMaterial) => {
              return displayQuantity(row.expectedConsumptionDisplayQuantity);
            },
            width: 150,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Actual Consumption",
            accessor: (row: IDashboardViewForMaterial) => {
              return displayQuantity(row.actualConsumptionDisplayQuantity);
            },
            width: 150,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Difference",
            accessor: (row: IDashboardViewForMaterial) => {
              return displayQuantity(row.differenceDisplayQuantity);
            },
            width: 150,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Status",
            accessor: "status",
            width: 140,
            Filter: SelectStatusFilter,
            filter: filterStatus,
            disableGlobalFilter: true,
            disableSortBy: true,
            Cell: (props: any) => {
              const dashboardViewForMaterial = props.row
                .original as IDashboardViewForMaterial;
              if (dashboardViewForMaterial.status === undefined) {
                return <></>;
              }

              let chipStyle;
              if (dashboardViewForMaterial.status === DashboardStatus.NORMAL) {
                chipStyle = styles.green;
              } else if (
                dashboardViewForMaterial.status === DashboardStatus.TOO_HIGH
              ) {
                chipStyle = styles.red;
              } else if (
                dashboardViewForMaterial.status === DashboardStatus.TOO_LOW
              ) {
                chipStyle = styles.yellow;
              }

              return (
                <Chip
                  label={displayNameForDashboardStatus(
                    dashboardViewForMaterial.status
                  )}
                  className={chipStyle}
                />
              );
            },
          },
        ],
      },
    ],
    [props.selectedBranch]
  );

  const initialStateFilters: IReactTableInitialStateFilters[] = [];

  if (params.materialFilter) {
    initialStateFilters.push({
      id: "materialName",
      value: params.materialFilter,
    });
  }

  if (params.statusFilter) {
    initialStateFilters.push({ id: "status", value: params.statusFilter });
  }

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      {selectedMaterial && startV2 && endV2 && (
        <MaterialConsumptionStatsModal
          materialId={selectedMaterial.materialId}
          materialContextValues={props.materialContextValues}
          expectedConsumptionDisplayQuantity={
            selectedMaterial.expectedConsumptionDisplayQuantity
          }
          actualConsumptionDisplayQuantity={
            selectedMaterial.actualConsumptionDisplayQuantity
          }
          start={startV2}
          end={endV2}
          selectedBranch={props.selectedBranch}
          onClose={() => {
            setSelectedMaterial(undefined);
          }}
        />
      )}
      {startV2 && endV2 && (
        <div
          style={{
            textAlign: "end",
          }}
        >
          <CSVDownload
            filename={`Analysis-${props.selectedBranch.name}-${moment(
              startV2
            ).format("DD MMM")}-to-${moment(endV2).format("DD MMM")}`}
            data={getDashboardDataForExport(dashboardView)}
          />
        </div>
      )}
      <DataTableWithDates
        isLoading={isFetching}
        columns={columns}
        data={dashboardView}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        centralMonthPicker={true}
        initialSortBy={"materialName"}
        initialStateFilters={initialStateFilters}
      />
    </>
  );
};

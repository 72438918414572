import React, { createContext } from "react";
import { IBranch, IExternalBranch } from "types/Branch";
import { Auth } from "lib/Auth";
import { enrichBranchesWithFavouriteMap } from "lib/BranchUtils";

export interface IBranchFavouriteContextInterface {
  branches: IBranch[];
}

export const BranchFavouriteContext = createContext<
  IBranchFavouriteContextInterface
>({
  branches: [],
});

const BranchFavouriteProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const branches: IExternalBranch[] = Auth.getInstance().getBranchesForCurrentUser();
  return (
    <BranchFavouriteContext.Provider
      value={{ branches: enrichBranchesWithFavouriteMap(branches) }}
    >
      {children}
    </BranchFavouriteContext.Provider>
  );
};

export default BranchFavouriteProvider;

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IStaffFoodForAllBranches } from "types/StaffFood";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

interface IStaffFoods {
  staffFoodList: IStaffFoodForAllBranches[];
}

export const getStaffFood = async (
  start: string,
  end: string
): Promise<IStaffFoodForAllBranches[] | FMSError> => {
  try {
    const response = await get<IStaffFoods>(
      `${BACKEND_URL_V1}/staffFood?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.staffFoodList) {
        return response.parsedBody.staffFoodList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Staff food could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStaffFoodUploadTemplate = async (
  branchId: string,
  date: Date
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/staffFood/branches/${branchId}/uploadReportFormat?date=${formatDate(
        date
      )}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Staff_Food_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Staff template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

import { IMaterial } from "./Material";

export interface ISupplierPost {
  id: string;
  name: string;
  email: string;
  address: string;
  contactPerson: string;
  contactNumber: string;
  gstNumber: string;
  fssi: string;
  materialIds: string[];
  createdAt: Date;
}

export interface ISupplier {
  id: string;
  name: string;
  email: string;
  address: string;
  contactPerson: string;
  contactNumber: string;
  gstNumber: string;
  fssi: string;
  materialList?: IMaterial[];
  createdAt: Date;
  isDeactivated: boolean;
}

export const getMaterialIds = (supplier: ISupplier) => {
  if (!supplier.materialList) {
    return [];
  }

  return supplier.materialList.map((material) => material.id);
};

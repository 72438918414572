import Button from "@material-ui/core/Button";
import styles from "./PredictionsRawMaterialTable.module.css";
import { DataTableWithDates } from "components/dataTableWithDates/DataTableWithDates";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { useQueryPredictionsRawMaterial } from "hooks/PredictionsRawMaterial";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import React, { useCallback, useEffect, useState } from "react";
import { IBranch } from "types/Branch";
import { IBranchWiseMaterialPrediction } from "types/BranchWiseMaterialPrediction";
import { FMSError } from "types/Error";
import { IMaterialPrediction } from "types/MaterialPrediction";
import { displayQuantity } from "types/MeasurementUnit";
import { MenuItem, Select } from "@material-ui/core";
import { HeaderGroup } from "react-table";
import { MaterialClassification, MaterialClassificationListForFoodAnalysis } from "types/MaterialClassification";

interface IFilterProps {
  column: HeaderGroup;
}

export const predictionsRawMaterialForBranch = (
  predictionsRawMaterialWithBranches: IBranchWiseMaterialPrediction[],
  selectedBranch: IBranch
): IMaterialPrediction[] => {
  for (let i = 0; i < predictionsRawMaterialWithBranches.length; i++) {
    if (predictionsRawMaterialWithBranches[i].branchId === selectedBranch.id) {
      return predictionsRawMaterialWithBranches[i].materialPredictionViews;
    }
  }
  return [];
};

export const PredictionsRawMaterialTable: React.FC<{
  selectedBranch: IBranch;
}> = (props) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();

  const { status, branchWiseMaterialPredictionViews, isFetching } =
    useQueryPredictionsRawMaterial(start, end);
  const [branchWiseMaterialPrediction, setBranchWiseMaterialPrediction] =
    useState<IMaterialPrediction[]>([]);

  useEffect(() => {
    if (status === "success") {
      if (
        !branchWiseMaterialPredictionViews ||
        branchWiseMaterialPredictionViews instanceof FMSError
      ) {
        const message =
          branchWiseMaterialPredictionViews?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setBranchWiseMaterialPrediction(
          predictionsRawMaterialForBranch(
            branchWiseMaterialPredictionViews,
            props.selectedBranch
          )
        );
      }
    }
  }, [status, branchWiseMaterialPredictionViews, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const SelectFavouritesFilter = useCallback((props: IFilterProps) => {
    return (
      <Select
        value={props.column.filterValue ?? ""}
        displayEmpty
        onChange={(e) => {
          const materialFilter = (e.target.value ?? "") as string;
          props.column.setFilter(materialFilter);
        }}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="favourites">Favourites</MenuItem>
      </Select>
    );
  },
    [],
  )

  const SelectMaterialsClassification = useCallback((props: IFilterProps) => {

    const materialFilterItems = [
      <MenuItem value="">All</MenuItem>,
      ...MaterialClassificationListForFoodAnalysis.map(value => {
        for (let key in MaterialClassification) {
          if (MaterialClassification[key] === value) {
            return <MenuItem value={key}>{value}</MenuItem>;
          }
        }
        return null;
      }),
    ];

    return (
      <Select
        value={props.column.filterValue ?? ""}
        displayEmpty
        onChange={(e) => {
          const materialCategory = (e.target.value ?? "") as String;
          props.column.setFilter(materialCategory);
        }}
      >
        {materialFilterItems}
      </Select>
    )
  }, [],
  )

  function filterFavourites(
    rows: [any],
    filterValue: string,
    selectedBranch: IBranch
  ) {
    if (filterValue === "favourites") {
      return rows.filter((row) => {
        const dashboardMaterialId = row.original.materialId as string;

        if (
          selectedBranch.favourites &&
          selectedBranch.favourites?.dashboard?.length > 0
        ) {
          return selectedBranch.favouriteDashboardMap.has(dashboardMaterialId);
        }
        return false;
      });
    }

    return rows;
  }

  function getMaterialClassificationValue(key: string): string | undefined {
    return MaterialClassification[key];
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            width: 436,
            disableSortBy: true,
            Filter: SelectFavouritesFilter,
            filter: (rows: [any], id: any, filterValue: string) => {
              return filterFavourites(rows, filterValue, props.selectedBranch);
            },
            Cell: (props: any) => {
              const materialPrediction = props.row
                .original as IMaterialPrediction;
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                >
                  {materialPrediction.materialName}
                </Button>
              );
            },
          },
          {
            Header: "Filter By Type",
            accessor: "materialClassification",
            disableSortBy: true,
            Filter: SelectMaterialsClassification,
            Cell: (props: any) => {
              const materialPrediction = props.row
                .original as IMaterialPrediction;
              return (
                <Button
                  style={{
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {getMaterialClassificationValue(materialPrediction.materialClassification)}
                </Button>
              );
            },
          },
          {
            Header: "Expected Consumption",
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.quantity > rowB.original.quantity ? 1 : -1;
            },
            accessor: (row: IMaterialPrediction) => displayQuantity(row.expectedConsumptionDisplayQuantity),
            width: 270,
            disableGlobalFilter: true,
            disableFilters: true,
          },
        ],
      },
    ],
    [props.selectedBranch, SelectFavouritesFilter, SelectMaterialsClassification]
  );

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <div className={styles.container}>
        <DataTableWithDates
          isLoading={isFetching}
          columns={columns}
          data={branchWiseMaterialPrediction}
          tableClass={styles.table}
          selectedDate={selectedDate!}
          selectedDateFrom={selectedDateFrom}
          selectedDateTo={selectedDateTo}
          handleDateChange={handleDateChange}
          handleDateChangeFrom={handleDateChangeFrom}
          handleDateChangeTo={handleDateChangeTo}
          initialSortBy={"materialName"}
          disableFuture={false}
        />
      </div>
    </>
  );
};

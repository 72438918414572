import {
  ConsolidatePreviewResponse,
  IConsolidatePoForSupplierRequest,
} from "api/PostPurchaseOrder";
import { ConsolidatePreview } from "components/consolidatePreview/ConsolidatePreview";
import { ConsolidateProcess } from "components/consolidateProcess/ConsolidateProcess";
import React, { useState } from "react";

export const ConsolidateComponent: React.FC<{ supplierId: string }> = (
  props
) => {
  const [consolidatePreviewResponse, setConsolidatePreviewResponse] =
    useState<ConsolidatePreviewResponse>();

  const [consolidatePoRequest, setConsolidatePoRequest] =
    useState<IConsolidatePoForSupplierRequest>();

  const onPreviewSubmit = (
    consolidatePoForSupplierRequest: IConsolidatePoForSupplierRequest,
    previewResponse: ConsolidatePreviewResponse
  ) => {
    setConsolidatePreviewResponse(previewResponse);
    setConsolidatePoRequest(consolidatePoForSupplierRequest);
  };

  const { supplierId } = props;
  return (
    <>
      {consolidatePreviewResponse ? (
        <ConsolidateProcess
          consolidatePoRequest={consolidatePoRequest}
          consolidatePreviewResponse={consolidatePreviewResponse}
        />
      ) : (
        <ConsolidatePreview
          supplierId={supplierId}
          onPreviewSubmit={onPreviewSubmit}
        />
      )}
    </>
  );
};

import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { getMaterials } from "api/GetMaterials";
import { IMaterial } from "types/Material";

interface IQueryMaterial {
  status: QueryStatus;
  materials: IMaterial[] | undefined;
}

export const useQueryMaterials = (): IQueryMaterial => {
  const { status, data: materials } = useQuery("materials", async () => {
    const allMaterials = await getMaterials();
    if (allMaterials instanceof FMSError) {
      return [];
    }
    return allMaterials.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
  });
  return { status, materials };
};

export const invalidateMaterialsCache = () => {
  queryCache.invalidateQueries("materials");
};

import firebase from "firebase/app";
import "firebase/storage";
import { FMSError } from "types/Error";
import { IEvent } from "types/Event";
import { Config } from "./Config";

export const uploadFileForEvent = (
  event: IEvent,
  file: File,
  listener: (pathOrError: string | FMSError) => void
) => {
  if (file == null) {
    return;
  }

  const storage = firebase.storage();
  const path = `${Config.getInstance().getFileStoragePath()}/events/${
    event.id
  }/${file.name}`;

  const uploadTask = storage.ref().child(path).put(file);
  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (error) => {
      listener(new FMSError(error.message));
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        listener(downloadURL as string);
      });
    }
  );
};

import { getSettings } from "api/GetSettings";
import FavouritesIcon from "assets/FavouritesIcon";
import { AddApplicableMaterialModal } from "components/addApplicableMaterial/AddApplicableMaterialModal";
import { BranchSelector, IBranchSelectorProps } from "components/branchSelector/BranchSelector";
import ContentPage from "components/contentPage/ContentPage";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { PredictionsSemiPreparedMaterialTable } from "components/predictions/semiPrepared/PredictionsSemiPreparedMaterialTable";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { IMaterialContextInterface, MaterialContext } from "contexts/MaterialsContext";
import { useLocalBranch } from "hooks/LocalStorage";
import React, { useContext, useState } from "react";
import { IBranch } from "types/Branch";
import { IMaterial } from "types/Material";
import styles from "./KitchenForecast.module.css";

const KitchenForecast: React.FC = React.memo(() => {
  const [applicableMaterials, setApplicableMaterials] = useState<IMaterial[]>([]);
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(branches.length > 0 ? branches[0] : undefined);

  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }

  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch!}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={<MainSection selectedBranch={selectedBranch} setApplicableMaterials={setApplicableMaterials} />}
      rightSection={
        <RightSection
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
          selectedBranch={selectedBranch}
          applicableMaterials={applicableMaterials}
        />
      }
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Kitchen Forecast"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{ selectedBranch: IBranch, setApplicableMaterials: any }> = (props) => (
  <>
    <PredictionsSemiPreparedMaterialTable selectedBranch={props.selectedBranch} setApplicableMaterials={props.setApplicableMaterials} />
  </>
);

const RightSection: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
  applicableMaterials: IMaterial[]
}> = (props) => (

  <>
    <ModalWithButton
      title={"Add Favourites"}
      icon={<FavouritesIcon />}
      onBackDropClick={getSettings}
      onEscapeKeyDown={getSettings}
    >
      {" "}
      <AddApplicableMaterialModal
        materials={props.materialContextValues.semiPreparedMaterialsArrForFoodAnalysis.concat(props.materialContextValues.sellableMaterialsArrForFoodAnalysis)}
        onClose={getSettings}
        applicableMaterialList={props.applicableMaterials}
      >
      </AddApplicableMaterialModal>
      {" "}
    </ModalWithButton>
  </>
);

export default KitchenForecast;

import React from "react";
import { Auth } from "lib/Auth";
import DashboardForAdmin from "./DashboardForAdmin";
import DashboardForNonAdmin from "./DashboardForNonAdmin";

const Dashboard: React.FC = React.memo(() => {
  const role = Auth.getInstance().getUser()!.role;
  return Auth.getInstance().isAdminOrSupervisor() || Auth.getInstance().isManager() ? (
    <DashboardForAdmin />
  ) : (
    <DashboardForNonAdmin role={role} />
  );
});

export default Dashboard;

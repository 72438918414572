import styles from "./ViewReminderModal.module.css";
import React, { useCallback, useContext, useState } from "react";
import {
  displayNameForEventFrequency,
  displayNameForPaymentMethod,
  EventStatus,
  IEvent,
  IUpdateEventStatusPost,
  PaymentMethod,
} from "types/Event";
import {
  Button,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { useMutation } from "react-query";
import { invalidateRemindersCache } from "hooks/Reminders";
import { updateEventStatus } from "api/PostEvent";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import { Auth } from "lib/Auth";
import { uploadFileForEvent } from "lib/RemoteStorage";
import { FMSError } from "types/Error";
import EditIcon from "assets/EditIcon";
import { AddReminderModal } from "components/addReminderModal/AddReminderModal";

interface IProps {
  event: IEvent;
  onClose: (message?: string) => void;
}

export const ViewReminderModal: React.FC<IProps> = (props) => {
  const { event, onClose } = props;

  const { handleSubmit, control, register } = useForm<IUpdateEventStatusPost>();

  const hideModal = (message?: string) => {
    setShowModal(false);
    onClose(message);
  };

  const [showModal, setShowModal] = useState(true);

  const [uploadedFile, setUploadedFile] = useState<{
    filePath: string;
    fileName: string;
  }>();

  const owners = event.ownerViews
    .map((employeeView) => employeeView.name)
    .join(", ");

  const reminderSms =
    event.targetEmployeesForReminders !== undefined
      ? event.targetEmployeesForReminders
          .map((employeeView) => employeeView.name)
          .join(", ")
      : "-";

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>(
    event.paymentMethod
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setSubmitting(true);
      const fileToUpload = acceptedFiles[0];
      uploadFileForEvent(event, fileToUpload, (pathOrError) => {
        setSubmitting(false);
        if (pathOrError instanceof FMSError) {
          setShowToast({
            open: true,
            message: pathOrError.message ?? "Something went wrong!",
            type: "error",
          });
          return;
        }

        setUploadedFile({ filePath: pathOrError, fileName: fileToUpload.name });
      });
    },
    [event]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  const [mutate] = useMutation(
    (request: IUpdateEventStatusPost) => {
      return updateEventStatus(event.id, request);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateRemindersCache();
          hideModal("Reminder completed successfully!");
          return;
        }

        setShowToast({
          open: true,
          message: error.message ?? "Something went wrong!",
          type: "error",
        });
      },
    }
  );

  const statusToUpdate = Auth.getInstance().isAdmin()
    ? EventStatus.REVIEWED
    : EventStatus.COMPLETE;
  const submitText =
    statusToUpdate === EventStatus.REVIEWED ? "Mark reviewed" : "Mark complete";

  const onSubmit = async (data: IUpdateEventStatusPost) => {
    setSubmitting(true);
    data.status = statusToUpdate;
    if (uploadedFile) {
      data.uploadedDocumentUris = [uploadedFile.filePath];
    }
    mutate(data);
  };

  const [edit, setEdit] = useState(false);

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
  });

  const auth = Auth.getInstance();

  return (
    <Dialog
      open={showModal}
      maxWidth={edit ? "lg" : "md"}
      onClose={() => hideModal()}
      scroll={"body"}
      disableBackdropClick
    >
      {edit && (
        <>
          <AddReminderModal hideModal={hideModal} event={event} />
        </>
      )}

      {!edit && (
        <ModalPopup
          className={styles.modal}
          header={
            <>
              {auth.isAdmin() && (
                <div
                  className={styles.edit}
                  onClick={() => {
                    setEdit(true);
                  }}
                >
                  <EditIcon />
                </div>
              )}
              Due date - {moment(event.date).format("MMMM Do YYYY")}
            </>
          }
          headerBackgroundColor={primaryColor}
          headerTintColor={primaryTextColor}
          onSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          showDatePicker={false}
          hideModal={hideModal}
          submitText={submitText}
        >
          <Toast
            message={showToast.message}
            open={showToast.open}
            onClose={handleToastClose}
            type={showToast.type}
          />

          <ThemeProvider theme={materialTheme}>
            <div className={styles.modalBody}>
              <div className={styles.eventDetails}>
                <div className={styles.section}>
                  <TextField
                    className={styles.textFieldMargin}
                    label="Business Unit"
                    value={event.businessUnit}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Item"
                    value={event.item}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Type"
                    value={event.type}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Amount"
                    value={
                      event.amount === undefined || event.amount === 0
                        ? "-"
                        : `Rs. ${event.amount}`
                    }
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
                <div className={styles.section}>
                  <TextField
                    className={styles.textFieldMargin}
                    label="Frequency"
                    value={displayNameForEventFrequency(event.frequency)}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Owner(s)"
                    value={owners}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Send Reminder to"
                    multiline
                    value={reminderSms}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                  <TextField
                    className={styles.textFieldMargin}
                    label="Remark"
                    multiline
                    value={
                      event.remark && event.remark.length > 0
                        ? event.remark
                        : "-"
                    }
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
              <div className={styles.userInput}>
                <Controller
                  control={control}
                  name="paymentMethod"
                  defaultValue={paymentMethod || null}
                  render={({ onChange }) => (
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Payment made via</FormLabel>
                      <RadioGroup
                        aria-label="Payment made via"
                        value={paymentMethod || ""}
                        row
                        onChange={(event) => {
                          const selectedPaymentMethod = event.target
                            .value as PaymentMethod;
                          setPaymentMethod(selectedPaymentMethod);
                          onChange(selectedPaymentMethod);
                        }}
                      >
                        <FormControlLabel
                          value={PaymentMethod.CASH}
                          control={<Radio color="primary" />}
                          label={displayNameForPaymentMethod(
                            PaymentMethod.CASH
                          )}
                        />
                        <FormControlLabel
                          value={PaymentMethod.ONLINE}
                          control={<Radio color="primary" />}
                          label={displayNameForPaymentMethod(
                            PaymentMethod.ONLINE
                          )}
                        />
                        <FormControlLabel
                          value={PaymentMethod.CHEQUE}
                          control={<Radio color="primary" />}
                          label={displayNameForPaymentMethod(
                            PaymentMethod.CHEQUE
                          )}
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                />
                <TextField
                  type={"text"}
                  label="Add Comment"
                  multiline
                  margin="normal"
                  variant="outlined"
                  name="comment"
                  inputRef={register()}
                  rows={3}
                />
                <div
                  style={{
                    display: "inline",
                  }}
                  {...getRootProps({ className: styles.dropzone })}
                >
                  <input {...getInputProps()} />
                  <TextField
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    label="Upload Document"
                    value={uploadedFile ? uploadedFile.fileName : ""}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Button variant="contained">Upload file</Button>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </ThemeProvider>
        </ModalPopup>
      )}
    </Dialog>
  );
};

import { BACKEND_URL_V1, get, post } from "lib/Api";
import { downloadFile } from "lib/FileUtils";
import { FMSError } from "types/Error";
import {
  IGetPurchaseOrderResponse,
  IGetPurchaseOrdersResponse,
  PurchaseOrderStatus,
} from "types/PurchaseOrder";

export const getPurchaseOrders = async (
  start: string,
  end: string
): Promise<IGetPurchaseOrdersResponse | FMSError> => {
  try {
    const response = await get<IGetPurchaseOrdersResponse>(
      `${BACKEND_URL_V1}/purchaseOrders?start=${start}&end=${end}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase orders could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getPurchaseOrdersForSupplier = async (
  supplierId: string,
  purchaseOrderStatusList: PurchaseOrderStatus[]
): Promise<IGetPurchaseOrdersResponse | FMSError> => {
  try {
    let url = `${BACKEND_URL_V1}/purchaseOrders/suppliers/${supplierId}`;
    purchaseOrderStatusList.forEach((status: PurchaseOrderStatus, index) => {
      if (index === 0) {
        url = url.concat(`?filterByStatus=${status}`);
      } else {
        url = url.concat(`&filterByStatus=${status}`);
      }
    });
    const response = await get<IGetPurchaseOrdersResponse>(url);

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase orders could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getPurchaseOrder = async (
  purchaseOrderId: string
): Promise<IGetPurchaseOrderResponse | FMSError> => {
  try {
    const response = await get<IGetPurchaseOrderResponse>(
      `${BACKEND_URL_V1}/purchaseOrders/${purchaseOrderId}`
    );

    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody) {
        return response.parsedBody;
      }
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase orders could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getPurchaseOrderTemplate = async (
  branchId: string,
  supplierId: string,
  deliveryAddress: string
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/branches/${branchId}/purchaseOrders/downloadTemplate`,
      undefined,
      {
        method: "post",
        body: JSON.stringify({
          supplierId,
          deliveryAddress,
        }),
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
        },
      },
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Generate_Purchase_Order_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase order template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const downloadedPurchaseOrder = async (
  purchaseOrderId: string
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/purchaseOrders/${purchaseOrderId}/export`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Purchase_Order_Export.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Purchase Order could not be exported";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

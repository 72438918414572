import styles from "./AddStaffFoodModal.module.css";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import { IStaffFoodPost } from "types/StaffFood";
import { useMutation } from "react-query";
import { IBranch } from "types/Branch";
import { addStaffFood, uploadStaffFood } from "api/PostStaffForm";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MealType, MealTypeList } from "types/MealType";
import { ModalContext } from "contexts/ModalContext";
import { invalidateStaffFoodCache } from "hooks/StaffFoods";
import { createArrayWithNumbers } from "lib/Arrays";
import { IMaterial } from "types/Material";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Toast from "components/Toast/Toast";
import {
  Tabs,
  Tab,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import { getStaffFoodUploadTemplate } from "api/GetStaffFood";
import { isUnitGmsOrMl, decorateDisplayUnit } from "lib/MaterialHelper";
import { ErrorBox } from "components/errorBox/ErrorBox";
import { EntryConfirmationDialog } from "components/addEntryConfirmationDialog/EntryConfirmationDialog";

const extractStaffFoodForPost = (staffFoods: any) => {
  const postStaffFoodsArr = new Array<IStaffFoodPost>();
  if (!staffFoods) {
    return postStaffFoodsArr;
  }

  staffFoods.forEach((staffFood: any) => {
    const materialId = staffFood.materialId as string | undefined;
    const quantity = staffFood.quantity as number | undefined;
    const servings = staffFood.servings as number | undefined;
    const mealType = staffFood.mealType as MealType;

    if (!materialId || materialId.trim().length === 0) {
      return;
    }

    if (!quantity || quantity === 0 || isNaN(quantity)) {
      return;
    }

    if (!servings || servings === 0) {
      return;
    }

    const postMaterial: IStaffFoodPost = {
      materialId: materialId,
      quantity: quantity,
      servings: servings,
      mealType: mealType,
      getMaterialId: () => {
        return materialId;
      },
      getQuantity: () => {
        return quantity;
      },
    };

    postStaffFoodsArr.push(postMaterial);
  });

  return postStaffFoodsArr;
};

interface IStaffFoodRow {
  index: number;
  name: string;
  materialsArr: IMaterial[];
  control: any;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
}

const StaffFoodRow: React.FC<IStaffFoodRow> = (props) => {
  const { index, name, materialsArr, control, getMaterial } = props;
  const defaultMaterial = getMaterial(name, index);
  const [material, setMaterial] = useState(defaultMaterial);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        name={`${name}.materialId`}
        control={control}
        defaultValue={defaultMaterial?.id}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              setMaterial(value);
              onChange(value?.id);
            }}
            value={getMaterial(name, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Item" variant="outlined" />
            )}
          />
        )}
      />

      <Controller
        control={control}
        name={`${name}.quantity`}
        render={({ onChange }) => (
          <FormControl className={styles.materialUnit} variant="outlined">
            <InputLabel htmlFor={"quantity-input" + index}>Quantity</InputLabel>
            <OutlinedInput
              id={"quantity-input" + index}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              disabled={material ? false : true}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                const shouldConvertToGmsOrMl = material
                  ? isUnitGmsOrMl(material.unitOfMeasure)
                  : false;
                onChange(shouldConvertToGmsOrMl ? value * 1000 : value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material ? decorateDisplayUnit(material) : ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />

      <Controller
        name={`${name}.servings`}
        control={control}
        defaultValue={1}
        render={({ onChange }) => (
          <TextField
            className={styles.materialUnit}
            type={"number"}
            label="Servings"
            variant="outlined"
            defaultValue={1}
            onChange={(e) => onChange(parseInt(e.target.value, 10))}
          />
        )}
      />
    </div>
  );
};

export const AddStaffFoodModal: React.FC<{
  materialContextValues: IMaterialContextInterface;
  selectedBranch: IBranch;
}> = (props) => {
  const { selectedBranch } = props;
  const { register, handleSubmit, control, getValues } =
    useForm<IStaffFoodPost[]>();
  const [insufficientMaterials, setInsufficientMaterials] = useState<
    IMaterial[] | undefined
  >(undefined);
  const modalProps = useContext(ModalContext);
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const [isDownloading, setDownloading] = useState(false);
  const [mutate] = useMutation(
    (staffFoods: IStaffFoodPost[]) => {
      return addStaffFood(staffFoods, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStaffFoodCache();
          modalProps.hideModal(true, "Staff food added successfully!");
          return;
        }

        if (error.insufficientMaterials) {
          setInsufficientMaterials(error.insufficientMaterials);
        } else {
          const message = error.message ?? "Something went wrong!";
          setToastMessage(message);
          setShowToast(true);
        }
      },
    }
  );

  const [staffFoodItems, setStaffFoodItems] = useState<IStaffFoodPost[]>([]);
  const onSubmit = async (data: any) => {
    const foodItems = extractStaffFoodForPost(data.staffFoods);
    if (foodItems.length === 0) {
      setToastMessage("Atleast one food item is required!");
      setShowToast(true);
      return;
    }
    setStaffFoodItems(foodItems);
  };

  const [mutateForFile] = useMutation(
    (files: File[]) => {
      return uploadStaffFood(files, selectedDate!, selectedBranch);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (!error) {
          invalidateStaffFoodCache();
          modalProps.hideModal(true, "Staff food added successfully!");
          return;
        }

        const message = error.message ?? "Something went wrong!";
        setToastMessage(message);
        setShowToast(true);
      },
    }
  );

  const handleUploadFile = async (files: File[]) => {
    setSubmitting(true);
    mutateForFile(files);
  };

  const { allMaterialsArrForFoodAnalysis, allMaterialsMapForFoodAnalysis } =
    props.materialContextValues;
  const [mealTypeValue, setMealTypeValue] = React.useState(0);
  const [size, setSize] = useState(5);

  const favourites: IMaterial[] = useMemo(() => {
    const favouriteStaffFood: IMaterial[] = [];
    if (
      selectedBranch.favourites &&
      selectedBranch.favourites.staffFood?.length > 0
    ) {
      selectedBranch.favourites.staffFood.forEach((favouriteMaterial) => {
        const material = allMaterialsMapForFoodAnalysis.get(
          favouriteMaterial.id
        );
        if (!material) {
          return;
        }

        favouriteStaffFood.push(material);
      });
    }

    return favouriteStaffFood;
  }, [allMaterialsMapForFoodAnalysis, selectedBranch.favourites]);

  useEffect(() => {
    if (favourites.length > size) {
      setSize(favourites.length);
    }
  }, [size, setSize, favourites]);

  const handleChange = (_event: any, newValue: number) => {
    setMealTypeValue(newValue);
  };

  if (allMaterialsArrForFoodAnalysis.length === 0) {
    return <></>;
  }

  const getMaterial = (fieldName: string, index: number): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`) as string;
    if (materialIdFromValues) {
      const material = allMaterialsMapForFoodAnalysis.get(materialIdFromValues);
      return material ? material : null;
    }

    if (favourites.length > 0 && index <= favourites.length - 1) {
      return favourites[index];
    }

    return null;
  };

  const handleDownloadTemplate = async () => {
    setDownloading(true);
    const error = await getStaffFoodUploadTemplate(
      selectedBranch.id,
      selectedDate!
    );
    setDownloading(false);
    if (error) {
      const message = error.message ?? "Something went wrong!";
      setToastMessage(message);
      setShowToast(true);
    }
  };

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Add Staff Food</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      branchName={props.selectedBranch.name}
      isUploadDownloadApplicable={true}
      isDownloading={isDownloading}
      handleDownloadTemplate={handleDownloadTemplate}
      handleUploadFile={handleUploadFile}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      {staffFoodItems.length > 0 && (
        <EntryConfirmationDialog
          materialsMap={allMaterialsMapForFoodAnalysis}
          entries={staffFoodItems}
          confirmationType={"Staff Food"}
          onClose={() => {
            setStaffFoodItems([]);
          }}
          onConfirm={async () => {
            setSubmitting(true);
            mutate(staffFoodItems);
            setStaffFoodItems([]);
          }}
          displayForRow={(index) => {
            const staffFood = staffFoodItems[index];
            const material = allMaterialsMapForFoodAnalysis.get(
              staffFood.getMaterialId()
            );
            if (!material) {
              return <></>;
            }

            const shouldConvertToKgOrLts = isUnitGmsOrMl(
              material.unitOfMeasure
            );
            const quantity = shouldConvertToKgOrLts
              ? staffFood.getQuantity() / 1000
              : staffFood.getQuantity();

            return (
              <div key={index}>
                <li>
                  {material.name} - {quantity} {decorateDisplayUnit(material)} x{" "}
                  {staffFood.servings} serving
                </li>
              </div>
            );
          }}
        />
      )}

      {insufficientMaterials !== undefined && (
        <ErrorBox
          errorMaterialsArr={insufficientMaterials}
          open={true}
          onClose={() => {
            setInsufficientMaterials(undefined);
          }}
          selectedBranch={selectedBranch}
        />
      )}

      <div className={styles.modalBody}>
        <Tabs
          value={mealTypeValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Breakfast" />
          <Tab label="Lunch" />
          <Tab label="Snacks" />
          <Tab label="Dinner" />
        </Tabs>
        <>
          <>
            {createArrayWithNumbers(size).map(
              (value: number, index: number) => {
                const fieldName = `staffFoods[${index}]`;
                return (
                  <div key={index}>
                    <StaffFoodRow
                      index={index}
                      name={fieldName}
                      control={control}
                      materialsArr={allMaterialsArrForFoodAnalysis}
                      getMaterial={getMaterial}
                    />
                    <input
                      type="hidden"
                      name={`${fieldName}.mealType`}
                      ref={register}
                      value={MealTypeList[mealTypeValue]}
                    ></input>
                  </div>
                );
              }
            )}
          </>

          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            Add More
          </button>
        </>
      </div>
    </ModalPopup>
  );
};

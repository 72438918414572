import React, { useEffect, useState } from "react";
import styles from "./SalesReportTable.module.css";
import { IBranch } from "types/Branch";
import { DataTableWithDates } from "components/dataTableWithDates/DataTableWithDates";
import { ISalesForAllBranches, ISaleFood } from "types/Sales";
import { invalidateSaleEntriesCache, useQuerySales } from "hooks/Sales";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { Button } from "@material-ui/core";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";
import moment from "moment";
import { DateSection } from "containers/salesReport/SalesReport";
import CSVDownload from "components/utils/CSVDownload";
import { getSalesDataForExport } from "lib/CSVExportUtils";
import { ModifySaleModal } from "components/modifySaleModal/ModifySaleModal";
import { Auth } from "lib/Auth";
import { useHistory, useLocation } from "react-router-dom";

export const salesForBranch = (
  salesWithBranches: ISalesForAllBranches[],
  selectedBranch: IBranch
): ISaleFood[] => {
  for (let i = 0; i < salesWithBranches.length; i++) {
    if (salesWithBranches[i].branchId === selectedBranch.id) {
      return salesWithBranches[i].saleFoodList;
    }
  }
  return [];
};

export const getLastUpdated = (
  salesWithBranches: ISalesForAllBranches[],
  selectedBranch: IBranch
): string => {
  for (let i = 0; i < salesWithBranches.length; i++) {
    if (salesWithBranches[i].branchId === selectedBranch.id) {
      return salesWithBranches[i].lastUpdated;
    }
  }
  return "";
};

export const SalesReportTable: React.FC<{ selectedBranch: IBranch }> = (
  props
) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();
  const { status, sales, isFetching } = useQuerySales(start, end);
  const [sale, setSale] = useState<ISaleFood[]>([]);
  const [lastUpdated, setLastUpdated] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState<ISaleFood>();
  useEffect(() => {
    if (status === "success") {
      if (!sales || sales instanceof FMSError) {
        const message = sales?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setSale(salesForBranch(sales, props.selectedBranch));
        setLastUpdated(
          moment(getLastUpdated(sales, props.selectedBranch)).format(
            "DD-MM-yyyy"
          )
        );
      }
    }
  }, [status, sales, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Branch Table",
        columns: [
          {
            Header: "Item",
            accessor: "materialName",
            width: 442,
            disableFilters: true,
            sortType: (rowA: any, rowB: any) => {
              return rowA.original.materialName.toUpperCase() >
                rowB.original.materialName.toUpperCase()
                ? 1
                : -1;
            },
            Cell: (props: any) => {
              const sales = props.row.original as ISaleFood;
              const history = useHistory();
              const queryStr = useLocation().search;
              return (
                <Button
                  style={{
                    display: "block",
                    textAlign: "left",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    if (Auth.getInstance().isAdmin()) {
                      setSelectedMaterial(sales);
                    } else {
                      history.push({
                        pathname: `/sales-stats/${sales.materialId}`,
                        search: queryStr,
                      });
                    }
                  }}
                >
                  {sales.materialName}
                </Button>
              );
            },
          },
          {
            Header: "UOM",
            accessor: (row: ISaleFood) =>
              displayNameForUnitOfMeasure(row.measurementUnit),
            width: 225,
            disableGlobalFilter: true,
            disableSortBy: true,
            disableFilters: true,
          },
          {
            Header: "Quantity",
            accessor: "quantity",
            width: 225,
            disableGlobalFilter: true,
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <DateSection lastUpdatedDate={lastUpdated} />
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <div
        style={{
          textAlign: "end",
        }}
      >
        <CSVDownload
          filename={`Sales-${props.selectedBranch.name}-${moment(start).format(
            "DD MMM"
          )}-to-${moment(end).format("DD MMM")}`}
          data={getSalesDataForExport(sale)}
        />
      </div>
      <DataTableWithDates
        isLoading={isFetching}
        columns={columns}
        data={sale}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        initialSortBy={"materialName"}
      />

      {selectedMaterial && (
        <ModifySaleModal
          startDate={start}
          endDate={end}
          materialId={selectedMaterial.materialId}
          selectedBranch={props.selectedBranch}
          materialName={selectedMaterial.materialName}
          onClose={(isSaleEntryUpdated) => {
            setSelectedMaterial(undefined);
            if (isSaleEntryUpdated) {
              invalidateSaleEntriesCache();
            }
          }}
        />
      )}
    </>
  );
};

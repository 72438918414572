import { Button, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  IMaterialContextInterface,
  MaterialContext,
} from "contexts/MaterialsContext";
import { createArrayWithNumbers } from "lib/Arrays";
import React, { useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { IMaterial } from "types/Material";
import { ISupplierPost } from "types/Supplier";
import styles from "./SupplierMaterialsForm.module.css";
import { Loading } from "components/loading/Loading";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { invalidateSuppliersCache } from "hooks/Suppliers";
import { addSupplier, updateSupplier } from "api/PostSupplier";
import { useMutation } from "react-query";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { useHistory } from "react-router-dom";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { MaterialType } from "types/MaterialType";
import { Auth } from "lib/Auth";

interface IRowProps {
  index: number;
  materialsArr: IMaterial[];
  control: any;
  defaultValue?: IMaterial;
  isEditable: boolean;
}

const RowComponent: React.FC<IRowProps> = (props) => {
  const { index, materialsArr, control, defaultValue, isEditable } = props;

  const fieldName = `materials[${index}]`;

  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        control={control}
        defaultValue={defaultValue ?? null}
        name={`${fieldName}.materialId`}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => {
              onChange(value?.id);
            }}
            defaultValue={defaultValue ?? null}
            disabled={!isEditable}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className={styles.inputField}
                label="Select Item"
                variant="outlined"
              />
            )}
          />
        )}
      />
    </div>
  );
};

interface IProps {
  supplier: ISupplierPost;
}

const SupplierMaterialsForm: React.FC<IProps> = (props) => {
  const { supplier } = props;
  const [size, setSize] = useState(8);
  const { handleSubmit, control } = useForm<IMaterial[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const materialContextValues = useContext(MaterialContext);
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  let allBranchesRawMaterialsSet = new Set<IMaterial>();

  const history = useHistory();
  const [mutate] = useMutation(
    (supplier: ISupplierPost) => {
      setIsSubmitting(true);
      return addSupplier(supplier);
    },
    {
      onSuccess: (error) => {
        setIsSubmitting(false);
        if (!error) {
          invalidateSuppliersCache();
          setShowSuccessDialog(true);
        } else {
          const message = error.message ?? "Something went wrong!";
          setShowToast({ open: true, message: message, type: "error" });
        }
      },
    }
  );

  const [mutateForEdit] = useMutation(
    (supplier: ISupplierPost) => {
      setIsSubmitting(true);
      return updateSupplier(supplier);
    },
    {
      onSuccess: (error) => {
        setIsSubmitting(false);
        if (!error) {
          invalidateSuppliersCache();
          setShowSuccessDialog(true);
        } else {
          const message = error.message ?? "Something went wrong!";
          setShowToast({ open: true, message: message, type: "error" });
        }
      },
    }
  );

  Array.from(materialContextValues.branchWiseMaterialsMap.values()).map(
    (value: IMaterialContextInterface) => {
      const rawMaterials = value.allMaterialsArr.filter(
        (material) => material.type === MaterialType.RAW
      );
      allBranchesRawMaterialsSet = new Set([
        ...allBranchesRawMaterialsSet,
        ...rawMaterials,
      ]);
      return null;
    }
  );

  const getMaterialById = (id: string) => {
    let material: IMaterial | undefined = undefined;
    Array.from(materialContextValues.branchWiseMaterialsMap.values()).map(
      (value: IMaterialContextInterface) => {
        material = value.allMaterialsMap.get(id);
        if (id === material?.id) {
          return null;
        }
        return null;
      }
    );
    return material;
  };

  interface IMaterialId {
    // useForm returns string when values are changed manually (onChange gets triggered)
    // useForm returns IMaterial when default value is set for the autoComplete.
    materialId: string | IMaterial;
  }

  interface IMaterials {
    materials: IMaterialId[];
  }

  const onSubmitSupplier = (materialList: IMaterials) => {
    const materialIds = materialList.materials
      .map((material: IMaterialId) => material.materialId)
      .filter((materialId?: string | IMaterial) => materialId !== null)
      .filter((materialId?: string | IMaterial) => materialId !== undefined);

    if (materialIds.length === 0) {
      setShowToast({
        open: true,
        message: "Materials list cannot be empty",
        type: "error",
      });
      return;
    }

    let data = {
      ...supplier,
      materialIds,
    };

    mutate(data as ISupplierPost);
  };

  const onEditSupplier = (materialList: IMaterials) => {
    const materialIds = materialList.materials
      .map((material: IMaterialId) => material.materialId)
      .map((materialId?: string | IMaterial) => {
        if (materialId !== null) {
          if (typeof materialId === "string") {
            return materialId;
          } else {
            return materialId?.id;
          }
        }
        return null;
      })
      .filter((materialId?: string | null) => materialId !== null)
      .filter((materialId?: string | null) => materialId !== undefined);

    if (materialIds.length === 0) {
      setShowToast({
        open: true,
        message: "Materials list cannot be empty",
        type: "error",
      });
      return;
    }

    let data = {
      ...supplier,
      materialIds,
    };

    mutateForEdit(data as ISupplierPost);
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const handleToastClose = () => {
    setShowToast({ open: false, message: "", type: "error" });
  };

  return (
    <>
      <Toast
        message={showToast.message}
        open={showToast.open}
        onClose={handleToastClose}
        type={showToast.type}
      />
      <ConfirmationDialog
        buttonText={"Okay"}
        bodyText={`Supplier is ${supplier && supplier.materialIds ? "updated" : "created"
          } successfully`}
        open={showSuccessDialog}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headText}>{"List of Available Items"}</div>
          {Auth.getInstance().isAdminOrSupervisor() && (
            <Button
              className={styles.addSupplierButton}
              style={{
                backgroundColor: primaryColor,
                color: primaryTextColor,
                fontWeight: "bold",
              }}
              onClick={
                supplier && supplier.materialIds
                  ? handleSubmit(onEditSupplier)
                  : handleSubmit(onSubmitSupplier)
              }
            >
              <Loading
                text={supplier && supplier.materialIds ? "Update" : "Submit"}
                isLoading={isSubmitting}
              />
            </Button>
          )}
        </div>

        <div
          style={{
            padding: "10px",
            background: "#ffffff",
          }}
        >
          {supplier &&
            supplier.materialIds &&
            [
              ...supplier.materialIds,
              ...Array(
                Auth.getInstance().isAdminOrSupervisor() ?
                  size > supplier.materialIds.length
                    ? size - supplier.materialIds.length
                    : 0
                  : 0
              ).fill(undefined),
            ].map((materialId: string, index: number) => {
              return (
                <RowComponent
                  key={index}
                  index={index}
                  control={control}
                  materialsArr={Array.from(allBranchesRawMaterialsSet)}
                  defaultValue={getMaterialById(materialId)}
                  isEditable={Auth.getInstance().isAdminOrSupervisor()}
                />
              );
            })}
          {(!supplier || !supplier.materialIds) &&
            createArrayWithNumbers(size).map((index: number) => {
              return (
                <RowComponent
                  key={index}
                  index={index}
                  control={control}
                  materialsArr={Array.from(allBranchesRawMaterialsSet)}
                  isEditable={Auth.getInstance().isAdminOrSupervisor()}
                />
              );
            })}
          {Auth.getInstance().isAdminOrSupervisor() && (
            <div className={styles.addItemDiv}>
              <button
                type="button"
                className={styles.addItem}
                onClick={() => setSize(size + 10)}
              >
                {" "}
                Add More{" "}
              </button>
              <div
                style={{
                  border: "1px solid #EAEAEA",
                  height: "1px",
                  width: "100%",
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SupplierMaterialsForm;

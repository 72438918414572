import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function ClosingStockIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={13.7}
      height={13.7}
      viewBox="0 0 13.7 13.7"
    >
      <defs>
        <style>
          {".closingStockIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke:" +
            primaryTextColor +
            ";stroke-width:.8px}"}
        </style>
      </defs>
      <path
        className="closingStockIconComponent__a"
        d="M9.806 13.301A3.494 3.494 0 1113.3 9.807a3.5 3.5 0 01-3.494 3.494zm0-6.45a2.956 2.956 0 102.956 2.956 2.96 2.96 0 00-2.956-2.956z"
      />
      <path
        className="closingStockIconComponent__a"
        d="M8.666 11.218a.268.268 0 01-.19-.458l2.28-2.28a.269.269 0 11.38.38l-2.28 2.28a.268.268 0 01-.19.078z"
      />
      <path
        className="closingStockIconComponent__a"
        d="M10.946 11.218a.27.27 0 01-.19-.078l-2.28-2.28a.269.269 0 01.38-.38l2.28 2.28a.268.268 0 01-.19.458zM4.969 11.688H1.744A1.345 1.345 0 01.4 10.344v-8.6A1.345 1.345 0 011.744.4h5.912A1.345 1.345 0 019 1.744v3.225a.269.269 0 11-.538 0V1.744a.807.807 0 00-.806-.806H1.744a.807.807 0 00-.806.806v8.6a.807.807 0 00.806.806h3.225a.269.269 0 110 .538z"
      />
    </svg>
  );
}
const ClosingStockIcon = React.memo(ClosingStockIconComponent);
export default ClosingStockIcon;

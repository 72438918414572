import { BACKEND_URL_V1, deletee } from "lib/Api";
import { FMSError } from "types/Error";

export const deleteApplicableMaterial = async (
    materialId: string
): Promise<FMSError | null> => {
    try {
        const response = await deletee(
            `${BACKEND_URL_V1}/predictions/prepared/applicableMaterial/${materialId}`
        );
        if (response.status === 202) {
            return null;
        }
    } catch (err) {
        return new FMSError("Something went wrong");
    }
    return new FMSError("Something went wrong");
};

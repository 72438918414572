import { post, BACKEND_URL_V1, deletee } from "lib/Api";
import { FMSError } from "types/Error";
import { IUser, IUserForm } from "types/User";

export const addUser = async (user: IUserForm): Promise<FMSError | null> => {
  const { name, mobileNumber, email, role, branchIds } = user;
  try {
    const response = await post<{ user: IUser }>(
      `${BACKEND_URL_V1}/employees`,
      {
        name,
        mobileNumber,
        email,
        role,
        branchIds: branchIds ? branchIds : null,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to add user";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

export const updateUser = async (
  employeeId: string,
  user: IUserForm
): Promise<FMSError | null> => {
  const { name, mobileNumber, email, role, branchIds } = user;
  try {
    const response = await post<{ user: IUser }>(
      `${BACKEND_URL_V1}/employees/${employeeId}`,
      {
        name,
        mobileNumber,
        email,
        role,
        branchIds: branchIds ? branchIds : null,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Unable to update user";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

export const deleteUser = async (
  employeeId: string
): Promise<FMSError | null> => {
  try {
    const response = await deletee<{}>(
      `${BACKEND_URL_V1}/employees/${employeeId}`
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Unable to delete employee";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

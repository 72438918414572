import React from "react";
import { MenuItem, Select } from "@material-ui/core";
import styles from "./DatePickerHeaderForPurchaseOrders.module.css";
import { IPurchaseOrderDateRange } from "types/PurchaseOrderDateRanges";

export interface IPurchaseOrderDatePickerHeaderProps {
  dateRanges: IPurchaseOrderDateRange[];
  selectedDateRange: IPurchaseOrderDateRange;
  setSelectedDateRange: (dateRange: IPurchaseOrderDateRange) => void;
}

export const DatePickerHeaderForPurchaseOrders: React.FC<
  IPurchaseOrderDatePickerHeaderProps
> = (props) => {
  const { dateRanges, selectedDateRange, setSelectedDateRange } = props;
  const dateRangeMap = new Map<String, IPurchaseOrderDateRange>();
  dateRanges.forEach((dateRange) =>
    dateRangeMap.set(dateRange.title, dateRange)
  );

  return (
    <Select
      className={styles.select}
      disableUnderline
      classes={{ root: styles.root }}
      value={selectedDateRange.title}
      onChange={(event) => {
        const title = event.target.value as string;
        setSelectedDateRange(dateRangeMap.get(title)!);
      }}
    >
      {dateRanges.map((dateRange, index) => (
        <MenuItem value={dateRange.title} key={index}>
          {dateRange.title}
        </MenuItem>
      ))}
    </Select>
  );
};

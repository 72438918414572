import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";
import { IStockTransferPost } from "types/StockTransfer";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";

export const addStockTransfer = async (
  transferRecords: IStockTransferPost[],
  transferDate: Date,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/stockTransfers/senderBranchId/${branch.id}`,
      {
        transferRecords,
        transferDate,
      }
    );
    if (response.status === 202) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock transfer could not be recorded";
    return new FMSError(
      errorMessage,
      response.serverError?.error.insufficientMaterials
    );
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const approveStockTransfer = async (
  stockTransferId: string
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/stockTransfers/${stockTransferId}/approve`
    );
    if (response.status === 200) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ??
      "Stock transfer could not be approved";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const commentOnStockTransfer = async (
  stockTransferId: string,
  comment: string
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/stockTransfers/${stockTransferId}/comments`,
      {
        comment,
      }
    );
    if (response.status === 200) {
      return null;
    }
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
  return new FMSError("Something went wrong");
};

export const uploadStockTranfer = async (
  files: File[],
  date: Date,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("uploadedFile", files[i]);
    }
    const response = await post(
      `${BACKEND_URL_V1}/stockTransfers/branches/${
        branch.id
      }/uploadReport?date=${formatDate(date)}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );

    if (response.status === 202) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Stock_Transfer_Errors.xlsx"
      );
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ??
        "Unable to upload stock transfer";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

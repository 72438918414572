import React from "react";
import { DatePickersForTableHeaders } from "components/datePickersForTableHeader/DatePickersForTableHeader";
import styles from "./GraphsHeaderMonthly.module.css";
import { ISelectedDatesHookValues } from "hooks/SelectedFromToDates";
import moment from "moment";
import { IBranch } from "types/Branch";

interface IProps {
  selectedDatesHookValues: ISelectedDatesHookValues;
  pageTitle: string;
  selectedBranch: IBranch;
}

export const GraphsHeaderMonthly: React.FC<IProps> = (props) => {
  const { selectedDatesHookValues, pageTitle, selectedBranch } = props;
  const { start, end } = selectedDatesHookValues;
  const startDisplay = moment(start).format("MMM YYYY");
  const endDisplay = moment(end).format("MMM YYYY");
  return (
    <>
      <>
        <div className={styles.headerBranch}> {selectedBranch.name}</div>
        <div className={styles.headerCentral}>
          <div className={styles.headerTitle}>
            {pageTitle} {" - Monthly"}
          </div>
          <div className={styles.headerDate}>
            {startDisplay !== endDisplay && `${startDisplay} - ${endDisplay}`}
            {startDisplay === endDisplay && `${startDisplay}`}
          </div>
        </div>
      </>
      <DatePickersForTableHeaders
        selectedDate={selectedDatesHookValues.selectedDate!}
        selectedDateFrom={selectedDatesHookValues.selectedDateFrom}
        selectedDateTo={selectedDatesHookValues.selectedDateTo}
        handleDateChange={selectedDatesHookValues.handleDateChange}
        handleDateChangeFrom={selectedDatesHookValues.handleDateChangeFrom}
        handleDateChangeTo={selectedDatesHookValues.handleDateChangeTo}
        hideCentralDatePicker={true}
        fromToClassName={styles.datepicker}
      />
    </>
  );
};

import { get, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import {
  IWastageStats,
  IStockStats,
  IStaffFoodStats,
  ISalesStats,
  IStats,
} from "types/Stats";

export const getWastageStats = async (
  materialId: string,
  start: string,
  end: string,
  branchId: string
): Promise<IStats | FMSError> => {
  try {
    const response = await get<IWastageStats>(
      `${BACKEND_URL_V1}/wastage/materials/${materialId}/stats?start=${start}&end=${end}&branchId=${branchId}`
    );
    if (response.parsedBody?.wastageStats) {
      return response.parsedBody.wastageStats;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Wastage stats could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStocksStats = async (
  materialId: string,
  start: string,
  end: string,
  branchId: string
): Promise<IStats | FMSError> => {
  try {
    const response = await get<IStockStats>(
      `${BACKEND_URL_V1}/stocks/materials/${materialId}/stats?start=${start}&end=${end}&branchId=${branchId}`
    );
    if (response.parsedBody?.stockStats) {
      return response.parsedBody.stockStats;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock stats could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStaffFoodStats = async (
  materialId: string,
  start: string,
  end: string,
  branchId: string
): Promise<IStats | FMSError> => {
  try {
    const response = await get<IStaffFoodStats>(
      `${BACKEND_URL_V1}/staffFood/materials/${materialId}/stats?start=${start}&end=${end}&branchId=${branchId}`
    );
    if (response.parsedBody?.staffFoodStats) {
      return response.parsedBody.staffFoodStats;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Staff Food stats could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getSalesStats = async (
  materialId: string,
  start: string,
  end: string,
  branchId: string
): Promise<IStats | FMSError> => {
  try {
    const response = await get<ISalesStats>(
      `${BACKEND_URL_V1}/sales/materials/${materialId}/stats?start=${start}&end=${end}&branchId=${branchId}`
    );
    if (response.parsedBody?.salesStats) {
      return response.parsedBody?.salesStats;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Sales stats could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

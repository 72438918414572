import styles from "./UpdateRecipeModal.module.css";
import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Checkbox,
  InputLabel,
  InputAdornment,
  OutlinedInput,
} from "@material-ui/core";
import { useMutation } from "react-query";
import { ModalPopup } from "components/modalPopup/ModalPopup";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createArrayWithNumbers } from "lib/Arrays";
import {
  IMaterial,
  IngredientToQuantityMap,
  IMaterialUpdatePost,
  MaterialUpdateStatus,
} from "types/Material";
import { updateMaterial } from "api/PostMaterial";
import { IMaterialContextInterface } from "contexts/MaterialsContext";
import classNames from "classnames";
import { MaterialType } from "types/MaterialType";
import { invalidateMaterialsCache } from "hooks/Materials";
import Toast from "components/Toast/Toast";
import { objToStrMap } from "lib/MapHelpers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { displayNameForUnitOfMeasure } from "types/MeasurementUnit";
import { Auth } from "lib/Auth";
import { Role } from "types/Employee";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { FMSError } from "types/Error";
import useInterval from "hooks/UseInterval";
import { getMaterialUpdateStatus } from "api/GetMaterials";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { isUnitKgsOrLtr } from "lib/MaterialHelper";

const POLLING_INTERVAL = 5 * 1000; // 5 seconds
const TIMEOUT_INTERVAL = 120 * 1000; // 120 seconds

interface IProps {
  materialContextValues: IMaterialContextInterface;
  material: IMaterial;
  hideModal: (successMessage?: string) => void;
}

interface IRecipeRow {
  index: number;
  fieldName: string;
  materialsArr: IMaterial[];
  control: any;
  getMaterial: (fieldName: string, index: number) => IMaterial | null;
  getDefaultQuantity: (
    fieldName: string,
    index: number,
    defaultValue: "" | 0
  ) => number | "";
}

const RecipeRow: React.FC<IRecipeRow> = (props) => {
  const {
    index,
    fieldName,
    materialsArr,
    control,
    getMaterial,
    getDefaultQuantity,
  } = props;
  const defaultMaterial = getMaterial(fieldName, index);
  const [material, setMaterial] = useState(defaultMaterial);
  return (
    <div key={index} className={styles.inputOptions}>
      <div className={styles.rowNumber}>{(index + 1).toString() + "."}</div>
      <Controller
        name={`${fieldName}.materialId`}
        control={control}
        defaultValue={getMaterial(fieldName, index)?.id}
        render={({ onChange }) => (
          <Autocomplete
            options={materialsArr}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            onChange={(_, value) => {
              if (value) {
                setMaterial(value);
                onChange(value?.id);
              }
            }}
            value={getMaterial(fieldName, index)}
            fullWidth
            classes={{
              root: styles.autocompleteRoot,
              input: styles.autocomplete,
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                variant="outlined"
                placeholder="Select Item"
              />
            )}
          />
        )}
      />

      <Controller
        name={`${fieldName}.quantity`}
        control={control}
        defaultValue={getDefaultQuantity(fieldName, index, "")}
        render={({ onChange }) => (
          <FormControl className={styles.quantity} variant="outlined">
            <InputLabel htmlFor={"quantity-input" + fieldName}>
              Quantity
            </InputLabel>
            <OutlinedInput
              id={"quantity-input" + fieldName}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              value={getDefaultQuantity(fieldName, index, "")}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                onChange(value);
              }}
              endAdornment={
                <InputAdornment position="end">
                  {material?.displayUnitOfMeasure ?? ""}
                </InputAdornment>
              }
              labelWidth={59}
            />
          </FormControl>
        )}
      />
    </div>
  );
};

export const UpdateRecipeModal: React.FC<IProps> = (props) => {
  const { handleSubmit, control, getValues, register, errors } =
    useForm<IMaterialUpdatePost>();

  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    new Date()
  );

  const [showConfirmation, setShowConfirmation] = useState<{
    show: boolean;
    materials?: IMaterial[],
    onConfirm: () => void
  }>({ show: false, onConfirm: () => { } });

  const [completedMaterialIds, setCompletedMaterialIds] = useState<string[]>([]);

  const [pollingMaterialUpdateRequestId, setPollingMaterialUpdateRequestId] = useState<string | null>(null);

  const [makeBaseRecipe, setMakeBaseRecipe] = React.useState(false);

  const [mutate] = useMutation(
    (material: IMaterialUpdatePost) => {
      return updateMaterial(props.material.id, material, makeBaseRecipe);
    },
    {
      onSuccess: (response, data) => {
        setSubmitting(false);
        if (response instanceof FMSError) {
          if (response.details) {
            setShowConfirmation({
              show: true,
              materials: response.details as IMaterial[],
              onConfirm: () => {
                setSubmitting(true);
                const newData = { ...data, forceUpdate: true };
                mutate(newData);
              }
            });
          } else {
            const message = response.message ?? "Something went wrong!";
            setToastMessage(message);
            setShowToast(true);
          }
          return;
        }

        if (response.shouldPoll) {
          setIsPolling(true);
          setPollingMaterialUpdateRequestId(response.materialUpdateRequestId);
        } else {
          invalidateMaterialsCache();
          props.hideModal("Material updated successfully");
        }
      },
    }
  );

  useInterval(async () => {
    if (pollingMaterialUpdateRequestId) {
      setIsPolling(true);
      const response = await getMaterialUpdateStatus(pollingMaterialUpdateRequestId);
      if (response instanceof FMSError) {
        setToastMessage(response.message ?? "Something went wrong!");
        setShowToast(true);
        setPollingMaterialUpdateRequestId(null);
        setIsPolling(false);
        return;
      }

      if (response.overallStatus === MaterialUpdateStatus.COMPLETED) {
        invalidateMaterialsCache();
        props.hideModal("Material updated successfully");
        setIsPolling(false);
        return;
      }

      const completedMaterialIds = response.materialUpdateViewsWithStatus
        .filter(materialWithStatus => materialWithStatus.status === MaterialUpdateStatus.COMPLETED)
        .map(materialWithStatus => materialWithStatus.materialId);

      setCompletedMaterialIds(completedMaterialIds);
    }
  }, pollingMaterialUpdateRequestId === null ? null : POLLING_INTERVAL);

  useInterval(async () => {
    setIsPolling(false);
    setShowConfirmation({ show: false, onConfirm: () => { } });
    setToastMessage("Something went wrong!");
    setShowToast(true);
    setPollingMaterialUpdateRequestId(null);
  }, pollingMaterialUpdateRequestId === null ? null : TIMEOUT_INTERVAL);

  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [isPolling, setIsPolling] = useState(false);
  const handleToastClose = () => {
    setShowToast(false);
  };

  const onSubmit = async (data: any) => {
    setSubmitting(true);
    const ingredientsArr: { materialId: string; quantity: number }[] =
      data.ingredientsArr;
    let numberOfIngredients = 0;
    if (ingredientsArr?.length > 0) {
      const ingredients: IngredientToQuantityMap = {};
      ingredientsArr.forEach((value) => {
        if (
          value.materialId?.length > 0 &&
          value.quantity &&
          value.quantity > 0
        ) {
          ingredients[value.materialId] = value.quantity;
          numberOfIngredients++;
        }
      });
      data.ingredients = ingredients;
      data.ingredientsArr = null;
    }

    if (numberOfIngredients === 0) {
      setToastMessage("Atleast one ingredient is required!");
      setShowToast(true);
      setSubmitting(false);
      return;
    }

    data.activationDateForNewRecipe = selectedDate!;
    mutate(data);
  };

  const { allMaterialsArrForFoodAnalysis, allMaterialsMapForFoodAnalysis } =
    props.materialContextValues;

  const existingIngredientsArr = useMemo(
    () => [...objToStrMap<IMaterial>(props.material.ingredients).values()],
    [props.material.ingredients]
  );

  const [size, setSize] = useState(4);
  useEffect(() => {
    if (existingIngredientsArr.length > size) {
      setSize(existingIngredientsArr.length);
    }
  }, [existingIngredientsArr, size, setSize]);

  const getMaterial = (fieldName: string, index: number): IMaterial | null => {
    const materialIdFromValues = getValues(`${fieldName}.materialId`) as string;
    if (materialIdFromValues) {
      let material;
      if (allMaterialsMapForFoodAnalysis.has(materialIdFromValues)) {
        material = allMaterialsMapForFoodAnalysis.get(materialIdFromValues);
      }

      return material ?? null;
    }

    if (
      existingIngredientsArr.length > 0 &&
      index <= existingIngredientsArr.length - 1
    ) {
      const material = existingIngredientsArr[index];
      material.displayUnitOfMeasure =
        allMaterialsMapForFoodAnalysis.get(material.id)?.displayUnitOfMeasure ??
        "";
      return material;
    }

    return null;
  };

  const getDefaultQuantity = (
    fieldName: string,
    index: number,
    defaultValue: "" | 0
  ) => {
    const material = getMaterial(fieldName, index);
    if (material === null) {
      return defaultValue;
    }

    const quantity = getValues(`${fieldName}.quantity`) as any;
    if (quantity === undefined || isNaN(quantity)) {
      if (material.displayQuantity !== undefined) {
        const shouldConvertToGmsOrMl = material ? isUnitKgsOrLtr(material.displayQuantity.unit) : false;
        return shouldConvertToGmsOrMl ? material.displayQuantity.quantity * 1000 : material.displayQuantity.quantity;
      } else {
        return defaultValue;
      }
    }

    return quantity as number;
  };

  let materialQuantity = props.material.displayQuantity.quantity;
  if (isUnitKgsOrLtr(props.material.displayQuantity.unit)) {
    materialQuantity = materialQuantity * 1000;
  }

  return (
    <ModalPopup
      className={styles.modal}
      header={<>Update Recipe</>}
      onSubmit={handleSubmit(onSubmit)}
      isSubmitting={isSubmitting}
      selectedDate={selectedDate!}
      handleDateChange={handleDateChange}
      hideModal={props.hideModal}
      submitText={"Update"}
    >
      <Toast
        message={toastMessage}
        open={showToast}
        onClose={handleToastClose}
        type="error"
      />

      <ConfirmationDialog
        buttonText="Yes"
        secondButtonText="Cancel"
        body={
          <div className={styles.confirmationBody}>
            <div className={styles.confirmationTitle}>
              {pollingMaterialUpdateRequestId === null ? "Following materials will be updated. Do you want to continue?" : "Updating materials..."}
            </div>
            {showConfirmation.materials && (
              <div className={styles.materialsListContainer} style={{ paddingBottom: "20px" }}>
                {showConfirmation.materials.map(
                  (element, index) => {
                    return (
                      <div className={styles.materialItem} key={index}>
                        {element.name} {completedMaterialIds.includes(element.id) ? <CheckCircleIcon fontSize="small" style={{ color: 'green' }} /> : undefined}
                      </div>
                    );
                  }
                )}
              </div>
            )}
          </div>
        }
        open={showConfirmation.show}
        onClose={(reason) => {
          if (isSubmitting || isPolling || reason === "backdropClick" || reason === "escapeKeyDown") {
            return;
          }
          setShowConfirmation({ show: false, onConfirm: () => { } });
        }}
        onApprove={showConfirmation.onConfirm}
        onDeny={() => {
          if (isSubmitting || isPolling) {
            return;
          }
          setShowConfirmation({ show: false, onConfirm: () => { } });
        }}
        isApproving={isSubmitting || isPolling}
      />

      <div className={styles.modalBody}>
        <>
          <div className={styles.inputOptions}>
            <TextField
              autoFocus={true}
              style={{ marginRight: 8 }}
              error={errors.name !== undefined}
              type={"text"}
              label="Name*"
              fullWidth
              margin="normal"
              variant="outlined"
              name="name"
              defaultValue={props.material.name}
              inputRef={register({ required: true })}
            />

            <TextField
              style={{ marginRight: 8 }}
              error={errors.quantity !== undefined}
              type={"number"}
              inputProps={{
                step: "0.01",
              }}
              label="Recipe Quantity*"
              fullWidth
              margin="normal"
              variant="outlined"
              name="quantity"
              defaultValue={materialQuantity}
              inputRef={register({ required: true })}
            />

            <TextField
              disabled={true}
              style={{ marginRight: 8 }}
              fullWidth
              label="Unit of Measure"
              margin="normal"
              variant="outlined"
              value={displayNameForUnitOfMeasure(
                props.material.unitOfMeasure
              )}
            />
          </div>

          <>
            <FormControl
              component="fieldset"
              classes={{
                root: classNames(styles.radioSelection),
              }}
            >
              <FormLabel component="legend">State</FormLabel>
              <RadioGroup row>
                <FormControlLabel
                  value={MaterialType.SEMI_PREPARED}
                  style={{
                    marginRight: 80,
                  }}
                  control={
                    <Radio
                      disabled={true}
                      checked={
                        props.material.type === MaterialType.SEMI_PREPARED
                      }
                    />
                  }
                  label="Semi Prepared"
                />
                <FormControlLabel
                  value={MaterialType.SELLABLE}
                  control={
                    <Radio
                      disabled={true}
                      checked={props.material.type === MaterialType.SELLABLE}
                    />
                  }
                  label="Prepared"
                />
              </RadioGroup>
            </FormControl>
          </>

          <>
            {createArrayWithNumbers(size).map(
              (value: number, index: number) => {
                const fieldName = `ingredientsArr[${index}]`;
                return (
                  <RecipeRow
                    key={index}
                    index={index}
                    fieldName={fieldName}
                    control={control}
                    materialsArr={allMaterialsArrForFoodAnalysis}
                    getMaterial={getMaterial}
                    getDefaultQuantity={getDefaultQuantity}
                  />
                );
              }
            )}
          </>

          <button
            type="button"
            className={styles.addMore}
            onClick={() => setSize(size + 1)}
          >
            Add More
          </button>
        </>
        {Auth.getInstance().getUser()?.role === Role.SUPER_ADMIN && (
          <FormControlLabel
            control={
              <Checkbox
                checked={makeBaseRecipe}
                onChange={(event) => {
                  setMakeBaseRecipe(event.target.checked);
                }}
                color="primary"
              />
            }
            label="Make base recipe"
          />
        )}
      </div>
    </ModalPopup>
  );
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";
import { IComplimentaryFoodPost } from "types/ComplimentaryFood";

export const addComplimentaryFood = async (
  foodList: IComplimentaryFoodPost[],
  date: Date,
  comment: string,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/complimentaryFood/branches/${branch.id}`,
      {
        foodList,
        date,
        comment,
      }
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Complimentary food could not be added";
    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const approveComplimentaryFood = async (
  complimentaryFoodRecordId: string
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/complimentaryFood/${complimentaryFoodRecordId}/approve`
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Complimentary food could not be approved";
    return new FMSError(
      errorMessage,
      response.serverError?.error.insufficientMaterials
    );
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

import { Dialog, IconButton, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { displayNameForEventFrequency, IEvent } from "types/Event";
import moment from "moment";
import styles from "./ViewReminderNonEditableModal.module.css";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import * as path from "path";
import { isImage } from "../../lib/FileUtils";

interface IProps {
  event: IEvent;
  onClose: () => void;
}

export const ViewReminderNonEditableModal: React.FC<IProps> = (props) => {
  const { event, onClose } = props;
  const url = event.uploadedDocumentUris
    ? new URL(event.uploadedDocumentUris![0])
    : undefined;
  const extension = path.extname(url?.pathname ?? "");
  const isImg = isImage(extension);

  const owners = event.ownerViews
    .map((employeeView) => employeeView.name)
    .join(", ");
  const reminderSms =
    event.targetEmployeesForReminders !== undefined
      ? event.targetEmployeesForReminders
          .map((employeeView) => employeeView.name)
          .join(", ")
      : "-";

  const [showModal, setShowModal] = useState<boolean>(true);

  const hideModal = () => {
    setShowModal(false);
    onClose();
  };

  const downloadImage = (url) => {
    // https://firebasestorage.googleapis.com/v0/b/lastbyte-fms.appspot.com/o/wadeshwar%2Fdev%2Fevents%2FSjmd35USfcvZZgIMmOUB%2FGroupshare-Bill-min.png?alt=media&token=873bcf50-edfc-4715-bad3-178dafc993c6
    var filename = path.basename(url); // this gives url encoded path with query params
    filename = filename.split("?")[0]; // removes query params
    filename = decodeURIComponent(filename); // decodes url
    filename = path.basename(filename); // gets the actual file name

    var element = document.createElement("a");
    var file = new Blob([url], { type: "image/*" });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    element.click();
  };

  return (
    <Dialog
      open={showModal}
      maxWidth={"md"}
      onClose={() => hideModal()}
      scroll={"body"}
      disableBackdropClick
    >
      <div className={styles.eventViewContainer}>
        <div className={styles.header} style={{ background: "#4D4D4D" }}>
          <div className={styles.headerText}>
            {event.reviewedTimestamp
              ? moment(event.reviewedTimestamp).format("Do MMMM, YYYY")
              : "-"}
          </div>

          <div className={styles.modalCloseIcon}>
            <IconButton
              name="close"
              color="inherit"
              onClick={() => {
                hideModal();
              }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.section}>
            <TextField
              className={styles.textFieldMargin}
              label="Business Unit"
              value={event.businessUnit}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Item"
              value={event.item}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Type"
              value={event.type}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Amount"
              value={
                event.amount === undefined || event.amount === 0
                  ? "-"
                  : `Rs. ${event.amount}`
              }
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Frequency"
              value={displayNameForEventFrequency(event.frequency)}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Owner(s)"
              value={owners}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Send Reminder to"
              multiline
              value={reminderSms}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Remark"
              multiline
              value={
                event.remark && event.remark.length > 0 ? event.remark : "-"
              }
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
          </div>
          <div className={styles.divider} />
          <div className={styles.section}>
            <TextField
              className={styles.textFieldMargin}
              label="Payment Made on"
              value={
                event.completionTimestamp
                  ? moment(event.completionTimestamp).format("DD-MM-YYYY")
                  : "-"
              }
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Payment Made via"
              value={event.paymentMethod}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            <TextField
              className={styles.textFieldMargin}
              label="Comment"
              value={event.comment}
              InputProps={{
                disableUnderline: true,
              }}
              inputProps={{
                readOnly: true,
              }}
            />
            {event.uploadedDocumentUris && (
              <div className={styles.invoice}>
                {isImg && (
                  <img
                    src={event.uploadedDocumentUris![0] ?? ""}
                    alt={""}
                    onClick={() => {
                      downloadImage(event.uploadedDocumentUris![0]);
                    }}
                  />
                )}
                {!isImg && (
                  <div className={styles.invoiceDoc}>
                    <a href={event.uploadedDocumentUris![0]} download>
                      Click here
                    </a>
                    {" to view uploaded document"}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

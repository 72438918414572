export enum MaterialClassification {
  VEGETABLES = "Vegetables",
  GROCERY = "Grocery",
  MILK_PRODUCTS = "Milk Products",
  BREAD_AND_CHAAT = "Bread and Chaat",
  HOUSEKEEPING = "Housekeeping",
  PACKAGING = "Packaging",
}

export const serverNameForClassification = (
  classification: MaterialClassification
) => {
  if (!classification) {
    return "";
  }

  switch (classification) {
    case MaterialClassification.VEGETABLES:
      return "VEGETABLES";
    case MaterialClassification.GROCERY:
      return "GROCERY";
    case MaterialClassification.MILK_PRODUCTS:
      return "MILK_PRODUCTS";
    case MaterialClassification.BREAD_AND_CHAAT:
      return "BREAD_AND_CHAAT";
    case MaterialClassification.HOUSEKEEPING:
      return "HOUSEKEEPING";
    case MaterialClassification.PACKAGING:
      return "PACKAGING";
    default:
      throw new Error("Unimplemented case " + classification);
  }
};

export const MaterialClassificationListForFoodAnalysis = Object.values([
  MaterialClassification.VEGETABLES,
  MaterialClassification.GROCERY,
  MaterialClassification.MILK_PRODUCTS,
  MaterialClassification.BREAD_AND_CHAAT,
]);

export const MaterialClassificationList = Object.values(MaterialClassification);

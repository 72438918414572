import { useQuery, QueryStatus, queryCache } from "react-query";
import { formatStartEndDates } from "lib/Date";
import {
  IGetPurchaseOrdersResponse,
  PurchaseOrderStatus,
} from "types/PurchaseOrder";
import { FMSError } from "types/Error";
import {
  getPurchaseOrders,
  getPurchaseOrdersForSupplier,
} from "api/GetPurchaseOrders";

interface IQueryPurchaseOrders {
  status: QueryStatus;
  purchaseOrdersResponse: IGetPurchaseOrdersResponse | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryPurchaseOrders = (
  startDate: Date,
  endDate: Date
): IQueryPurchaseOrders => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const {
    status,
    data: purchaseOrdersResponse,
    isFetching,
  } = useQuery(
    ["purchaseOrders", start, end],
    async (key: string, start: string, end: string) => {
      return await getPurchaseOrders(start, end);
    }
  );
  return { status, purchaseOrdersResponse, isFetching };
};

export const useQueryPurchaseOrdersForSupplier = (
  supplierId: string,
  purchaseOrderStatusList: PurchaseOrderStatus[]
): IQueryPurchaseOrders => {
  const {
    status,
    data: purchaseOrdersResponse,
    isFetching,
  } = useQuery(
    [
      "purchaseOrdersForSupplier-".concat(supplierId),
      supplierId,
      purchaseOrderStatusList,
    ],
    async (
      key: string,
      supplierId: string,
      purchaseOrderStatusList: PurchaseOrderStatus[]
    ) => {
      return await getPurchaseOrdersForSupplier(
        supplierId,
        purchaseOrderStatusList
      );
    }
  );
  return { status, purchaseOrdersResponse, isFetching };
};

export const invalidatePurchaseOrdersCache = () => {
  queryCache.invalidateQueries("purchaseOrders");
};

import { Checkbox, CircularProgress, IconButton } from "@material-ui/core";
import { deleteApplicableMaterial } from "api/DeleteApplicableMaterial";
import { postApplicableMaterial } from "api/PostApplicableMaterial";
import { useContext, useEffect, useState } from "react";
import { IMaterial } from "types/Material";
import { ModalContext } from "contexts/ModalContext";
import styles from "./AddApplicableMaterialModal.module.css";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

interface IProps {
    materials: IMaterial[];
    onClose: () => void;
    applicableMaterialList: IMaterial[];
}

interface IApplicableMaterial {
    material: IMaterial;
    isApplicable: boolean;
}

const ApplicableMaterial: React.FC<IApplicableMaterial> = (props) => {
    const [checked, setChecked] = useState(props.isApplicable);
    const [checkChanged, setCheckChanged] = useState<boolean | null>(null);
    const { material } = props;
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    useEffect(() => {
        async function toggleFavourite() {
            setIsUpdating(true);
            const err = checked
                ? await deleteApplicableMaterial(material.id)
                : await postApplicableMaterial(material.id);
            setIsUpdating(false);

            if (!err) {
                setChecked(!checked);
            }
        }
        if (checkChanged !== null) {
            toggleFavourite();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkChanged]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckChanged(!checkChanged);
    };
    return (
        <div className={styles.favourite}>
            {isUpdating && (
                <CircularProgress
                    size={15}
                />
            )}
            <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
            />
            <span className={styles.favouriteName}>
                {props.material.name}
            </span>
        </div>
    );
}

export const AddApplicableMaterialModal: React.FC<IProps> = (props) => {
    const modalProps = useContext(ModalContext);
    const { materials, applicableMaterialList } = props;

    return (
        <>
            <div className={styles.modalTitleBox}>
                <div className={styles.modalTitleText}>
                    <>{`Add Favourites`}</>
                </div>
                <div className={styles.modalCloseIcon}>
                    <IconButton
                        name="close"
                        color="inherit"
                        onClick={() => {
                            modalProps.hideModal();
                            props.onClose();
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
            </div>
            <div className={styles.modalBody}>
                {materials.map((material, index) => (
                    <ApplicableMaterial
                        key={index}
                        material={material}
                        isApplicable={applicableMaterialList.some(applicableMaterial => applicableMaterial.id === material.id)}
                    />
                ))}
            </div>
        </>
    );
}

import { useQuery, QueryStatus, queryCache } from "react-query";
import { FMSError } from "types/Error";
import { getStock, getStockEntries } from "api/GetStock";
import { IStockForAllBranches } from "types/Stock";
import { formatStartEndDates } from "lib/Date";

interface IQueryBranch {
  status: QueryStatus;
  stocks: IStockForAllBranches[] | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryStock = (startDate: Date, endDate: Date): IQueryBranch => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stocks, isFetching } = useQuery(
    ["stock", start, end],
    async (key: string, start: string, end: string) => {
      return await getStock(start, end);
    }
  );
  return { status, stocks, isFetching };
};

export const invalidateStockCache = () => {
  queryCache.invalidateQueries("stock");
};

export const useQueryStockEntries = (
  branchId: string,
  materialId: string,
  startDate: Date,
  endDate: Date
) => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stockEntriesFetched, isFetching } = useQuery(
    ["stockEntries", start, end],
    async (key: String, start: string, end: string) => {
      return await getStockEntries(start, end, branchId, materialId);
    }
  );
  return { status, stockEntriesFetched, isFetching };
};

export const invalidateStockEntriesCache = () => {
  queryCache.invalidateQueries("stockEntries");
  queryCache.invalidateQueries("stock");
};

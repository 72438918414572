import { IDisplayQuantity, MeasurementUnit } from "./MeasurementUnit";

export enum DashboardStatus {
  TOO_HIGH = "TOO_HIGH",
  TOO_LOW = "TOO_LOW",
  NORMAL = "NORMAL",
}

export const displayNameForDashboardStatus = (status: DashboardStatus) => {
  if (status === undefined) {
    return "";
  }

  switch (status) {
    case DashboardStatus.NORMAL:
      return "Okay";
    case DashboardStatus.TOO_HIGH:
      return "High";
    case DashboardStatus.TOO_LOW:
      return "Low";
    default:
      throw new Error("Unimplemented case " + status);
  }
};

export interface IDashboardViewForMaterial {
  materialId: string;
  materialName: string;
  measurementUnit: MeasurementUnit;
  expectedConsumption: number;
  actualConsumption: number;
  difference: number;
  isAllClosingDataEntered: boolean;
  earliestOpeningDate: Date;
  latestClosingDate: Date;
  status: DashboardStatus;
  expectedConsumptionDisplayQuantity?: IDisplayQuantity;
  actualConsumptionDisplayQuantity?: IDisplayQuantity;
  differenceDisplayQuantity: IDisplayQuantity;
}

export interface IDashboardViewForBranch {
  branchName: string;
  branchId: string;
  materialStatisticViews: IDashboardViewForMaterial[];
}

import { useQuery, QueryStatus } from "react-query";
import { FMSError } from "types/Error";
import {
  getWastageStats,
  getStocksStats,
  getStaffFoodStats,
  getSalesStats,
} from "api/GetStats";
import { IStats } from "types/Stats";
import { formatStartEndDates } from "lib/Date";

interface IQueryStats {
  status: QueryStatus;
  stats: IStats | FMSError | undefined;
  isFetching: boolean;
}

export const useQueryWastageStats = (
  materialId: string,
  startDate: Date,
  endDate: Date,
  branchId: string
): IQueryStats => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stats, isFetching } = useQuery(
    ["wastageStats", materialId, start, end, branchId],
    async () => {
      return getWastageStats(materialId, start, end, branchId);
    }
  );
  return { status, stats, isFetching };
};

export const useQueryStockStats = (
  materialId: string,
  startDate: Date,
  endDate: Date,
  branchId: string
): IQueryStats => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stats, isFetching } = useQuery(
    ["stockStats", materialId, start, end, branchId],
    async () => {
      return getStocksStats(materialId, start, end, branchId);
    }
  );
  return { status, stats, isFetching };
};

export const useQueryStaffFoodStats = (
  materialId: string,
  startDate: Date,
  endDate: Date,
  branchId: string
): IQueryStats => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stats, isFetching } = useQuery(
    ["staffFoodStats", materialId, start, end, branchId],
    async () => {
      return getStaffFoodStats(materialId, start, end, branchId);
    }
  );
  return { status, stats, isFetching };
};

export const useQuerySalesStats = (
  materialId: string,
  startDate: Date,
  endDate: Date,
  branchId: string
): IQueryStats => {
  const [start, end] = formatStartEndDates(startDate, endDate);
  const { status, data: stats, isFetching } = useQuery(
    ["salesStats", materialId, start, end, branchId],
    async () => {
      return getSalesStats(materialId, start, end, branchId);
    }
  );
  return { status, stats, isFetching };
};

import React from "react";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddUserModal } from "components/addUserModal/AddUserModal";
import { AddBranchModal } from "components/addBranchModal/AddBranchModal";
import { BranchTable } from "components/branchTable/BranchTable";
import styles from "./Settings.module.css";
import { EmployeesTable } from "components/employeesTable/EmployeesTable";
import ContentPage from "components/contentPage/ContentPage";
import { getSettings } from "api/GetSettings";
import AddIcon from "assets/AddIcon";

const Settings: React.FC = () => (
  <ContentPage
    header={"Settings"}
    mainContent={<MainSection />}
    rightSection={<RightSection />}
  />
);

const MainSection: React.FC = () => (
  <div className={styles.datatables}>
    <EmployeesTable />
    <BranchTable />
  </div>
);

const RightSection: React.FC = () => {
  return (
    <>
      <ModalWithButton title={"Add User"} icon={<AddIcon />}>
        {" "}
        <AddUserModal title={"Add User"} buttonText={"Add"} />{" "}
      </ModalWithButton>
      <ModalWithButton title={"Add Branch"} icon={<AddIcon />}>
        {" "}
        <AddBranchModal
          title={"Add Branch"}
          buttonText={"Add"}
          onClose={(branchAdded) => {
            if (branchAdded) {
              getSettings();
            }
          }}
        />{" "}
      </ModalWithButton>
    </>
  );
};

export default Settings;

import {
  useGlobalFilter,
  useTable,
  Column,
  useBlockLayout,
  useSortBy,
  useFilters,
  HeaderGroup,
} from "react-table";
import {
  CircularProgress,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import styles from "./DataTableWithDates.module.css";
import classNames from "classnames";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { GlobalFilter } from "components/globalFilter/GlobalFilter";
import { DatePickersForTableHeaders } from "components/datePickersForTableHeader/DatePickersForTableHeader";
import { FixedSizeList } from "react-window";

interface IDataTableWithDatesProps {
  columns: Column<{}>[];
  data: any;
  tableClass: string;
  isLoading: boolean;
  selectedDate: Date;
  selectedDateFrom: Date | null;
  selectedDateTo: Date | null;
  handleDateChangeFrom: (date: MaterialUiPickersDate) => void;
  handleDateChangeTo: (date: MaterialUiPickersDate) => void;
  handleDateChange: (date: MaterialUiPickersDate) => void;
  centralMonthPicker?: boolean;
  showPendingButton?: boolean;
  handleShowPendingButtonClicked?: (showPending: boolean) => void;
  initialSortBy: string;
  initialSortOrder?: "asc" | "desc";
  initialStateFilters?: IReactTableInitialStateFilters[];
  disableFuture?: boolean;
}

export interface IReactTableInitialStateFilters {
  id: string;
  value: any;
}

function renderHeader(column: HeaderGroup) {
  return (
    <>
      {column.render("Header")}
      <div>{column.disableFilters ? <></> : column.render("Filter")}</div>
    </>
  );
}

export const DataTableWithDates: React.FC<IDataTableWithDatesProps> = (
  props
) => {
  const {
    columns,
    data,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
    centralMonthPicker,
    showPendingButton = false,
    handleShowPendingButtonClicked = (showPending: boolean) => {},
    initialSortBy,
    initialSortOrder = "asc",
    initialStateFilters = [],
    disableFuture,
  } = props;
  const {
    getTableProps,
    headerGroups,
    rows,
    state,
    prepareRow,
    totalColumnsWidth,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      disableMultiSort: true,
      disableSortRemove: true,
      defaultCanSort: false,
      initialState: {
        sortBy: [{ id: initialSortBy, desc: initialSortOrder === "desc" }],
        filters: initialStateFilters,
      },
    },
    useBlockLayout,
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const headerGroup = headerGroups[1];
  const [sortOrder, setSortOrder] = React.useState<"asc" | "desc">(
    initialSortOrder
  );
  const [sortBy, setSortBy] = React.useState<string>(initialSortBy);
  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      prepareRow(row);
      return (
        <TableRow hover {...row.getRowProps({ style })} className={styles.row}>
          {row.cells.map((cell, index) => {
            return (
              <TableCell
                {...cell.getCellProps()}
                className={classNames(
                  styles.cell,
                  index !== 0 ? styles.centerAlign : ""
                )}
              >
                {cell.render("Cell")}
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows]
  );

  const [showPendingClicked, setShowPendingClicked] = useState(false);
  const onClickShowPending = () => {
    const showPending = !showPendingClicked;
    setShowPendingClicked(showPending);
    handleShowPendingButtonClicked(showPending);
  };

  const listRef = React.useRef<FixedSizeList>(null);

  // Render the UI for your table
  return (
    <div className={classNames(styles.container, props.tableClass)}>
      <div className={styles.tableHeader}>
        {!showPendingClicked && (
          <DatePickersForTableHeaders
            selectedDate={selectedDate!}
            selectedDateFrom={selectedDateFrom}
            selectedDateTo={selectedDateTo}
            handleDateChange={handleDateChange}
            handleDateChangeFrom={handleDateChangeFrom}
            handleDateChangeTo={handleDateChangeTo}
            centralDatePickerFormat={centralMonthPicker ? "month" : "date"}
            disableFuture={disableFuture}
          />
        )}
        {showPendingButton && (
          <Button
            classes={{
              root: styles.pendingButton,
            }}
            variant="contained"
            onClick={onClickShowPending}
          >
            {!showPendingClicked && "View Pending"}
            {showPendingClicked && "Back"}
          </Button>
        )}

        {!showPendingClicked && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
      </div>
      {props.isLoading && (
        <div className={styles.loadingDiv}>
          <CircularProgress
            size={30}
            color={"inherit"}
            className={styles.loading}
          />
        </div>
      )}
      <Table {...getTableProps()}>
        <TableHead>
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <TableCell
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className={classNames(
                  styles.cell,
                  styles.header,
                  index !== 0 ? styles.centerAlign : ""
                )}
                key={column.id}
                onClick={() => {
                  if (column.disableSortBy) {
                    return;
                  }

                  const isAsc = sortBy === column.id && sortOrder === "asc";
                  setSortOrder(isAsc ? "desc" : "asc");
                  setSortBy(column.id);
                  column.toggleSortBy(isAsc, false);
                }}
              >
                {column.disableSortBy && renderHeader(column)}
                {!column.disableSortBy && (
                  <TableSortLabel
                    active={sortBy === column.id}
                    direction={sortBy === column.id ? sortOrder : undefined}
                  >
                    {renderHeader(column)}
                  </TableSortLabel>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <FixedSizeList
            ref={listRef}
            height={600}
            itemCount={rows.length}
            itemSize={51}
            width={totalColumnsWidth}
          >
            {RenderRow}
          </FixedSizeList>
        </TableBody>
      </Table>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import styles from "./ComplimentaryFoodTable.module.css";
import { useQueryComplimentaryFoods } from "hooks/ComplimentaryFoods";
import { IBranch } from "types/Branch";
import {
  IComplimentaryFoodForAllBranches,
  IComplimentaryFoodView,
} from "types/ComplimentaryFood";
import { ComplimentaryFoodTableHelper } from "./ComplimentaryFoodTableHelper";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { FMSError } from "types/Error";
import { useQuerySelectedFromToDates } from "hooks/SelectedFromToDates";
import { Status } from "types/Status";
import { getPendingComplimentaryFood } from "api/GetComplimentaryFood";
import CSVDownload from "components/utils/CSVDownload";
import moment from "moment";
import { getComplimentaryFoodDataForExport } from "lib/CSVExportUtils";

export const complimentaryFoodForBranch = (
  complimentaryFoodWithBranches: IComplimentaryFoodForAllBranches[],
  selectedBranch: IBranch
): IComplimentaryFoodView[] => {
  for (let i = 0; i < complimentaryFoodWithBranches.length; i++) {
    if (complimentaryFoodWithBranches[i].branchId === selectedBranch.id) {
      return complimentaryFoodWithBranches[i].complimentaryFoodList.sort(
        (a, b) => {
          if (a.status === b.status) {
            return a.date < b.date ? 1 : -1;
          }

          if (a.status === Status.PENDING) {
            return -1;
          }

          if (b.status === Status.PENDING) {
            return 1;
          }

          return 0;
        }
      );
    }
  }
  return [];
};

export const ComplimentaryFoodTable: React.FC<{ selectedBranch: IBranch }> = (
  props
) => {
  const {
    start,
    end,
    selectedDate,
    selectedDateFrom,
    selectedDateTo,
    handleDateChange,
    handleDateChangeFrom,
    handleDateChangeTo,
  } = useQuerySelectedFromToDates();
  const { status, complimentaryFoods, isFetching } = useQueryComplimentaryFoods(
    start,
    end
  );
  const [complimentaryFoodViews, setComplimentaryFood] = useState<
    IComplimentaryFoodView[]
  >([]);
  useEffect(() => {
    if (status === "success") {
      if (!complimentaryFoods || complimentaryFoods instanceof FMSError) {
        const message = complimentaryFoods?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        setComplimentaryFood(
          complimentaryFoodForBranch(complimentaryFoods, props.selectedBranch)
        );
      }
    }
  }, [status, complimentaryFoods, props.selectedBranch]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [showPending, setShowPending] = useState(false);
  const [pendingComplimentaryFood, setPendingComplimentaryFood] = useState<
    IComplimentaryFoodView[]
  >([]);
  const [pendingStatus, setPendingStatus] = useState<"loading" | "complete">();

  const handleShowPendingButtonClicked = async (showPending: boolean) => {
    setShowPending(showPending);
    if (showPending) {
      setPendingStatus("loading");
      const result = await getPendingComplimentaryFood();
      setPendingStatus("complete");
      if (result instanceof FMSError) {
        const message = result.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
        return;
      }

      setPendingComplimentaryFood(
        complimentaryFoodForBranch(result, props.selectedBranch)
      );
    }
  };

  useEffect(() => {
    handleShowPendingButtonClicked(showPending);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedBranch]);

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <div
        style={{
          textAlign: "end",
        }}
      >
        <CSVDownload
          filename={`Complimentary-Food-${props.selectedBranch.name}-${moment(
            start
          ).format("DD MMM")}-to-${moment(end).format("DD MMM")}`}
          data={getComplimentaryFoodDataForExport(complimentaryFoodViews)}
        />
      </div>
      <ComplimentaryFoodTableHelper
        selectedBranch={props.selectedBranch}
        isLoading={isFetching || pendingStatus === "loading"}
        data={showPending ? pendingComplimentaryFood : complimentaryFoodViews}
        tableClass={styles.table}
        selectedDate={selectedDate!}
        selectedDateFrom={selectedDateFrom}
        selectedDateTo={selectedDateTo}
        handleDateChange={handleDateChange}
        handleDateChangeFrom={handleDateChangeFrom}
        handleDateChangeTo={handleDateChangeTo}
        handleShowPendingButtonClicked={handleShowPendingButtonClicked}
      />
    </>
  );
};

import {
  IEmployee,
  isRoleAdmin,
  isRoleManager,
  isRoleSupervisor,
} from "types/Employee";
import { FMSError } from "types/Error";
import firebase from "firebase/app";
import { IPage, allPages } from "types/Page";
import "firebase/auth";
import { IExternalBranch } from "types/Branch";
import { Config } from "./Config";

export class Auth {
  private static instance: Auth;
  private auth: firebase.auth.Auth;
  private authSession: firebase.auth.ConfirmationResult | undefined;

  private constructor() {
    firebase.initializeApp(Config.getInstance().getFirebaseConfig());
    this.auth = firebase.auth();
  }

  public static getInstance() {
    if (!Auth.instance) {
      Auth.instance = new Auth();
    }
    return Auth.instance;
  }

  public setUser(employee: IEmployee) {
    window.localStorage.setItem("Employee", JSON.stringify(employee));
  }

  private clearUser() {
    window.localStorage.removeItem("Employee");
  }

  public getUser(): IEmployee | undefined {
    const employee = window.localStorage.getItem("Employee");
    if (employee) {
      return JSON.parse(employee);
    }

    return undefined;
  }

  public async getToken(): Promise<string | FMSError> {
    const self = this;
    return new Promise<string | FMSError>(function (resolve, reject) {
      self.auth.onAuthStateChanged(async function (user) {
        if (user) {
          const token = await user.getIdToken();
          if (token) {
            resolve(token);
          }
          reject(new FMSError("token not found"));
        } else {
          reject(new FMSError("user not found"));
        }
      });
    }).catch((error) => {
      if (error instanceof FMSError) {
        return error;
      }
      console.log(error);
      return new FMSError("Something went wrong");
    });
  }

  public async refreshToken() {
    await this.auth.currentUser?.getIdToken(true);
  }

  public logout() {
    this.auth.signOut();
    this.clearUser();
  }

  public async signInWithPhoneNumber(
    phoneNumber: string
  ): Promise<FMSError | undefined> {
    const appVerifier = new firebase.auth.RecaptchaVerifier("validate", {
      size: "invisible",
      callback: function () {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
      },
    });

    const self = this;
    return await this.auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((result) => {
        self.authSession = result;
        return undefined;
      })
      .catch((error) => {
        console.log(error);
        return new FMSError("Something went wrong");
      });
  }

  public async verifyOtp(otp: string): Promise<FMSError | undefined> {
    if (!this.authSession) {
      return new FMSError("No auth session in progress");
    }

    if (otp.length < 6) {
      return new FMSError("Please enter a valid OTP");
    }

    const self = this;
    return await this.authSession
      .confirm(otp)
      .then((result) => {
        if (result && result.user) {
          self.authSession = undefined;
          return;
        }
        console.log(result);
        return new FMSError("Something went wrong");
      })
      .catch((error) => {
        console.log(error);
        return new FMSError("Invalid OTP entered, please try again.");
      });
  }

  public getPagesForCurrentUser(): IPage[] {
    const user = this.getUser();
    if (!user) {
      return [];
    }

    return allPages.filter((page) => page.allowedRoles.includes(user.role));
  }

  public getBranchesForCurrentUser(): IExternalBranch[] {
    const user = this.getUser();
    if (!user || !user.branches) {
      return [];
    }

    return user.branches.sort((a, b) =>
      a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    );
  }

  public isAdmin(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    return isRoleAdmin(user.role);
  }

  public isAdminOrSupervisor(): boolean {
    const user = this.getUser();

    if (!user) {
      return false;
    }

    return isRoleAdmin(user.role) || isRoleSupervisor(user.role);
  }

  public isManager(): boolean {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    return isRoleManager(user.role);
  }
}

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function SubtractIconComponent() {
  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      viewBox="0 0 25 25"
    >
      <path
        d="M25 12.5A12.5 12.5 0 1112.5 0 12.5 12.5 0 0125 12.5z"
        fill={primaryColor}
      />
      <g fill={primaryTextColor}>
        <path d="M7.578 12.685v-.369h9.844v.369H7.578z" />
        <path d="M18.422 12.316v.369a1 1 0 01-1 1H7.578a1 1 0 01-1-1v-.369a1 1 0 011-1h9.844a1 1 0 011 1z" />
      </g>
    </svg>
  );
}
const SubtractIcon = React.memo(SubtractIconComponent);
export default SubtractIcon;

import React from "react";
import styles from "./CommentBox.module.css";
import { IconButton } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import moment from "moment";

interface ICommentBoxProps {
  employeeName: string;
  comment: string;
  date: Date;
  onClose: () => void;
}
export const CommentBox: React.FC<ICommentBoxProps> = (props) => {
  const { employeeName, comment, date } = props;
  const dateToDisplay = moment(new Date(date)).format("h:mm a, MMMM Do");
  return (
    <div className={styles.commentParent}>
      <div className={styles.commentDiv}>
        <div className={styles.miniBody}>
          <div className={styles.employeeName}>{employeeName}</div>
          <div className={styles.modalCloseIcon}>
            <IconButton
              name="close"
              color="inherit"
              onClick={props.onClose}
              classes={{ root: styles.iconButton }}
            >
              <CloseRoundedIcon />
            </IconButton>
          </div>
        </div>
        <div className={styles.date}>{dateToDisplay}</div>
        <div className={styles.commentText}>{comment}</div>
      </div>
    </div>
  );
};

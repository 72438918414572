import { Role } from "./Employee";
import DashboardIcon from "assets/DashboardIcon";
import React from "react";
import SalesIcon from "assets/SalesIcon";
import WastageIcon from "assets/WastageIcon";
import RecipeIcon from "assets/RecipeIcon";
import StaffFoodIcon from "assets/StaffFoodIcon";
import ComplimentaryFoodIcon from "assets/ComplimentaryFoodIcon";
import StockTransferIcon from "assets/StockTransferIcon";
import ReportsIcon from "assets/ReportsIcon";
import SettingsIcon from "assets/SettingsIcon";
import LogoutIcon from "assets/LogoutIcon";
import StockIcon from "assets/StockIcon";
import ReminderIcon from "assets/RemindersIcon";
import PurchaseOrdersIcon from "assets/PurchaseOrdersIcon";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import KitchenIcon from "@material-ui/icons/Kitchen";
import SdStorageIcon from "@material-ui/icons/SdStorage";

export interface IPage {
  icon?: React.ReactNode;
  title: string;
  href: string;
  allowedRoles: Role[];
  hidden?: boolean;
  disabledInProd?: boolean;
}

export const allPages: IPage[] = [
  {
    icon: <DashboardIcon></DashboardIcon>,
    title: "Dashboard",
    href: "/",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
  {
    icon: <SalesIcon />,
    title: "Sales Report",
    href: "/sales",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    title: "Sales Stats",
    href: "/sales-stats/:id",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
    hidden: true,
  },
  {
    icon: <StockIcon />,
    title: "Stock",
    href: "/stock",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
  {
    title: "Stock Stats",
    href: "/stock-stats/:id",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
    hidden: true,
  },
  {
    icon: <WastageIcon />,
    title: "Wastage",
    href: "/wastage",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
  {
    title: "Wastage Stats",
    href: "/wastage-stats/:id",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
    hidden: true,
  },
  {
    icon: <RecipeIcon />,
    title: "Recipe",
    href: "/recipe",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN],
  },
  {
    icon: <StaffFoodIcon />,
    title: "Staff Food",
    href: "/staff-food",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
  {
    title: "Staff Food Stats",
    href: "/staff-food-stats/:id",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
    hidden: true,
  },
  {
    icon: <ComplimentaryFoodIcon />,
    title: "Complimentary Food",
    href: "/complimentary-food",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    icon: <StockTransferIcon />,
    title: "In-flow / Out-flow",
    href: "/in-out-flow",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
  {
    icon: <KitchenIcon fontSize="small" />,
    title: "Store forecast",
    href: "/store-forecast",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    icon: <RestaurantIcon fontSize="small" />,
    title: "Kitchen forecast",
    href: "/kitchen-forecast",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    icon: <SdStorageIcon fontSize="small" />,
    title: "Sales forecast",
    href: "/sales-forecast",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    icon: <ReportsIcon />,
    title: "Reports",
    href: "/report",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.SUPERVISOR, Role.MANAGER],
  },
  {
    icon: <SettingsIcon />,
    title: "Settings",
    href: "/settings",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN],
  },
  {
    icon: <ReminderIcon />,
    title: "Reminders",
    href: "/reminders",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    title: "Add Event Categories",
    href: "/reminders/add-event-categories",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN],
    hidden: true,
  },
  {
    icon: <PurchaseOrdersIcon />,
    title: "Purchase Orders",
    href: "/purchase-orders",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
  },
  {
    title: "Add Purchase Order",
    href: "/purchase-orders/create",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
    hidden: true,
  },
  {
    title: "View Purchase Order",
    href: "/purchase-orders/:purchaseOrderId",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
    hidden: true,
  },
  {
    title: "View Suppliers",
    href: "/purchase-orders/suppliers",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.SUPERVISOR, Role.MANAGER],
    hidden: true,
  },
  {
    title: "Add Supplier",
    href: "/purchase-orders/suppliers/create",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.SUPERVISOR],
    hidden: true,
  },
  {
    title: "Edit Supplier",
    href: "/purchase-orders/suppliers/:supplierId",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.SUPERVISOR, Role.MANAGER],
    hidden: true,
  },
  {
    title: "Generate GRN",
    href: "/purchase-orders/:purchaseOrderId/grn",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.MANAGER, Role.SUPERVISOR],
    hidden: true,
  },
  {
    title: "Consolidate Purchase Orders",
    href: "/purchase-orders/suppliers/:supplierId/consolidate",
    allowedRoles: [Role.SUPER_ADMIN, Role.ADMIN, Role.SUPERVISOR],
    hidden: true,
  },
  {
    icon: <LogoutIcon />,
    title: "Logout",
    href: "/logout",
    allowedRoles: [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.MANAGER,
      Role.SUPERVISOR,
      Role.DATA_ENTRY,
    ],
  },
];

import { FMSThemeContext } from "contexts/FMSThemeContext";
import React, { useContext } from "react";

function ReportsIconComponent() {
  const { primaryTextColor } = useContext(FMSThemeContext);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.6}
      height={14.612}
      viewBox="0 0 12.6 14.612"
    >
      <defs>
        <style>
          {".reportsIconComponent__a{fill:" +
            primaryTextColor +
            ";stroke:" +
            primaryTextColor +
            ";stroke-width:.3px}"}
        </style>
      </defs>
      <path
        className="reportsIconComponent__a"
        d="M12.324 3.142L9.4.27a.419.419 0 00-.294-.12H1.714A1.566 1.566 0 00.15 1.715v11.183a1.566 1.566 0 001.565 1.565h9.171a1.566 1.566 0 001.564-1.565V3.441a.419.419 0 00-.125-.3zm-2.8-1.573l1.479 1.453H9.659a.134.134 0 01-.133-.133zm1.359 12.055H1.714a.727.727 0 01-.726-.726V1.715a.727.727 0 01.726-.726h6.973v1.9a.973.973 0 00.972.972h1.952v9.037a.727.727 0 01-.726.726zm0 0"
      />
      <path
        className="reportsIconComponent__a"
        d="M9.211 6.803H3.3a.42.42 0 100 .839h5.911a.42.42 0 100-.839zm0 0M3.3 9.319h4.82a.42.42 0 000-.839H3.3a.42.42 0 000 .839zm0 0M8.701 10.157h-5.4a.42.42 0 000 .839h5.4a.42.42 0 000-.839zm0 0"
      />
    </svg>
  );
}
const ReportsIcon = React.memo(ReportsIconComponent);
export default ReportsIcon;

import React, { ReactNode, useContext } from "react";
import { Chart } from "react-charts";
import styles from "./ColumnGraph.module.css";
import "./style.module.css";
import { ChartCoordinateData } from "types/Chart";
import { FMSThemeContext } from "contexts/FMSThemeContext";

interface IColumnGraph {
  data: ChartCoordinateData[];
  header: ReactNode;
}
const ColumnGraph: React.FC<IColumnGraph> = React.memo((props) => {
  const { primaryColor } = useContext(FMSThemeContext);
  const series = React.useMemo(
    () => ({
      type: "bar",
    }),
    []
  );
  const getSeriesStyle = React.useCallback(
    () => ({
      color: primaryColor,
      transition: "height .5s ease",
    }),
    [primaryColor]
  );

  const getDatumStyle = React.useCallback(
    () => ({
      transition: "height .5s ease",
    }),
    []
  );

  const axes = React.useMemo(() => {
    let minY = props.data[0].y;
    props.data.forEach((value) => {
      if (value.y < minY) {
        minY = value.y;
      }
    });

    minY = minY - minY / 10;
    if (minY < 0) {
      minY = 0;
    }

    return [
      {
        primary: true,
        type: "ordinal",
        position: "bottom",
        showGrid: false,
      },
      {
        position: "left",
        type: "linear",
        stacked: false,
        showGrid: false,
        hardMin: minY,
      },
    ];
  }, [props.data]);

  return (
    <div className={styles.body}>
      <div className={styles.graphHeader}>{props.header}</div>
      <div className={styles.chart}>
        <Chart
          series={series}
          data={[{ datums: props.data, label: "Monthly Value" }]}
          axes={axes}
          getSeriesStyle={getSeriesStyle}
          getDatumStyle={getDatumStyle}
          tooltip
        />
      </div>
    </div>
  );
});

export default ColumnGraph;

import ContentPage from "components/contentPage/ContentPage";
import EditSupplierComponent from "components/editSupplier/EditSupplierComponent";
import React from "react";
import { useParams } from "react-router-dom";

interface IParams {
  supplierId: string;
}

const EditSupplier: React.FC = React.memo(() => {
  const { supplierId } = useParams<IParams>();
  return (
    <ContentPage
      header={<Header />}
      mainContent={<MainSection supplierId={supplierId} />}
      rightSection={<RightSection />}
    />
  );
});

const Header: React.FC = (props) => {
  return <></>;
};

const MainSection: React.FC<{ supplierId: string }> = (props) => {
  return <EditSupplierComponent supplierId={props.supplierId} />;
};

const RightSection: React.FC = () => {
  return <></>;
};

export default EditSupplier;

import { FMSThemeContext } from "contexts/FMSThemeContext";
import * as React from "react";
import { SVGProps, memo } from "react";

const PurchaseOrdersIconComponent = (props: SVGProps<SVGSVGElement>) => {
  const { primaryTextColor } = React.useContext(FMSThemeContext);
  return (
    <svg
      data-name="PO Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            data-name="Rectangle 2112"
            fill={primaryTextColor}
            stroke={primaryTextColor}
            strokeWidth={0.3}
            d="M0-.138h14v14H0z"
          />
        </clipPath>
      </defs>
      <g
        data-name="Group 3207"
        transform="translate(0 .138)"
        clipPath="url(#a)"
        fill={primaryTextColor}
        stroke={primaryTextColor}
        strokeWidth={0.3}
      >
        <path
          data-name="Path 11737"
          d="M2.708 0h8.446a.3.3 0 0 0 .073.027 3.02 3.02 0 0 1 2.539 2.252c.037.141.064.286.1.429v8.447c-.05.208-.084.422-.154.624a3.015 3.015 0 0 1-2.931 2.083H3.076a2.963 2.963 0 0 1-1-.154A3.019 3.019 0 0 1 0 10.773V3.085a3.4 3.4 0 0 1 .026-.431A3.008 3.008 0 0 1 2.265.1c.146-.038.295-.068.443-.1M13 6.937v-3.8a2.58 2.58 0 0 0-.039-.484A2.158 2.158 0 0 0 10.774.867H3.087a2.373 2.373 0 0 0-.444.04A2.16 2.16 0 0 0 .866 3.1v7.673a2.388 2.388 0 0 0 .039.444A2.16 2.16 0 0 0 3.088 13q3.844 0 7.687-.005a2.427 2.427 0 0 0 .755-.122A2.141 2.141 0 0 0 13 10.78V6.937"
        />
        <path data-name="Rectangle 2109" d="M5.638 3.473h5.184v.848H5.638z" />
        <path data-name="Rectangle 2110" d="M5.643 6.507h5.179v.849H5.643z" />
        <path data-name="Rectangle 2111" d="M5.642 9.536h5.179v.849H5.642z" />
        <path
          data-name="Path 11738"
          d="M4.765 3.899v.419a.425.425 0 0 1-.44.445h-.853a.423.423 0 0 1-.439-.43v-.866a.423.423 0 0 1 .44-.434h.853a.425.425 0 0 1 .439.446v.419"
        />
        <path
          data-name="Path 11739"
          d="M3.899 6.065h.433a.422.422 0 0 1 .431.425q.005.439 0 .879a.422.422 0 0 1-.431.425h-.866a.423.423 0 0 1-.433-.437v-.852a.427.427 0 0 1 .447-.44h.419"
        />
        <path
          data-name="Path 11740"
          d="M3.912 9.097h.419a.423.423 0 0 1 .432.425q.005.439 0 .879a.423.423 0 0 1-.432.425h-.866a.423.423 0 0 1-.432-.439v-.853a.425.425 0 0 1 .445-.439h.433"
        />
      </g>
    </svg>
  );
};

const PurchaseOrdersIcon = memo(PurchaseOrdersIconComponent);
export default PurchaseOrdersIcon;

import { Button } from "@material-ui/core";
import {
  ConsolidatePreviewResponse,
  processPurchaseOrdersForSupplier,
  IConsolidatePoForSupplierRequest,
} from "api/PostPurchaseOrder";
import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./ConsolidateProcess.module.css";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Loading } from "components/loading/Loading";
import { FMSError } from "types/Error";
import { useMutation } from "react-query";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import { invalidatePurchaseOrdersCache } from "hooks/purchaseOrders";
import { displayQuantity, IDisplayQuantity } from "types/MeasurementUnit";
import { ConfirmationDialog } from "components/confirmationDialog/ConfirmationDialog";
import { objToStrMap } from "lib/MapHelpers";

interface IMaterialsDisplayData {
  matertialName: string;
  branchStats: { displayQuantity?: IDisplayQuantity }[];
}

const displayDataForPreview = (
  consolidatePreviewResponse: ConsolidatePreviewResponse
) => {
  const sortedBranches = consolidatePreviewResponse.data.involvedBranches.sort(
    (a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1)
  );

  const sortedBranchIds = sortedBranches.map((branch) => branch.id);

  const sortedConsolidatedMaterials =
    consolidatePreviewResponse.data.consolidatedMaterialData.sort((a, b) =>
      a.materialView.name.toUpperCase() > b.materialView.name.toUpperCase()
        ? 1
        : -1
    );

  const sortedMaterialsWithBranchStats: IMaterialsDisplayData[] = [];
  const sortedDeliveryAddresses: string[] = [];
  const sortedNoOfItems: number[] = [];
  const branchIdToDeliveryAddressMap = objToStrMap<string>(
    consolidatePreviewResponse.data.branchIdToDeliveryAddressMap
  );
  const branchIdToMaterialCount = objToStrMap<number>(
    consolidatePreviewResponse.data.branchIdToMaterialCount
  );
  sortedBranchIds.forEach((branchId) => {
    const deliveryAddress = branchIdToDeliveryAddressMap.get(branchId);
    sortedDeliveryAddresses.push(deliveryAddress ?? "-");

    const numberOfItems = branchIdToMaterialCount.get(branchId);
    sortedNoOfItems.push(numberOfItems ?? 0);
  });

  sortedConsolidatedMaterials.forEach((data) => {
    const allBranchStats: { displayQuantity?: IDisplayQuantity }[] = [];
    for (var i = 0; i < sortedBranchIds.length; i++) {
      allBranchStats.push({});
    }

    data.branchWiseMaterialStatistics.forEach((branchWiseData) => {
      const branchId = branchWiseData.branchView.id;
      const index = sortedBranchIds.indexOf(branchId);
      allBranchStats[index].displayQuantity = branchWiseData.displayQuantity;
    });

    const materialDisplayData: IMaterialsDisplayData = {
      matertialName: data.materialView.name,
      branchStats: allBranchStats,
    };

    sortedMaterialsWithBranchStats.push(materialDisplayData);
  });

  return {
    sortedBranches,
    sortedDeliveryAddresses,
    sortedMaterialsWithBranchStats,
    sortedNoOfItems,
  };
};

export const ConsolidateProcess: React.FC<{
  consolidatePoRequest?: IConsolidatePoForSupplierRequest;
  consolidatePreviewResponse: ConsolidatePreviewResponse;
}> = (props) => {
  const { consolidatePreviewResponse, consolidatePoRequest } = props;
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [mutate] = useMutation(
    (request: IConsolidatePoForSupplierRequest) => {
      setSubmitting(true);
      return processPurchaseOrdersForSupplier(request);
    },
    {
      onSuccess: (error) => {
        setSubmitting(false);
        if (error instanceof FMSError) {
          setShowToast({
            open: true,
            message: error.message ?? "Something went wrong!",
            type: "error",
          });
        } else {
          invalidatePurchaseOrdersCache();
          setShowSuccessDialog(true);
        }
      },
    }
  );

  const displayData = displayDataForPreview(consolidatePreviewResponse);

  return (
    <>
      <Toast
        open={showToast.open}
        message={showToast.message}
        type={showToast.type}
        onClose={handleToastClose}
      />
      <ConfirmationDialog
        className={styles.successDialog}
        buttonText={"Okay"}
        bodyText={"Purchase Order is successfully emailed to supplier"}
        open={showSuccessDialog}
        onClose={() => {
          history.goBack();
        }}
        onApprove={() => {
          history.goBack();
        }}
        isApproving={false}
      />
      <div className={styles.header}>
        <div style={{ display: "flex" }}>
          <Button
            className={styles.backButton}
            onClick={() => {
              history.goBack();
            }}
          >
            <KeyboardBackspaceIcon style={{ marginRight: "10px" }} /> {"Back"}
          </Button>
          <Button
            style={{ color: "#ffffff" }}
            className={styles.sendEmailButton}
            onClick={async () => {
              if (consolidatePoRequest) {
                mutate(consolidatePoRequest);
              }
            }}
          >
            <Loading text={"Send Email"} isLoading={submitting} />
          </Button>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.head}>
          <div
            className={styles.headText}
          >{`Supplier: ${consolidatePreviewResponse.data.supplier.name}`}</div>
          <div className={styles.headText}>{`Date: ${moment().format(
            "DD-MM-YYYY"
          )}`}</div>
        </div>
        <table className={styles.consolidatePreviewTable}>
          <tr>
            <th className={styles.headCell}>{"Branch"}</th>
            {displayData.sortedBranches.map((branch, index) => {
              return (
                <th key={`branch-${index}`} className={styles.headCell}>
                  {branch.name}
                </th>
              );
            })}
          </tr>
          <tr>
            <th>{"No of items"}</th>
            {displayData.sortedNoOfItems.map((number, index) => {
              return <td key={`noOfItems-${index}`}>{number}</td>;
            })}
          </tr>
          {displayData.sortedMaterialsWithBranchStats.map(
            (consolidatedMaterial, index) => {
              return (
                <tr key={`material-${index}`}>
                  <th>{consolidatedMaterial.matertialName}</th>
                  {consolidatedMaterial.branchStats.map(
                    (consolidatedMaterial, index) => {
                      return (
                        <td
                          key={`branchStats-${index}`}
                          className={styles.quantityCell}
                        >
                          {displayQuantity(
                            consolidatedMaterial.displayQuantity
                          )}
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            }
          )}
          <tr>
            <th>{"Firm Name"}</th>
            {displayData.sortedBranches.map((branch, index) => {
              return (
                <td key={`firmName-${index}`}>{branch.firmName ?? "-"}</td>
              );
            })}
          </tr>
          <tr>
            <th>{"GST"}</th>
            {displayData.sortedBranches.map((branch, index) => {
              return <td key={`gst-${index}`}>{branch.gstNumber ?? "-"}</td>;
            })}
          </tr>
          <tr>
            <th>{"Billing Address"}</th>
            {displayData.sortedBranches.map((branch, index) => {
              return (
                <td key={`billing-${index}`}>{branch.billingAddress ?? "-"}</td>
              );
            })}
          </tr>
          <tr>
            <th>{"Delivery Address"}</th>
            {displayData.sortedDeliveryAddresses.map(
              (deliveryAddress, index) => {
                return <td key={`delivery-${index}`}>{deliveryAddress}</td>;
              }
            )}
          </tr>
        </table>
      </div>
    </>
  );
};

import { BACKEND_URL_V1, post } from "lib/Api";
import { FMSError } from "types/Error";

export const postApplicableMaterial = async (
    materialId: string
): Promise<FMSError | null> => {
    try {
        const response = await post(
            `${BACKEND_URL_V1}/predictions/prepared/applicableMaterial/${materialId}`
        );
        if (response.status === 202) {
            return null;
        }
    } catch (err) {
        return new FMSError("Something went wrong");
    }
    return new FMSError("Something went wrong");
};

import { post, BACKEND_URL_V1 } from "lib/Api";
import { IBranch } from "types/Branch";
import { FMSError } from "types/Error";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";
import { ISalePost } from "types/Sales";

export const addSales = async (
  saleObjects: ISalePost[],
  date: Date,
  branch: IBranch
): Promise<FMSError | null> => {
  try {
    const response = await post(
      `${BACKEND_URL_V1}/sales/branches/${branch.id}`,
      {
        saleObjects,
        date,
      }
    );
    if (response.status === 202) {
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ?? "Sales could not be added";
    return new FMSError(
      errorMessage,
      response.serverError?.error.insufficientMaterials
    );
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const uploadSales = async (
  selectedBranch: IBranch,
  filesToUpload: File[],
  date: Date
): Promise<FMSError | null> => {
  const formData = new FormData();
  for (let i = 0; i < filesToUpload.length; i++) {
    formData.append("uploadedFile", filesToUpload[i]);
  }
  try {
    const response = await post(
      `${BACKEND_URL_V1}/sales/branches/${
        selectedBranch.id
      }/uploadReport?date=${formatDate(date)}`,
      formData,
      {
        method: "post",
        body: formData,
      },
      true,
      false
    );
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }

    let errorMessage;
    if (response.status === 428) {
      downloadFile(response.headers, await response.blob(), "errors.xlsx");
      errorMessage =
        "Invalid file contents, please open downloaded file to check and resolve errors";
    } else {
      response.serverError = await response.json();
      errorMessage =
        response.serverError?.error.message ?? "Unable to upload sales";
    }

    return new FMSError(errorMessage);
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
};

export const updateSaleEntry = async (
  id: string,
  quantity: Number
): Promise<FMSError | null> => {
  try {
    const response = await post(`${BACKEND_URL_V1}/sales/${id}`, {
      quantity,
    });
    if (Math.floor(response.status / 100) === 2) {
      return null;
    }
    const errorMessage =
      response.serverError?.error.message ?? "Sale could not be updated";
    return new FMSError(errorMessage);
  } catch (err) {
    return new FMSError("Something went wrong");
  }
};

import React, { useContext, useEffect, useState } from "react";
import styles from "./DailyEntryReportTable.module.css";
import {
  Button,
  InputAdornment,
  InputLabel,
  Tab,
  Tabs,
  ThemeProvider,
} from "@material-ui/core";
import { useQueryReport } from "hooks/Reports";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePicker } from "@material-ui/pickers";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import classNames from "classnames";
import { DataTable } from "components/dataTable/DataTable";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { getEntryData } from "lib/ReportUtils";
import { IEntryReportRow } from "types/EntryReport";
import { TicksAndCrossesCell } from "./TicksAndCrossesCell";
import CalendarIcon from "assets/CalendarIcon";
import { FMSThemeContext } from "contexts/FMSThemeContext";
import { FMSError } from "types/Error";
import { createTheme } from "@material-ui/core/styles";

export const DailyEntryReportTable: React.FC<{}> = () => {
  const { branches } = useContext(BranchFavouriteContext);
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [datesForQuery, setDatesForQuery] = useState<{
    start: Date;
    end: Date;
  }>({ start: firstDayOfMonth, end: today });

  const [startDate, handleStartDateChange] = useState<MaterialUiPickersDate>(
    datesForQuery.start
  );
  const [endDate, handleEndDateChange] = useState<MaterialUiPickersDate>(
    datesForQuery.end
  );

  const { status, entryReport, isFetching } = useQueryReport(
    "dailyEntry",
    datesForQuery.start,
    datesForQuery.end
  );
  const [dateTab, setDateTab] = useState<string[]>([]);
  const [dateWiseEntryReportRows, setDateWiseEntryReportRows] =
    useState<Map<string, IEntryReportRow[]>>();
  const [tableData, setTableData] = useState<IEntryReportRow[]>([]);
  useEffect(() => {
    if (status === "success") {
      if (!entryReport || entryReport instanceof FMSError) {
        const message = entryReport?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        const branchMap = new Map();
        branches.forEach((branch) => branchMap.set(branch.id, branch));
        const entryReports = getEntryData(entryReport, branchMap);
        setDateTab(entryReports.tabs);
        setDateWiseEntryReportRows(entryReports.data);
        // set the table data to first date in the tab
        setTableData(entryReports.data.get(entryReports.tabs[0])!);
      }
    }
  }, [entryReport, branches, status]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const handleSubmit = () => {
    if (startDate && endDate) {
      setDatesForQuery({ start: startDate, end: endDate });
    }
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
    setTableData(dateWiseEntryReportRows?.get(dateTab[newValue])!);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Entry Report Table",
        columns: [
          {
            Header: "Branch",
            accessor: "branchName",
            width: 260,
            disableFilters: true,
          },
          {
            Header: "Stock Addition",
            accessor: "stock",
            width: 180,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
          {
            Header: "Wastage",
            accessor: "wastage",
            width: 70,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
          {
            Header: "Staff Food",
            accessor: "staffFood",
            width: 150,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
          {
            Header: "Stock Transfer",
            accessor: "stockTransfer",
            width: 150,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  const { primaryColor, primaryTextColor } = useContext(FMSThemeContext);
  const materialTheme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
        contrastText: primaryTextColor,
      },
    },
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <InputLabel
            style={{
              marginRight: "10px",
            }}
          >
            From:
          </InputLabel>
          <ThemeProvider theme={materialTheme}>
            <DatePicker
              views={["date"]}
              value={startDate}
              placeholder={"From"}
              onChange={(startDate) => {
                handleStartDateChange(startDate);
              }}
              autoOk={true}
              variant={"inline"}
              disableFuture
              inputVariant="outlined"
              format="MMM dd"
              InputProps={{
                className: classNames(styles.input, styles.widthDates),
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
            <InputLabel
              style={{
                marginRight: "10px",
                marginLeft: "10px",
              }}
            >
              To:
            </InputLabel>
            <DatePicker
              views={["date"]}
              value={endDate}
              placeholder={"To"}
              onChange={(endDate) => {
                handleEndDateChange(endDate);
              }}
              autoOk={true}
              variant={"inline"}
              disableFuture
              inputVariant="outlined"
              format="MMM dd"
              InputProps={{
                className: classNames(styles.input, styles.widthDates),
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          </ThemeProvider>
          <Button
            className={styles.submitButton}
            style={{
              marginLeft: "10px",
              color: primaryTextColor,
              backgroundColor: primaryColor,
            }}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        <Toast
          open={showToast.open}
          message={showToast.message}
          type={showToast.type}
          onClose={handleToastClose}
        />
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          onChange={handleChange}
        >
          {dateTab.map((date, index) => (
            <Tab key={index} label={date} />
          ))}
        </Tabs>

        <DataTable
          columns={columns}
          data={tableData}
          tableClass={styles.table}
          isLoading={isFetching}
          tableHeight={400}
          marginBottom={20}
        />
      </div>
    </>
  );
};

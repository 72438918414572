import React, { useContext, useState, useEffect } from "react";
import { IconButton, Checkbox } from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import styles from "./AddFavouritesModal.module.css";
import { ModalContext } from "contexts/ModalContext";
import { IBranch } from "types/Branch";
import { IMaterial } from "types/Material";
import { addFavourite } from "api/PostFavourites";
import { FavouriteEntity } from "types/Favourite";
import { deleteFavourite } from "api/DeleteFavourite";

interface IProps {
  selectedBranch: IBranch;
  materials: IMaterial[];
  favouriteEntity: FavouriteEntity;
  onClose: () => void;
}

interface IFavourite {
  material: IMaterial;
  isFavourite: boolean;
  selectedBranch: IBranch;
  favouriteEntity: FavouriteEntity;
}

function favouritesForEntity(branch: IBranch, entity: FavouriteEntity) {
  switch (entity) {
    case FavouriteEntity.RAW_STOCK:
      return branch.favouriteRawStockMap;
    case FavouriteEntity.STAFF_FOOD:
      return branch.favouriteStaffFoodMap;
    case FavouriteEntity.STOCK_TRANSFER:
      return branch.favouriteStockTransfer;
    case FavouriteEntity.WASTAGE:
      return branch.favouriteWastageMap;
    case FavouriteEntity.DASHBOARD:
      return branch.favouriteDashboardMap;
    default:
      throw new Error("Unimplemented case " + entity);
  }
}

function displayNameForEntity(entity: FavouriteEntity) {
  switch (entity) {
    case FavouriteEntity.RAW_STOCK:
      return "Stock";
    case FavouriteEntity.STAFF_FOOD:
      return "Staff food";
    case FavouriteEntity.STOCK_TRANSFER:
      return "Out stock";
    case FavouriteEntity.WASTAGE:
      return "Wastage";
    case FavouriteEntity.DASHBOARD:
      return "Dashboard";
    default:
      throw new Error("Unimplemented case " + entity);
  }
}

const Favourite: React.FC<IFavourite> = (props) => {
  const [checked, setChecked] = useState(props.isFavourite);
  const [checkChanged, setCheckChanged] = useState<boolean | null>(null);
  const { selectedBranch, favouriteEntity, material } = props;
  useEffect(() => {
    async function toggleFavourite() {
      const err = checked
        ? await deleteFavourite(selectedBranch, favouriteEntity, material.id)
        : await addFavourite(selectedBranch, favouriteEntity, material.id);
      if (!err) {
        setChecked(!checked);
      }
      // or else show error
    }
    if (checkChanged !== null) {
      toggleFavourite();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkChanged]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckChanged(!checkChanged);
  };
  return (
    <div className={styles.favourite}>
      <Checkbox
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "primary checkbox" }}
      />
      <span className={styles.favouriteName}> {props.material.name} </span>
    </div>
  );
};

export const AddFavouritesModal: React.FC<IProps> = (props) => {
  const modalProps = useContext(ModalContext);
  const { selectedBranch, materials, favouriteEntity } = props;
  const favouritesMap = favouritesForEntity(selectedBranch, favouriteEntity);
  return (
    <>
      <div className={styles.modalTitleBox}>
        <div className={styles.modalTitleText}>
          <>
            <div className={styles.branchName}>{props.selectedBranch.name}</div>
            {`Add Favourites (${displayNameForEntity(favouriteEntity)})`}
          </>
        </div>
        <div className={styles.modalCloseIcon}>
          <IconButton
            name="close"
            color="inherit"
            onClick={() => {
              modalProps.hideModal();
              props.onClose();
            }}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      </div>

      <div className={styles.modalBody}>
        {materials.map((material, index) => (
          <Favourite
            key={index}
            selectedBranch={selectedBranch}
            material={material}
            isFavourite={favouritesMap.has(material.id)}
            favouriteEntity={favouriteEntity}
          />
        ))}
      </div>
    </>
  );
};

import React, { useContext } from "react";
import { ModalWithButton } from "components/modalWithButton/ModalWithButton";
import { AddRawOrClosingMaterialModal } from "components/addRawMaterialModal/AddRawOrClosingMaterialModal";
import { StockType } from "types/StockType";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import {
  MaterialContext,
  IMaterialContextInterface,
} from "contexts/MaterialsContext";
import { useLocalBranch } from "hooks/LocalStorage";
import { IBranch } from "types/Branch";
import ContentPage from "components/contentPage/ContentPage";
import {
  IBranchSelectorProps,
  BranchSelector,
} from "components/branchSelector/BranchSelector";
import styles from "./DashboardForNonAdmin.module.css";
import { AddComplimentaryFoodModal } from "components/addComplimentaryFoodModal/AddComplimentaryFoodModal";
import { AddSalesModal } from "components/addSalesModal/AddSalesModal";
import { AddWastageModal } from "components/addWastageModal/AddWastageModal";
import { AddStaffFoodModal } from "components/addStaffFoodModal/AddStaffFoodModal";
import { AddStockTransferModal } from "components/addStockTransferModal/AddStockTransferModal";
import { isRoleManager, Role } from "types/Employee";
import SalesIcon from "assets/SalesIcon";
import WastageIcon from "assets/WastageIcon";
import StaffFoodIcon from "assets/StaffFoodIcon";
import ComplimentaryFoodIcon from "assets/ComplimentaryFoodIcon";
import StockTransferIcon from "assets/StockTransferIcon";
import ClosingStockIcon from "assets/ClosingStockIcon";
import StockIcon from "assets/StockIcon";

const DashboardForNonAdmin: React.FC<{ role: Role }> = React.memo((props) => {
  const { branches } = useContext(BranchFavouriteContext);
  const materialContextValues = useContext(MaterialContext);
  const [selectedBranch, setSelectedBranch] = useLocalBranch(
    branches.length > 0 ? branches[0] : undefined
  );

  // // TODO handle no branches error
  if (!selectedBranch || materialContextValues.fetchingStatus !== "completed") {
    return <></>;
  }
  return (
    <ContentPage
      header={
        <Header
          branches={branches!}
          selectedBranch={selectedBranch}
          setSelectedBranch={setSelectedBranch}
        />
      }
      mainContent={
        <MainSection
          selectedBranch={selectedBranch}
          materialContextValues={
            materialContextValues.branchWiseMaterialsMap.get(selectedBranch.id)!
          }
          role={props.role}
        />
      }
      rightSection={<></>}
    />
  );
});

const Header: React.FC<IBranchSelectorProps> = (props) => (
  <>
    <div className={styles.pageName}>{"Dashboard"}</div>
    <BranchSelector {...props} />
  </>
);

const MainSection: React.FC<{
  selectedBranch: IBranch;
  materialContextValues: IMaterialContextInterface;
  role: Role;
}> = (props) => (
  <>
    <div className={styles.main}>
      <div className={styles.row}>
        <div className={styles.column}>
          <ModalWithButton title={"Add Raw Material"} icon={<StockIcon />}>
            {" "}
            <AddRawOrClosingMaterialModal
              materialContextValues={props.materialContextValues}
              selectedBranch={props.selectedBranch}
              type={StockType.ADDITION}
            />{" "}
          </ModalWithButton>
        </div>
        <div className={styles.column}>
          <ModalWithButton title={"Add Wastage"} icon={<WastageIcon />}>
            {" "}
            <AddWastageModal
              materialContextValues={props.materialContextValues}
              selectedBranch={props.selectedBranch}
            />{" "}
          </ModalWithButton>
        </div>
        <div className={styles.column}>
          <ModalWithButton title={"Add Staff Food"} icon={<StaffFoodIcon />}>
            {" "}
            <AddStaffFoodModal
              materialContextValues={props.materialContextValues}
              selectedBranch={props.selectedBranch}
            />{" "}
          </ModalWithButton>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <ModalWithButton title={"Add Stock Out"} icon={<StockTransferIcon />}>
            {" "}
            <AddStockTransferModal
              materialContextValues={props.materialContextValues}
              selectedBranch={props.selectedBranch}
            />{" "}
          </ModalWithButton>
        </div>
        <div className={styles.column}>
          <ModalWithButton
            title={"Add Closing Material"}
            icon={<ClosingStockIcon />}
          >
            {" "}
            <AddRawOrClosingMaterialModal
              materialContextValues={props.materialContextValues}
              selectedBranch={props.selectedBranch}
              type={StockType.CLOSING}
            />{" "}
          </ModalWithButton>
        </div>
        <div className={styles.column}>
          {isRoleManager(props.role) && (
            <ModalWithButton title={"Add Sales"} icon={<SalesIcon />}>
              {" "}
              <AddSalesModal
                materialContextValues={props.materialContextValues}
                selectedBranch={props.selectedBranch}
              />{" "}
            </ModalWithButton>
          )}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          {isRoleManager(props.role) && (
            <ModalWithButton
              title={"Add Complimentary Food"}
              icon={<ComplimentaryFoodIcon />}
            >
              {" "}
              <AddComplimentaryFoodModal
                materialContextValues={props.materialContextValues}
                selectedBranch={props.selectedBranch}
              />{" "}
            </ModalWithButton>
          )}
        </div>
      </div>
    </div>
  </>
);

export default DashboardForNonAdmin;

import React, { useContext, useEffect, useState } from "react";
import styles from "./OtherEntryReportTable.module.css";
import {
  Button,
  InputAdornment,
  InputLabel,
  Tab,
  Tabs,
} from "@material-ui/core";
import { useQueryReport } from "hooks/Reports";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePicker } from "@material-ui/pickers";
import Toast, { IToastBasicProps } from "components/Toast/Toast";
import CalendarIcon from "assets/CalendarIcon";
import classNames from "classnames";
import { DataTable } from "components/dataTable/DataTable";
import { BranchFavouriteContext } from "contexts/BranchFavouriteContext";
import { getEntryData } from "lib/ReportUtils";
import { IEntryReportRow } from "types/EntryReport";
import { TicksAndCrossesCell } from "./TicksAndCrossesCell";
import { FMSError } from "types/Error";

export const OtherEntryReportTable: React.FC<{}> = () => {
  const { branches } = useContext(BranchFavouriteContext);
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [datesForQuery, setDatesForQuery] = useState<{
    start: Date;
    end: Date;
  }>({ start: firstDayOfMonth, end: today });

  const [startDate, handleStartDateChange] = useState<MaterialUiPickersDate>(
    datesForQuery.start
  );
  const [endDate, handleEndDateChange] = useState<MaterialUiPickersDate>(
    datesForQuery.end
  );

  const { status, entryReport, isFetching } = useQueryReport(
    "otherEntry",
    datesForQuery.start,
    datesForQuery.end
  );
  const [dateTab, setDateTab] = useState<string[]>([]);
  const [dateWiseEntryReportRows, setDateWiseEntryReportRows] = useState<
    Map<string, IEntryReportRow[]>
  >();
  const [tableData, setTableData] = useState<IEntryReportRow[]>([]);
  useEffect(() => {
    if (status === "success") {
      if (!entryReport || entryReport instanceof FMSError) {
        const message = entryReport?.message ?? "Something went wrong!";
        setShowToast({
          open: true,
          message: message,
          type: "error",
        });
      } else {
        const branchMap = new Map();
        branches.forEach((branch) => branchMap.set(branch.id, branch));
        const otherReports = getEntryData(entryReport, branchMap);
        setDateTab(otherReports.tabs);
        setDateWiseEntryReportRows(otherReports.data);
        // set the table data to first date in the tab
        setTableData(otherReports.data.get(otherReports.tabs[0])!);
      }
    }
  }, [entryReport, branches, status]);

  const handleToastClose = () => {
    setShowToast({
      open: false,
      message: showToast.message,
      type: showToast.type,
    });
  };

  const handleSubmit = () => {
    if (startDate && endDate) {
      setDatesForQuery({ start: startDate, end: endDate });
    }
  };

  const [showToast, setShowToast] = useState<IToastBasicProps>({
    open: false,
    message: "",
    type: "error",
  });

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event: any, newValue: number) => {
    setTabValue(newValue);
    setTableData(dateWiseEntryReportRows?.get(dateTab[newValue])!);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Other Report Table",
        columns: [
          {
            Header: "Branch",
            accessor: "branchName",
            width: 260,
            disableFilters: true,
          },
          {
            Header: "Sales",
            accessor: "sale",
            width: 80,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
          {
            Header: "Complimentary Food",
            accessor: "complimentaryFood",
            width: 190,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
          {
            Header: "Closing Stock",
            accessor: "closing",
            width: 90,
            Cell: (row: any) => {
              return <TicksAndCrossesCell bool={row.value} />;
            },
            disableFilters: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <InputLabel
            style={{
              marginRight: "10px",
            }}
          >
            From:
          </InputLabel>
          <DatePicker
            views={["date"]}
            value={startDate}
            placeholder={"From"}
            onChange={(startDate) => {
              handleStartDateChange(startDate);
            }}
            autoOk={true}
            variant={"inline"}
            disableFuture
            inputVariant="outlined"
            format="MMM dd"
            InputProps={{
              className: classNames(styles.input, styles.widthDates),
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          />
          <InputLabel
            style={{
              marginRight: "10px",
              marginLeft: "10px",
            }}
          >
            To:
          </InputLabel>
          <DatePicker
            views={["date"]}
            value={endDate}
            placeholder={"To"}
            onChange={(endDate) => {
              handleEndDateChange(endDate);
            }}
            autoOk={true}
            variant={"inline"}
            disableFuture
            inputVariant="outlined"
            format="MMM dd"
            InputProps={{
              className: classNames(styles.input, styles.widthDates),
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            className={styles.submitButton}
            style={{
              marginLeft: "10px",
            }}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>

        <Toast
          open={showToast.open}
          message={showToast.message}
          type={showToast.type}
          onClose={handleToastClose}
        />
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="on"
          onChange={handleChange}
        >
          {dateTab.map((date) => (
            <Tab label={date} style={{ minWidth: "12%" }} />
          ))}
        </Tabs>

        <DataTable
          columns={columns}
          data={tableData}
          tableClass={styles.table}
          isLoading={isFetching}
          tableHeight={400}
          marginBottom={20}
        />
      </div>
    </>
  );
};

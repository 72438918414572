import { get, BACKEND_URL_V1, BACKEND_URL_V2 } from "lib/Api";
import { FMSError } from "types/Error";
import { IStockTransfersByBranch } from "types/StockTransfer";
import { formatDate } from "lib/Date";
import { downloadFile } from "lib/FileUtils";
import { Status } from "types/Status";

interface IStockTransfers {
  stockTransfersList: IStockTransfersByBranch[];
}

export const getStockTransfers = async (
  start: string,
  end: string
): Promise<IStockTransfersByBranch[] | FMSError> => {
  try {
    const response = await get<IStockTransfers>(
      `${BACKEND_URL_V2}/stockTransfers?start=${start}&end=${end}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.stockTransfersList) {
        return response.parsedBody.stockTransfersList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock transfers could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getPendingStockTransfers = async (): Promise<
  IStockTransfersByBranch[] | FMSError
> => {
  try {
    const response = await get<IStockTransfers>(
      `${BACKEND_URL_V2}/stockTransfers?status=${Status.PENDING}`
    );
    if (Math.floor(response.status / 100) === 2) {
      if (response.parsedBody?.stockTransfersList) {
        return response.parsedBody.stockTransfersList;
      }

      return [];
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock transfers could not be retrieved";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

export const getStockTransferUploadTemplate = async (
  branchId: string,
  date: Date
): Promise<FMSError | null> => {
  try {
    const response = await get(
      `${BACKEND_URL_V1}/stockTransfers/branches/${branchId}/uploadReportFormat?date=${formatDate(
        date
      )}`,
      false
    );

    if (Math.floor(response.status / 100) === 2) {
      downloadFile(
        response.headers,
        await response.blob(),
        "Stock_Tranfer_Template.xlsx"
      );
      return null;
    }

    const errorMessage =
      response.serverError?.error.message ??
      "Stock transfer template could not be downloaded";
    return new FMSError(errorMessage);
  } catch (err) {
    console.log(err);
    return new FMSError("Something went wrong");
  }
};

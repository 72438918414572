import { BACKEND_URL_V1, deletee } from "lib/Api";
import { FMSError } from "types/Error";
import { IBranch } from "types/Branch";
import { FavouriteEntity } from "types/Favourite";

export const deleteFavourite = async (
  branch: IBranch,
  favouriteEntity: FavouriteEntity,
  materialId: string
): Promise<FMSError | null> => {
  try {
    const response = await deletee(
      `${BACKEND_URL_V1}/branches/${branch.id}/favourites/${favouriteEntity}/materials/${materialId}`
    );
    if (response.status === 202) {
      return null;
    }
  } catch (err) {
    // Change error state after http change
    return new FMSError("Something went wrong");
  }
  return new FMSError("Something went wrong");
};
